import { DeleteOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { isNull, isSome } from '@salescore/buff-common'
import {
  CreateSlackOrgIntegrationDocument,
  CreateSlackUserIntegrationDocument,
  DeleteSlackOrgIntegrationDocument,
  DeleteSlackUserIntegrationDocument,
  FetchSlackBotJoinedChannelDocument,
  FetchSlackChannelsDocument,
  FetchSlackOrgIntegrationDocument,
  FetchSlackOrgIntegrationUrlDocument,
  FetchSlackUserIntegrationDocument,
  FetchSlackUserIntegrationUrlDocument,
  JoinSlackBotToChannelDocument,
  type SlackChannel,
  UpdateSlackOrgIntegrationDocument,
  UpdateSlackUserIntegrationDocument,
} from '@salescore/client-api'
import { setRedirectPathToLocalStorage } from '@salescore/client-common'
import { App, Button, Card, Popover, Select, Typography } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { z } from 'zod'

// eslint-disable-next-line complexity
export function SlackIntegrationForm({ isOrg, organizationId }: { isOrg: boolean; organizationId: string }) {
  const channelsQuery = useQuery(FetchSlackChannelsDocument, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'network-only',
  })
  const [allSlackChannels, setAllSlackChannels] = useState<SlackChannel[]>([])
  const slackOrgIntegrationUrlQuery = useQuery(FetchSlackOrgIntegrationUrlDocument, {
    variables: {
      organizationId,
    },
  })
  const slackUserIntegrationUrlQuery = useQuery(FetchSlackUserIntegrationUrlDocument, {
    variables: {
      organizationId,
    },
  })
  const [createSlackOrgIntegration] = useMutation(CreateSlackOrgIntegrationDocument)
  const [createSlackUserIntegration] = useMutation(CreateSlackUserIntegrationDocument)
  const slackOrgIntegrationQuery = useQuery(FetchSlackOrgIntegrationDocument, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'network-only',
  })

  const slackUserIntegrationQuery = useQuery(FetchSlackUserIntegrationDocument, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'network-only',
  })
  const [updateSlackOrgIntegration] = useMutation(UpdateSlackOrgIntegrationDocument)
  const [updateSlackUserIntegration] = useMutation(UpdateSlackUserIntegrationDocument)
  const slackBotJoinedChannelQuery = useQuery(FetchSlackBotJoinedChannelDocument, {
    variables: {
      organizationId,
      channelId: slackOrgIntegrationQuery.data?.slackOrgIntegration?.defaultChannelId,
    },
  })
  const [joinSlackBotToChannel] = useMutation(JoinSlackBotToChannelDocument)
  const [deleteSlackOrgIntegration] = useMutation(DeleteSlackOrgIntegrationDocument)
  const [deleteSlackUserIntegration] = useMutation(DeleteSlackUserIntegrationDocument)
  const [options, setOptions] = useState<SlackChannel[]>([])
  const { message, modal } = App.useApp()

  async function callCreateSlackIntegration(code: string, organizationId: string) {
    try {
      if (isOrg) {
        await createSlackOrgIntegration({
          variables: {
            slackOrgIntegrationCreateInput: {
              authCode: code,
              organizationId,
            },
          },
        })
        message.info(t('Slack 組織連携が正常に完了しました。'))
        await slackOrgIntegrationQuery.refetch()
      } else {
        await createSlackUserIntegration({
          variables: {
            slackUserIntegrationCreateInput: {
              authCode: code,
              organizationId,
            },
          },
        })
        message.info(t('Slack 個人連携が正常に完了しました。'))
        await slackUserIntegrationQuery.refetch()
      }
    } catch (error) {
      message.error(t('Slack 連携に失敗しました。{{error}}', { error }))
    }
  }

  useEffect(() => {
    const parameters = new URLSearchParams(window.location.search)
    const oauthCode = parameters.get('oauthCode')
    const provider = parameters.get('provider')
    // クエリパラメータが残っていると意図しない挙動につながるため、クリアする
    window.history.replaceState(null, '', window.location.pathname)
    if (oauthCode !== null && provider === 'slack') {
      void callCreateSlackIntegration(oauthCode, organizationId)
    }
  }, [window.location.search])

  useEffect(() => {
    void channelsQuery.refetch()
  }, [slackOrgIntegrationQuery.data?.slackOrgIntegration])

  useEffect(() => {
    setAllSlackChannels(channelsQuery.data?.slackChannels ?? [])
    setOptions(channelsQuery.data?.slackChannels ?? [])
  }, [channelsQuery.data?.slackChannels])

  return (
    <Card className="mb-8">
      <div className="flex flex-col gap-4">
        <Typography.Title level={4}>
          {isOrg ? t(`メッセージサービス組織連携`) : t(`メッセージサービス個人連携`)}
        </Typography.Title>

        <div className="flex items-center justify-between">
          <div className="flex items-start gap-2">
            <a
              onClick={(e) => {
                setRedirectPathToLocalStorage(window.location.pathname)
              }}
              href={
                isOrg
                  ? slackOrgIntegrationUrlQuery.data?.slackOrgIntegrationUrl
                  : slackUserIntegrationUrlQuery.data?.slackUserIntegrationUrl
              }
            >
              <img
                alt="Add to Slack"
                height="40"
                width="139"
                src="https://platform.slack-edge.com/img/add_to_slack.png"
                srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
              />
            </a>
            {(isOrg
              ? isSome(slackOrgIntegrationQuery.data?.slackOrgIntegration)
              : isSome(slackUserIntegrationQuery.data?.slackUserIntegration)) && (
              <Button
                type="text"
                icon={<DeleteOutlined />}
                size="middle"
                onClick={() => {
                  if (isOrg) {
                    modal.confirm({
                      title: t('Slack 組織連携の削除'),
                      content: t(
                        'Slack 組織連携を削除すると、 SALESCORE から Slack への投稿がすべてできなくなります。よろしいですか？',
                      ),
                      onOk: async () => {
                        await deleteSlackOrgIntegration({
                          variables: {
                            slackOrgIntegrationDeleteInput: {
                              organizationId,
                            },
                          },
                        })
                        message.info(t('Slack 組織連携を削除しました。'))
                        await slackOrgIntegrationQuery.refetch()
                      },
                    })
                  } else {
                    modal.confirm({
                      title: t('Slack 個人連携の削除'),
                      content: t(
                        'Slack 個人連携を削除すると、 SALESCORE 上でのメンションを Slack で受けとることができなくなります。よろしいですか？',
                      ),
                      onOk: async () => {
                        await deleteSlackUserIntegration({
                          variables: {
                            slackUserIntegrationDeleteInput: {
                              organizationId,
                            },
                          },
                        })
                        message.info(t('Slack 個人連携を削除しました。'))
                        await slackUserIntegrationQuery.refetch()
                      },
                    })
                  }
                }}
              />
            )}
          </div>
          {isSome(slackOrgIntegrationQuery.data?.slackOrgIntegration) &&
            (isOrg || isSome(slackUserIntegrationQuery.data?.slackUserIntegration)) && (
              <Popover
                content={t(`Slack ボットをチャネルに参加させると、そのチャネルでの返信が SALESCORE に反映されます。`)}
              >
                <Button
                  type="primary"
                  disabled={
                    isNull(
                      isOrg
                        ? slackOrgIntegrationQuery.data.slackOrgIntegration.defaultChannelId
                        : slackUserIntegrationQuery.data?.slackUserIntegration?.defaultChannelId,
                    ) ||
                    (slackBotJoinedChannelQuery.data?.slackBotJoinedChannel.status ?? false)
                  }
                  onClick={async () => {
                    const joinSlackBotToChannelResponse = await joinSlackBotToChannel({
                      variables: {
                        joinSlackBotToChannelMutationInput: {
                          organizationId,
                          defaultChannelId: isOrg
                            ? slackOrgIntegrationQuery.data?.slackOrgIntegration?.defaultChannelId
                            : slackUserIntegrationQuery.data?.slackUserIntegration?.defaultChannelId,
                        },
                      },
                    })
                    await slackBotJoinedChannelQuery.refetch()
                    switch (joinSlackBotToChannelResponse.data?.joinSlackBotToChannel.status) {
                      case 'SLACK_BOT_ALREADY_JOINED_TO_CHANNEL': {
                        message.info(t('Slack ボットは既にチャネルに参加しています。'))
                        break
                      }
                      case 'SLACK_BOT_JOINED_TO_CHANNEL': {
                        message.info(t('Slack ボットがチャネルに参加しました。'))
                        break
                      }
                      default: {
                        message.error(
                          t('Slack ボットがチャネルに参加できませんでした。{{status}}', {
                            status: joinSlackBotToChannelResponse.data?.joinSlackBotToChannel.status,
                          }),
                        )
                        break
                      }
                    }
                  }}
                  loading={slackBotJoinedChannelQuery.loading}
                >
                  {(slackBotJoinedChannelQuery.data?.slackBotJoinedChannel.status ?? false)
                    ? t(`Slack ボットはチャネルに参加しています`)
                    : t(`Slack ボットをチャネルに参加させる`)}
                </Button>
              </Popover>
            )}
        </div>
      </div>
      {isSome(slackOrgIntegrationQuery.data?.slackOrgIntegration) ? (
        !isOrg && !isSome(slackUserIntegrationQuery.data?.slackUserIntegration) ? (
          <div className="flex items-center justify-between">
            <Typography.Text type="secondary">
              {t(
                'Slack 個人連携が完了すると、 SALESCORE 上のコメントでメンションされた際に Slack でも通知が受け取れるようになります。',
              )}
            </Typography.Text>
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <Typography.Text type="secondary">
              {t('SALESCORE 上でスレッドを作った際にコメントが投稿される Slack チャネル')}
            </Typography.Text>
            <Select
              className="w-1/2"
              showSearch
              filterOption={false}
              filterSort={(a, b) => a.label.toString().localeCompare(b.label.toString())}
              disabled={allSlackChannels.length === 0}
              placeholder={t('デフォルトチャネル')}
              onClick={async () => {
                if (allSlackChannels.length === 0) {
                  const channels = await channelsQuery.refetch()
                  setAllSlackChannels(channels.data.slackChannels ?? [])
                  setOptions(channels.data.slackChannels ?? [])
                }
              }}
              onSearch={(value) => {
                const filteredOptions = allSlackChannels.filter((channel) => channel.name.includes(value))
                setOptions(filteredOptions)
              }}
              onChange={async (value) => {
                if (!z.string().safeParse(value).success) {
                  return
                }
                if (isOrg) {
                  await updateSlackOrgIntegration({
                    variables: {
                      slackOrgIntegrationUpdateInput: {
                        organizationId,
                        defaultChannelId: value,
                      },
                    },
                  })
                  message.info(t('Slack 組織連携のデフォルトチャネルを更新しました。'))
                } else {
                  await updateSlackUserIntegration({
                    variables: {
                      slackUserIntegrationUpdateInput: {
                        organizationId,
                        defaultChannelId: value,
                      },
                    },
                  })
                  message.info(t('Slack 個人連携のデフォルトチャネルを更新しました。'))
                }
              }}
              options={options.map((channel) => ({
                label: channel.name,
                value: channel.id,
              }))}
              loading={channelsQuery.loading || slackOrgIntegrationQuery.loading || slackUserIntegrationQuery.loading}
              value={
                isOrg
                  ? slackOrgIntegrationQuery.data.slackOrgIntegration.defaultChannelId
                  : slackUserIntegrationQuery.data?.slackUserIntegration?.defaultChannelId
              }
            />
          </div>
        )
      ) : (
        <div className="flex items-center justify-between">
          <Typography.Text type="secondary">
            {t(
              'Slack 組織連携が完了すると、 SALESCORE 上でコメントした際に Slack にもコメントが投稿されるようになります。',
            )}
          </Typography.Text>
        </div>
      )}
    </Card>
  )
}
