import { type ApolloClient, useApolloClient } from '@apollo/client'
import { FetchViewSearchSqlResultDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import type { ViewQueryRecordNode } from '@salescore/core'
import { logger, TimeWindowExecutor } from '@salescore/frontend-common'
import { message } from 'antd'
import { t } from 'i18next'

import { compileSearchSql, type SearchResultRow } from '../../domain/service/generateSearchSql'

export function searchRelation(client: ApolloClient<object>, organizationId: string) {
  return async (
    searchSql: string | undefined,
    searchKeyword: string,
    recordNode: ViewQueryRecordNode | undefined = undefined,
    parentRecordNode: ViewQueryRecordNode | undefined = undefined,
  ): Promise<
    | {
        values: SearchResultRow[]
        sql: string
      }
    | undefined
  > => {
    if (searchSql === undefined) {
      return undefined
    }

    const sql = compileSearchSql(searchSql, searchKeyword, recordNode, parentRecordNode)

    logger.debug(`${t(`検索中`)}...`, {
      sql,
      recordNode,
      parentRecordNode,
      searchSql,
      searchKeyword,
    })
    const { data } = await client.query({
      query: FetchViewSearchSqlResultDocument,
      variables: {
        organizationId,
        sql,
      },
    })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    const rows = (data.viewSearchSqlResult.rows ?? []) as SearchResultRow[]
    return {
      values: rows.uniqueBy((x) => x.value),
      sql,
    }
  }
}

interface Option {
  value: string
  label: string
}

export const useSearchQueue = ({
  setLoading,
  setOptions,
  setSql,
}: {
  setLoading: (loading: boolean) => void
  setOptions: (options: Option[]) => void
  setSql?: (sql: string) => void
}) => {
  const client = useApolloClient()

  const search = async (
    searchSql: string,
    searchKey: string,
    recordNode?: ViewQueryRecordNode,
    parentRecordNode?: ViewQueryRecordNode,
  ) => {
    try {
      setLoading(true)
      const result = await searchRelation(client, getOrganizationIdFromPath())(
        searchSql,
        searchKey,
        recordNode,
        parentRecordNode,
      )
      const options = result?.values ?? []
      setOptions(options)
      if (setSql !== undefined) {
        setSql(result?.sql ?? '')
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        void message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }
  const executor = new TimeWindowExecutor(search, 400)

  const queueSearch = (
    sql: string,
    key: string,
    recordNode?: ViewQueryRecordNode,
    parentRecordNode?: ViewQueryRecordNode,
  ) => {
    executor.queue([sql, key, recordNode, parentRecordNode])
  }

  return {
    queueSearch,
    search,
  }
}
