import { useSuspenseQuery } from '@apollo/client'
import { isNull, isSome } from '@salescore/buff-common'
import { FetchOrganizationSettingDocument, type OrganizationSettingFieldsFragment } from '@salescore/client-api'
import { Posthog, type PosthogEvent } from '@salescore/client-base'
import { DatePicker, Select } from 'antd'
import dayjs, { type Dayjs } from 'dayjs'
import { t } from 'i18next'
import { type Dispatch, type ReactNode, type SetStateAction, useEffect, useState } from 'react'

import { getOrganizationIdFromPath } from '../presentation/common/auth'
import {
  addPeriodNameToCache,
  getPeriodsForDashboard,
  getPeriodsForGoalView,
  getPeriodsForKpiTimeSeriesKpiDateX,
  getPeriodsForKpiTimeSeriesKpiDateY,
} from '../presentation/common/constant/periods'
import { HandleQuery } from '../presentation/common/handleQuery'

export interface Period {
  startAt?: Dayjs
  endAt?: Dayjs
  name: string
  nameLabel?: string
  groupName?: string
  groupNameLabel?: string
}

type PickerType = 'dashboard' | 'goal' | 'kpiTimeSeriesKpiDateX' | 'kpiTimeSeriesKpiDateY'

interface Argument {
  pickerType: PickerType
  setPeriod: Dispatch<SetStateAction<Period>>
  selectedPeriod?: Period
  disabled?: boolean
  posthogEventName?: PosthogEvent
  withInitialization?: boolean // デフォルトで初期化
  initialPeriodName?: string
  stretch?: boolean
  width?: number
}

export const getPeriods = (pickerType: PickerType, setting: OrganizationSettingFieldsFragment): Period[] => {
  switch (pickerType) {
    case 'dashboard': {
      return getPeriodsForDashboard(setting)
    }
    case 'goal': {
      return getPeriodsForGoalView(setting)
    }
    case 'kpiTimeSeriesKpiDateX': {
      return getPeriodsForKpiTimeSeriesKpiDateX(setting)
    }
    case 'kpiTimeSeriesKpiDateY': {
      return getPeriodsForKpiTimeSeriesKpiDateY(setting)
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      return getPeriodsForDashboard(setting)
    }
  }
}

const PeriodPickerBody = ({
  setPeriod,
  pickerType,
  selectedPeriod,
  disabled,
  posthogEventName,
  withInitialization,
  initialPeriodName,
  stretch,
  width,
}: Argument): ReactNode => {
  const {
    data: {
      organization: { setting },
    },
  } = useSuspenseQuery(FetchOrganizationSettingDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })

  const periods = getPeriods(pickerType, setting)
  const [customPickerType, setCustomPickerType] = useState<'date' | 'month' | 'quarter' | undefined>()

  const onChange = (selectedValue: string): void => {
    if (selectedValue.startsWith('custom_')) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      setCustomPickerType(selectedValue.replace('custom_', '') as 'date')
      if (selectedValue === 'custom_month') {
        setPeriod({
          ...selectedPeriod,
          startAt: selectedPeriod?.startAt?.startOf('month') ?? dayjs().startOf('month'),
          endAt: selectedPeriod?.endAt?.endOf('month') ?? dayjs().endOf('month'),
          name: 'カスタム(月)',
          nameLabel: t(`カスタム(月)`),
        })
      } else {
        setPeriod({
          ...selectedPeriod,
          name: 'カスタム',
          nameLabel: t(`カスタム`),
        })
      }
      return
    }
    setCustomPickerType(undefined)

    const newPeriod = periods.find((x) => x.name === selectedValue)
    if (!newPeriod) {
      return
    }

    setPeriod(newPeriod)
    addPeriodNameToCache(newPeriod.name)
    Posthog.track(posthogEventName ?? `click-periodSelector`, {
      organizationId: getOrganizationIdFromPath(),
      value: newPeriod.name,
    })
  }

  const groupNames = periods
    .map((x) => ({ groupName: x.groupName, groupNameLabel: x.groupNameLabel }))
    .uniqueBy((x) => x.groupName) // 非破壊操作になっているので、この順番で表示する

  useEffect(() => {
    if (isNull(selectedPeriod) && withInitialization !== false) {
      const initialPeriod =
        initialPeriodName === undefined ? periods[0] : (periods.find((v) => v.name === initialPeriodName) ?? periods[0])
      if (initialPeriod !== undefined) {
        setPeriod(initialPeriod)
      }
    }
  }, [])

  useEffect(() => {
    if (selectedPeriod?.name === 'カスタム(月)') {
      setCustomPickerType('month')
    } else if (selectedPeriod?.name.includes('カスタム') === true) {
      setCustomPickerType('date')
    } else {
      setCustomPickerType(undefined)
    }
  }, [selectedPeriod])

  return (
    <>
      <Select
        defaultValue={selectedPeriod?.name}
        value={selectedPeriod?.name}
        style={{ width: width ?? 170, flex: stretch === true ? '1' : undefined }}
        onChange={onChange}
        disabled={disabled}
      >
        {groupNames.map((groupName) => (
          <Select.OptGroup label={groupName.groupNameLabel} key={groupName.groupName}>
            {periods
              .filter((period) => period.groupName === groupName.groupName)

              .map((period) => {
                if (period.name === 'カスタム') {
                  return (
                    <Select.Option key={`custom-${period.name}`} value={'custom_date'}>
                      {t(`カスタム`)}
                    </Select.Option>
                  )
                }
                if (period.name === 'カスタム(日)') {
                  return (
                    <Select.Option key={`custom-day-${period.name}`} value={'custom_date'}>
                      {t(`カスタム(日)`)}
                    </Select.Option>
                  )
                }
                if (period.name === 'カスタム(月)') {
                  return (
                    <Select.Option key={`custom-month-${period.name}`} value={'custom_month'}>
                      {t(`カスタム(月)`)}
                    </Select.Option>
                  )
                }
                if (period.name === 'カスタム(Q)') {
                  return (
                    <Select.Option key={`custom-q-${period.name}`} value={'custom_quarter'}>
                      {t(`カスタム(Q)`)}
                    </Select.Option>
                  )
                }

                return (
                  <Select.Option value={period.name} key={`${groupName.groupName ?? ''}-${period.name}`}>
                    {period.nameLabel}
                  </Select.Option>
                )
              })}
          </Select.OptGroup>
        ))}
      </Select>
      {isSome(customPickerType) && (
        <DatePicker.RangePicker
          value={[dayjs(selectedPeriod?.startAt?.toISOString()), dayjs(selectedPeriod?.endAt?.toISOString())]}
          className="ml-2"
          picker={customPickerType}
          onChange={(rangeValue) => {
            if (!rangeValue) {
              return
            }
            let startAt = rangeValue[0]
            let endAt = rangeValue[1]
            if (!startAt || !endAt) {
              return
            }
            if (customPickerType !== 'date') {
              startAt = startAt.startOf('month')
              endAt = endAt.endOf('month')
            }
            // i18n: ロジックに含まれるため対応を保留
            setPeriod({
              startAt: dayjs(startAt.format('YYYY-MM-DD')),
              endAt: dayjs(endAt.format('YYYY-MM-DD')),
              name: customPickerType === 'month' ? 'カスタム(月)' : 'カスタム',
              nameLabel: customPickerType === 'month' ? t(`カスタム(月)`) : t(`カスタム`),
              groupName: 'よく使う',
              groupNameLabel: t(`よく使う`),
            })
          }}
        />
      )}
    </>
  )
}

export const PeriodPicker = (argument: Argument): ReactNode => (
  <HandleQuery loadingElement={<Select loading={true} />}>
    <PeriodPickerBody {...argument} />
  </HandleQuery>
)
