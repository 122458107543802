import { type ApolloClient, useApolloClient } from '@apollo/client'
import type { ViewQueryList } from '@salescore/core'
import { useSetRecoilState } from 'recoil'

import type { OnAfterChange } from '../../../recoil/records/mutations/addNewRecords'
import type { RelationChange } from '../../../recoil/records/mutations/updateRecordNodeRelations'
import type { RecordChange } from '../../../recoil/records/mutations/upsertViewRecords'
import { upsertViewRecordsMutation } from '../../../recoil/records/mutations/upsertViewRecordsMutation'
import { useQueryValue } from '../../../recoil/view/hooks'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import { searchRelation } from './searchRelation'

export interface RowChange {
  rowIndex: number
  innerRowIndex: number
  column: RSheetColumn<RSheetRecordNode>
  value: unknown
  label?: string
  isDelete?: boolean
  isDeleteAllChildren?: boolean
}

async function generateRecordChangeAndRelationChange(
  rowChange: RowChange,
  client: ApolloClient<object>,
  query: ViewQueryList,
) {
  const { rowIndex, column, innerRowIndex, value, label, isDelete, isDeleteAllChildren } = rowChange
  const { field } = column
  if (field === undefined) {
    // システム系カラムのはず、かつシステム系カラムは別で出し分けしているためここには到達しないはず
    return
  }

  const recordChange: RecordChange = {
    rowIndex,
    isNestedColumn: field.nodePath.length > 1,
    nodePath: field.nodePath,
    innerRowIndex,
    field,
    value,
    label,
    isDelete,
    isDeleteAllChildren,
  }
  const relationChange = await searchRelation({ recordChange, client, query })
  return {
    recordChange,
    relationChange,
  }
}

export const useUpsertSheetRowMutation = () => {
  const upsertViewRecords = useSetRecoilState(upsertViewRecordsMutation)
  const client = useApolloClient()
  const query = useQueryValue()

  return async (rowChange: RowChange, option?: { onAfterChange?: OnAfterChange }) => {
    const recordChangeAndRelationChange = await generateRecordChangeAndRelationChange(rowChange, client, query)
    if (recordChangeAndRelationChange === undefined) {
      return
    }
    const { recordChange, relationChange } = recordChangeAndRelationChange
    upsertViewRecords({
      recordChanges: [recordChange],
      relationChanges: [relationChange].compact(),
      onAfterChange: option?.onAfterChange,
    })
  }
}

export const useUpsertSheetRowsMutation = () => {
  const upsertViewRecords = useSetRecoilState(upsertViewRecordsMutation)
  const client = useApolloClient()
  const query = useQueryValue()

  return async (rowChanges: RowChange[], option?: { onAfterChange?: OnAfterChange }) => {
    const recordChanges: RecordChange[] = []
    const relationChanges: RelationChange[] = []
    const recordChangeAndRelationChangePromises = rowChanges.map(
      async (rowChange) => await generateRecordChangeAndRelationChange(rowChange, client, query),
    )
    const results = await Promise.allSettled(recordChangeAndRelationChangePromises)
    for (const result of results.compact()) {
      if (result.status === 'rejected' || result.value === undefined) {
        continue
      }
      const { recordChange, relationChange } = result.value
      recordChanges.push(recordChange)
      if (relationChange === undefined) {
        continue
      }
      relationChanges.push(relationChange)
    }
    upsertViewRecords({
      recordChanges,
      relationChanges,
      onAfterChange: option?.onAfterChange,
    })
  }
}
