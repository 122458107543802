import { Either, pipe } from 'effect'

import { getPseudoRootNodes, type PasteRelatedData } from '../recoil/mutations/paste/getRowsAndColumns'
import { getArea } from '../recoil/mutations/useCursorMutation'
import type { RSheetColumn, RSheetRecordNode } from '../types'
import type { RSheetsCursorPosition } from '../types/CursorTypes'
import { getRecordNodesWithInnerRowIndex, type RecordNodeWithInnerRowIndex } from '../util/innerRowRelated'

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type CellValue = unknown | unknown[]
type ValuesPerColumn = CellValue[]

const transpose = (a: unknown[][]) => a[0]!.map((_, c) => a.map((r) => r[c]))

function convertValueSlicesToText<T extends RSheetRecordNode>(
  columns: Array<RSheetColumn<T>>,
  rows: ValuesPerColumn[],
) {
  // 全てのカラムが同じノードのとき、単純な行列としてコピーする
  if (columns.map((x) => x.node.path.join(',')).unique().length === 1) {
    const columnValues = columns.map((_, columnIndex) => rows.map((row) => row[columnIndex]))
    return transpose(columnValues)
  }

  // カラム数1のとき
  // TODO: このケースは上でカバーされているはずで、このケースでプログラムが走ることはないはず。不要とはっきりわかったら取り除く。
  if (columns.length === 1) {
    // 選択されたカラムがネストしたカラムだった場合は、flattenして返す
    if (columns[0]!.node.path.length > 1) {
      // eslint-disable-next-line unicorn/no-magic-array-flat-depth
      const flattenRows = rows.every((row) => Array.isArray(row[0])) ? rows.flat(2).map((x) => [x]) : rows
      return flattenRows.map((row) => row.map((columnValue) => convertCopiedValueToStringValue(columnValue)))
    }
    // そうでないとき、普通にrowsをstringに変換してから返す
    return rows.map((row) => row.map((columnValue) => convertCopiedValueToStringValue(columnValue)))
  }

  // カラム数が2以上で、ネストしたカラムを含んでいる場合、ネストしたカラムはJSON値にした上で返す
  return rows.map((row) =>
    row.map((columnValue: CellValue, index) => {
      const column = columns[index]
      if (column === undefined) {
        return convertCopiedValueToStringValue(columnValue)
      }
      if (Array.isArray(columnValue) && column.node.path.length > 1) {
        return JSON.stringify(columnValue.map((x) => convertCopiedValueToStringValue(x)))
      }
      return convertCopiedValueToStringValue(columnValue)
    }),
  )
}

function convertCopiedValueToStringValue(x: unknown): string {
  if (x === null || x === undefined) {
    return ''
  }
  if (Array.isArray(x)) {
    return JSON.stringify(x)
  }
  if (typeof x === 'string') {
    if (x.includes(`\n`)) {
      return JSON.stringify(x)
    }
    return x
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  return x as string
}

function getShallowestLevel(columns: Array<RSheetColumn<RSheetRecordNode>>): Either.Either<number, string> {
  return pipe(
    columns.map((column) => column.node.path.length).min(),
    Either.fromNullable(() => 'failed to get shallowest level'),
  )
}

// eslint-disable-next-line @typescript-eslint/max-params
function filterByInnerIndices(
  nodesWithIndex: RecordNodeWithInnerRowIndex[],
  numberSelectedRows: number,
  rowIndex: number,
  upInnerRowIndex: number,
  downInnerRowIndex: number,
): RecordNodeWithInnerRowIndex[] {
  if (numberSelectedRows === 1) {
    return nodesWithIndex.filter(
      (n) => n.innerRowIndexStart >= upInnerRowIndex && n.innerRowIndexStart < downInnerRowIndex,
    )
  }
  if (rowIndex === 0) {
    return nodesWithIndex.filter((n) => n.innerRowIndexStart >= upInnerRowIndex)
  }
  if (rowIndex === numberSelectedRows - 1) {
    return nodesWithIndex.filter((n) => n.innerRowIndexStart < downInnerRowIndex)
  }
  return nodesWithIndex
}

export function getPasteRelatedData(
  data: RSheetRecordNode[],
  columns: Array<RSheetColumn<RSheetRecordNode>>,
  cursor: {
    main: RSheetsCursorPosition
    expand?: RSheetsCursorPosition
  },
): PasteRelatedData {
  const { left, right, up, down, upInnerRowIndex, downInnerRowIndex } = getArea(
    cursor.main,
    cursor.expand ?? cursor.main,
  )
  const selectedRows = data.slice(up, down + 1)
  const selectedColumns = columns.slice(left, right + 1)
  const pseudoRootNodes = getPseudoRootNodes(selectedColumns.map((column) => column.node))
  return {
    rows: selectedRows,
    columns: selectedColumns,
    area: {
      leftColumnIndex: left,
      rightColumnIndex: right,
      upInnerRowIndex,
      downInnerRowIndex: downInnerRowIndex + 1, // endのindexは、次のセルのindex。
      upRowIndex: up,
      downRowIndex: down,
    },
    pseudoRootNodes,
  }
}

const convertTreeToTable = ({
  rows: selectedRows,
  columns: selectedColumns,
  area: { upInnerRowIndex, downInnerRowIndex },
}: PasteRelatedData): Either.Either<
  {
    selectedColumns: Array<RSheetColumn<RSheetRecordNode>>
    selectedRowValues: ValuesPerColumn[]
  },
  string
> =>
  pipe(
    getShallowestLevel(selectedColumns),
    Either.map((right) => {
      const selectedRowValues = selectedRows.map(
        (row, rowIndex): ValuesPerColumn =>
          selectedColumns.map((column): CellValue => {
            const nodesWithIndex = getRecordNodesWithInnerRowIndex(row, column.node)
            const targetNodes =
              column.node.path.length === right
                ? filterByInnerIndices(
                    nodesWithIndex,
                    selectedRows.length,
                    rowIndex,
                    upInnerRowIndex,
                    downInnerRowIndex,
                  )
                : nodesWithIndex // `column` がコピー領域の中で最上位のカラムでない場合、すべてのレコードをコピー対象とする
            return (
              targetNodes
                // eslint-disable-next-line max-nested-callbacks
                .map((x) => x.recordNode)
                // eslint-disable-next-line max-nested-callbacks
                .map((x) => (x === undefined ? null : (column.label ?? column.value)(x)))
            )
          }),
      )
      return { selectedRowValues, selectedColumns }
    }),
  )

export function sliceCellValuesInText(pasteRelatedData: PasteRelatedData): Either.Either<unknown[][], string> {
  return pipe(
    convertTreeToTable(pasteRelatedData),
    Either.map(({ selectedColumns, selectedRowValues }) =>
      convertValueSlicesToText(selectedColumns, selectedRowValues),
    ),
  )
}

export const copy = (
  data: RSheetRecordNode[],
  columns: Array<RSheetColumn<RSheetRecordNode>>,
  cursor: {
    main: RSheetsCursorPosition
    expand?: RSheetsCursorPosition
  },
): Either.Either<{ json: PasteRelatedData; text: string }, string> => {
  const pasteRelatedData = getPasteRelatedData(data, columns, cursor)
  return pipe(
    sliceCellValuesInText(pasteRelatedData),
    Either.map((right) => ({
      text: right
        .map((value) => value.join('\t'))
        .join('\n')
        .replaceAll('\r', ''), // \rがあると各所で問題になる？ので注意。この挙動を変えるのであればusePaste周りも変更すること。
      json: pasteRelatedData,
    })),
  )
}
