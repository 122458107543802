// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { isNull, isSome, isTruthy, normalizeZodSafeParseResult, treeUtil } from '@salescore/buff-common'
import {
  CORE_CONSTANT,
  type ModelSearcher,
  nodePropertyNameSchema,
  presetFunctions,
  viewConfigKpiSchema,
  type ViewConfigSheet,
  ViewConfigSheetValueObject,
  type ViewConfigTreeNode,
  type ViewKpiAppearance,
} from '@salescore/core'
import { mutation } from '@salescore/frontend-common'
import { t } from 'i18next'
import { useEffect } from 'react'
import { DefaultValue, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { z } from 'zod'

import { riDisplayFormatAtom } from '../../navigation/atoms'
import {
  kpiFormAtom,
  kpiFormCallbackFlagsAtom,
  kpiFormModifiedAtom,
  kpiFormStepAtom,
  riFormAtom,
  viewsContextAtom,
} from '../atoms'
import {
  findSameReferenceToChild,
  type ReferenceToPropertyForAddChildNodeMutation,
} from '../mutations/tree/addChildNode'
import { modelSearcherSelector } from './modelSearcherSelector'
import { viewsRelatedSelector } from './viewsRelatedSelector'

const prefix = `view/kpiForm`
const rootModelSelectOptionsSelector = selector({
  key: `${prefix}/rootModelSelectOptionsSelector`,
  get({ get }) {
    const { models } = get(viewsContextAtom)
    return models.map((model) => ({
      value: model.name,
      label: (
        <span>
          {model.label}
          <span className="ml-2 text-xs text-gray-500">- {model.name}</span>
        </span>
      ),
      key: `${model.name} ${model.label}`, // 検索用
      model,
    }))
  },
})

const rootModelsSelector = selector({
  key: `${prefix}/rootModelsSelector`,
  get({ get }) {
    const { models } = get(viewsContextAtom)
    return models
  },
})

const defaultSheetConfig: ViewConfigSheet = {
  type: `sheet`,
}
const currentSheetSelector = selector({
  key: `${prefix}/currentSheetSelector`,
  get({ get }) {
    const form = get(kpiFormAtom)
    const sheet = form.sheet ?? defaultSheetConfig
    if (sheet.type !== 'sheet') {
      throw new Error(`sheet.typeがsheet以外です`) // ありえないはず
    }
    return sheet
  },
})

const currentNodesSelector = selector({
  key: `${prefix}/currentNodesSelector`,
  get({ get }) {
    const sheet = get(currentSheetSelector)
    const nodes = sheet.tree === undefined ? [] : treeUtil.flatNodesWithParents(sheet.tree)
    return nodes
  },
})

const currentNodeModelsSelector = selector({
  key: `${prefix}/currentNodeModelsSelector`,
  get({ get }) {
    const nodes = get(currentNodesSelector)
    const modelSearcher = get(modelSearcherSelector)
    return nodes
      .map((node) => {
        const model = modelSearcher.searchModel(node.modelName)
        if (model === undefined) {
          return
        }
        const parentModels = node.parents.map((parent) => modelSearcher.searchModel(parent.modelName)).compact()
        // 「関連付けするオブジェクト」で表示するのにふさわしい、ノードのラベル名のパス
        return {
          node,
          model,
          parentModels,
          nodePathAsLabel: [node, ...node.parents].map((n) => generateNodeLabel(n, modelSearcher)).reverse(),
        }
      })
      .compact()
  },
})

const currentFieldsSelector = selector({
  key: `${prefix}/currentFieldsSelector`,
  get({ get }) {
    const sheet = get(currentSheetSelector)
    const modelSearcher = get(modelSearcherSelector)
    const nodes = get(currentNodeModelsSelector)
    return (sheet.fields ?? [])
      .map((field) => {
        const result = modelSearcher.searchPropertyWithModel(field.property.modelName, field.property.propertyName)
        if (result === undefined) {
          return // undefinedでいいだろうか？
        }
        const node = nodes.find((node) => node.node.name === field.property.nodeName)
        if (node === undefined) {
          return
        }
        return {
          field,
          node,
          model: result.model,
          property: result.property,
        }
      })
      .compact()
  },
})

function generateNodeLabel(node: ViewConfigTreeNode, modelSearcher: ModelSearcher) {
  if (node.ui?.label !== undefined) {
    return node.ui.label
  }
  if (node.referenceToProperty === undefined) {
    const model = modelSearcher.searchModel(node.modelName)
    return model?.label ?? node.name
  }
  const x = modelSearcher.searchPropertyWithModel(
    node.referenceToProperty.modelName,
    node.referenceToProperty.propertyName,
  )
  if (x === undefined) {
    return node.name
  }
  // 親のノードに参照項目があるとき、参照項目の項目名を表示する
  if (node.name !== node.referenceToProperty.nodeName) {
    return x.property.label.replace(/ID/, '')
  }
  // 自分のノードに参照項目があるとき、自分のモデル名とプロパティ名を表示する
  return `${x.model.label}(${x.property.label.replace(/ID/, '')})`
}

const childModelsSelectOptionsSelector = selector({
  key: `${prefix}/childModelsSelectOptionsSelector`,
  get({ get }) {
    const modelSearcher = get(modelSearcherSelector)
    const nodes = get(currentNodeModelsSelector)
    const { models } = get(viewsContextAtom)

    return nodes.flatMap(({ node, model, parentModels, nodePathAsLabel }) => {
      //
      // 子ノードの候補を作成
      //
      // このノードのモデルの参照プロパティ
      const parentRelationNodes = (model.properties ?? []).flatMap((property) => {
        const referenceTo = property.referenceTo ?? []
        if (referenceTo.isEmpty()) {
          return []
        }

        return referenceTo
          .map((referenceToValue) => {
            const referencedModel = modelSearcher.searchModel(referenceToValue.modelName)
            if (referencedModel === undefined) {
              return
            }

            const referenceToProperty: ReferenceToPropertyForAddChildNodeMutation = {
              parentNodeName: node.name,
              nodeName: node.name, // parent側が参照しているということ
              modelName: model.name,
              propertyName: property.name,
              referenceTo: referenceToValue,
            }

            const existedNode = findSameReferenceToChild(node, referenceToProperty)
            return {
              type: 'parent',
              groupLabel: t(`{{label}}の参照項目({{name}})`, { label: nodePathAsLabel.join(': '), name: node.name }),
              // newNodeModel: referencedModel,
              // property,
              value: JSON.stringify([`parent`, node.name, referenceToProperty]),
              key: JSON.stringify([
                `parent`,
                node.name,
                referenceToProperty,
                ...nodePathAsLabel,
                model.label,
                property.label,
              ]),
              // 参照項目の場合、項目名をユーザーに提示してやる仕様
              // label: <span>{referencedModel.label}<span className="text-gray-400 text-sm"> - {referencedModel.label}.{referenceToValue.key ?? 'id'}と{model.label}.{property.label}で紐付け</span></span>,
              label: (
                <span>
                  {property.label.replace(/ID$/, '')}
                  <span className="text-sm text-gray-400"> - {referencedModel.name}</span>
                </span>
              ),
              referenceToProperty,
              node,
              existedNode,
              nodePathAsLabel,
            }
          })
          .compact()
      })

      // このノードのモデルを参照している、他のモデルの参照プロパティ
      const childRelationNodes = models
        .flatMap((m) =>
          m.properties.map((property) => {
            const referenceTo = property.referenceTo ?? []
            // eslint-disable-next-line max-nested-callbacks
            const referenceToValue = referenceTo.find((x) => x.modelName === model.name)
            if (isNull(referenceToValue)) {
              return
            }

            const referenceToProperty: ReferenceToPropertyForAddChildNodeMutation = {
              parentNodeName: node.name,
              nodeName: undefined, // 参照しているのはchild側のプロパティ
              modelName: m.name,
              propertyName: property.name,
              referenceTo: referenceToValue,
            }

            const existedNode = findSameReferenceToChild(node, referenceToProperty)
            return {
              type: 'child',
              groupLabel: t(`{{label}}を参照しているモデル({{name}})`, {
                label: nodePathAsLabel.join(': '),
                name: node.name,
              }),
              // newNodeModel: m,
              // property,
              value: JSON.stringify([`child`, node.name, referenceToProperty]),
              key: JSON.stringify([
                `child`,
                node.name,
                referenceToProperty,
                ...nodePathAsLabel,
                m.label,
                property.label,
              ]),
              // 参照されている項目は、モデル名(プロパティ名)の形式にする
              label: (
                <span>
                  {m.label}({property.label.replace(/ID/, '')})
                  <span className="text-sm text-gray-400"> - {m.name}</span>
                </span>
              ),
              referenceToProperty,
              node,
              existedNode,
              nodePathAsLabel,
            }
          }),
        )
        .compact()
      return [...parentRelationNodes, ...childRelationNodes].uniqueBy((x) => x.value)
    })
  },
})

const childModelsSelectOptionGroupsSelector = selector({
  key: `${prefix}/childModelsSelectOptionGroupsSelector`,
  get({ get }) {
    const options = get(childModelsSelectOptionsSelector)
    return options
      .groupBy((x) => x.groupLabel)
      .map((groupLabel, options) => ({
        label: groupLabel,
        options,
      }))
  },
})

const currentNodePropertiesSelector = selector({
  key: `${prefix}/currentNodePropertiesSelector`,
  get({ get }) {
    const nodes = get(currentNodeModelsSelector)
    return nodes.flatMap((node) =>
      node.model.properties.map((property) => ({
        node,
        model: node.model,
        property,
        nodeProeprtyName: {
          nodeName: node.node.name,
          modelName: node.model.name,
          propertyName: property.name,
        },
      })),
    )
  },
})

const useCurrentNodePropertiesSelector = () => useRecoilValue(currentNodePropertiesSelector)
type KpiFormCurrentNodeProperties = ReturnType<typeof useCurrentNodePropertiesSelector>
export type KpiFormCurrentNodeProperty = KpiFormCurrentNodeProperties[0]

const currentNodeReferencePropertiesSelector = selector({
  key: `${prefix}/currentNodeReferencePropertiesSelector`,
  get({ get }) {
    const properites = get(currentNodePropertiesSelector)
    return properites
      .map((x) => {
        const { referenceTo } = x.property
        if (referenceTo === undefined) {
          return
        }
        return {
          ...x,
          referenceTo,
        }
      })
      .compact()
  },
})

const currentNodeDatePropertiesSelector = selector({
  key: `${prefix}/currentNodeDatePropertiesSelector`,
  get({ get }) {
    const properites = get(currentNodePropertiesSelector)
    return properites.filter(({ property }) => property.type === 'date' || property.type === 'datetime')
  },
})

const currentNodeStringPropertiesSelector = selector({
  key: `${prefix}/currentNodeStringPropertiesSelector`,
  get({ get }) {
    const properites = get(currentNodePropertiesSelector)
    return properites.filter(({ property }) => property.type === 'string')
  },
})

const currentNodeNumberPropertiesSelector = selector({
  key: `${prefix}/currentNodeNumberPropertiesSelector`,
  get({ get }) {
    const properites = get(currentNodePropertiesSelector)
    return properites.filter(({ property }) => property.type === 'integer' || property.type === 'numeric')
  },
})

const currentNodeIdPropertiesSelector = selector({
  key: `${prefix}/currentNodeIdPropertiesSelector`,
  get({ get }) {
    const properites = get(currentNodePropertiesSelector)
    return properites.filter(({ property }) => property.name === 'id')
  },
})

const currentNodeSelectPropertiesSelector = selector({
  key: `${prefix}/currentNodeSelectPropertiesSelector`,
  get({ get }) {
    const properites = get(currentNodePropertiesSelector)
    return properites.filter(({ property }) => property.meta === 'select')
  },
})

const propertiesForCurrentMeasureSelector = selector({
  key: `${prefix}/propertiesForCurrentMeasureSelector`,
  get({ get }) {
    const form = get(kpiFormAtom)
    const { measure } = form
    if (measure?.type !== 'kpiPreset') {
      return []
    }
    const presetFunction = presetFunctions().find((x) => x.value === measure.function)
    if (presetFunction === undefined) {
      return []
    }
    const properites = get(currentNodePropertiesSelector)
    if (presetFunction.availableFieldType === undefined) {
      return properites
    }
    return properites.filter(({ property }) => presetFunction.availableFieldType?.includes(property.type))
  },
})

const userModels = new Set([
  'salescore_users',
  'salesforce_user',
  'hubspot_owners',
  'hubspot_users',
  'zoho_user',
  'next_sfa_user_info',
  'comdesk_staff',
  'senses_users',
  'google_sheets_users',
  'microsoft_dynamics_systemusers',
])
const currentNodeUserReferencePropertiesSelector = selector({
  key: `${prefix}/currentNodeUserReferencePropertiesSelector`,
  get({ get }) {
    const properites = get(currentNodeReferencePropertiesSelector)
    return properites.filter(({ referenceTo }) => referenceTo.some((x) => userModels.has(x.modelName)))
  },
})

const validatedFormValueSelector = selector({
  key: `${prefix}/validatedFormValueSelector`,
  get({ get }) {
    const form = get(kpiFormAtom)
    const { name, viewGroupId, dashboardIds, ...config } = form
    const kpiConfig = viewConfigKpiSchema
      .merge(
        z.object({
          // NOTE: RI の期間項目
          dateY: z
            .object({
              property: nodePropertyNameSchema,
            })
            .optional(),
        }),
      )
      .safeParse({
        ...config,
        // formとしての型を、configに変換する必要がある
        // schemaが違う時に変換したい時とエラーにしたいときの2パターンがあり、どう処理するか悩ましい
        // 一旦問題になるのが以下のみなので、ここにベタ書きする
        user:
          config.user === undefined
            ? undefined
            : config.user.joinAs !== undefined && config.user.property !== undefined
              ? config.user
              : undefined,
        // NOTE: config object に dateY property が存在しない場合は undefined とし、クリアした場合に config が正しく更新されるようにする
        dateY: config.dateY ?? undefined,
      })
    return {
      name,
      viewGroupId,
      dashboardIds,
      kpiConfig: normalizeZodSafeParseResult(kpiConfig),
    }
  },
})

const setSheetConfigMutation = mutation<ViewConfigSheet>({
  key: `${prefix}/setSheetConfigMutation`,
  set({ get, set }, sheetConfig) {
    set(kpiFormAtom, (oldValue) => ({
      ...oldValue,
      sheet: sheetConfig,
    }))
  },
})

const useKpi = () => {
  const [form, setForm] = useRecoilState(kpiFormAtom)
  const kpi: Partial<ViewKpiAppearance> = form.ui?.kpi ?? {}
  const setKpi = (kpi: Partial<ViewKpiAppearance>) => {
    setForm((oldValue) => ({
      ...oldValue,
      ui: {
        ...oldValue.ui,
        kpi: {
          ...oldValue.ui?.kpi,
          ...kpi,
        },
      },
    }))
  }
  return { kpi, setKpi }
}

const stepStatusSelector = selector({
  key: `${prefix}/stepStatusSelector`,
  get({ get }) {
    const form = get(kpiFormAtom)
    const step = get(kpiFormStepAtom)
    const isStep2Enabled = typeof form.name === 'string' && form.name.length > 0
    const isStep3Enabled = form.sheet?.type === 'sheet' && form.sheet.tree !== undefined
    const isStep4Enabled = isStep3Enabled && form.measure !== undefined
    return {
      isStep2Enabled,
      isStep3Enabled,
      isStep4Enabled,
    }
  },
})

const sheetValueObjectSelector = selector<ViewConfigSheetValueObject>({
  key: `${prefix}/sheetValueObject`,
  get({ get }) {
    const sheet = get(currentSheetSelector)
    return new ViewConfigSheetValueObject(sheet)
  },
  set({ set }, sheet) {
    if (sheet instanceof DefaultValue) {
      return
    }
    set(setSheetConfigMutation, sheet.config)
  },
})

// NOTE: このようなさまざまなselectorをまとめたフックを作ると、本来更新が必要がないコンポーネントでも更新が走るため、Recoilの良さを潰してしまう。
//       しかし、パフォーマンスが問題ない個所では、この形で実装した方が楽なのでこの形とする。
export function useKpiFormSelectors() {
  const { parentViewGroups } = useRecoilValue(viewsRelatedSelector)
  const modelSearcher = useRecoilValue(modelSearcherSelector)
  const [form, setForm] = useRecoilState(kpiFormAtom)
  const [formModified, setFormModified] = useRecoilState(kpiFormModifiedAtom)
  const validatedFormValue = useRecoilValue(validatedFormValueSelector)
  const rootModelSelectOptions = useRecoilValue(rootModelSelectOptionsSelector)
  const rootModels = useRecoilValue(rootModelsSelector)
  const childModelsSelectOptions = useRecoilValue(childModelsSelectOptionsSelector)
  const childModelsSelectOptionGroups = useRecoilValue(childModelsSelectOptionGroupsSelector)
  const currentSheet = useRecoilValue(currentSheetSelector)
  const currentNodes = useRecoilValue(currentNodesSelector)
  const currentNodeModels = useRecoilValue(currentNodeModelsSelector)
  const currentFields = useRecoilValue(currentFieldsSelector)
  const currentNodeProperties = useRecoilValue(currentNodePropertiesSelector)
  const currentNodeReferenceProperties = useRecoilValue(currentNodeReferencePropertiesSelector)
  const currentNodeDateProperties = useRecoilValue(currentNodeDatePropertiesSelector)
  const currentNodeStringProperties = useRecoilValue(currentNodeStringPropertiesSelector)
  const currentNodeNumberProperties = useRecoilValue(currentNodeNumberPropertiesSelector)
  const currentNodeIdProperties = useRecoilValue(currentNodeIdPropertiesSelector)
  const currentNodeSelectProperties = useRecoilValue(currentNodeSelectPropertiesSelector)
  const currentNodeUserReferenceProperties = useRecoilValue(currentNodeUserReferencePropertiesSelector)
  const propertiesForCurrentMeasure = useRecoilValue(propertiesForCurrentMeasureSelector)
  const { kpi, setKpi } = useKpi()
  const [step, setStep] = useRecoilState(kpiFormStepAtom)
  const stepStatus = useRecoilValue(stepStatusSelector)
  const setSheetConfig = useSetRecoilState(setSheetConfigMutation)
  const [sheet, setSheet] = useRecoilState(sheetValueObjectSelector)
  const [callbackFlags, setCallbackFlags] = useRecoilState(kpiFormCallbackFlagsAtom)

  // このhooksは各所から呼ばれるので、ここでuseEffectを定義すると何度も呼ばれてしまう。注意。

  return {
    parentViewGroups,
    modelSearcher,
    sheet,
    setSheet,
    rootModelSelectOptions,
    rootModels,
    childModelsSelectOptions,
    childModelsSelectOptionGroups,
    form,
    formModified,
    validatedFormValue,
    currentSheet,
    currentNodes,
    currentNodeModels,
    currentFields,
    currentNodeProperties,
    currentNodeReferenceProperties,
    currentNodeDateProperties,
    currentNodeStringProperties,
    currentNodeNumberProperties,
    currentNodeIdProperties,
    currentNodeSelectProperties,
    currentNodeUserReferenceProperties,
    propertiesForCurrentMeasure,
    kpi,
    step,
    stepStatus,
    callbackFlags,
    setKpi,
    setForm,
    setFormModified,
    setSheetConfig,
    setStep,
    setCallbackFlags,
  }
}

export const useKpiFormEffects = () => {
  const {
    callbackFlags,
    form,
    sheet,
    currentNodeDateProperties,
    currentNodeUserReferenceProperties,
    setForm,
    setCallbackFlags,
    setFormModified,
  } = useKpiFormSelectors()

  useEffect(() => {
    // ルートのオブジェクトが変わったら、各種のデフォルト設定を行う
    if (callbackFlags.setDefaultByRoot) {
      // TODO: デフォルト設定は便利でありつつ、ここでデフォルト設定したものを詳細設定に含めるか悩ましい
      const { tree } = sheet.config
      if (tree === undefined) {
        return
      }
      const sheetValueObject = new ViewConfigSheetValueObject(sheet.config)
      const userProperty = currentNodeUserReferenceProperties.first()?.nodeProeprtyName
      const dateProperty = currentNodeDateProperties.first()?.nodeProeprtyName

      const sheetWithUserField = isSome(userProperty)
        ? sheetValueObject.addPropertyField(userProperty, { toLast: true })
        : sheetValueObject
      const sheetWithUserAndDateField = isSome(dateProperty)
        ? sheetWithUserField.addPropertyField(dateProperty, { toLast: true })
        : sheetWithUserField

      setForm({
        ...form,
        sheet: sheetWithUserAndDateField.config,
        measure: {
          type: `kpiPreset`,
          function: `count`,
          property: {
            nodeName: tree.name,
            modelName: tree.modelName,
            propertyName: `id`,
          },
        },
        date:
          dateProperty === undefined
            ? undefined
            : {
                property: dateProperty,
              },
        user:
          userProperty === undefined
            ? undefined
            : {
                joinAs: `id`,
                property: userProperty,
              },
      })
      setCallbackFlags((x) => ({ ...x, setDefaultByRoot: false }))
    }
  }, [callbackFlags]) // こんな残念な発火方法しかないだろうか？
}

export const useKpiFormEffectsForKpiTimeSeries = () => {
  const {
    callbackFlags,
    form,
    sheet,
    currentNodeUserReferenceProperties,
    setForm,
    setCallbackFlags,
    childModelsSelectOptions,
    modelSearcher,
  } = useKpiFormSelectors()
  const setRiForm = useSetRecoilState(riFormAtom)
  const riFormat = useRecoilValue(riDisplayFormatAtom)

  function addViewConfigChildNode(sheet: ViewConfigSheetValueObject, option: (typeof childModelsSelectOptions)[0]) {
    const result = sheet.addViewConfigChildNode(option.node, option.referenceToProperty)
    if (!result.success) {
      throw new Error(
        t(`スナップショットオブジェクトに関連付けるオブジェクトが見つかりません。`) +
          JSON.stringify(option.referenceToProperty),
      )
    }
    return result
  }

  useEffect(() => {
    // ルートのオブジェクトが変わったら、各種のデフォルト設定を行う
    if (callbackFlags.setDefaultByRoot) {
      // TODO: デフォルト設定は便利でありつつ、ここでデフォルト設定したものを詳細設定に含めるか悩ましい
      const { tree } = sheet.config
      if (tree === undefined) {
        return
      }
      const isRootModelSnapshot =
        tree.modelName.startsWith(CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX) &&
        !tree.modelName.endsWith(CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX)
      if (!isRootModelSnapshot) {
        throw new Error(t(`集計のベースとなるオブジェクトがスナップショットではありません。`))
      }
      // id カラムを自動で関連付ける元オブジェクトのオプション
      const idChildOption = childModelsSelectOptions.find((x) => x.referenceToProperty.propertyName === `id`)
      if (idChildOption === undefined) {
        throw new Error(t(`スナップショットオブジェクトに関連付ける元オブジェクトが見つかりません。`))
      }
      // シーケンスオブジェクトを自動で関連付ける
      const sequenceChildOption = childModelsSelectOptions.find(
        (x) =>
          x.referenceToProperty.referenceTo.modelName ===
          `${tree.modelName}_${CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX}`,
      )
      if (sequenceChildOption === undefined) {
        // ありえないはず
        throw new Error(t(`スナップショットオブジェクトに関連付けるシーケンスオブジェクトが見つかりません。`))
      }

      const sheetWithIdChildOption = addViewConfigChildNode(sheet, idChildOption)
      const result = addViewConfigChildNode(sheetWithIdChildOption.data, sequenceChildOption)

      const idModel = modelSearcher.searchModel(sheetWithIdChildOption.newNode.modelName)
      if (idModel === undefined) {
        throw new Error(t(`スナップショットオブジェクトに関連付ける元オブジェクトが見つかりません。`))
      }
      const sequenceModel = modelSearcher.searchModel(result.newNode.modelName)
      if (sequenceModel === undefined) {
        throw new Error(t(`スナップショットオブジェクトに関連付けるシーケンスオブジェクトが見つかりません。`))
      }
      const originalObjectNameProperty = {
        nodeName: sheetWithIdChildOption.newNode.name,
        modelName: idModel.name,
        propertyName: `name`,
      }
      const idProperty = {
        nodeName: tree.modelName,
        modelName: tree.modelName,
        propertyName: `id`,
      }
      // シーケンスオブジェクトのcreated_atを横軸の日付として設定
      const dateProperty = {
        nodeName: result.newNode.name,
        modelName: sequenceModel.name,
        propertyName: `created_at`,
      }
      const sheetWithSequence = result.data
        .addDefaultPropertyFields(result.newNode, sequenceModel, { toLast: true })
        .togglePropertyField(idProperty) // id 列は非表示にする
        .togglePropertyField(originalObjectNameProperty) // 元オブジェクトの名前列を追加
        .togglePropertyField(dateProperty) // スナップショットを取った時刻の列を追加

      const userProperty = currentNodeUserReferenceProperties.first()?.nodeProeprtyName
      setForm({
        ...form,
        measure: {
          type: `kpiPreset`,
          function: riFormat === 'timeSeries' ? 'count' : 'countUnique',
          property: {
            nodeName: tree.name,
            modelName: tree.modelName,
            propertyName: `id`,
          },
        },
        date: {
          property: dateProperty,
        },
        user:
          userProperty === undefined
            ? undefined
            : {
                joinAs: `id`,
                property: userProperty,
              },
        sheet: sheetWithSequence.config,
      })
      setRiForm((oldValue) => {
        if (isNull(oldValue)) {
          return { type: 'kpiTimeSeries' }
        }
        if (!isTruthy(oldValue.option?.asWaterfall)) {
          return oldValue
        }
        if (isNull(oldValue.waterfall)) {
          return oldValue
        }

        return {
          ...oldValue,
          waterfall: {
            dimensions: oldValue.waterfall.dimensions.map((x) => ({
              ...x,
              property: undefined,
            })),
            drillDownSheet: undefined,
          },
        }
      })
      setCallbackFlags((x) => ({ ...x, setDefaultByRoot: false }))
    }
  }, [callbackFlags]) // こんな残念な発火方法しかないだろうか？
}
