import { CaretDownFilled } from '@ant-design/icons'
import { useEffect, useState } from 'react'

import { type Side, useMoveColumnMutation } from '../../recoil/mutations/moveColumnMutation'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import { getHeaderColor } from './RSheetsNodeBlockHeader'

export function DraggableHeaderColumn<T extends RSheetRecordNode>({
  width,
  column,
  columnIndex,
  widths,
  height,
  onClick,
  onContextMenu,
  popoverContent,
}: {
  column: RSheetColumn<T>
  width: number
  columnIndex: number
  widths: number[]
  height: number
  popoverContent: JSX.Element
  onClick?: () => void
  onContextMenu?: () => void
}) {
  const moveColumn = useMoveColumnMutation()
  const [x, setX] = useState<number | undefined>()
  const [columnRightEdgeXs, setColumnRightEdgeXs] = useState(() => calculateColumnRightEdgeXs(widths, columnIndex))
  const [columnLeftEdgeXs, setColumnLeftEdgeXs] = useState(() => calculateColumnLeftEdgeXs(widths, columnIndex))

  useEffect(() => {
    setColumnRightEdgeXs(calculateColumnRightEdgeXs(widths, columnIndex))
    setColumnLeftEdgeXs(calculateColumnLeftEdgeXs(widths, columnIndex))
  }, [widths, columnIndex, width])

  const { movingOntoIndex, side } = calculateMovedColumnIndex(columnIndex, x ?? 0, columnRightEdgeXs, columnLeftEdgeXs)
  const movedEdge = calculateMovedEdge(movingOntoIndex, side, columnIndex, columnRightEdgeXs, columnLeftEdgeXs)

  return (
    <>
      {/* ドラッグ可能な要素 */}
      {/* <Popover content={popoverContent} placement="top"> */}
      <div
        className="visible-on-hover" // NOTE: これを指定するとドロップ直前に戻っていくアニメーションが見えてしまう副作用があったはずだが、いつの間にかなくなっていた。再発したらここを検討。
        style={{
          position: 'absolute',
          height,
          backgroundColor: 'rgba(0,0,0,0)',
          // opacity: 0, // few-visible-on-hover を使うなら不要
          // opacity: 0.5,
          zIndex: 2,
          top: 0,
          left: 0,
          cursor: 'pointer',
          // cursor: 'grab',
          width,
        }}
        onClick={() => {
          if (onClick !== undefined) {
            onClick()
          }
        }}
        onContextMenu={(e) => {
          if (onContextMenu !== undefined) {
            onContextMenu()
            e.preventDefault()
          }
        }}
        draggable={true}
        onDragStart={(e) => {
          setX(e.nativeEvent.offsetX)
          // TODO: ドラッグイベントの終了時に、ドラッグした要素が「元の位置に戻る」アニメーションが発火してしまい、onDragEndが発火するまで少し時間がかかる。
          //       以下やonDropなど諸々を試したが、うまくいかなかった
          // e.dataTransfer.effectAllowed = 'copy'
          // e.dataTransfer.setData('text/plain', '')
        }}
        onDrag={(e) => {
          if (e.clientX !== 0 && x !== e.clientX) {
            // 書き方が悪いのか、onDragEndの直前にclientXが0になってしまうのでそれを防ぐ(ユーザーが0の位置までカーソルを移動させることは、今回の文脈だとありえない)
            setX(e.nativeEvent.offsetX)
          }
        }}
        onDragEnd={(e) => {
          if (columnIndex !== movingOntoIndex) {
            moveColumn({
              oldColumnIndex: columnIndex,
              movingOntoIndex,
              side,
            })
          }

          setX(undefined)
        }}
      >
        <div
          className="absolute right-0 top-0"
          style={{ color: getHeaderColor(column.color).textColor, top: 8, right: 6 }}
        >
          {/* <SettingOutlined/> */}
          <CaretDownFilled />
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: getHeaderColor(column.color).bgColorDark,
            opacity: 0.1,
          }}
        />
      </div>
      {/* </Popover> */}
      {/* ドラッグ中に表示される要素 */}
      {x !== undefined && (
        <>
          <div
            style={{
              position: 'absolute',
              height: window.screen.height,
              width,
              backgroundColor: '#5991F6',
              zIndex: 1,
              top: 0,
              left: x ?? 0,
              opacity: 0.1,
            }}
          />
          <div
            style={{
              position: 'absolute',
              height: window.screen.height,
              width: 2,
              backgroundColor: '#5991F6',
              zIndex: 1,
              top: 0,
              left: movedEdge,
              opacity: 1,
            }}
          />
        </>
      )}
    </>
  )
}

// index と、それより右側のカラムの右端のX座標（原点は index カラムの左端）を計算する
function calculateColumnRightEdgeXs(widths: number[], index: number) {
  const rightWidths = widths.slice(index)
  return [0, ...rightWidths.map((w, index_) => rightWidths.slice(0, index_ + 1).sum())]
}

// index と、それより左側のカラムの左端のX座標（原点は index カラムの左端）を計算する
function calculateColumnLeftEdgeXs(widths: number[], index: number) {
  const leftWidths = widths.slice(0, index).reverse()
  return [0, ...leftWidths.map((w, index_) => -leftWidths.slice(0, index_ + 1).sum())]
}

function calculateMovedColumnIndex(
  columnIndex: number,
  diffX: number,
  columnRightEdgeXs: number[],
  columnLeftEdgeXs: number[],
): {
  movingOntoIndex: number
  side: Side
} {
  if (diffX === 0) {
    return {
      movingOntoIndex: columnIndex,
      side: 'left',
    }
  }

  if (diffX > 0) {
    if (columnRightEdgeXs.length === 1) {
      // 自分より右側にカラムがない場合
      return {
        movingOntoIndex: columnIndex,
        side: 'left',
      }
    }
    const sections = columnRightEdgeXs.map(
      (x, index) => [x, columnRightEdgeXs[index + 1]] as [number, number | undefined],
    )
    const sectionIndex = sections.findIndex(
      (section) => section[0] <= diffX && (section[1] === undefined || diffX < section[1]),
    )
    const section = sections[sectionIndex]
    if (section === undefined) {
      // ありえないケース。例外を投げたいが、安全側に倒しておく。
      return {
        movingOntoIndex: columnIndex,
        side: 'left',
      }
    }
    if (section[1] === undefined) {
      return {
        movingOntoIndex: columnIndex + sectionIndex,
        side: 'left',
      }
    }
    const sectionMidX = (section[0] + section[1]) / 2
    const side = diffX < sectionMidX ? 'left' : 'right'
    return {
      movingOntoIndex: columnIndex + sectionIndex,
      side: diffX < sectionMidX ? 'left' : 'right',
    }
  }
  // diffX < 0 の場合
  if (columnLeftEdgeXs.length === 0) {
    return {
      movingOntoIndex: columnIndex,
      side: 'left',
    }
  }
  const sections = columnLeftEdgeXs.map((x, index) => [x, columnLeftEdgeXs[index + 1]] as [number, number | undefined])

  const sectionIndex = sections.findIndex(
    (section) => diffX <= section[0] && (section[1] === undefined || section[1] < diffX),
  )
  const section = sections[sectionIndex]
  if (section === undefined) {
    // ありえないケース。例外を投げたいが、安全側に倒しておく。
    return {
      movingOntoIndex: columnIndex,
      side: 'left',
    }
  }
  if (section[1] === undefined) {
    return {
      movingOntoIndex: columnIndex - sectionIndex - 1,
      side: 'left',
    }
  }
  const sectionMidX = (section[0] + section[1]) / 2
  const side = diffX < sectionMidX ? 'left' : 'right'
  return {
    movingOntoIndex: columnIndex - sectionIndex - 1,
    side,
  }
}

// eslint-disable-next-line @typescript-eslint/max-params
function calculateMovedEdge(
  movingOntoIndex: number,
  side: Side,
  columnIndex: number,
  columnRightEdgeXs: number[],
  columnLeftEdgeXs: number[],
) {
  const sideOffset = side === 'left' ? 0 : 1

  if (movingOntoIndex >= columnIndex) {
    const edgeX = columnRightEdgeXs[movingOntoIndex - columnIndex + sideOffset]
    return edgeX ?? 0
  } else {
    const edgeX = columnLeftEdgeXs[columnIndex - movingOntoIndex - sideOffset]
    return edgeX ?? 0
  }
}
