import { UploadOutlined } from '@ant-design/icons'
import { isNull } from '@salescore/buff-common'
import { notifyBugsnag } from '@salescore/client-base'
import { Button, message, Modal, Upload, type UploadFile } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

export const UploadCsvFileByBase64Button = ({
  onLoad,
  loading,
}: {
  onLoad: (base64: string, decoded: string) => void
  loading?: boolean
}) => {
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([])
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false)
  const reader = new FileReader()

  reader.addEventListener('load', (onloadEvent) => {
    try {
      if (typeof onloadEvent.target?.result !== 'string') {
        void message.error(t(`エラーが発生しました。ファイルが文字列になっていません`)) // ありえないはず
        return
      }
      const base64 = onloadEvent.target.result
      if (!base64.startsWith('data:text/csv;base64,')) {
        // csvじゃないデータを選択した時
        void message.error(t(`ファイルがCSVではありません`))
      }
      const decoded = Buffer.from(base64.replace('data:text/csv;base64,', ''), 'base64').toString('utf8')
      onLoad(base64, decoded)
    } catch (error) {
      if (error instanceof Error) {
        notifyBugsnag({ error })
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  })

  if (loading === true) {
    return <Button loading={true} />
  }

  return (
    <>
      <Upload
        fileList={uploadFiles}
        maxCount={1}
        onChange={(info) => {
          setUploadFiles(info.fileList)

          if (info.fileList.length > 0) {
            setShowUploadModal(true)
          }
        }}
      >
        <Button icon={<UploadOutlined />}>{t(`ファイルを選択`)}</Button>
      </Upload>
      <Modal
        open={showUploadModal}
        onOk={() => {
          const file = uploadFiles[0]
          try {
            if (isNull(file)) {
              // ありえないはずだが一応
              void message.error(t(`ファイルが添付されていません`))
              return
            }

            if (isNull(file.originFileObj)) {
              void message.error(t(`ファイルが添付されていません`))
              return
            }

            reader.readAsDataURL(file.originFileObj)
            setShowUploadModal(false)
          } catch (error) {
            if (error instanceof Error) {
              notifyBugsnag({ error })
              void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
            } else {
              void message.error(t(`エラーが発生しました`))
            }
          }
        }}
        onCancel={() => {
          setShowUploadModal(false)
        }}
        width={'60%'}
        okText={t(`アップロード`)}
        cancelText={t(`キャンセル`)}
        // okButtonProps={{ style: { display: 'none' } }}
        title={<div>{t(`確認`)}</div>}
        style={{ top: '3%' }}
      >
        <div>{t(`{{name}}をアップロードします。よろしいですか？`, { name: uploadFiles[0]?.name })}</div>
      </Modal>
    </>
  )
}
