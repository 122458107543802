import { PlusOutlined } from '@ant-design/icons'
import { isSome } from '@salescore/buff-common'
import { Button, Divider, Empty, message, Select, Spin } from 'antd'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'

import type { SearchResultRow } from '../../../../../domain/service/generateSearchSql'
import { useSearchQueue } from '../../../../../recoil/hooks/searchRelation'
import { useNavigationModal } from '../../../../../recoil/navigation/hooks'
import { useChangeRelationsMutation } from '../../../../../recoil/records/mutations/changeRelationsMutation'
import { useViewAbilityValue } from '../../../../../recoil/view/hooks'
import { useUpsertSheetRowMutation } from '../../../../recoil/mutations/upsertSheetRowMutation'
import type { RSheetsCellInputRenderTypeArgument } from '../rSheetsCellInputRenderTypeArgument'

type SearchResultRowWithKey = SearchResultRow & {
  key: string
}

export function RSheetsCellRelationInput({
  parentRecordNode,
  recordNode,
  column,
  rowIndex,
  innerRowIndex,
  defaultWidth,
  finishEditMode,
  boxStyle,
  emptyCell,
}: RSheetsCellInputRenderTypeArgument & {
  emptyCell?: boolean
}) {
  const upsertSheetRowMutation = useUpsertSheetRowMutation()
  const changeRelationsMutation = useChangeRelationsMutation()
  const { modelFormViewModal } = useNavigationModal()
  const reference = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SearchResultRowWithKey[]>([])
  const { queueSearch, search } = useSearchQueue({
    setLoading,
    setOptions: (options) => {
      setOptions(options.map((x) => ({ ...x, key: x.value })))
    },
  })
  const ability = useViewAbilityValue()
  const searchSql = column.field?.read.searchSql ?? column.node.read.join?.search.sql ?? ''

  useEffect(() => {
    if (options.length === 0) {
      void search(searchSql, '', recordNode ?? parentRecordNode, parentRecordNode) // 空セルのとき、recordNodeは存在せず、parentRecordNodeで検索する
    }

    setTimeout(() => {
      if (reference.current) {
        reference.current.focus()
      }
    }, 200)
  }, [])

  return (
    <div
      style={{
        ...boxStyle,
        // Spreadsheetと似たロジックで、中身の文字列が増えたら幅を広げて全部表示させる。
        // 他のセルの位置に影響を与えずこれを実現するため、position: absoluteにして実現している
        width: defaultWidth,
        padding: 2,
        backgroundColor: 'white',
        marginTop: 1, // これがないと1pxずれるが、Cell側と比べてなぜ1px分の差があるのか不明
        marginLeft: 1,
        // boxShadow: '1px 4px 7px 2px rgb(60 64 67 / 15%)',
      }}
      onClick={() => {
        finishEditMode()
      }}
    >
      <Select
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Button
              disabled={!ability.canOpenRelationInputForm}
              className="w-full"
              type="text"
              icon={<PlusOutlined />}
              onClick={() => {
                // 参照先のモデル名を無理やり取得する

                const getModelName = () => {
                  if (emptyCell === true) {
                    return {
                      modelName: column.configField?.property.modelName,
                      referencingNode: undefined,
                    }
                  }
                  const referencingNodeName = column.field?.read.labelNodePath?.last()
                  const referencingNode = column.node.children?.find((x) => x.name === referencingNodeName)
                  return {
                    modelName: referencingNode?.write?.streamName,
                    referencingNode,
                  }
                }
                const modelName = getModelName()
                if (modelName.modelName === undefined) {
                  void message.warning(
                    modelName.referencingNode === undefined
                      ? `参照先のモデルが見つかりませんでした。`
                      : `書き込み禁止のオブジェクトのため、新規作成できません。`,
                  )
                  return
                }
                modelFormViewModal.showModal({
                  modelName: modelName.modelName,
                  onFinish: async (id) => {
                    // eslint-disable-next-line unicorn/prefer-ternary
                    if (emptyCell === true) {
                      await changeRelationsMutation({
                        id,
                        rowIndex,
                        innerRowIndex,
                        node: column.node,
                      })
                    } else {
                      await upsertSheetRowMutation({
                        value: id,
                        rowIndex,
                        innerRowIndex,
                        column,
                        label: id, // XXX: mutation側で解決してくれるので、適当に指定している
                      })
                    }
                  },
                })
              }}
            >
              {t('新規作成')}
            </Button>
          </>
        )}
        loading={loading}
        allowClear
        placeholder={t('検索...')}
        autoFocus={true}
        defaultOpen={true}
        bordered={false}
        // options={options}
        showSearch
        filterOption={false}
        onSearch={(searchKey) => {
          queueSearch(searchSql, searchKey, recordNode ?? parentRecordNode, parentRecordNode) // 空セルのとき、recordNodeは存在せず、parentRecordNodeで検索する
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            // 漢字変換中のEnterを区別することができないため、enterのハンドリングはしない。
            // finishEditMode({
            // moveRowDiff: 1, // 2021/11 この場合のエンターは移動させたくないという意見があったため
            // })
            e.preventDefault()
            e.stopPropagation()
          }
          if (e.key === 'Tab') {
            finishEditMode({ moveColumnDiff: 1 })
            e.preventDefault()
            e.stopPropagation()
          }
          if (e.key === 'Escape') {
            finishEditMode()
            e.preventDefault()
            e.stopPropagation()
          }
        }}
        notFoundContent={loading ? <Spin size="small" /> : <Empty />}
        style={{
          width: '100%',
          minWidth: 400,
        }}
        onChange={async (value) => {
          const selectedOption = options.find((x) => x.value === value)
          if (selectedOption === undefined) {
            return
          }
          await (emptyCell === true
            ? changeRelationsMutation({
                id: selectedOption.value,
                rowIndex,
                innerRowIndex,
                node: column.node,
              })
            : upsertSheetRowMutation({
                value: selectedOption.value,
                rowIndex,
                innerRowIndex,
                column,
                label: selectedOption.label,
              }))

          // enterで選択した時はカーソルを下に移動するが、onClickで選択した場合は下に移動させない
          finishEditMode()
        }}
      >
        {options.map((option) => (
          <Select.Option key={option.key} value={option.value}>
            {option.label}
            <span className="ml-2 text-gray-400">
              {isSome(option.meta_label) ? '- ' : ''}
              {option.meta_label}
            </span>
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}
