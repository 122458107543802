import type { PolicyAction } from '../action'
import { memberRolePolicyDefinition } from './memberRolePolicyDefinition'

// 全ての権限がfalse(off)のロール：現状ではSSTユーザー専用ロールとして利用(2025/01)
export const minimalRolePolicyDefinition: Record<PolicyAction, boolean> = {
  ...memberRolePolicyDefinition,
  // sheet
  [`sheet-create`]: false,
  [`sheet-update`]: false,
  [`sheet-delete`]: false,
  [`sheet-create-new-record`]: false,
  [`sheet-save-record`]: false,
  [`sheet-delete-record`]: false,
  [`sheet-open-relation-input-form`]: false,
  [`sheet-download-csv`]: false,
  [`sheet-custom-object`]: false,
  [`convert-lead`]: false,

  // kpi
  [`kpi-create-new-record`]: false,
  [`kpi-save-record`]: false,
  [`kpi-delete-record`]: false,
  [`kpi-open-relation-input-form`]: false,
  [`kpi-download-csv`]: false,

  // kpi-pivot
  [`kpi-pivot-download-csv`]: false,

  //other
  [`view-group-create`]: false,
  [`view-group-delete`]: false,
  [`read-user-activity`]: false,
}
