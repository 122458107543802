import { WarningOutlined } from '@ant-design/icons'
import { isPresent, r } from '@salescore/buff-common'
import type { ViewConfigSheet, ViewConfigSheetPreset } from '@salescore/core'
import { Button, Form, Input, Table, Tag } from 'antd'
import { diff as deepObjectDiff } from 'deep-object-diff'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'

import { useNavigationModal, useSheetPickedPresetName } from '../../../../recoil/navigation/hooks'
import { configAtom, isSavingCurrentViewAtom, isUpdatedSheetPresetAtom } from '../../../../recoil/view/atoms'
import { useViewAbilityValue } from '../../../../recoil/view/hooks'
import { recursiveRemoveEmptyValues } from '../../ViewUIKpiPivot/recursive'

interface SheetPresetFormValues {
  name: string
}

type PresetParameterKeys = keyof ViewConfigSheetPreset

export function SheetPresetForm() {
  const { sheetPresetFormModal } = useNavigationModal()
  const formPreset = sheetPresetFormModal.content?.preset
  const [config, setConfig] = useRecoilState(configAtom)
  const [isUpdatedPreset, setIsUpdatedPreset] = useRecoilState(isUpdatedSheetPresetAtom)
  const configReference = useRef(config)
  const [isSavingCurrentView, setIsSavingCurrentView] = useRecoilState(isSavingCurrentViewAtom)
  const [picked, setPicked] = useSheetPickedPresetName()
  const ability = useViewAbilityValue()
  const [selectedRowKeys, setSelectedRowKeys] = useState<PresetParameterKeys[]>(
    r(formPreset ?? config)
      .compact()
      .keys() as PresetParameterKeys[],
  )

  useEffect(() => {
    const diff = deepObjectDiff(config, configReference.current)
    if (isPresent(Object.keys(diff)) && isUpdatedPreset) {
      configReference.current = config
      setIsSavingCurrentView(true)
    }
  }, [config])

  const dataSource: Array<{
    label: string
    key: PresetParameterKeys
    content: unknown
  }> = [
    {
      label: t(`表示項目`),
      key: `fields` as const,
      content: formPreset?.fields,
    },
    {
      label: t(`絞り込み`),
      key: `filterTree` as const,
      content: formPreset?.filterTree,
    },
    {
      label: t(`並び替え`),
      key: `sorters` as const,
      content: formPreset?.sorters,
    },
  ]

  function onFinish(values: SheetPresetFormValues) {
    if (!ability.canUpdate) {
      return
    }

    setConfig((previousConfig) => {
      if (previousConfig.type !== 'sheet') {
        return previousConfig
      }

      // 作成
      if (formPreset === undefined) {
        const newPreset = {
          name: values.name,
          tree: previousConfig.tree,
          fields: previousConfig.fields,
          filterTree: selectedRowKeys.includes('filterTree') ? previousConfig.filterTree : undefined,
          sorters: selectedRowKeys.includes('sorters') ? previousConfig.sorters : undefined,
          meta: previousConfig.meta,
        }
        const newPresets = previousConfig.presets
          ? [newPreset, ...previousConfig.presets]
              // 同名のプリセットがあれば上書き
              .uniqueBy((p) => p.name)
          : [newPreset]
        const { name, ...configPreset } = newPreset
        return {
          type: 'sheet',
          ...configPreset,
          presets: newPresets,
        }
      }

      // 更新
      const editPreset = previousConfig.presets?.find((p) => p.name === formPreset.name)
      const editPresets = previousConfig.presets

        ?.map((p) =>
          p.name === formPreset.name
            ? {
                ...p,
                name: values.name,
                tree: selectedRowKeys.includes('fields') ? previousConfig.tree : p.tree,
                fields: selectedRowKeys.includes('fields') ? previousConfig.fields : p.fields,
                filterTree: selectedRowKeys.includes('filterTree') ? previousConfig.filterTree : p.filterTree,
                sorters: selectedRowKeys.includes('sorters') ? previousConfig.sorters : p.sorters,
              }
            : p,
        )
        // 同名のプリセットがあれば上書き
        // ただし、先頭のプリセットと衝突した場合は結果的に先頭のプリセットの設定が優先される
        // なぜなら、setIsSavingCurrentView(true) を起因として発生する sheet の更新時に先頭のプリセットを sheet.config の tree と filterTree で更新しているため

        .map((p) =>
          p.name === values.name
            ? {
                ...p,
                tree: selectedRowKeys.includes('fields') ? previousConfig.tree : editPreset?.tree,
                fields: selectedRowKeys.includes('fields') ? previousConfig.fields : editPreset?.fields,
                filterTree: selectedRowKeys.includes('filterTree') ? previousConfig.filterTree : editPreset?.filterTree,
                sorters: selectedRowKeys.includes('sorters') ? previousConfig.sorters : editPreset?.sorters,
                meta: editPreset?.meta,
              }
            : p,
        )
        .uniqueBy((p) => p.name)
      return {
        ...previousConfig,
        presets: editPresets,
      }
    })

    // 選択中のpresetを編集 or 新規作成時は対象のプリセットに明示的に切り替える
    if (formPreset?.name === picked || formPreset === undefined) {
      setPicked(values.name)
    }
    sheetPresetFormModal.hideModal()
    setIsUpdatedPreset(true)
  }

  if (config.type !== 'sheet' || !ability.canUpdate) {
    return null
  }

  return (
    <Form<SheetPresetFormValues>
      layout="vertical"
      initialValues={{
        name: formPreset?.name,
      }}
      onFinish={onFinish}
      validateMessages={{
        required: t(`プリセット名を入力してください`),
        pattern: {
          mismatch: t(`[共有リンク]から始まる文字列をプリセット名に使用することはできません`),
        },
      }}
    >
      <Form.Item
        name="name"
        label={t(`プリセット名`)}
        rules={[
          {
            required: true,

            pattern: /^(?!\[共有リンク\]).*/,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="parameter"
        label={
          <>
            {t(`プリセットに含める設定`)}
            <Tag color="yellow" icon={<WarningOutlined />} style={{ marginLeft: 10 }}>
              {t(`現在のシートの設定内容でプリセットを上書きします`)}
            </Tag>
          </>
        }
      >
        <Table
          columns={[
            {
              dataIndex: `label`,
              width: 200,
            },
            {
              title: t(`設定内容`),
              width: 500,
              dataIndex: `content`,
              render: (_, record) => getParameterContent(config, record.key),
            },
            {
              title: ``,
              width: 500,
              dataIndex: `isOverriding`,

              render: (_, record) => {
                if (formPreset === undefined || record.key === 'name') {
                  return <></>
                }

                const oldValue = formPreset[record.key] ?? {}
                const newValue = config[record.key] ?? {}
                const diff = recursiveRemoveEmptyValues(deepObjectDiff(oldValue, newValue))
                if (!isPresent(Object.keys(diff))) {
                  return <></>
                }
                return (
                  <>
                    <Tag color="yellow" icon={<WarningOutlined />}>
                      {t(`下記の設定が上書きされます。`)}
                    </Tag>
                    <br />
                    <span className="text-yellow-600">
                      {t(`既存の設定内容`)}: {getParameterContent(formPreset, record.key)}
                    </span>
                  </>
                )
              },
            },
          ]}
          dataSource={dataSource}
          pagination={false}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys,
            onChange: (selectedRowKeys) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
              setSelectedRowKeys(selectedRowKeys as PresetParameterKeys[])
            },
            getCheckboxProps: (record) => ({
              // 新規作成時は開いているシートの列を踏襲するためfieldsを編集不可とする
              disabled: record.key === 'fields' && formPreset === undefined,
              name: record.key,
            }),
          }}
        />
      </Form.Item>
      <Form.Item className="flex justify-end">
        <Button type="primary" htmlType="submit" loading={isSavingCurrentView}>
          {t(`保存`)}
        </Button>
      </Form.Item>
    </Form>
  )
}

function getParameterContent(config: ViewConfigSheet | ViewConfigSheetPreset | undefined, key: PresetParameterKeys) {
  switch (key) {
    // NOTE: useConnectionsSelectorを使ってmodel(+property)名まで出した方が親切か?
    case 'fields': {
      const fields = config?.fields
      return t(`列数 : ${fields?.length}`)
    }
    // TODO: どの項目(列)に設定がかかっているかの内訳も出したい
    case 'filterTree': {
      const filterTreeLeafs = config?.filterTree?.leafs
      return isPresent(filterTreeLeafs) ? t(`項目数 : ${filterTreeLeafs.length}`) : t(`未設定`)
    }
    case 'sorters': {
      const sorter = config?.sorters
      return isPresent(sorter) ? t(`項目数 : ${sorter.length}`) : t(`未設定`)
    }
    default: {
      break
    }
  }

  return <></>
}
