import { useMutation } from '@apollo/client'
import { isSome, isTruthy } from '@salescore/buff-common'
import {
  type PolicyUserRoleFieldsFragment,
  ReplaceCustomRolePoliciesDocument,
  UpdatePolicyUserRoleDocument,
} from '@salescore/client-api'
import { getOrganizationIdFromPath, Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { SuspenseWithLoading } from '@salescore/client-common'
import type { PolicyAction } from '@salescore/features'
import type { useModal } from '@salescore/frontend-common'
import { message, Modal } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { PolicyForm, PolicyFormSchema, type PolicyFormValue } from './PolicyForm'

export const PolicyEditModal = ({
  modal,
}: {
  modal: ReturnType<typeof useModal<{ policyUserRole: PolicyUserRoleFieldsFragment }>>
}) => (
  <Modal
    open={modal.isModalVisible}
    onCancel={modal.hideModal}
    width={'60%'}
    cancelText={t(`閉じる`)}
    okButtonProps={{ style: { display: 'none' } }}
    destroyOnClose
  >
    <SuspenseWithLoading>
      <>
        {modal.content !== undefined && (
          <Body
            policyUserRole={modal.content.policyUserRole}
            onAfterFinish={() => {
              modal.hideModal()
            }}
          />
        )}
      </>
    </SuspenseWithLoading>
  </Modal>
)

function Body({
  policyUserRole,
  onAfterFinish,
}: {
  policyUserRole: PolicyUserRoleFieldsFragment
  onAfterFinish: () => void
}) {
  const [loading, setLoading] = useState(false)
  const [updatePolicyUserRoleMutation] = useMutation(UpdatePolicyUserRoleDocument)
  const [replaceCustomRolePoliciesMutation] = useMutation(ReplaceCustomRolePoliciesDocument)

  const onFinish = async (value: PolicyFormValue) => {
    const validated = PolicyFormSchema.safeParse(value)
    if (!validated.success) {
      void message.warning(t(`入力に誤りがあります`))
      return
    }

    setLoading(true)
    try {
      onAfterFinish()
      const result = await updatePolicyUserRoleMutation({
        variables: {
          organizationId: getOrganizationIdFromPath(),
          policyUserRole: {
            id: policyUserRole.id,
            label: validated.data.label,
            description: validated.data.description ?? '',
          },
        },
        refetchQueries: ['fetchPolicyUserRoles'],
      })

      const actionsWithPermission = Object.entries(validated.data.actionsWithPermission).map(
        ([action, permission]) => ({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          action: action as PolicyAction,
          permission: isTruthy(permission),
        }),
      )

      if (isSome(result.data) && result.data.updatePolicyUserRole.name) {
        const customRolePolicies = await replaceCustomRolePoliciesMutation({
          variables: {
            organizationId: getOrganizationIdFromPath(),
            customRolePolicies: {
              userRole: result.data.updatePolicyUserRole.name,
              actionsWithPermission,
            },
          },
          refetchQueries: ['fetchPolicies'],
        })
        Posthog.track(POSTHOG_EVENTS.save_custom_role, {
          organizationId: getOrganizationIdFromPath(),
          config: customRolePolicies.data?.replaceCustomRolePolicies,
        })
      } else {
        throw new Error(`権限セットを更新できませんでした`)
      }
      void message.success(t(`権限セットを更新しました`))
    } catch {
      void message.error(t(`エラーが発生しました`))
    } finally {
      setLoading(false)
    }
  }

  return <PolicyForm onFinish={onFinish} loading={loading} policyUserRole={policyUserRole} />
}
