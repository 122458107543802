import { useApolloClient, useSuspenseQuery } from '@apollo/client'
import { isPresent, isTruthy } from '@salescore/buff-common'
import {
  type CreateViewInput,
  FetchSharedUrlDocument,
  FetchSheetCustomModelsDocument,
  type MyUserFieldsFragment,
  type SharedUrl,
  SheetThreadCommentNotificationsDocument,
  type ViewFieldsFragment,
  type ViewForSiderFieldsFragment,
  type ViewGroupFieldsFragment,
  type ViewUpdateInputSingle,
} from '@salescore/client-api'
import { getLocalStorageFlags, POSTHOG_EVENTS, routes, Tracker } from '@salescore/client-base'
import { getOrganizationIdFromPath, HandleQuery, NextTickRender, SuspenseWithLoading } from '@salescore/client-common'
import type { UrlQueryParameterMap } from '@salescore/client-recoil'
import { checkSyncEnabled, checkVisualizeEnabled, recoil } from '@salescore/client-recoil'
import { CORE_CONSTANT, type CoreModel, type ViewConfigAdditionalConfig } from '@salescore/core'
import { stableStringify } from '@salescore/features'
import { isSharedLinkPresetName, logger, RecoilDebugObserver } from '@salescore/frontend-common'
import { Button, message } from 'antd'
import { t } from 'i18next'
import Link from 'next/link'
import { type ReactNode, useEffect } from 'react'
import { RecoilRoot, useRecoilValue } from 'recoil'

import { initializeState, type InitializeStateProperties, useReInitializeState } from '../recoil/initializeState'
import { useSheetPickedPresetName } from '../recoil/navigation/hooks'
import { useChangesValue } from '../recoil/records/hooks'
import { useRefetchMutation } from '../recoil/records/mutations/useRefetchMutation'
import { useFeedbackMessagesEffect } from '../recoil/useFeedbackMessagesEffect'
import { useInitializeResources } from '../recoil/useInitialize'
import { usePosthogTrackView } from '../recoil/usePosthogTrack'
import { useWatch } from '../recoil/useWatch'
import {
  type CachedView,
  type DurationMetrics,
  hasChangeToKpiPivotParameterAtom,
  hasChangeToViewSchemaAtom,
  isSavingCurrentViewAtom,
  type ViewAbility,
} from '../recoil/view/atoms'
import { useKpiFormModified, useUiValue, useViewAbilityValue } from '../recoil/view/hooks'
import { useViewMutation } from '../recoil/view/mutations'
import { useCachedViewsSelector } from '../recoil/view/selectors/cachedViewSelector'
import { ConditionalEffectsFormModal } from '../rsheet/components/footer/ConditionalEffectsFormModal'
import { ConditionalEffectsFormModalV2 } from '../rsheet/components/footer/ConditionalEffectsFormModalV2'
import { ConvertLeadsFormModal } from '../rsheet/components/footer/ConvertLeadFormModal'
import { KpiPivotFormModal } from '../rsheet/components/footer/KpiPivotFormModal'
import { MeasureFormModal } from '../rsheet/components/footer/MeasureFormModal'
import { ViewFormModal } from '../rsheet/components/footer/NewKpiFormModal'
import { RecordChangeHistoriesDrawer } from '../rsheet/components/footer/RecordChangeHistoriesDrawer'
import { ViewNotification } from '../rsheet/components/footer/ViewNotification'
import type { ChangeHistory } from '../state/useViewRecordsState/useChangesState'
import { DndContextWrapper } from './view_ui/DndContextWrapper'
import { ViewUIComponentC, ViewUIContext } from './view_ui/ViewUIComponent'
import { KpiBulkDuplicateModal } from './view_ui/ViewUIKpiPivot/KpiBulkDuplicateModal'
import { KpiPresetFormModal } from './view_ui/ViewUIKpiPivot/KpiPresetFormModal'
import { ChangesModal } from './view_ui/ViewUISheetWithNavigation/SheetNavigation/ChangesModal'
import { ColumnsDrawer } from './view_ui/ViewUISheetWithNavigation/SheetNavigation/ColumnsDrawer'
import { CompileLogsModal } from './view_ui/ViewUISheetWithNavigation/SheetNavigation/CompileLogsModal'
import { DimensionFiltersModal } from './view_ui/ViewUISheetWithNavigation/SheetNavigation/DimensionFiltersModal'
import { ModelFormViewModal } from './view_ui/ViewUISheetWithNavigation/SheetNavigation/ModelFormViewModal'
import { NodeSearchSqlFormModal } from './view_ui/ViewUISheetWithNavigation/SheetNavigation/NodeSearchSqlFormModal'
import { ViewQueryResultsMetadataModal } from './view_ui/ViewUISheetWithNavigation/SheetNavigation/ViewQueryResultsMetadataModal'
import { SyncUserPlanNotFoundModal } from './view_ui/ViewUISheetWithNavigation/SyncUserPlanNotFoundModal'
import { ViewDebugModal } from './view_ui/ViewUISheetWithNavigation/ViewDebugModal'
import { ViewDrillDownModal } from './ViewDrillDownModal'
import { ViewLoading } from './ViewLoading' // ViewComponent内でviewの作成・更新を行う場合、サイドバー側のviewsも更新を行う必要がある。

// ViewComponent内でviewの作成・更新を行う場合、サイドバー側のviewsも更新を行う必要がある。
// これを円滑に行うため、これらのロジックはViewComponentには持たせず、注入する形にする
type ViewContextCreateViewArgument = CreateViewInput & {
  shouldOpenAsTab?: boolean
}

export interface ViewsContext {
  models: CoreModel[]
  views: ViewFieldsFragment[]
  viewGroups: ViewGroupFieldsFragment[]
  viewsForSider: ViewForSiderFieldsFragment[]
  cachedViews?: CachedView[]
  durationMetricsList?: DurationMetrics[]
  prevView?: ViewFieldsFragment
  parentViewProtectingUnload?: boolean
  shouldInitializeWithNewRecord?: boolean // このフラグが指定されているとき、queryは実行せず、1つの空レコードのみでrecordsを初期化する。フォームUIでの新規作成で利用。
  createView: (argument: ViewContextCreateViewArgument) => Promise<ViewFieldsFragment | undefined>
  updateView: (argument: ViewUpdateInputSingle) => Promise<ViewFieldsFragment | undefined>
  deleteView: (argument: { id: string }) => Promise<void>
  pickView: (viewId: string) => void
  refetchViews: () => Promise<void>
  onSaveView: (
    views: ViewFieldsFragment[],
    option?: { forceFetch?: boolean; shouldOpenAsTab?: boolean },
  ) => void | Promise<void>
  onAddResource: (argument: { viewIds?: string[] }) => Promise<void>
  onChangesChange: (hasChange: boolean) => void
  hasChanges: boolean
  updateCachedView?: (view: CachedView) => void
  updateDurationMetricsList?: (function_: (oldMetricsList: DurationMetrics[]) => DurationMetrics[]) => void
  setIsSaveConfigButtonActive: (isActive: boolean) => void
  isSaveConfigButtonActive: boolean
  isSheetMoreActionsCopyVisible?: boolean
  isChangedDrillDownModal?: boolean
  setIsChangedDrillDownModal?: (isChanged: boolean) => void
  isDrillDown?: boolean
  kpiPivotPresetName?: string
}

export interface ViewComponentArgument {
  view: ViewFieldsFragment
  parameter: Record<string, unknown>
  urlQueryParameters?: UrlQueryParameterMap
  sharedUrl?: SharedUrl
  additionalConfig?: ViewConfigAdditionalConfig
  oldestSyncedAt?: string
  isEditMode?: boolean // TODO: 編集もviewの中で行う形にし、引数として渡すのはやめたい。
  onFinish?: (argument?: { id?: string }) => void
  viewsContext: ViewsContext
}

const ViewComponentBody = (argument: ViewComponentArgument): ReactNode => {
  const { parameter, view, urlQueryParameters, additionalConfig, oldestSyncedAt, viewsContext } = argument
  const client = useApolloClient()
  const me = recoil.global.useMe()
  const sheetThreadCommentNotificationsQuery = useSuspenseQuery(SheetThreadCommentNotificationsDocument, {
    variables: {
      organizationId: me.organization.id,
    },
  })
  const {
    data: { sheetCustomModels },
  } = useSuspenseQuery(FetchSheetCustomModelsDocument, {
    variables: {
      organizationId: me.organization.id,
      viewId: view.id,
    },
    fetchPolicy: 'no-cache', // 最新の値じゃないと不具合になりうるので、キャッシュしないことにした
  })
  const posthogTrackView = usePosthogTrackView()
  const shareId = urlQueryParameters?.get('shareId')
  const { data: sharedUrlQuery } = useSuspenseQuery(FetchSharedUrlDocument, {
    variables: {
      id: shareId?.value ?? ``,
      organizationId: me.organization.id,
    },
  })
  const sharedUrl: SharedUrl | undefined =
    argument.sharedUrl ??
    (isPresent(shareId) && isPresent(sharedUrlQuery.sharedUrl) ? sharedUrlQuery.sharedUrl : undefined)

  useEffect(() => {
    switch (view.type) {
      case 'sheet': {
        posthogTrackView(POSTHOG_EVENTS.view_sheet, {
          viewId: view.id,
          prevViewId: view.id.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX)
            ? view.id.slice(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX.length)
            : viewsContext.prevView?.id,
          prevViewType: view.id.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX)
            ? 'kpi'
            : viewsContext.prevView?.type,
        })
        Tracker.shared().track({ category: 'view', value: `sheet` })
        break
      }
      case 'kpi': {
        posthogTrackView(POSTHOG_EVENTS.view_kpi, {
          viewId: view.id,
          prevViewId: viewsContext.prevView?.id,
          prevViewType: viewsContext.prevView?.type,
        })
        Tracker.shared().track({ category: 'view', value: `kpi` })
        break
      }
      case 'kpiPivot': {
        posthogTrackView(POSTHOG_EVENTS.view_dashboard, {
          viewId: view.id,
          prevViewId: viewsContext.prevView?.id,
          prevViewType: viewsContext.prevView?.type,
        })
        Tracker.shared().track({ category: 'view', value: `kpiPivot` })
        break
      }
      default: {
        break
      }
    }
  }, [])

  const mustacheParameter = {
    ...parameter,
    ...me.myUser.resourceUser, // TODO: ネストさせる？
  }
  const recoilProperties = {
    organizationId: getOrganizationIdFromPath(),
    view,
    client,
    mustacheParameter,
    me,
    additionalConfig,
    oldestSyncedAt,
    viewsContext,
  }

  // additionalConfigが変わったときにリフレッシュするかどうか
  // 基本的にするべきだが、KPIのシートのときは列設定するたびにadditionalConfigの中身が変わるので、リフレッシュしない
  // TODO: この「リフレッシュすべきかどうか」の判定ロジックが現状整理できておらず、必要かどうかもわかっていない。。
  //       一旦以下対応はしているが、そもそも全体的に見直ししたい
  const shouldRefreshWhenAdditionalConfigChanged = !view.id.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX)

  return (
    <SuspenseWithLoading type="custom" loadingElement={<ViewLoading context={{ view }} />}>
      <AbilityWrapper view={view} user={me.myUser}>
        {(ability) => (
          <RecoilRoot
            initializeState={initializeState({
              ...recoilProperties,
              ability,
              sheetCustomModels,
              sharedUrl,
              sheetThreadCommentNotifications:
                sheetThreadCommentNotificationsQuery.data.sheetThreadCommentNotifications,
            })}
            // TODO: ここのキャッシュ条件がかなり微妙。。nameはKPIのためだけに指定している
            key={[
              view.id,
              view.name,
              shouldRefreshWhenAdditionalConfigChanged ? JSON.stringify(additionalConfig) : ``,
              stableStringify(shareId),
            ].join(',')}
          >
            {/* // viewの切り替え時に、新しいviewに対して古いconfigでmutationを発火させるなどが発生しないよう、都度RecoilRootを作り直す */}
            <RecoilDebugObserver />
            <Wrapper fallback={<ViewLoading context={{ view }} />}>
              <Body
                recoilProps={recoilProperties}
                isEditMode={argument.isEditMode}
                parentViewProtectingUnload={viewsContext.parentViewProtectingUnload}
                onFinish={argument.onFinish}
                onChangesChange={argument.viewsContext.onChangesChange}
                setIsSaveConfigButtonActive={argument.viewsContext.setIsSaveConfigButtonActive}
                onSaveView={argument.viewsContext.onSaveView}
              />
            </Wrapper>
          </RecoilRoot>
        )}
      </AbilityWrapper>
    </SuspenseWithLoading>
  )
}

export function ViewComponent(argument: ViewComponentArgument): ReactNode {
  const { view } = argument
  return (
    <HandleQuery loadingElement={<ViewLoading context={{ view }} />}>
      <ViewComponentBody {...argument} />
    </HandleQuery>
  )
}

//
// 本当は各所のロジックでuseCanを呼び出したかったが、この中身はasync selectorであり、
// mutationから呼び出そうとするとエラーになってしまうため、ここで前もって計算した値をrecoilのatomに渡すことにした。
// 結局残念な実装になってしまった感がある
function AbilityWrapper({
  user,
  view,
  children,
}: {
  user: MyUserFieldsFragment
  view: ViewFieldsFragment
  children: (ability: ViewAbility) => JSX.Element
}): ReactNode {
  // KPIの中のシートの場合、元のKPIの権限を使う
  if (view.id.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX)) {
    const ability = useViewAbilityValue()
    return <>{children(ability)}</>
  }

  const canCreate = recoil.global.useCanForView('create', view)
  const canUpdate = recoil.global.useCanForView('update', view)
  const canDelete = recoil.global.useCanForView('delete', view)
  const canSaveRecord = recoil.global.useCanForView('save-record', view)
  const canCreateNewRecord = recoil.global.useCanForView('create-new-record', view)
  const canDeleteRecord = recoil.global.useCanForView('delete-record', view)
  const canOpenRelationInputForm = recoil.global.useCanForView('open-relation-input-form', view)
  const canDownloadCsv = recoil.global.useCanForView('download-csv', view)
  // ついでにライセンスのチェックも行なってしまう
  const isVisualizeEnabled = checkVisualizeEnabled(user)
  const isSyncEnabled = checkSyncEnabled(user)

  return (
    <>
      {children({
        canCreate,
        canUpdate,
        canDelete,
        canCreateNewRecord,
        canSaveRecord,
        canDeleteRecord,
        isVisualizeEnabled,
        isSyncEnabled,
        canOpenRelationInputForm,
        canDownloadCsv,
      })}
    </>
  )
}

//
// children以下のコンポーネントの描画を1tick遅らせるためのラッパー
// recoil周りの挙動で不可解な点があり、このラッパーをいれないとSuspenseが解決されないため導入。以下、その説明
//
// ・RecoilRootのinitializeStateでatomを初期化する(ここではmeAtomを初期化することにする)
// ・RecoilRootのすぐ下のコンポーネントで、useCanというフックを呼び出す
// ・useCanの実態はuseRecoilValue(selectorFamily)であり、このfamilyの中でmeAtomを参照している。このselectorFamilyはasync selectorである。
// ・これらの条件が揃った時、Suspenseが一向に解決されない。
//
function Wrapper({ children, fallback }: { children: JSX.Element; fallback: JSX.Element }) {
  return <NextTickRender fallback={fallback}>{children}</NextTickRender>
}

function Body({
  recoilProps,
  isEditMode,
  parentViewProtectingUnload,
  onFinish,
  onChangesChange,
  setIsSaveConfigButtonActive,
  onSaveView,
}: {
  recoilProps: InitializeStateProperties
  isEditMode?: boolean
  parentViewProtectingUnload?: boolean
  onFinish?: ViewComponentArgument['onFinish']
  onChangesChange?: (hasChange: boolean) => void
  setIsSaveConfigButtonActive?: (isActive: boolean) => void
  onSaveView?: (views: ViewFieldsFragment[]) => void
}) {
  useReInitializeState(recoilProps)
  useInitializeResources(recoilProps)
  useWatch({ onSaveView })
  useFeedbackMessagesEffect()
  const ui = useUiValue()
  const mutation = useViewMutation()
  const changes = useChangesValue()
  const kpiFormModified = useKpiFormModified()
  useStopUnload(parentViewProtectingUnload ?? false, changes, kpiFormModified, onChangesChange)
  const recordsMutation = useRefetchMutation()
  const hasChangeToViewSchema = useRecoilValue(hasChangeToViewSchemaAtom)
  const [sheetPresetName] = useSheetPickedPresetName()
  const isSavingCurrentView = useRecoilValue(isSavingCurrentViewAtom)

  useEffect(() => {
    if (setIsSaveConfigButtonActive !== undefined) {
      setIsSaveConfigButtonActive(
        !isSharedLinkPresetName(sheetPresetName) && (hasChangeToViewSchema || isSavingCurrentView),
      )
    }
  }, [hasChangeToViewSchema || isSavingCurrentView, sheetPresetName])

  return (
    <ViewUIContext.Provider
      value={{
        isEditMode: isEditMode ?? false,
        onFinish,
      }}
    >
      <DndContextWrapper isEditMode={isEditMode ?? false} mutation={mutation}>
        <>
          <div className="view-component-root h-full">
            {ui.map((component) => (
              <ViewUIComponentC component={component} />
            ))}
          </div>

          <>
            {/* 汎用modalはここにおくべきだろうか？ */}
            <ChangesModal />
            <ViewQueryResultsMetadataModal />
            <ViewDebugModal />
            <ViewDrillDownModal
              onFinish={async () => {
                await recordsMutation.refetch({ kind: 'reset' })
              }}
            />
            <KpiPivotFormModal />
            <CompileLogsModal />
            <MeasureFormModal />
            <ViewFormModal />
            <NodeSearchSqlFormModal />
            <ConditionalEffectsFormModal />
            <ConditionalEffectsFormModalV2 />
            <ConvertLeadsFormModal />
            <RecordChangeHistoriesDrawer />
            <ViewNotification />
            <KpiPresetFormModal />
            <SyncUserPlanNotFoundModal />
            <DimensionFiltersModal />
            <ColumnsDrawer />
            <ModelFormViewModal />
            <KpiBulkDuplicateModal />
          </>
        </>
      </DndContextWrapper>
    </ViewUIContext.Provider>
  )
}

function useStopUnload(
  parentViewProtectingUnload: boolean,
  changes: ChangeHistory[],
  kpiFormModified: boolean,
  onChangesChange?: (hasChange: boolean) => void,
) {
  const { filteredCachedViews } = useCachedViewsSelector()
  const hasChangeToViewSchema = useRecoilValue(hasChangeToViewSchemaAtom)
  const hasChangeToKpiPivotParameter = useRecoilValue(hasChangeToKpiPivotParameterAtom)
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const stopUnload = (event: BeforeUnloadEvent) => {
    logger.debug(`stopUnload called`)
    event.preventDefault()
    event.returnValue = ''
  }

  useEffect(() => {
    if (parentViewProtectingUnload) {
      return
    }
    //
    // スクロールバックをoverscrollBehaviorを使って禁止しているのだが、一部PCで動作しないため、以下の対応をしている。
    // この方法でブラウザバックを禁止しても、ウィンドウを開いた直後だと上手く動作しない
    // https://www.zdnet.com/article/google-working-on-blocking-back-button-hijacking-in-chrome/
    //
    if (getLocalStorageFlags()?.shouldPreventPopstate ?? false) {
      window.addEventListener('popstate', function (e) {
        history.pushState(null, '', null)
        void message.info(
          <span>
            {t(`ブラウザバックが禁止されています。`)}
            <Link href={routes.mePath()} legacyBehavior>
              <Button size="small">{t(`設定`)}</Button>
            </Link>
          </span>,
        )
      })
    }
  }, [])

  useEffect(() => {
    if (parentViewProtectingUnload) {
      return
    }

    const hasChangesToCurrentTab =
      changes.isPresent() || kpiFormModified || hasChangeToViewSchema || hasChangeToKpiPivotParameter
    const hasChangesToOtherTab = filteredCachedViews.some(
      (x) =>
        isPresent(x.changes) ||
        isTruthy(x.isModifiedKpiForm) ||
        isTruthy(x.hasChangeToViewSchema) ||
        isTruthy(x.hasChangeToKpiPivotParameter),
    )

    if (hasChangesToCurrentTab || hasChangesToOtherTab) {
      window.addEventListener('beforeunload', stopUnload)
      if (hasChangesToCurrentTab && onChangesChange !== undefined) {
        onChangesChange(true)
      }
    } else {
      window.removeEventListener('beforeunload', stopUnload)
      if (onChangesChange !== undefined) {
        onChangesChange(false)
      }
    }
    return () => {
      window.removeEventListener('beforeunload', stopUnload)
      if (onChangesChange !== undefined) {
        onChangesChange(false)
      }
    }
  }, [
    kpiFormModified,
    hasChangeToViewSchema,
    hasChangeToKpiPivotParameter,
    stableStringify(changes),
    stableStringify(filteredCachedViews),
  ])
}
