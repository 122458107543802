import {
  EditOutlined,
  FlagOutlined,
  FolderOutlined,
  PartitionOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { isNull, isPresent, isSome, stringUtil } from '@salescore/buff-common'
import {
  DeleteGoalConfigDocument,
  FetchGoalConfigsDocument,
  type GoalConfigFieldsFragment,
} from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import { getOrganizationIdFromPath, SuspenseWithLoading } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import {
  createDestroyColumn,
  getColumnSearchProps,
  getQueryParameter,
  useModal,
  useRedirect,
} from '@salescore/frontend-common'
import { Alert, Button, Input, Modal, Space, Table } from 'antd'
import { t } from 'i18next'
import { type ReactNode, useMemo, useState } from 'react'

import { CreateGoalConfigForm } from './CreateGoalConfigForm'
import { goalConfigTimeSpanTypeOptions, goalConfigUserTypeOptions, UpdateGoalConfigForm } from './UpdateGoalConfigForm'

interface GoalConfigGroup {
  key: string
  dashboardViewId: string
  dashboardViewName: string | undefined
  goalConfigs: GoalConfigFieldsFragment[]
  dashboardViewCreatedBy: string | undefined
  viewGroupPath: string | undefined
}

export function GoalConfigsTable(): ReactNode {
  const {
    data: { goalConfigs },
    refetch,
  } = useSuspenseQuery(FetchGoalConfigsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const { canManageGoal } = recoil.global.policy.useCanForFeatures()

  if (!canManageGoal) {
    // そもそも辿り着かないはずなので、最小限のUIにしている
    return (
      <div>
        <Alert type="warning" message={t(`権限がありません`)} />
      </div>
    )
  }

  return (
    <Body
      goalConfigs={goalConfigs}
      refetch={() => {
        void refetch()
      }}
    />
  )
}

function Body({ goalConfigs, refetch }: { goalConfigs: GoalConfigFieldsFragment[]; refetch: () => void }): ReactNode {
  const [deleteGoalConfigMutation] = useMutation(DeleteGoalConfigDocument)
  const createModal = useModal(isSome(getQueryParameter('dashboardViewId')))
  const editModal = useModal<{ goalConfig: GoalConfigFieldsFragment }>()
  const [destroyLoading, setDestroyLoading] = useState(false)
  const redirect = useRedirect()
  const { searchViewGroupWithParents } = recoil.global.view.useViewGroupsRelatedSelector()
  const { searchView } = recoil.global.view.useViewsRelatedSelector()
  const [searchQuery, setSearchQuery] = useState('')

  const goalConfigGroups = useMemo(
    () =>
      goalConfigs
        .groupBy((x) => x.dashboardViewId ?? '')

        .map((dashboardViewId, groupedGoalConfigs): GoalConfigGroup | undefined => {
          const dashboardView = searchView(dashboardViewId)
          const viewGroupWithParents = searchViewGroupWithParents(dashboardView?.viewGroupId ?? '')
          const goalConfigGroup: GoalConfigGroup = {
            key: dashboardViewId,
            dashboardViewId,
            dashboardViewName: groupedGoalConfigs.first()!.dashboardView?.name,
            dashboardViewCreatedBy: dashboardView?.createdBy?.name ?? undefined,
            viewGroupPath: [
              ...(viewGroupWithParents?.parents.map((x) => x.name) ?? []),
              viewGroupWithParents?.viewGroup.name,
            ].join(' / '),
            goalConfigs: groupedGoalConfigs,
          }
          if (!isPresent(searchQuery)) {
            return goalConfigGroup
          }
          //
          // 検索クエリがあるとき
          //
          // ダッシュボード名、作者名、パスが検索クエリにマッチしたら、紐づく全てのgoalConfigsを返す
          if (
            stringUtil.search(
              searchQuery,
              [
                goalConfigGroup.dashboardViewName,
                goalConfigGroup.dashboardViewCreatedBy,
                goalConfigGroup.viewGroupPath,
              ].compact(),
            )
          ) {
            return goalConfigGroup
          }
          // 一致するgoalConfigsのみを返す。なければ返さない
          const filteredGoalConfigs = groupedGoalConfigs.filter((x) => stringUtil.search(searchQuery, [x.name]))
          if (filteredGoalConfigs.isBlank()) {
            return undefined
          }
          return {
            ...goalConfigGroup,
            goalConfigs: filteredGoalConfigs,
          }
        })
        .compact(),
    [searchQuery, goalConfigs],
  )

  return (
    <PageHeader
      title={t(`目標設定`)}
      className="mx-4 my-6"
      extra={[
        <Button
          key={'redirect'}
          // type="primary"
          icon={<PartitionOutlined />}
          onClick={() => {
            redirect(routes.goalDimensionsPathV2())
          }}
        >
          {t(`目標軸の設定`)}
        </Button>,
        <Button
          key={'new'}
          type="primary"
          onClick={() => {
            createModal.showModal()
          }}
        >
          {t(`新規作成`)}
        </Button>,
      ]}
    >
      <div className="mb-3">
        <Input.Search
          placeholder={t(`キーワードで検索`)}
          // enterButton="検索"
          onSearch={(query) => {
            setSearchQuery(query)
          }}
        />
      </div>
      <Table
        dataSource={goalConfigGroups.sortBy((x) => [x.dashboardViewName])}
        expandable={{
          expandRowByClick: true,
          expandedRowKeys: isPresent(searchQuery) ? goalConfigGroups.map((x) => x.key) : undefined,
          expandedRowRender: (groupedGoalConfigs) => (
            <Table
              columns={[
                {
                  title: t(`目標名`),
                  dataIndex: `name`,
                  ...getColumnSearchProps<GoalConfigFieldsFragment>((record) => record.name),
                  render: (_, record) => (
                    <Space className="font-bold">
                      <FlagOutlined />
                      {record.name}
                    </Space>
                  ),
                },
                {
                  title: t(`作成者`),
                  dataIndex: `createdBy`,
                  render: (_, record) => {
                    if (!isPresent(record.createdBy)) {
                      return
                    }
                    return (
                      <Space>
                        <UserOutlined />
                        {record.createdBy.name}
                      </Space>
                    )
                  },
                },
                {
                  title: t(`時間種別`),
                  dataIndex: `timeSpanType`,
                  render: (_, record) => (
                    <span>
                      {goalConfigTimeSpanTypeOptions.find((x) => x.value === record.timeSpanType)?.label ??
                        record.timeSpanType}
                    </span>
                  ),
                },
                {
                  title: t(`ユーザー種別`),
                  dataIndex: `userType`,
                  render: (_, record) => (
                    <span>
                      {goalConfigUserTypeOptions.find((x) => x.value === record.userType)?.label ?? record.userType}
                    </span>
                  ),
                },
                {
                  title: t(`目標軸`),
                  dataIndex: `goalDimension`,

                  render: (_, record) => {
                    const { goalDimension1, goalDimension2, goalDimension3, goalDimension4, goalDimension5 } = record
                    return (
                      <div>
                        {[goalDimension1, goalDimension2, goalDimension3, goalDimension4, goalDimension5].every((x) =>
                          isNull(x),
                        ) && t(`なし`)}
                        {isSome(goalDimension1) && <div>1: {goalDimension1.label}</div>}
                        {isSome(goalDimension2) && <div>2: {goalDimension2.label}</div>}
                        {isSome(goalDimension3) && <div>3: {goalDimension3.label}</div>}
                        {isSome(goalDimension4) && <div>4: {goalDimension4.label}</div>}
                        {isSome(goalDimension5) && <div>5: {goalDimension5.label}</div>}
                      </div>
                    )
                  },
                },
                {
                  title: ``,
                  key: `input`,
                  width: 100,
                  render: (_, record) => (
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => {
                        redirect(routes.goalConfigPathV2(record.id))
                      }}
                    >
                      {t(`目標入力`)}
                    </Button>
                  ),
                },
                {
                  title: ``,
                  key: `edit`,
                  width: 100,
                  render: (_, record) => (
                    <Button
                      icon={<SettingOutlined />}
                      onClick={() => {
                        editModal.showModal({ goalConfig: record })
                      }}
                    >
                      {t(`設定`)}
                    </Button>
                  ),
                },
                createDestroyColumn(destroyLoading, setDestroyLoading, async (record: GoalConfigFieldsFragment) => {
                  setDestroyLoading(true)
                  await deleteGoalConfigMutation({
                    variables: {
                      id: record.id,
                      organizationId: getOrganizationIdFromPath(),
                    },
                  })
                  refetch()
                  setDestroyLoading(false)
                }),
              ]}
              dataSource={groupedGoalConfigs.goalConfigs}
              pagination={false}
            />
          ),
          defaultExpandedRowKeys: ['0'],
        }}
        columns={[
          {
            title: t(`ダッシュボード`),
            key: `dashboardViewId`,
            render: (_, record) => <Space className="font-bold">{record.dashboardViewName}</Space>,
            ...getColumnSearchProps<GoalConfigGroup>((record) => record.dashboardViewName ?? ''),
          },
          {
            title: t(`作成者`),
            key: `dashboardViewCreatedBy`,
            render: (_, record) => {
              if (!isPresent(record.dashboardViewCreatedBy)) {
                return <></>
              }
              return (
                <Space>
                  <UserOutlined /> {record.dashboardViewCreatedBy}
                </Space>
              )
            },
            ...getColumnSearchProps<GoalConfigGroup>((record) => record.dashboardViewCreatedBy ?? ''),
          },
          {
            title: t(`フォルダ`),
            key: `viewGroupPath`,
            render: (_, record) => {
              if (!isPresent(record.viewGroupPath)) {
                return <></>
              }
              return (
                <Space>
                  <FolderOutlined /> {record.viewGroupPath}
                </Space>
              )
            },
            ...getColumnSearchProps<GoalConfigGroup>((record) => record.viewGroupPath ?? ''),
          },
        ]}
      />
      <Modal
        open={createModal.isModalVisible}
        onCancel={createModal.hideModal}
        width={'60%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div></div>}
        style={{ top: '3%' }}
        destroyOnClose
      >
        <SuspenseWithLoading>
          <CreateGoalConfigForm
            onAfterFinish={(goalId: string | undefined) => {
              if (isPresent(goalId)) {
                redirect(routes.goalConfigPathV2(goalId))
              }
              refetch()
              createModal.hideModal()
            }}
          />
        </SuspenseWithLoading>
      </Modal>
      <Modal
        open={editModal.isModalVisible}
        onCancel={editModal.hideModal}
        width={'60%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div></div>}
        style={{ top: '3%' }}
        destroyOnClose
      >
        {editModal.content !== undefined && (
          <UpdateGoalConfigForm
            goalConfig={editModal.content.goalConfig}
            onAfterFinish={() => {
              refetch()
              editModal.hideModal()
            }}
            onBack={() => {
              editModal.hideModal()
            }}
          />
        )}
      </Modal>
    </PageHeader>
  )
}
