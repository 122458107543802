import { CONSTANT } from '@salescore/client-base'

import type { KpiUserAppearance } from '../../../../../recoil/navigation/atoms'
import type { KpiInfo } from './extractKpiInfo'

interface TransitionInfo {
  transitionRatio?: number
  transitionRatioGoal?: number
  diffTransitionRatio?: number
}

interface ColorSchema {
  success: string
  danger: string
  normal: string
}

const COLOR_SCHEMA_NORMAL: ColorSchema = {
  success: CONSTANT.colorClasses.SUCCESS_COLOR,
  danger: CONSTANT.colorClasses.DANGER_COLOR,
  normal: CONSTANT.colorClasses.NORMAL_COLOR,
}
const COLOR_SCHEMA_BLUE: ColorSchema = {
  success: CONSTANT.colorClasses.SUCCESS_COLOR_BLUE,
  danger: CONSTANT.colorClasses.DANGER_COLOR,
  normal: CONSTANT.colorClasses.NORMAL_COLOR,
}

export type CellStyle = ReturnType<typeof extractCellStyle>

// eslint-disable-next-line complexity
const calculateColor = (kpiInfo: KpiInfo, colorSchema: ColorSchema) => {
  if (kpiInfo.actual === undefined || kpiInfo.kpi === undefined) {
    return colorSchema.normal
  }
  const progressPercent = kpiInfo.progressRate === undefined ? undefined : Math.round(kpiInfo.progressRate * 100)
  const actual = kpiInfo.actual ?? 0

  // 割合系のとき、常にgoalの値と比較
  if (kpiInfo.kpi.aggregationFunction === 'avg') {
    if (kpiInfo.goal === undefined) {
      return colorSchema.normal
    }

    if (
      kpiInfo.kpi.achievementStatusCalculationType === 'greaterThanOrEqual' ||
      kpiInfo.kpi.achievementStatusCalculationType === undefined
    ) {
      return actual >= kpiInfo.goal ? colorSchema.success : colorSchema.danger
    }
    if (kpiInfo.kpi.achievementStatusCalculationType === 'lessThanOrEqual') {
      return actual <= kpiInfo.goal ? colorSchema.success : colorSchema.danger
    }
    const x: never = kpiInfo.kpi.achievementStatusCalculationType
    return colorSchema.normal
  }

  if (kpiInfo.kpi.aggregationFunction === 'sum' || kpiInfo.kpi.aggregationFunction === undefined) {
    // 目標タイプがゼロのとき
    if (kpiInfo.kpi.achievementStatusTargetValueType === 'zero') {
      if (
        kpiInfo.kpi.achievementStatusCalculationType === 'greaterThanOrEqual' ||
        kpiInfo.kpi.achievementStatusCalculationType === undefined
      ) {
        return actual >= 0 ? colorSchema.success : colorSchema.danger
      }
      if (kpiInfo.kpi.achievementStatusCalculationType === 'lessThanOrEqual') {
        return actual <= 0 ? colorSchema.success : colorSchema.danger
      }
      const x: never = kpiInfo.kpi.achievementStatusCalculationType
    }

    if (kpiInfo.goal === undefined) {
      return colorSchema.normal
    }

    // 目標タイプが目標そのままのとき
    if (kpiInfo.kpi.achievementStatusTargetValueType === 'goal') {
      if (
        kpiInfo.kpi.achievementStatusCalculationType === 'greaterThanOrEqual' ||
        kpiInfo.kpi.achievementStatusCalculationType === undefined
      ) {
        return actual >= kpiInfo.goal ? colorSchema.success : colorSchema.danger
      }
      if (kpiInfo.kpi.achievementStatusCalculationType === 'lessThanOrEqual') {
        return actual <= kpiInfo.goal ? colorSchema.success : colorSchema.danger
      }
      const x: never = kpiInfo.kpi.achievementStatusCalculationType
    }

    // どちらも0だったら通常色とする
    if (kpiInfo.actual === 0 && kpiInfo.goal === 0) {
      return colorSchema.normal
    }

    if (kpiInfo.ratio === undefined || progressPercent === undefined) {
      // 割合が求められないパターンのうち、実績値はあって目標が0のときはsuccess。そうでなければ通常色。
      return kpiInfo.actual >= 0 && kpiInfo.goal === 0 ? colorSchema.success : colorSchema.normal
    }

    const targetGoal = kpiInfo.kpi.achievementStatusTargetValueType === `goal` ? 100 : progressPercent
    if (
      kpiInfo.kpi.achievementStatusCalculationType === 'greaterThanOrEqual' ||
      kpiInfo.kpi.achievementStatusCalculationType === undefined
    ) {
      return kpiInfo.ratio >= targetGoal ? colorSchema.success : colorSchema.danger
    }
    if (kpiInfo.kpi.achievementStatusCalculationType === 'lessThanOrEqual') {
      return kpiInfo.ratio <= targetGoal ? colorSchema.success : colorSchema.danger
    }
    const x: never = kpiInfo.kpi.achievementStatusCalculationType
    return kpiInfo.ratio >= targetGoal ? colorSchema.success : colorSchema.danger
  }

  const x: never = kpiInfo.kpi.aggregationFunction
  return colorSchema.normal
}

export const extractCellStyle = (
  kpiInfo: KpiInfo,
  transitionInfo: TransitionInfo | undefined,
  kpiUserAppearance: KpiUserAppearance,
) => {
  const colorSchema = kpiUserAppearance.colorSchema === 'success-green' ? COLOR_SCHEMA_NORMAL : COLOR_SCHEMA_BLUE
  const color = calculateColor(kpiInfo, colorSchema)
  const transitionColor =
    transitionInfo === undefined
      ? ''
      : transitionInfo.transitionRatio !== undefined &&
          transitionInfo.transitionRatioGoal !== undefined &&
          Number.isFinite(transitionInfo.transitionRatio) &&
          Number.isFinite(transitionInfo.transitionRatioGoal)
        ? transitionInfo.transitionRatio >= transitionInfo.transitionRatioGoal
          ? colorSchema.success
          : colorSchema.danger
        : ''
  const actualSize =
    kpiInfo.actual === undefined
      ? 'text-xl'
      : Math.abs(kpiInfo.actual) > 10 ** 10
        ? 'text-xs'
        : Math.abs(kpiInfo.actual) > 10 ** 4
          ? ''
          : 'text-xl'
  const goalSize =
    kpiInfo.goal === undefined
      ? 'text-sm'
      : Math.abs(kpiInfo.goal) > 10 ** 10
        ? 'text-xs'
        : Math.abs(kpiInfo.goal) > 10 ** 4
          ? 'text-xs'
          : 'text-sm'

  return {
    color,
    transitionColor,
    actualSize,
    goalSize,
  }
}
