import { PlayCircleFilled, ShareAltOutlined, UserOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useSuspenseQuery } from '@apollo/client'
import { isNull, isPresent, isSome, r } from '@salescore/buff-common'
import { FetchS3SignedUrlDocument } from '@salescore/client-api'
import { CONSTANT, getOrganizationIdFromPath } from '@salescore/client-base'
import { SuspenseWithLoading } from '@salescore/client-common'
import { JsonSyntaxHighlighter, logger } from '@salescore/frontend-common'
import { Avatar, Button, Select, Space, Tag } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { z } from 'zod' // produces a formatted HTML string

import { useRecordsValue } from '../../../recoil/records/hooks'
import { useViewConfig } from '../../../recoil/view/hooks'
import { calculateTextDiff } from './textDiff'

export const extractedModelName = `custom_d_extracted_calling_metadata_refined`

//
// 2023/11 IS-GPTの仮実装。実装を楽にするため、ViewComponentのロジックに一部のっかりつつ、ViewUIFormから分岐する形で実装している
//         特定のモデルで、特定のフィールドを持つことや、また文字起こしの内容が特定の形式であることを前提としている
//

export function ExtractedCallingMetadataPage() {
  const config = useViewConfig()
  const records = useRecordsValue()
  const [displayType, setDisplayType] = useState<'refined' | 'raw' | 'diff'>('raw')
  // eslint-disable-next-line complexity
  const metadata = useMemo(() => {
    logger.debug(`[DEBUG] records`, records)
    const record =
      config.type === 'form' && config.tree?.modelName === extractedModelName
        ? records.first()
        : records
            .first()
            ?.children.find((x) => x.nodeName === extractedModelName)
            ?.children.first()
    const outputSchema = z.object({
      isReceiptBreak: z.boolean().nullish(),
      isReceiptBreakTime: z.string().nullish(),
      isWinApo: z.boolean().nullish(),
      isCallback: z.boolean().nullish(),
      competMention: z.union([z.string().array().nullish(), z.string()]).nullish(),
      competMentionTime: z.string().nullish(),
      isBudgetMention: z.boolean().nullish(),
      isPayerMention: z.boolean().nullish(),
      isPairInquiry: z.boolean().nullish(),
      isPairInquiryTime: z.string().nullish(),
      performanceTalkCnt: z.number().nullish(),
      inCharge: z.string().nullish(),
      department: z.string().nullish(),
      roll: z.string().nullish(),
      settleMonth: z.string().nullish(),
      appoDate: z.string().nullish(),
      followUpDate: z.string().nullish(),
      salesCnt: z.number().nullish(),
      closeness: z.string().nullish(),
      summary: z.string().nullish(),
      s3_recording_path: z.string().nullish(),
      transcript: z.string().nullish(),
      transcript_refined: z.string().nullish(),
      // taskの情報
      salesforce_task_owner_id: z.string().nullish(),
      salesforce_task_owner_id_label: z.string().nullish(),
      salesforce_task_account_id: z.string().nullish(),
      salesforce_task_account_id_label: z.string().nullish(),
      salesforce_task_contact_id: z.string().nullish(),
      salesforce_task_contact_id_label: z.string().nullish(),
      salesforce_task_activity_date: z.string().nullish(),
      // アノテーションの情報
      custom_s_extracted_calling_metadata_annotations_memo: z.string().nullish(),
    })
    const shortened = r(record?.attributes ?? {}).transformKeys((key) => key.replace(`${extractedModelName}_`, ''))
    logger.debug(`[DEBUG] shortened`, shortened.data)
    // taskを関連づけしていれば、その情報も使う
    const taskAttributes = records[0]?.children.find((x) => x.nodeName === `salesforce_task`)?.children[0]?.attributes
    const annotationAttributes = records[0]?.children.find(
      (x) => x.nodeName === 'custom_s_extracted_calling_metadata_annotations',
    )?.children[0]?.attributes
    const parsed = outputSchema.safeParse({
      ...annotationAttributes,
      ...taskAttributes,
      ...shortened.data,
    })
    if (!parsed.success) {
      logger.debug(`[DEBUG] parsed.error`, parsed.error)
    }
    const data = parsed.success ? parsed.data : {}
    // 言及箇所の時刻をfloatにしておく
    const competMentionTimeFloat = isPresent(data.competMentionTime)
      ? Number.parseFloat(data.competMentionTime)
      : undefined
    const isPairInquiryTimeFloat = isPresent(data.isPairInquiryTime)
      ? Number.parseFloat(data.isPairInquiryTime)
      : undefined

    const toMessage = (line: string) => {
      const [meta, text] = line.split(': ')
      if (meta === undefined || text === undefined) {
        return
      }
      const [time, speaker] = meta.split('] ')
      if (time === undefined || speaker === undefined) {
        return
      }
      const [startString, endString] = time.replace('[', '').split(' - ')
      if (startString === undefined || endString === undefined) {
        return
      }
      const start = Number.parseFloat(startString)
      const end = Number.parseFloat(endString)

      return {
        time,
        start,
        end,
        speaker,
        text: text.trim() ?? line, // textがない場合はlineをそのまま使う
        // 競合打診、ペア打診などにフラグをつけておく
        isCompetMention: isSome(competMentionTimeFloat)
          ? start <= competMentionTimeFloat && competMentionTimeFloat < end
          : false,
        isPairInquiry: isSome(isPairInquiryTimeFloat)
          ? start <= isPairInquiryTimeFloat && isPairInquiryTimeFloat < end
          : false,
      }
    }
    const transcriptsRefined =
      data.transcript_refined
        ?.replace(/\n\n/g, '\n')
        .split('\n')
        .map((line) => toMessage(line))
        .compact() ?? []
    const transcripts = data.transcript
      ?.replace(/\n\n/g, '\n')
      .split('\n')
      .map((line) => toMessage(line))
      .compact()
      .map((message, index) => ({
        ...message,
        html: calculateTextDiff(message.text ?? '', transcriptsRefined[index]?.text ?? '').html,
      }))
    const res = {
      ...data,
      competMentionTimeFloat,
      isPairInquiryTimeFloat,
      transcriptsRefined,
      transcripts,
    }
    logger.debug(`[DEBUG] metadata`, res)
    return res
  }, [records])

  const audioReference = useRef<HTMLAudioElement>(null)
  const messagesDivReference = useRef<HTMLDivElement>(null)

  const scrollToMentionTime = async (mentionTimeString: string) => {
    const mentionTime = Number.parseFloat(mentionTimeString) // mentionTimeStringは 95.54s - 102.96s のような形式
    const audio = audioReference.current
    if (audio) {
      audio.currentTime = mentionTime
      await audio.play()
    }
    const index = (metadata.transcripts?.findIndex((x) => x.start >= mentionTime) ?? 0) - 1 // なぜか-1で正しい？
    const messagesDiv = messagesDivReference.current?.getElementsByClassName(`message-${index}`)[0]
    if (messagesDiv !== undefined) {
      messagesDivReference.current?.scrollTo({
        top: (messagesDiv.getBoundingClientRect().top ?? 0) - 50,
        behavior: 'smooth',
      })
    }
  }

  //
  // 現在の秒数をハイライトする関連のロジック
  //
  const [currentMessageIndex, setcurrentMessageIndex] = useState<number | null>(null)
  const [currentTime, setCurrentTime] = useState<number | null>(null)
  // 本来はcurrentTimeが書き換わるたびにeffectを呼び出す(=レンダリングが走る)のは避けたかったが、うまくやる方法が分からなかった :pray:

  useEffect(() => {
    if (currentTime === null) {
      setcurrentMessageIndex(null)
      return
    }

    // 現在のインデックスと同じなら何もしない
    if (currentMessageIndex !== null) {
      const current = metadata.transcripts?.[currentMessageIndex]
      if (current !== undefined && current.start <= currentTime && currentTime < current.end) {
        return
      }
    }
    // timeに相当するインデックスを探す
    const index = metadata.transcripts?.findIndex(
      (transcript) => transcript.start <= currentTime && currentTime < transcript.end,
    )
    logger.debug(`[DEBUG] useEffect called`, index) // なぜか本番で機能してないのでデバッグ用に残しておく
    if (index !== undefined && index !== -1) {
      setcurrentMessageIndex(index)
    }
  }, [currentTime])
  useEffect(() => {
    const audio = audioReference.current
    logger.debug(`[DEBUG] useEffect for audioRef called`, audioReference) // なぜか本番で機能してないのでデバッグ用に残しておく
    if (audio) {
      const handleTimeUpdate = () => {
        logger.debug(`[DEBUG] handleTimeUpdate called`, audio.currentTime) // なぜか本番で機能してないのでデバッグ用に残しておく
        setCurrentTime(audio.currentTime)
      }

      logger.debug(`[DEBUG] timeupdate handler registered`, audioReference) // なぜか本番で機能してないのでデバッグ用に残しておく
      audio.addEventListener('timeupdate', handleTimeUpdate)

      // イベントリスナーのクリーンアップ
      return () => {
        logger.debug(`[DEBUG] timeupdate handler removed`, audioReference) // なぜか本番で機能してないのでデバッグ用に残しておく
        audio.removeEventListener('timeupdate', handleTimeUpdate)
      }
    }
  }, [audioReference.current])

  const startFrom = async (seconds: number) => {
    const audio = audioReference.current
    if (isNull(audio)) {
      return
    }

    // 再生中だったら一旦止める
    // if (!audio.paused) {
    //   audio.pause()
    //   return
    // }

    audio.currentTime = seconds
    await audio.play()
  }

  return (
    <PageHeader
      title={
        <div className="flex items-center align-middle">
          書き起こし{' '}
          <Select
            style={{ width: 140 }}
            value={displayType}
            onChange={(value) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
              setDisplayType(value as 'refined')
            }}
            options={[
              {
                value: 'refined' as const,
                label: '整形済み',
              },
              {
                value: 'raw' as const,
                label: '未整形',
              },
              {
                value: 'diff' as const,
                label: '未整形 -> 整形の差分',
              },
            ]}
          />
          <div className="ml-3 flex items-center align-middle">
            {[
              // view.name,
              metadata.salesforce_task_activity_date,
              metadata.salesforce_task_owner_id_label,
              metadata.salesforce_task_account_id_label,
              metadata.salesforce_task_contact_id_label,
            ]
              .compact()
              .map((x) => (
                <Tag>{x}</Tag>
              ))}
          </div>
        </div>
      }
      extra={[<Button icon={<ShareAltOutlined />} />]}
      // どこかでモーダルの余白を消している？のでその分を設定
      className="px-8 py-4"
    >
      <div className="grid grid-cols-3 gap-4 divide-x divide-y-0 divide-solid divide-gray-200">
        <div className="col-span-2">
          {/* 書き起こし */}
          <div ref={messagesDivReference} style={{ maxHeight: '70vh' }} className=" overflow-y-scroll">
            {(displayType === 'refined' ? metadata.transcriptsRefined : metadata.transcripts)?.map(
              (transcript, index) => (
                <ChatMessage
                  className={`message-${index}`}
                  key={[index, transcript.start].join('_')}
                  message={transcript}
                  highlight={index === currentMessageIndex}
                  asHtml={displayType === 'diff'}
                  onClick={async () => {
                    await startFrom(transcript.start)
                  }}
                />
              ),
            )}
          </div>
          <div className="mt-8">
            {isPresent(metadata.s3_recording_path) && (
              <SuspenseWithLoading>
                <AudioWithS3Path
                  audioRef={audioReference}
                  s3ObjectPath={metadata.s3_recording_path}
                  className="w-full"
                />
              </SuspenseWithLoading>
            )}
          </div>
        </div>
        <div className="pl-4">
          <div className="mb-4">
            <div className="mb-2 text-lg font-bold">サマリ</div>
            {/* <TextArea rows={8} value={ metadata.summary ?? '' }/> */}
            <pre>{metadata.summary}</pre>
          </div>
          <div className="mb-4">
            <div className="mb-2 text-lg font-bold">言及情報</div>
            <div>
              競合への言及箇所：{' '}
              {isPresent(metadata.competMentionTime) ? (
                <span>
                  <Button
                    style={{ color: CONSTANT.colors.primaryColor }}
                    icon={<PlayCircleFilled />}
                    type="text"
                    onClick={async () => {
                      await scrollToMentionTime(metadata.competMentionTime!)
                    }}
                  >
                    {metadata.competMentionTime}{' '}
                  </Button>
                </span>
              ) : (
                <span className="text-gray-500">なし</span>
              )}
            </div>
            <div>
              ペア打診の言及箇所：{' '}
              {isPresent(metadata.isPairInquiryTime) ? (
                <span>
                  <Button
                    style={{ color: CONSTANT.colors.primaryColor }}
                    icon={<PlayCircleFilled />}
                    type="text"
                    onClick={async () => {
                      await scrollToMentionTime(metadata.isPairInquiryTime!)
                    }}
                  >
                    {metadata.isPairInquiryTime}{' '}
                  </Button>
                </span>
              ) : (
                <span className="text-gray-500">なし</span>
              )}
            </div>
          </div>
          {/* <div className='mb-4'>
          <div className='text-lg font-bold mb-2'>ネクストアクション</div>
          <TextArea rows={8} value={''} />
        </div> */}
          <div className="mb-4">
            <div className="mb-2 text-lg font-bold">デバッグ用</div>
            {isPresent(metadata.custom_s_extracted_calling_metadata_annotations_memo) && (
              <div className="mb-4">メモ：{metadata.custom_s_extracted_calling_metadata_annotations_memo}</div>
            )}
            <div className="overflow-y-scroll" style={{ maxHeight: 800 }}>
              <JsonSyntaxHighlighter jsonString={JSON.stringify(metadata)} />
              {/* <JsonSyntaxHighlighter jsonString={JSON.stringify(records)} /> */}
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
  )
}

interface Message {
  time: string
  speaker: string
  text: string
  start: number
  end: number
  isCompetMention: boolean
  isPairInquiry: boolean
  html?: string // diffで使う
}

function formatTime(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60)
  const sec = seconds - hours * 3600 - minutes * 60
  const hourString = hours < 10 ? `0${hours}` : `${hours}`
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`
  const secString = sec < 10 ? `0${sec}` : `${sec}`
  return `${hourString}:${minutesString}:${secString}`
}

function ChatMessage({
  className,
  message,
  highlight,
  asHtml,
  onClick,
}: {
  className: string
  message: Message
  highlight?: boolean
  asHtml?: boolean
  onClick: (x: Message) => void
}) {
  const isUser = message.speaker.includes(`01`) // SPEAKER_01になっている
  return (
    <Space
      onClick={() => {
        onClick(message)
      }}
      className={`${className} mb-2 flex w-full cursor-pointer transition-all duration-200 hover:opacity-70 ${
        isUser ? `flex-row-reverse pl-12` : `pr-12`
      } ${highlight === true ? `bg-yellow-100` : ``} items-end `}
    >
      {isUser ? (
        <Avatar className="mb-4" icon={<UserOutlined />} />
      ) : (
        <Avatar className="mb-4" icon={<UserOutlined />} />
      )}
      <div>
        <div
          style={{ fontSize: 10 }}
          className={`mb-1 flex w-full text-gray-500 ${isUser ? `flex-row-reverse` : ``} items-center align-middle`}
        >
          {formatTime(Math.floor(message.start))} {message.speaker}{' '}
          {message.isCompetMention && <Tag color="pink">競合言及</Tag>}{' '}
          {message.isPairInquiry && <Tag color="pink">ペア言及</Tag>}
        </div>
        <pre
          className={`extracted-calling-metadata-page-chat-message whitespace-pre-wrap p-4 ${
            isUser ? `is-user bg-blue-500 text-white` : `bg-gray-100`
          }`}
          style={{
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            borderBottomRightRadius: isUser ? 0 : 20,
            borderBottomLeftRadius: isUser ? 20 : 0,
            fontWeight: 'normal',
          }}
        >
          {asHtml === true ? <span dangerouslySetInnerHTML={{ __html: message.html ?? '' }} /> : message.text}
        </pre>
      </div>
    </Space>
  )
}

function AudioWithS3Path({
  s3ObjectPath,
  audioRef,
  className,
}: {
  s3ObjectPath: string
  audioRef: React.RefObject<HTMLAudioElement>
  className: string
}) {
  const { data: s3SignedUrl } = useSuspenseQuery(FetchS3SignedUrlDocument, {
    variables: { organizationId: getOrganizationIdFromPath(), s3ObjectPath },
  })

  return <audio ref={audioRef} controls src={s3SignedUrl.s3SignedUrl} className={className} />
}
