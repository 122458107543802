import { ConsoleSqlOutlined, FilterOutlined, PlusCircleFilled } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { CONSTANT } from '@salescore/client-base'
import { type ViewConfigFilterNode, viewConfigFilterNodeSchema, type ViewConfigTreeNode } from '@salescore/core'
import { addModelIdProperty, useBooleanState, useMessage } from '@salescore/frontend-common'
import { Button, Divider, Modal, Select, Space, Switch, Tabs, type TabsProps } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'

import { convertToFilterNodeForForm, useNavigationModal } from '../../../../recoil/navigation/hooks'
import { useChangesValue } from '../../../../recoil/records/hooks'
import { useMeValue, useViewConfigSheet } from '../../../../recoil/view/hooks'
import { useFilterMutations } from '../../../../recoil/view/mutations/filterMutations'
import { useTreeMutations } from '../../../../recoil/view/mutations/treeMutations'
import { useConfigSheetSelector } from '../../../../recoil/view/selectors/configSheetSelector'
import { useConnectionsSelector } from '../../../../recoil/view/selectors/connectionsSelector'
import type { ViewConfigFilterNodeForForm } from './filter/common'
import { applyAdvancedSettingsFlag, ViewFilterTreeForm } from './filter/ViewFilterTreeForm'

export const ViewQueryFiltersModal = () => {
  const { filtersModal } = useNavigationModal()
  const config = useViewConfigSheet()
  const [joinFiltersNodes, setJoinFiltersNodes] = useState<ViewConfigTreeNode[]>([])
  const [availableJoinFiltersNodes, setAvailableJoinFiltersNodes] = useState<ViewConfigTreeNode[]>([])
  const changes = useChangesValue()
  const message = useMessage()

  const tabItems: TabsProps['items'] = [
    {
      key: 'FiltersSetting',
      label: t(`フィルタ`),
      children: (
        <FiltersSetting
          initialFilterTree={filtersModal.content}
          onAfterFinish={() => {
            filtersModal.hideModal()
          }}
        />
      ),
    },
    joinFiltersNodes.length > 0 || availableJoinFiltersNodes.length > 0
      ? {
          key: 'JoinFiltersSettingList',
          label: t(`ブロックフィルタ`),
          children: (
            <JoinFiltersSettingList
              onAfterFinish={() => {
                filtersModal.hideModal()
              }}
              joinFiltersNodes={joinFiltersNodes}
              availableJoinFiltersNodes={availableJoinFiltersNodes}
            />
          ),
        }
      : null,
  ].compact()

  useEffect(() => {
    if (filtersModal.isModalVisible && changes.length > 0) {
      message.warning(t('設定を変更すると変更情報が失われます。'))
    }
  }, [filtersModal.isModalVisible])

  useEffect(() => {
    const joinFiltersNodesTemporary: ViewConfigTreeNode[] = []
    const availableJoinFiltersNodesTemporary: ViewConfigTreeNode[] = []

    function pickJoinFiltersNode(tree: ViewConfigTreeNode) {
      if (tree.additionalJoinOn === undefined) {
        availableJoinFiltersNodesTemporary.push(tree)
      } else {
        joinFiltersNodesTemporary.push(tree)
      }
      tree.children?.forEach(pickJoinFiltersNode)
    }

    config.tree?.children?.forEach(pickJoinFiltersNode)

    setJoinFiltersNodes(joinFiltersNodesTemporary)
    setAvailableJoinFiltersNodes(availableJoinFiltersNodesTemporary)
  }, [config.tree])

  return (
    <Modal
      open={filtersModal.isModalVisible}
      onCancel={() => {
        filtersModal.hideModal()
      }}
      width={'90%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        // top: '3%',
        maxWidth: 1000,
      }}
      destroyOnClose
      footer={<></>}
    >
      <PageHeader
        title={
          <Space>
            <FilterOutlined />
            <span>{t(`絞り込み`)}</span>
          </Space>
        }
      >
        <Tabs items={tabItems} />
      </PageHeader>
    </Modal>
  )
}

function FiltersSetting({
  onAfterFinish,
  initialFilterTree,
}: {
  onAfterFinish: () => void
  initialFilterTree: ViewConfigFilterNodeForForm | undefined
}) {
  const me = useMeValue()
  const { debugModal } = useNavigationModal() // modal系を余計にsubscribeしているので、パフォーマンスが問題になれば個別に参照
  const { config, flattenNodes, getModel } = useConfigSheetSelector()
  const filterTree: ViewConfigFilterNodeForForm | undefined =
    initialFilterTree ?? (config.filterTree === undefined ? undefined : convertToFilterNodeForForm(config.filterTree))
  const mutation = useFilterMutations()
  const isAdvancedModeState = useBooleanState(
    (filterTree?.children ?? []).isPresent() || filterTree?.logicalOperator === 'or',
  )
  const availableModels = flattenNodes
    .map((node) => {
      const model = getModel(node.modelName)
      if (model === undefined) {
        return
      }

      return {
        node,
        model,
      }
    })
    .compact()
  const { properties } = useMemo(() => {
    const properties = availableModels.flatMap(({ model, node }) =>
      model.properties.map((property) => ({
        node,
        model,
        property,
      })),
    )
    const rootModel = availableModels.first()
    const propertiesAndRootId = addModelIdProperty(rootModel, properties)
    return {
      properties: propertiesAndRootId,
    }
  }, [availableModels])

  return (
    <div className="gap flex flex-col gap-4 pt-4">
      <div className="self-end">
        <div className="flex items-center gap-4">
          <div className="flex gap-2">
            <div>{t(`高度なフィルタ条件`)}</div>
            <Switch
              checked={isAdvancedModeState.isTrue}
              onChange={(value) => {
                if (value) {
                  isAdvancedModeState.setTrue()
                } else {
                  isAdvancedModeState.setFalse()
                }
              }}
            />
          </div>

          {me.isAdmin && (
            <Button
              type="text"
              className={CONSTANT.colorClasses.ADMIN_TEXT_COLOR}
              icon={<ConsoleSqlOutlined />}
              onClick={() => {
                debugModal.showModal({
                  content: filterTree,
                  schema: viewConfigFilterNodeSchema, // TODO: 指定はしているものの、現状ワークしていない
                  onChange(filterTree) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                    mutation.set({ filterTree: filterTree as ViewConfigFilterNode })
                    onAfterFinish()
                  },
                })
              }}
            >
              {t(`スキーマ表示`)}
            </Button>
          )}
        </div>
      </div>

      <ViewFilterTreeForm
        filterTree={filterTree}
        propertiesWithNode={properties}
        isAdvancedMode={isAdvancedModeState.isTrue}
        onAfterFinish={(filterTree) => {
          const newFilterTree = applyAdvancedSettingsFlag(isAdvancedModeState.isTrue, filterTree)
          mutation.set({ filterTree: newFilterTree })

          // ここで保存しても最新のviewになっていないので、間接的に保存をキューする
          onAfterFinish()
        }}
      />
    </div>
  )
}

function JoinFiltersSettingList({
  onAfterFinish,
  joinFiltersNodes,
  availableJoinFiltersNodes,
}: {
  onAfterFinish: () => void
  joinFiltersNodes: ViewConfigTreeNode[]
  availableJoinFiltersNodes: ViewConfigTreeNode[]
}) {
  const [isAdding, setIsAdding] = useState(false)

  return (
    <div className="flex flex-col pt-6">
      {joinFiltersNodes.map((joinFilterNode, index) => (
        <div key={joinFilterNode.name}>
          <JoinFiltersSetting onAfterFinish={onAfterFinish} node={joinFilterNode} />
          {index !== joinFiltersNodes.length - 1 && <Divider />}
        </div>
      ))}
      {availableJoinFiltersNodes.length > 0 &&
        (isAdding ? (
          <>
            {joinFiltersNodes.length > 0 && <Divider />}
            <NewJoinFiltersSetting
              onAfterFinish={() => {
                setIsAdding(false)
                onAfterFinish()
              }}
              availableJoinFiltersNodes={availableJoinFiltersNodes}
            />
          </>
        ) : (
          <Button
            type="text"
            icon={<PlusCircleFilled />}
            color={CONSTANT.colors.primaryColor}
            style={{
              color: CONSTANT.colors.primaryColor,
              alignSelf: 'center',
            }}
            onClick={() => {
              setIsAdding(true)
            }}
          >
            {t(`新規追加`)}
          </Button>
        ))}
    </div>
  )
}

function JoinFiltersSetting({ onAfterFinish, node }: { onAfterFinish: () => void; node: ViewConfigTreeNode }) {
  const { editNode } = useTreeMutations()
  const { getModel } = useConnectionsSelector()
  const label = node.ui?.label ?? getModel(node.modelName)?.label ?? node.modelName

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div>{t(`オブジェクトを選択`)}</div>
          <div className="rounded border border-solid border-gray-300 px-4 py-1">{label}</div>
        </div>
        <Button
          danger
          onClick={() => {
            editNode({
              node,
              f: (node) => {
                node.additionalJoinOn = undefined
              },
            })
          }}
        >
          削除
        </Button>
      </div>
      <JoinFiltersSettingBody onAfterFinish={onAfterFinish} node={node} />
    </div>
  )
}

function NewJoinFiltersSetting({
  onAfterFinish,
  availableJoinFiltersNodes,
}: {
  onAfterFinish: () => void
  availableJoinFiltersNodes: ViewConfigTreeNode[]
}) {
  const { getModel } = useConnectionsSelector()
  const getLabel = (node: ViewConfigTreeNode) => node.ui?.label ?? getModel(node.modelName)?.label ?? node.modelName

  const [currentNode, setCurrentNode] = useState<ViewConfigTreeNode | undefined>(availableJoinFiltersNodes[0])
  const onChangeCurrentNode = (value: string) => {
    setCurrentNode(availableJoinFiltersNodes.find((node) => node.name === value))
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div>{t(`オブジェクトを選択`)}</div>
          <Select
            value={currentNode?.name}
            options={availableJoinFiltersNodes.map((node) => ({ value: node.name, label: getLabel(node) }))}
            onChange={onChangeCurrentNode}
          />
        </div>
        {currentNode !== undefined && (
          <Button danger onClick={onAfterFinish}>
            削除
          </Button>
        )}
      </div>
      {currentNode !== undefined && (
        <JoinFiltersSettingBody key={currentNode.name} onAfterFinish={onAfterFinish} node={currentNode} />
      )}
    </div>
  )
}

function JoinFiltersSettingBody({ onAfterFinish, node }: { onAfterFinish: () => void; node: ViewConfigTreeNode }) {
  const { editNode } = useTreeMutations()
  const { getModel } = useConnectionsSelector()
  const isAdvancedModeState = useBooleanState(
    (node.additionalJoinOn?.children ?? []).isPresent() || node.additionalJoinOn?.logicalOperator === 'or',
  )
  const model = getModel(node.modelName)
  const propertiesWithNode =
    model?.properties.map((property) => ({
      node,
      model,
      property,
    })) ?? []
  const propertiesAndModelIdWithNode = addModelIdProperty({ node, model }, propertiesWithNode)

  return (
    <div className="gap flex flex-col gap-4">
      <div className="self-end">
        <div className="flex gap-2">
          <div>{t(`高度なフィルタ条件`)}</div>
          <Switch
            checked={isAdvancedModeState.isTrue}
            onChange={(value) => {
              if (value) {
                isAdvancedModeState.setTrue()
              } else {
                isAdvancedModeState.setFalse()
              }
            }}
          />
        </div>
      </div>

      <ViewFilterTreeForm
        isAdvancedMode={isAdvancedModeState.isTrue}
        filterTree={node.additionalJoinOn}
        propertiesWithNode={propertiesAndModelIdWithNode}
        onAfterFinish={(filterTree) => {
          editNode({
            node,
            f: (node) => {
              node.additionalJoinOn = applyAdvancedSettingsFlag(isAdvancedModeState.isTrue, filterTree)
            },
          })
          onAfterFinish()
        }}
      />
    </div>
  )
}
