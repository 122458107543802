import { useApolloClient } from '@apollo/client'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import type { SelectOption, ViewUISearchSelect } from '@salescore/core'
import { TimeWindowExecutor } from '@salescore/frontend-common'
import { Empty, message, Select, Spin } from 'antd'
import { t } from 'i18next'
import { useContext, useEffect, useMemo, useState } from 'react'

import { searchRelation } from '../../recoil/hooks/searchRelation'
import { useViewUIRecordNodeContext } from './contexts'
import { ViewUIContext } from './ViewUIComponent'

export function ViewUISearchSelectC({ component }: { component: ViewUISearchSelect }) {
  const { isEditMode } = useContext(ViewUIContext)
  const { getValue, onChange, recordNode } = useViewUIRecordNodeContext()
  const value = getValue(component.fieldName)
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)
  const initialLabel = useMemo(() => {
    const { labelFieldName } = component
    if (labelFieldName === undefined) {
      return
    }
    return recordNode?.attributes[labelFieldName]
  }, [recordNode])

  const [options, setOptions] = useState<SelectOption[]>(
    typeof value === 'string' && typeof initialLabel === 'string'
      ? [
          {
            value,
            label: initialLabel,
          },
        ]
      : [],
  )

  const searcher = client === undefined ? undefined : searchRelation(client, getOrganizationIdFromPath())

  const search = async (searchKey: string, remainPreviousOptions?: boolean) => {
    if (searcher === undefined) {
      return
    }

    try {
      setLoading(true)
      const searchResult = await searcher(component.searchSql, searchKey) // TODO
      const options = searchResult?.values ?? []
      if (remainPreviousOptions === true) {
        setOptions((xs) => [...xs, ...options].uniqueBy((x) => x.value))
      } else {
        setOptions(options)
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        void message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }
  const executor = new TimeWindowExecutor(search, 400)

  useEffect(() => {
    executor.queue(['', true])
  }, [])

  return (
    <Select
      value={
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        value as string
      }
      onChange={(value) => {
        onChange({ fieldName: component.fieldName, value })
      }}
      options={options.map((x) => ({ ...x, key: x.value ?? x.label }))}
      filterOption={false}
      notFoundContent={loading ? <Spin size="small" /> : <Empty />}
      showSearch
      onSearch={(searchKey) => {
        executor.queue([searchKey])
      }}
      allowClear
      placeholder={`${t(`検索`)}...`}
      onClick={() => {
        if (component.readonly === true) {
          void message.warning(t(`この項目は書き込みが禁止されています。`))
        }
      }}
      disabled={isEditMode || component.readonly}
    />
  )
}
