import { range } from '@salescore/buff-common'
import { logger, parseJsonIfValid } from '@salescore/frontend-common'
import { t } from 'i18next'

import type { FeedbackMessage } from '../../../recoil/records/atoms'
import type { RecordChange } from '../../../recoil/records/mutations/upsertViewRecords'
import { validateAndNormalize } from '../../action/validateAndNormalize'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import type { RSheetsCursor } from '../../types/CursorTypes'
import { getRecordNodesWithInnerRowIndex } from '../../util/innerRowRelated'
import { getArea } from './useCursorMutation'

interface Argument {
  copiedValues: unknown[][]
  data: RSheetRecordNode[]
  columns: Array<RSheetColumn<RSheetRecordNode>>
  cursor: RSheetsCursor | undefined
  maxRowIndex: number
  options?: {
    asDelete?: boolean
  }
  feedback: (x: FeedbackMessage) => void
}

const transpose = (a: unknown[][]) => a[0]!.map((_, c) => a.map((r) => r[c]))

export const generateRecordChangesByCopiedValues = (argument: Argument): RecordChange[] => {
  const { copiedValues, data, columns, cursor, maxRowIndex, options, feedback } = argument
  const recordChanges: RecordChange[] = []

  if (cursor === undefined) {
    return []
  }

  const transposedCopiedValues = transpose(expandValues(copiedValues, cursor, data, columns))
  const area = getArea(cursor.main, cursor.expand ?? cursor.main)

  // asyncが正しいが、build時に以下エラーが出るので一旦voidにしてみる。
  // Module parse failed: The keyword 'yield' is reserved(268: 36)

  const onChange = (
    column: RSheetColumn<RSheetRecordNode>,
    value: unknown,
    rowIndex: number,
    nestedColumnIndex: number,
  ) => {
    // TODO: 書き込み禁止系以外すべてペーストできて大丈夫か？
    if (column.readonly === true) {
      feedback({
        type: 'warn',
        message: t(`このカラムは書き込み禁止です`),
      })
      return
    }

    if (options?.asDelete === true) {
      recordChanges.push({
        rowIndex,
        innerRowIndex: nestedColumnIndex,
        nodePath: column.field!.nodePath,
        isNestedColumn: column.node.path.length > 1,
        field: column.field!,
        value: null,
        label: undefined,
      })
    } else {
      const copiedValue = convertStringValueToCopiedValue(value)
      const normalizedValue = validateAndNormalize(copiedValue, column, feedback)
      if (normalizedValue === undefined) {
        return
      }
      if (column.metaType === 'relation' && normalizedValue === '') {
        // 参照列の場合、空文字を recordChanges に push すると一番上のレコードが選択されてしまうため何もしない
        return
      }
      recordChanges.push({
        rowIndex,
        innerRowIndex: nestedColumnIndex,
        nodePath: column.field!.nodePath,
        isNestedColumn: column.node.path.length > 1,
        field: column.field!,
        value: normalizedValue,
        label: undefined,
      })
    }
  }

  // 全てのカラムが同じノードのとき、単純な行列としてコピーする
  const targetColumns = columns.slice(area.left, area.left + transposedCopiedValues.length)
  if (targetColumns.map((x) => x.node.path.join(',')).unique().length === 1) {
    targetColumns.map((targetColumn, columnIndex) => {
      const copiedValuesForColumn = transposedCopiedValues[columnIndex]!
      pasteToNestedColumn(area.up, area.upInnerRowIndex, copiedValuesForColumn, data, targetColumn, onChange)
    })
    return recordChanges
  }

  //
  // 全てのカラムが同じノードでない時、すなわち子ブロックを含む際は、それぞれの行の最上段の子レコードへのペーストとする。
  // これは挙動としては奇妙になる可能性があるが、そもそも同じブロックでないカラムへのペーストを文字列コピーで行うのは、正しい仕様がない。
  // pasteの新ロジックのような形にすることで、もう少し直感的なペーストになる可能性があるが、実装が難しいのと、それでもエッジケースは残るため、一旦この挙動とする
  //
  transposedCopiedValues.map((copiedValuesForColumn, valueColumnIndex) => {
    logger.debug({
      copiedValuesForColumn,
      valueColumnIndex,
    })
    const column = columns[area.left + valueColumnIndex]
    if (column === undefined) {
      logger.debug(`column undefined`)
      return
    }

    // ネストしている場合
    if (column.node.path.length > 1) {
      for (const [valueRowIndex, value] of copiedValuesForColumn.entries()) {
        const rowIndex = area.up + valueRowIndex
        if (rowIndex <= maxRowIndex) {
          onChange(column, value, rowIndex, 0) // 子レコードの最上段にペーストする
        } else {
          // ネストしているカラムで最大行を超えた場合、とりあえず新規レコード作成しない。
        }
      }
    } else {
      // ネストしていない場合
      copiedValuesForColumn.map((value, valueRowIndex) => {
        const rowIndex = area.up + valueRowIndex
        onChange(column, value, rowIndex, 0)
      })
    }
  })
  return recordChanges
}

function expandValues(
  values: unknown[][],
  cursor: RSheetsCursor,
  data: RSheetRecordNode[],
  columns: Array<RSheetColumn<RSheetRecordNode>>,
): unknown[][] {
  const { main, expand } = cursor
  if (expand === undefined) {
    return values
  }

  if (values.length !== 1) {
    return values
  }
  const area = getArea(main, expand)
  const cellCounts = columns.slice(area.left, area.right + 1).map((column) => calculateCellCount(column, data, area))
  const minCellCount = Math.min(...cellCounts)
  if (minCellCount <= 1) {
    return values
  }

  const value = values[0]!
  const expandedValues = range(0, minCellCount - 1).map((_) => [...value])

  return expandedValues
}

function calculateCellCount(
  column: RSheetColumn<RSheetRecordNode>,
  data: RSheetRecordNode[],
  area: ReturnType<typeof getArea>,
) {
  if (column.node.path.length <= 1) {
    return area.down - area.up + 1
  }

  // 子レコードの場合、セルの数を愚直に数える
  let cnt = 0
  for (let rowIndex = area.up; rowIndex <= area.down; rowIndex++) {
    const row = data[rowIndex]
    if (row === undefined) {
      break
    }
    const nodesWithIndex = getRecordNodesWithInnerRowIndex(row, column.node)
    const nodesAboveHead =
      rowIndex === area.up ? nodesWithIndex.filter((x) => x.innerRowIndexStart < area.upInnerRowIndex) : []
    const nodesBelowTail =
      rowIndex === area.down ? nodesWithIndex.filter((x) => x.innerRowIndexStart > area.downInnerRowIndex) : []
    cnt += nodesWithIndex.length - nodesAboveHead.length - nodesBelowTail.length
  }
  return cnt
}

// eslint-disable-next-line @typescript-eslint/max-params
function pasteToNestedColumn(
  initialRowIndex: number,
  initialInnerRowIndex: number,
  copiedValuesForColumn: unknown[],
  data: RSheetRecordNode[],
  column: RSheetColumn<RSheetRecordNode>,
  onChange: (
    column: RSheetColumn<RSheetRecordNode>,
    value: unknown,
    rowIndex: number,
    nestedColumnIndex: number,
  ) => void,
) {
  let currentValueIndex = 0
  let currentRowIndex = initialRowIndex
  let currentNestedColumnIndex = initialInnerRowIndex
  // ペーストした値がある限りペースト
  while (copiedValuesForColumn[currentValueIndex] !== undefined) {
    const row = data[currentRowIndex]

    // 行がない場合、ネストを無視して1行だけ生成して次へいく
    if (row === undefined) {
      currentNestedColumnIndex = 0
      const copiedValue = copiedValuesForColumn[currentValueIndex]
      if (copiedValue === undefined) {
        return
      }
      onChange(column, copiedValue, currentRowIndex, currentNestedColumnIndex)
      currentValueIndex += 1
      currentRowIndex += 1
      continue
    }

    // 行がある場合、ネストを考慮してペースト
    const nodesWithIndex = getRecordNodesWithInnerRowIndex(row, column.node).filter(
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      (x) => x.innerRowIndexStart >= currentNestedColumnIndex,
    )
    for (const nodeWithIndex of nodesWithIndex) {
      const copiedValue = copiedValuesForColumn[currentValueIndex]
      if (copiedValue === undefined) {
        return
      }
      onChange(column, copiedValue, currentRowIndex, currentNestedColumnIndex)
      currentNestedColumnIndex = nodeWithIndex.innerRowIndexEnd
      currentValueIndex += 1
    }
    currentRowIndex += 1
    currentNestedColumnIndex = 0
  }
}

function convertStringValueToCopiedValue(x: unknown) {
  if (typeof x === 'string' && x.startsWith('"') && x.endsWith('"')) {
    return parseJsonIfValid(x)
  }
  return x
}
