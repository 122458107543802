import { BarChartOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartWaterfall } from '@fortawesome/sharp-regular-svg-icons'
import { isNull, isSome, isTruthy } from '@salescore/buff-common'
import { UserRoleEnumV2 } from '@salescore/client-api'
import { usePeriod } from '@salescore/client-common'
import { checkRiEnabled } from '@salescore/client-recoil'
import type {
  AggregationDay,
  GoalConfigFragment,
  KpiFragment,
  ViewConfigKpiMeasure,
  ViewConfigKpiTimeSeries,
} from '@salescore/core'
import { getAllPeriod, getCustomPeriod } from '@salescore/features'
import { Alert, Button } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import type * as React from 'react'
import { useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { type RiDisplayFormat, riDisplayFormatAtom } from '../../../recoil/navigation/atoms'
import { useNavigationModal } from '../../../recoil/navigation/hooks'
import { autoDrillDownParameterAtom, riFormAtom, viewAtom } from '../../../recoil/view/atoms'
import { useMeValue, useViewConfigKpiTimeSeries, useViewsContextValue, useViewValue } from '../../../recoil/view/hooks'
import { setConfigWithoutChangeMutation } from '../../../recoil/view/mutations/setConfigMutation'
import { useKpiFormSelectors } from '../../../recoil/view/selectors/kpiFormSelector'
import type { PanelOption } from '../VerticalPanelPicker'
import { VerticalPanelPicker } from '../VerticalPanelPicker'
import {
  kpiIgnoreMeasureFunctions,
  waterfallIgnoreMeasureFunctions,
} from '../ViewUIKpiPivot/KpiForm/KpiFormMeasureFormItem'
import { useSavedPeriod, useUpdateKpiTimeSeriesView } from './hooks'
import { ViewUIKpiTimeSeriesEmpty } from './ViewUIRiEmpty'
import { ViewUIRiForm } from './ViewUIRiForm'
import { ViewUIRiPreview } from './ViewUIRiPreview'

type RiDisplayFormatOption = PanelOption & {
  value: RiDisplayFormat
}

export function ViewUIRi(): React.ReactNode {
  const view = useViewValue()
  const { views } = useViewsContextValue()
  const me = useMeValue()
  const [config] = useViewConfigKpiTimeSeries()
  const [autoDrillDownParameter, setAutoDrillDownParameter] = useRecoilState(autoDrillDownParameterAtom)
  const { drillDownModal } = useNavigationModal()
  const [format, setFormat] = useRecoilState(riDisplayFormatAtom)
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [, setView] = useRecoilState(viewAtom)
  const setConfigWithoutChange = useSetRecoilState(setConfigWithoutChangeMutation)
  const { serializePeriod } = usePeriod()
  const { getSavedPeriod } = useSavedPeriod()
  const { updateKpiTimeSeriesView } = useUpdateKpiTimeSeriesView()
  const setRiForm = useSetRecoilState(riFormAtom)
  const { setForm } = useKpiFormSelectors()
  const isRiEnabled = checkRiEnabled(me.myUser)
  const canRiUpdate = me.myUser?.role !== UserRoleEnumV2.Minimal

  const options: RiDisplayFormatOption[] = [
    {
      value: 'timeSeries',
      label: t('時系列推移'),
      icon: <BarChartOutlined className="" style={{ fontSize: 26 }} />,
      disabled: false,
    },
    {
      value: 'waterfall',
      label: t('滝グラフ'),
      icon: <FontAwesomeIcon icon={faChartWaterfall} style={{ fontSize: 26 }} />,
      disabled: false,
    },
  ]

  const initializeConfig = (): void => {
    if (isNull(config)) {
      return
    }

    const { startPeriod, endPeriod } = getWaterfallDefaultPeriod()

    // NOTE: 保存された期間を復元する
    const savedConfig: ViewConfigKpiTimeSeries = {
      ...config,
      kpiFragment: generateInitialKpiFragment(config),
      period: serializePeriod(getSavedPeriod(config.period)),
      filters: {
        ...config.filters,
        dateY: serializePeriod(getSavedPeriod(config.filters?.dateY) ?? getAllPeriod()),
        startPeriod: serializePeriod(getSavedPeriod(config.filters?.startPeriod ?? startPeriod)),
        endPeriod: serializePeriod(getSavedPeriod(config.filters?.endPeriod ?? endPeriod)),
      },
    }

    setView((previous) => ({ ...previous, config: savedConfig }))
    setConfigWithoutChange(savedConfig)
  }

  const onChangePanel = (option: RiDisplayFormatOption): void => {
    setFormat(option.value)

    if (isNull(config)) {
      return
    }

    // NOTE:
    // 滝グラフで選択できる集計方法は時系列グラフより少ないため、時系列グラフから切り替えた時に集計方法を引き継げない場合がある
    // その際は初期値としてレコード数をセットする
    const measure = getInitialMeasure(option.value, config)
    void updateKpiTimeSeriesView({
      configForm: {
        ...config,
        kpiFragment: isSome(config.kpiFragment)
          ? {
              ...config.kpiFragment,
              measure,
            }
          : undefined,
        option: {
          ...config.option,
          asWaterfall: option.value === 'waterfall',
        },
      },
      savedMessage: t('グラフ設定を保存しました。'),
    })
    setForm((oldValue) => ({
      ...oldValue,
      measure,
    }))
  }

  useEffect(() => {
    if (autoDrillDownParameter !== undefined) {
      drillDownModal.showModal(autoDrillDownParameter)
      setAutoDrillDownParameter(undefined)
    }

    if (format === undefined) {
      setFormat(isTruthy(config?.option?.asWaterfall) ? 'waterfall' : 'timeSeries')
    }

    initializeConfig()
  }, [])

  useEffect(() => {
    const latestView = views.find((v) => v.id === view.id)
    const latestConfig = latestView?.config
    if (isNull(latestConfig) || latestConfig.type !== 'kpiTimeSeries') {
      return
    }

    setRiForm(latestConfig)
  }, [views])

  if (view.config.type !== 'kpiTimeSeries') {
    return null
  }

  if (!isRiEnabled) {
    return (
      <div>
        <Alert type="warning" message={t(`RIライセンスが有効化されていないため、案件管理を閲覧できません`)} />
      </div>
    )
  }

  if (isOpenForm) {
    return (
      <VerticalPanelPicker
        options={options}
        selectedValue={format}
        onClick={onChangePanel}
        body={
          <ViewUIRiForm
            onClose={() => {
              setIsOpenForm(false)
            }}
          />
        }
      />
    )
  }

  return isEmptyRi(view.config) ? (
    <ViewUIKpiTimeSeriesEmpty
      onClickCreateNew={() => {
        setIsOpenForm(true)
      }}
    />
  ) : (
    <VerticalPanelPicker
      options={options}
      selectedValue={format}
      onClick={onChangePanel}
      body={
        <div className="flex size-full flex-col gap-2 bg-white py-3">
          <div className="flex justify-end px-10">
            {/*  TODO: シミュレーションになる */}
            <Button
              onClick={() => {
                setIsOpenForm(true)
              }}
              disabled={!canRiUpdate}
            >
              設定
            </Button>
          </div>
          <div className="flex-1 px-4">
            <div className="relative size-full">
              <div className="absolute inset-0">
                <ViewUIRiPreview fullWidth />
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}

function isEmptyRi(config: ViewConfigKpiTimeSeries): boolean {
  const { kpiFragment, goalConfigFragment, period, designSettings } = config
  return isNull(kpiFragment) || isNull(goalConfigFragment) || isNull(period) || isNull(designSettings)
}

function getWaterfallDefaultPeriod() {
  const defaultStartAt = dayjs().startOf('month').format('YYYY-MM-DD')
  const defaultEndAt = dayjs().format('YYYY-MM-DD')

  return {
    startPeriod: {
      ...getCustomPeriod(),
      startAt: defaultStartAt,
      endAt: defaultStartAt,
    },
    endPeriod: {
      ...getCustomPeriod(),
      startAt: defaultEndAt,
      endAt: defaultEndAt,
    },
  }
}

function generateInitialKpiFragment(config: ViewConfigKpiTimeSeries): KpiFragment | undefined {
  const { kpiFragment } = config
  const timeSpanType = config.goalConfigFragment?.timeSpanType
  if (isNull(kpiFragment) || isNull(timeSpanType)) {
    return
  }

  return {
    ...kpiFragment,
    aggregationDay: kpiFragment.aggregationDay ?? getDefaultAggregationDay(timeSpanType),
  }
}

function getDefaultAggregationDay(timeSpanType?: GoalConfigFragment['timeSpanType']): AggregationDay | undefined {
  switch (timeSpanType) {
    case 'month': {
      return {
        timeSpan: timeSpanType,
        filterType: 'day',
        filterValue: '1',
      }
    }
    case 'week': {
      return {
        timeSpan: timeSpanType,
        filterType: 'dayOfWeek',
        filterValue: 'monday',
      }
    }
    default: {
      break
    }
  }
}

function getInitialMeasure(format: RiDisplayFormat, config: ViewConfigKpiTimeSeries): ViewConfigKpiMeasure | undefined {
  const { kpiFragment } = config
  const measure = kpiFragment?.measure
  const sheet = kpiFragment?.sheet
  const tree = sheet?.type === 'sheet' ? sheet.tree : undefined

  if (isNull(measure) || isNull(tree)) {
    return measure
  }

  if (measure.type !== 'kpiPreset') {
    return measure
  }

  if (format === 'waterfall' && waterfallIgnoreMeasureFunctions.has(measure.function)) {
    return {
      type: 'kpiPreset',
      function: 'countUnique',
      property: {
        modelName: tree.modelName,
        nodeName: tree.name,
        propertyName: 'id',
      },
    }
  }

  if (format === 'timeSeries' && kpiIgnoreMeasureFunctions.has(measure.function)) {
    return {
      type: 'kpiPreset',
      function: 'count',
      property: {
        modelName: tree.modelName,
        nodeName: tree.name,
        propertyName: 'id',
      },
    }
  }

  return measure
}
