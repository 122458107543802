import { range } from '@salescore/buff-common'
import type { ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'

import { generateNewRootRecord } from '../../../../recoil/records/mutations/addNewRecord/addNewRootRecord'
import type { RSheetColumn, RSheetRecordNode } from '../../../types'
import { type ExpandCursorArea, expandCursorAreaSchema } from '../../selectors/cursorSelector'

export interface PasteRelatedData {
  area: ExpandCursorArea
  columns: Array<RSheetColumn<RSheetRecordNode>>
  rows: ViewQueryRecordNode[]
  pseudoRootNodes: ViewQueryNode[]
}

export function isPasteRelatedData(argument: unknown): argument is PasteRelatedData {
  return (
    typeof argument === 'object' &&
    argument !== null &&
    'area' in argument &&
    expandCursorAreaSchema.safeParse(argument.area).success &&
    'columns' in argument &&
    Array.isArray(argument.columns) &&
    'rows' in argument &&
    Array.isArray(argument.rows) &&
    'pseudoRootNodes' in argument &&
    Array.isArray(argument.pseudoRootNodes)
  )
}

export type PasteRelatedDataDest = PasteRelatedData & {
  editingRows: ViewQueryRecordNode[]
}

// eslint-disable-next-line @typescript-eslint/max-params
export function getDestAndRecordRelated(
  source: PasteRelatedData,
  destinationArea: ExpandCursorArea,
  columns: Array<RSheetColumn<RSheetRecordNode>>,
  records: ViewQueryRecordNode[],
  recordIds: string[],
): {
  dest: PasteRelatedDataDest
  newRecordIds: string[]
} {
  const destinationColumns = getDestinationColumns(columns, source.columns.length, destinationArea)

  const { newRecordIds, destRows, destPseudoRootNodes, editingDestRows } = getDestinationRows(
    records,
    recordIds,
    source.rows,
    destinationArea,
    destinationColumns,
  )

  return {
    dest: {
      area: destinationArea,
      columns: destinationColumns,
      rows: destRows,
      editingRows: editingDestRows,
      pseudoRootNodes: destPseudoRootNodes,
    },
    newRecordIds,
  }
}

// 擬似ルートノード(選択している範囲内のカラムのノードで一番浅いノード)を求める
export function getPseudoRootNodes(nodes: ViewQueryNode[]): ViewQueryNode[] {
  const rootDepth = nodes.map((x) => x.path.length).min()
  if (rootDepth === undefined) {
    return []
  }
  return nodes.filter((node) => node.path.length === rootDepth).uniqueBy((node) => node.name)
}

// eslint-disable-next-line @typescript-eslint/max-params
function getDestinationRows(
  records: ViewQueryRecordNode[],
  recordIds: string[],
  sourceRows: ViewQueryRecordNode[],
  destinationArea: ExpandCursorArea,
  destinationColumns: Array<RSheetColumn<ViewQueryRecordNode>>,
) {
  // ペーストの対象行は、destAreaそのものではなく、destAreaの左上を起点としてコピーした範囲に相当するエリア
  // ただし、コピー行が1行だったときのみ、単一行コピー複数行ペーストを行うため、元の行数とする
  // TODO: sourceとdestでnodeが異なるとき、この辺のロジックが微妙
  const destinationRows = structuredClone(
    records.slice(
      destinationArea.upRowIndex,
      sourceRows.length === 1 ? destinationArea.downRowIndex + 1 : destinationArea.upRowIndex + sourceRows.length,
    ),
  )
  // 不足している分の行数を追加する
  const additionalRows = range(0, sourceRows.length - destinationRows.length - 1).map((_) => generateNewRootRecord())
  destinationRows.push(...additionalRows)

  // 子ノードのペーストについては、行だと足りない場合があるため（TODO: ここのロジックが微妙）
  const editingDestinationRows = structuredClone(records.slice(destinationArea.upRowIndex))
  editingDestinationRows.push(...additionalRows) // TODO: ここで新規追加すべきではない。

  const newRecordIds = [...recordIds, ...additionalRows.map((x) => x.id!)]

  const destinationPseudoRootNodes = getPseudoRootNodes(destinationColumns.map((column) => column.node))

  return {
    newRecordIds,
    destRows: destinationRows,
    destPseudoRootNodes: destinationPseudoRootNodes,
    editingDestRows: editingDestinationRows,
  }
}

export function getSourceRelated(
  sourceArea: ExpandCursorArea,
  columns: Array<RSheetColumn<RSheetRecordNode>>,
  records: ViewQueryRecordNode[],
  sourceFromClipboard: PasteRelatedData | undefined,
) {
  if (sourceFromClipboard !== undefined) {
    return {
      sourceColumns: sourceFromClipboard.columns,
      sourceRows: sourceFromClipboard.rows,
      sourcePseudoRootNodes: sourceFromClipboard.pseudoRootNodes,
    }
  }
  return {
    sourceColumns: columns.slice(sourceArea.leftColumnIndex, sourceArea.rightColumnIndex + 1),
    sourceRows: records.slice(sourceArea.upRowIndex, sourceArea.downRowIndex + 1),
    sourcePseudoRootNodes: getPseudoRootNodes(columns.map((column) => column.node)),
  }
}

function getDestinationColumns(
  columns: Array<RSheetColumn<RSheetRecordNode>>,
  numberSourceColumns: number,
  selectedArea: ExpandCursorArea,
) {
  const destinationColumns = columns.slice(
    selectedArea.leftColumnIndex,
    selectedArea.leftColumnIndex + numberSourceColumns,
  )
  return destinationColumns
}
