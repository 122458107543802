import type { PolicyAction } from './action'
import { defaultPoliciesMapper } from './defaultPolicies'
import type { Policy, PolicySubject, PolicyUserRole } from './schema'

// TODO: ここでエラー定義してしまって良いだろうか？
export class ForbiddenError extends Error {
  public constructor(message: string) {
    super(message)
    this.name = 'ForbiddenError'
  }
}

// ユーザー以外のabilityも今後実装する可能性があるので、抽象化しておく
abstract class Ability {
  public abstract can(action: PolicyAction, subject?: PolicySubject): boolean | Promise<boolean>

  public async cannot(action: PolicyAction, subject?: PolicySubject) {
    return !(await this.can(action, subject))
  }

  public abstract throwIfCannot(action: PolicyAction, subject?: PolicySubject): Promise<void>
}

interface AbilityUser {
  id: string
  role: PolicyUserRole
  organizationId: string // 不要かも
}

interface PolicyResovlerArgument {
  action: PolicyAction
  subject?: PolicySubject
}

interface PolicyResovler {
  resolve: (x: PolicyResovlerArgument) => Promise<Policy[]> | Policy[]
}

export class UserAbility extends Ability {
  private readonly user: AbilityUser
  private readonly resolver: PolicyResovler

  public constructor(user: AbilityUser, resolver: PolicyResovler) {
    super()
    this.user = user
    this.resolver = resolver
  }

  public async throwIfCannot(action: PolicyAction, subject?: PolicySubject): Promise<void> {
    if (await this.cannot(action, subject)) {
      throw new ForbiddenError(
        `この操作を行う権限がありません: ${JSON.stringify({
          user: this.user,
          action,
          'subject.id': subject?.id,
          'subject.entityType': subject?.entityType,
        })}`,
      )
    }
  }

  private async resolve({ action, subject }: PolicyResovlerArgument): Promise<Policy[]> {
    const policies: Policy[] = await this.resolver.resolve({ action, subject })
    if (subject !== undefined) {
      // この辺のロジックがやや煩雑だろうか？
      return policies
    }

    const defaultPolicies: Policy[] = defaultPoliciesMapper[action] ?? []
    // subjectがないとき、policiesにはカスタム権限や組織に紐づかないデフォルト権限が含まれる
    // TODO: カスタム権限機能の安定稼働確認まで保険としてハードコードされたデフォルトポリシー設定も含める。
    //       - 問題発生時などの対応を最小限にとどめるため
    //       - リリース直後、デフォルト権限の参照先が変わる際にユーザー影響を発生させないため
    return [...policies, ...defaultPolicies].compact()
  }

  public async can(action: PolicyAction, subject?: PolicySubject): Promise<boolean> {
    // adminは常になんでもできる
    if (this.user.role === `admin`) {
      return true
    }
    // minimalは常に何もできない
    if (this.user.role === `minimal`) {
      return false
    }

    const policiesWithSubject: Policy[] = await this.resolve({
      action,
      subject,
    })
    const policiesWithoutSubject: Policy[] = await this.resolve({
      action,
    })

    // canの対象があるとき
    if (subject !== undefined) {
      // XXX: policiesの並び順にも意味があり、findを使っている理由があるので注意
      const userPolicy = policiesWithSubject.find(
        (policy) => policy.principal.type === 'user' && policy.principal.id === this.user.id,
      ) // 複数ポリシーが設定されているケースは想定しない
      const rolePolicy = policiesWithSubject.find(
        (policy) => policy.principal.type === 'userRole' && policy.principal.role === this.user.role,
      )
      const createdByPolicy = policiesWithSubject.find(
        (policy) => policy.principal.type === 'userAttribute' && policy.principal.attributeType === 'createdBy',
      ) // createdBy以外のケースがでたとき、優先順位をどうするかは未検討
      const everyonePolicy = policiesWithSubject.find((policy) => policy.principal.type === 'everyone')

      // 非常にややこしいが、「作成者」の判定では、ポリシー側ではsubjectはなしで、canの対象にはsubjectありを想定するようなポリシーがありうる
      // 「XXXというレコードの作成者に対するポリシー」と「作成者に対するポリシー」の2パターンあり、これは後者
      const createdByPolicyWithoutPolicySubject = policiesWithoutSubject.find(
        (policy) => policy.principal.type === 'userAttribute' && policy.principal.attributeType === 'createdBy',
      )

      // ユーザー、作成者、ロール、デフォルト、作成者(ポリシー側のsubjectなし)の順に評価
      // TODO: 厳密には、作成者(ポリシー側のsubjectなし)は「ユーザー（ポリシー側のsubjectなし）=L114以降）」で評価されるべきだが、現状userごとの権限がないので一旦無視
      const matchedPolicies = [
        userPolicy,
        subject.createdById === this.user.id ? createdByPolicy : undefined,
        rolePolicy,
        everyonePolicy,
        subject.createdById === this.user.id ? createdByPolicyWithoutPolicySubject : undefined,
      ].compact()

      const policy = matchedPolicies.first()
      if (policy !== undefined) {
        return policy.permission
      }
    }

    const userPolicy = policiesWithoutSubject.find(
      (policy) => policy.principal.type === 'user' && policy.principal.id === this.user.id,
    )
    const rolePolicy = policiesWithoutSubject.find(
      (policy) => policy.principal.type === 'userRole' && policy.principal.role === this.user.role,
    )
    const everyonePolicy = policiesWithoutSubject.find((policy) => policy.principal.type === 'everyone')
    // ユーザー、ロール、デフォルトの順に評価（ほぼロールで決まるはず）
    const policy = [userPolicy, rolePolicy, everyonePolicy].compact().first()
    if (policy !== undefined) {
      return policy.permission
    }
    return false
  }
}
