import { logger, mutation } from '@salescore/frontend-common'
import { t } from 'i18next'

import {
  changesAtom,
  feedbackMessagesAtom,
  pickedIdsAtom,
  recordAtomFamily,
  recordIdsAtom,
  recordsAtom,
} from '../../../recoil/records/atoms'
import { updateHeight, updateInnerRow } from '../../../recoil/records/mutations/upsertViewRecords'
import { columnsAtom } from '../atoms'
import { cursorSelector } from '../selectors/cursorSelector'
import { getChangesByPseudoRootNode } from './paste/getChangesByPseudoRootNode'
import {
  getDestAndRecordRelated as getDestinationAndRecordRelated,
  getSourceRelated,
  type PasteRelatedData,
} from './paste/getRowsAndColumns'
import { PseudoRowIterator } from './paste/PseudoRowIterator'

// SALESCORE で完結するタイプのペースト
export const pasteMutation = mutation<PasteRelatedData | undefined>({
  key: `rsheet/pasteMutation`,
  set({ get, set }, sourceFromClipboard) {
    const { copiedArea, selectedArea } = get(cursorSelector)
    const columns = get(columnsAtom)
    const records = get(recordsAtom)
    const recordIds = get(recordIdsAtom)
    if (selectedArea === undefined) {
      logger.debug(`paste destination area is undefined`)
      return
    }
    const sourceArea = sourceFromClipboard?.area ?? copiedArea?.area
    if (sourceArea === undefined) {
      logger.debug(`copy source area is undefined`)
      return
    }
    const x = getSourceRelated(sourceArea, columns, records, sourceFromClipboard)
    const source = {
      area: sourceArea,
      columns: x.sourceColumns,
      rows: x.sourceRows,
      pseudoRootNodes: x.sourcePseudoRootNodes,
    }
    const { dest, newRecordIds } = getDestinationAndRecordRelated(
      source,
      selectedArea.area,
      columns,
      records,
      recordIds,
    )
    if (source.pseudoRootNodes.length > 1 || dest.pseudoRootNodes.length > 1) {
      set(feedbackMessagesAtom, [
        {
          type: 'warn' as const,
          message: t(`複数の子ブロックにまたがるようなペーストはできません`),
        },
      ])
      logger.debug(`failed to getDestAndRecordRelated()`)
      return
    }
    if (dest.columns.some((x) => x.readonly === true && x.field?.write === undefined)) {
      set(feedbackMessagesAtom, [
        {
          type: 'warn' as const,
          message: t(`書き込み禁止列にはペーストできません`),
        },
      ])
    }
    // 擬似ルートごとに処理を行う
    const results = source.pseudoRootNodes.map((sourcePseudoRootNode, pseudoRootNodeIndex) => {
      // TODO: sourceとdestで対応する擬似ルートを適切に選択
      const destinationPseudoRootNode = dest.pseudoRootNodes[pseudoRootNodeIndex]!
      const iterator = new PseudoRowIterator(destinationPseudoRootNode, dest.editingRows, {
        innerRowIndex: dest.area.upInnerRowIndex,
        rowIndex: dest.area.upRowIndex,
      })
      const changes = getChangesByPseudoRootNode(
        sourcePseudoRootNode,
        destinationPseudoRootNode,
        source,
        dest,
        iterator,
        set,
      )
      return {
        iterator,
        changes,
      }
    })

    const newRecords = structuredClone(records)

    // 行関連の処理をまとめて行う
    // TODO: この辺のロジック、upsertViewRecord関連に近い。共通化したい。
    // heightを参照渡しで更新
    const maxRowIndex = results.map((x) => x.iterator.lastEditedRowIndex).max() ?? 0
    for (const [index, destinationRow] of dest.editingRows.entries()) {
      if (index > maxRowIndex) {
        continue
      }
      updateHeight(destinationRow)
      // innerStartIndexを参照渡しで更新
      updateInnerRow(destinationRow)

      // 全ての変更をまとめて適用
      set(recordAtomFamily({ id: destinationRow.id! }), destinationRow)

      // newRecordsにも反映させる
      const changedRecord = newRecords.find((x) => x.id === destinationRow.id)
      if (changedRecord !== undefined) {
        Object.assign(changedRecord, destinationRow)
      }
    }

    // recoilに反映させる
    if (!newRecordIds.isEqual(recordIds)) {
      set(recordIdsAtom, newRecordIds)
    }
    const changes = results.flatMap((x) => x.changes)
    const rowCheckboxChanges = changes.map((x) => (x.type === 'rowCheckbox' ? x : undefined)).compact()
    set(pickedIdsAtom, (oldPickedIds) => {
      const picks = rowCheckboxChanges.filter((x) => x.value).map((x) => x.recordId)
      const unpicks = rowCheckboxChanges.filter((x) => !x.value).map((x) => x.recordId)
      return [...oldPickedIds.difference(unpicks), ...picks].unique()
    })
    const recordChanges = changes.map((x) => (x.type === 'rowCheckbox' ? undefined : x)).compact()
    if (recordChanges.isPresent()) {
      const newChange = {
        datetime: new Date().toISOString(),
        rowIndices: [0], // TODO: 結局使ってないので廃止
        snapshot: JSON.stringify(records), // snapshotは以前のものをいれる
        changes: recordChanges,
        snapshotAfterChange: JSON.stringify(newRecords),
      }
      set(changesAtom, (oldChanges) => [...oldChanges, newChange])
    }
  },
})
