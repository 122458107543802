import { isTruthy } from '@salescore/buff-common'
import type { ViewQueryField, ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'
import { logger } from '@salescore/frontend-common'

import { generateNewChildRecord } from '../../../../recoil/records/mutations/addNewRecord/addNewChildRecord'
import type { ViewRecordFieldChange } from '../../../../state/useViewRecordsState/useChangesState'
import type { RSheetColumn, RSheetTableNode } from '../../../types'
import type { ViewQueryRecordNodeWithIndex } from './PseudoRowIterator'

export type PasteChange =
  | ViewRecordFieldChange
  | {
      type: 'rowCheckbox'
      recordId: string
      value: boolean
    }

// eslint-disable-next-line @typescript-eslint/max-params
export function paste(
  node: ViewQueryNode,
  sourceColumns: Array<RSheetColumn<ViewQueryRecordNode>>,
  destinationColumns: Array<RSheetColumn<ViewQueryRecordNode>>,
  sourceRecord: ViewQueryRecordNode,
  destinationRecord: ViewQueryRecordNodeWithIndex,
): PasteChange[] {
  if (node.path.length === 1) {
    return pasteToRoot(sourceRecord, destinationRecord, sourceColumns, destinationColumns, node)
  }
  if (node.path.length === 2) {
    return pasteToChildren(sourceRecord, destinationRecord, node, sourceColumns, destinationColumns, node)
  }
  return pasteToGrandChildren(sourceRecord, destinationRecord, node, sourceColumns, destinationColumns)
}

// eslint-disable-next-line @typescript-eslint/max-params
function pasteToRoot(
  sourceRecord: ViewQueryRecordNode,
  destinationRecord: ViewQueryRecordNodeWithIndex,
  sourceColumns: Array<RSheetColumn<ViewQueryRecordNode>>,
  destinationColumns: Array<RSheetColumn<ViewQueryRecordNode>>,
  destinationNode: ViewQueryNode,
): PasteChange[] {
  const changesPerFieldWithRowCheckbox = sourceColumns

    .map((sourceColumn, columnIndex) => {
      const sourceField = sourceColumn.field
      const destinationColumn = destinationColumns[columnIndex]
      const destinationField = destinationColumn?.field
      if (destinationColumn?.type === 'rowCheckbox') {
        // if (sourceColumn?.type === 'rowCheckbox') {
        // TODO: ここでpickedIdsを取得しなければならないが、改修量が多くなってきたのでやめる
        // }
        const value = sourceRecord.attributes[sourceField?.name ?? '']
        if (value === undefined) {
          return {
            type: `rowCheckbox` as const,
            recordId: destinationRecord.record.id!,
            value: true, // 適当にデフォルトをtrueにしておく
          }
        }
        return {
          type: `rowCheckbox` as const,
          recordId: destinationRecord.record.id!,
          value: isTruthy(value),
        }
      }
      if (sourceField === undefined || destinationField === undefined || destinationColumn === undefined) {
        logger.debug(
          `[pasteToRoot] field not found or not writable field`,
          sourceField,
          destinationColumn,
          destinationField,
        )
        return
      }

      if (
        (destinationField.meta.creatable === false && destinationField.meta.updatable === false) ||
        destinationField.write === undefined ||
        destinationColumn.readonly === true
      ) {
        logger.debug(`[pasteToRoot] unwritable field`)
        return
      }

      // 同じ値なら何もしない（わざわざこのロジックを置かなくても良いが、参照項目のペーストでブロックが書き変わるときなどで挙動がおかしくならないようにするため）
      if (destinationRecord.record.attributes[destinationField.name] === sourceRecord.attributes[sourceField.name]) {
        logger.debug(`[pasteToRoot] same value`)
        return
      }

      const beforeValue = destinationRecord.record.attributes[destinationField.name]
      destinationRecord.record.attributes[destinationField.name] = sourceRecord.attributes[sourceField.name]
      const afterValue = destinationRecord.record.attributes[destinationField.name]
      const labelName = `${destinationField.name}_label` // TODO
      if (sourceRecord.attributes[labelName] !== undefined) {
        destinationRecord.record.attributes[labelName] = sourceRecord.attributes[labelName]
      }

      pasteRelationChildren({
        sourceField,
        destField: destinationField,
        sourceRecord,
        destRecord: destinationRecord.record,
        destNode: destinationNode,
      })

      return {
        type: `write` as const,
        name: destinationField.name,
        write: destinationField.write,
        beforeValue,
        afterValue,
      }
    })
    .compact()
  const changesPerField = changesPerFieldWithRowCheckbox.map((x) => (x.type === 'write' ? x : undefined)).compact()
  const changesForRowCheckbox = changesPerFieldWithRowCheckbox
    .map((x) => (x.type === 'rowCheckbox' ? x : undefined))
    .compact()

  // changesの生成
  const streamNames = changesPerField.map((x) => x.write.streamName)
  const streamName = streamNames.first()
  if (streamName === undefined || streamNames.unique().length !== 1) {
    // ロジック上ありえないはずだが一応チェックする（他のロジックの不具合がここに影響したことがあった）
    return changesForRowCheckbox
  }

  const change: ViewRecordFieldChange = {
    id: destinationRecord.record.id,
    streamName,
    fieldChanges: changesPerField.map((x) => ({
      fieldName: x.name,
      propertySourceName: x.write.propertySourceName,
      value: x.afterValue,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      valueLabel: destinationRecord.record.attributes[`${x.name}_label`] as string | undefined,
      rowIndex: destinationRecord.rowIndex,
      innerRowIndex: destinationRecord.innerRowIndex,
      id: destinationRecord.record.id!,
    })),
    before: changesPerField.toObject((x) => [x.write.propertySourceName, x.beforeValue]),
    after: changesPerField.toObject((x) => [x.write.propertySourceName, x.afterValue]),
  }

  return [change, ...changesForRowCheckbox]
}

function pasteRelationChildren({
  sourceField,
  destField,
  sourceRecord,
  destRecord,
  destNode,
}: {
  sourceField: ViewQueryField
  destField: ViewQueryField
  sourceRecord: ViewQueryRecordNode
  destRecord: ViewQueryRecordNode
  destNode: ViewQueryNode
}) {
  // sourceとdestが同じ列の参照項目であり、かつ参照ブロックがある場合、参照先のブロックもペーストする
  if (sourceField.name === destField.name && sourceField.meta.fieldMetaType === 'relation') {
    // 参照しているノードの取得
    const referencingNode = (destNode.children ?? []).find((child) =>
      child.path.isEqual(destField.read.labelNodePath ?? []),
    )
    if (referencingNode === undefined) {
      return
    }
    // 参照しているテーブルノードの取得
    const sourceTableNode = sourceRecord.children.find((child) => child.nodeName === referencingNode.name)
    const destinationTableNode = destRecord.children.find((child) => child.nodeName === referencingNode.name)
    if (sourceTableNode === undefined || destinationTableNode === undefined) {
      return
    }
    const newChildren = structuredClone(sourceTableNode.children)
    destinationTableNode.children.splice(0, 9999, ...newChildren)
  }
}

// relationがone_to_manyのとき、childrenをペーストする
// eslint-disable-next-line @typescript-eslint/max-params
function pasteToChildren(
  sourceRootRecord: ViewQueryRecordNode,
  destinationRootRecord: ViewQueryRecordNodeWithIndex,
  node: ViewQueryNode,
  sourceColumns: Array<RSheetColumn<ViewQueryRecordNode>>,
  destinationColumns: Array<RSheetColumn<ViewQueryRecordNode>>,
  destinationNode: ViewQueryNode,
): PasteChange[] {
  // このロジックに至るケースでは、nodeの深さは必ず2
  if (node.path.length > 2) {
    return []
  }

  // いったん、innerRowIndexは一切無視して、全ての子をペーストする
  const sourceChildRecords = getChildRecordNodeFromRootNode(sourceRootRecord, node.path)
  const destinationChildRecords = getChildRecordNodeFromRootNode(destinationRootRecord.record, node.path, {
    forceCreateChildTableNode: true,
  })

  // 子レコードのペーストのとき、これらは必ず配列またはundefinedのはず。undefinedのときはレコードが存在しないということなので無視する
  // TODO: undefinedのときの処理、もう少し丁寧に行うか？
  if (!Array.isArray(sourceChildRecords) || !Array.isArray(destinationChildRecords)) {
    return []
  }

  // 更新と作成
  const changes = sourceChildRecords.flatMap((sourceRecord, index): PasteChange[] => {
    const cs: ViewRecordFieldChange[] = []

    // レコードが存在しないとき、one_to_manyの関係のときのみ新規作成を行う（参照ブロックの場合は新規作成しない？参照をペーストすべきか？TODO）
    if (destinationChildRecords.length <= index && node.read.join?.relation === 'one_to_many') {
      const { newRecord, changes } = generateNewChildRecord(node, destinationRootRecord.record.id)
      destinationChildRecords.push(newRecord)
      cs.push(...changes)
    }
    const destinationRecord = destinationChildRecords[index]
    if (destinationRecord === undefined) {
      return []
    }

    return [
      ...cs,
      ...pasteToRoot(
        sourceRecord,
        {
          record: destinationRecord,
          rowIndex: destinationRootRecord.rowIndex,
          innerRowIndex: destinationRootRecord.innerRowIndex, // TODO: これであってるのか？
        },
        sourceColumns,
        destinationColumns,
        destinationNode,
      ),
    ]
  })

  return changes.compact()
}

// 孫以下への子孫ノードへのペーストのとき、子ノードを再帰的に処理する
// eslint-disable-next-line @typescript-eslint/max-params
function pasteToGrandChildren(
  sourceRootRecord: ViewQueryRecordNode,
  destinationRootRecord: ViewQueryRecordNodeWithIndex,
  node: ViewQueryNode,
  sourceColumns: Array<RSheetColumn<ViewQueryRecordNode>>,
  destinationColumns: Array<RSheetColumn<ViewQueryRecordNode>>,
): PasteChange[] {
  // このロジックに至るケースでは、nodeの深さは必ず2より大きい
  if (node.path.length <= 2) {
    return []
  }

  // 子ノードを配列で取り出す
  const sourceChildRecords = getChildRecordNodeFromRootNode(sourceRootRecord, node.path.slice(0, 2))
  const destinationChildRecords = getChildRecordNodeFromRootNode(destinationRootRecord.record, node.path.slice(0, 2))

  // 子レコードのペーストのとき、これらは必ず配列またはundefinedのはず。undefinedのときはレコードが存在しないということなので無視する
  // TODO: undefinedのときの処理、もう少し丁寧に行うか？
  if (!Array.isArray(sourceChildRecords) || !Array.isArray(destinationChildRecords)) {
    return []
  }

  const changes = sourceChildRecords.flatMap((sourceRecord, index) => {
    const cs: ViewRecordFieldChange[] = []
    // 子ノードがなかったら生成する
    // TODO: 子ノードへのペーストが別であるとき、これはちゃんと継承されるか？
    if (destinationChildRecords.length <= index) {
      const { newRecord, changes } = generateNewChildRecord(node, destinationRootRecord.record.id)
      destinationChildRecords.push(newRecord)
      cs.push(...changes)
    }
    const destinationRecord = destinationChildRecords[index]
    if (destinationRecord === undefined) {
      return []
    }

    // 再起的に処理する
    return [
      ...cs,
      ...paste(
        {
          ...node,
          path: node.path.slice(1),
        },
        sourceColumns,
        destinationColumns,
        sourceRecord,
        {
          record: destinationRecord,
          rowIndex: destinationRootRecord.rowIndex,
          innerRowIndex: destinationRootRecord.innerRowIndex,
        },
      ),
    ]
  })

  return changes
}

// 深さ2の子レコードを取得する
// TODO: nodeUtilの実装をほぼコピペしたもの。共通化したい。
function getChildRecordNodeFromRootNode(
  rootNode: ViewQueryRecordNode,
  nodePath: string[],
  option?: { forceCreateChildTableNode?: boolean },
): ViewQueryRecordNode[] | undefined {
  if (nodePath.length !== 2) {
    return undefined
  }
  return getRecordNode(rootNode, nodePath.slice(1), option)
}

function getRecordNode(
  node: ViewQueryRecordNode,
  nodePath: string[],
  option?: { forceCreateChildTableNode?: boolean },
): ViewQueryRecordNode[] | undefined {
  const nodeName = nodePath.first()
  if (nodeName === undefined) {
    return undefined
  }

  const childTableNode = node.children.find((x) => x.nodeName === nodeName)
  if (childTableNode === undefined) {
    if (option?.forceCreateChildTableNode ?? false) {
      const newChildTableNode: RSheetTableNode = {
        nodeName,
        children: [],
      }
      node.children.push(newChildTableNode)
      return newChildTableNode.children
    }
    return undefined
  }
  return childTableNode.children
}
