import { isSome } from '@salescore/buff-common'
import { CORE_CONSTANT } from '@salescore/core'
import { t } from 'i18next'

function extractStringBetween(string_: string, start: string, end: string) {
  const pattern = new RegExp(`${start}(.*?)${end}`, 's')
  const match = string_.match(pattern)
  return match ? match[1] : `-`
}

// TODO: エラー内容と対応するアクション、タイトルのマッピングはBEで完結させたいので移行する
// 愚直に文字列で判定しているがthrowするerrorクラスやerrorID等で判定したい
// eslint-disable-next-line complexity
export function getActionMessage(message: string) {
  if (message.includes('データ値が大きすぎる')) {
    const extract = /max length=(\d+)/
    const maxLength = extract.exec(message)?.[1]
    return isSome(maxLength) ? t(`${maxLength}文字以内で入力してください`) : t(`文字数を減らして入力してください`)
  }

  if (message.includes('個人の連携設定が行われていない')) {
    return t(`書き込みを行うために連携をしてください`)
  }

  if (message.includes('連携設定の再設定をお願いします。')) {
    return t(`書き込みを行うために再連携をしてください`)
  }

  if (message.includes('オブジェクトへのアクセス権限が不足しています')) {
    return t(`オブジェクトを利用する権限が不足しています。SFAのシステム管理者にお問い合わせください`)
  }

  if (message.includes('SalesforceFieldIntegrityError')) {
    return t(
      `Salesforceのフローでエラーが発生しようているようです。エラー内容を確認し、自力解決が難しい場合Salesforceのシステム管理者にお問い合わせください`,
    )
  }

  if (message.includes('初期設定が終わっていません。')) {
    const kpiInfo = message.split(': 初期設定が終わっていません')
    return t(`${kpiInfo[0]}のKPI設定が終わっていません`)
  }

  if (message.includes('指定したオブジェクトが見つかりません。')) {
    const kpiInfo = message.split(': 指定したオブジェクトが見つかりません。')
    return t(
      `KPI(${kpiInfo[0]})に使用されているオブジェクト(${kpiInfo[1]})がSFA側で削除されたか、同期項目から除外された可能性があります。SALESCOREまたはSFAの管理者にオブジェクトが存在するか確認してください。`,
    )
  }

  if (message.includes('JOINの生成に失敗しました。')) {
    return t(
      `オブジェクトのJOINに失敗しました。SFA側でオブジェクトもしくは項目が削除されたか、同期項目から除外された可能性があります。SALESCOREまたはSFAの管理者に項目が存在するか確認してください。もしくは不明列があれば消してください。`,
    )
  }

  if (message.includes('ノードが見つかりません。')) {
    const objectInfo = message.split('ノードが見つかりません。')
    return t(
      `KPIに使用されているオブジェクト(${objectInfo[1]})がSFA側で削除されたか、同期項目から除外された可能性があります。SALESCOREまたはSFAの管理者にオブジェクトが存在するか確認してください。もしくは不明列またはKPIを削除してください。`,
    )
  }

  if (message.includes('terminating connection due to administrator command')) {
    return t(
      `データ数が多すぎて実行に時間がかかりすぎています。集計に使うオブジェクトや絞り込みを調整する、もしくはカスタマーサクセスにお問い合わせください。`,
    )
  }

  if (message.includes('FIELD_CUSTOM_VALIDATION_EXCEPTION')) {
    const extractMessage = extractStringBetween(message, 'FIELD_CUSTOM_VALIDATION_EXCEPTION,', 'Class.CT_')
    return t(`${extractMessage} 解決しない場合はSalesforce管理者にお問い合わせください。`)
  }

  if (message.includes('CANNOT_EXECUTE_FLOW_TRIGGER')) {
    const extractMessage = extractStringBetween(message, 'CANNOT_EXECUTE_FLOW_TRIGGER,', 'Class.CT_')
    return t(
      `Salesforce側のフローでエラーが発生しています。以下エラーメッセージで解消できない場合はSalesforceの管理者にお問い合わせください。${extractMessage}`,
    )
  }

  if (message.includes('制限つき選択リスト項目の値が不適切')) {
    return t(
      `連動項目の場合誤った選択肢を選んでいる可能性があります。正しい選択肢をえらんでください。もしくは古い選択肢の可能性があります。その場合は管理者にお問合せください。`,
    )
  }

  if (message.includes('エンティティは削除済みです')) {
    return t(`該当レコードは削除されています。同期ボタンをクリックしてデータを最新状態にしてください。`)
  }

  if (message.includes('duplicate key value violates unique constraint')) {
    return t(
      '同期中の処理が影響しエラーが発生しています。時間を置いてから再度お試しください。解決しない場合はカスタマーサクセスにお問い合わせください。',
    )
  }

  if (message.includes('HttpError: HTTP request failed') || message.includes('"name":"ApolloError","graphQLErrors"')) {
    return t('ネットワークエラーです。再度実行してください。解決しない場合はカスタマーサクセスにお問い合わせください。')
  }

  if (message.includes('Error: SQLの実行中にエラー')) {
    if (message.includes('does not exist')) {
      const firstLine = extractStringBetween(message, 'Error: SQLの実行中にエラー。', ' does not exist')
      if (isSome(firstLine)) {
        const objectName = firstLine.split(' ').last()
        return (objectName?.includes(CORE_CONSTANT.CUSTOM_MODEL_PREFIX) ??
          objectName?.includes(CORE_CONSTANT.SALESCORE_CUSTOM_MODEL_PREFIX))
          ? t(
              `カスタムオブジェクト({{objectName}})の読み込みに失敗しました。設定に問題があるかもしれませんので、カスタマーサポートに連絡してください。`,
              { objectName },
            )
          : t(
              `対象オブジェクトが読み込み中の場合は完了するまでしばらくお待ちください。または連携先のSFAで {{objectName}} が削除もしくはAPI参照名が変更された可能性があります。SFAの管理者にお問い合わせください。`,
              { objectName },
            )
      }
    }

    if (message.includes('division by zero')) {
      return t(`0で割り算を行う可能性のあるSQL、または項目を修正してください。`)
    }

    if (message.includes('syntax error at or near')) {
      return t(`SQLを修正してください。もしくは管理者にお問合せください。`)
    }
  }

  if (message.startsWith('親レコードの作成に失敗したため、保存されませんでした')) {
    return t(`別で表示されているエラーを修正してから再度保存してください。`)
  }

  if (message.startsWith('insufficient access rights on')) {
    return t(`参照レコードの編集権限がないため書き込めません。管理者に問い合わせてください。`)
  }

  if (
    message.startsWith('SalesforceApiResponseError') ||
    message.startsWith('あなたのプロファイルでは、編集できません。') ||
    message.startsWith('失注・スライド詳細理由を入力してください。') ||
    /フェーズを.*で登録する際は/.test(message) ||
    /フェーズが.*のとき、/.test(message)
  ) {
    return t(`解決しない場合はSalesforce管理者にお問い合わせください。`)
  }
}

// エラー文をどこで区切ってタイトルとするか、もしくは内容に応じてタイトルを作成するか検討中
// マッピング対象がない場合は一旦仮で改行で区切って最初の行をタイトルとする

export function getErrorTitle(message: string) {
  if (message.includes('個人の連携設定が行われていない')) {
    return t(`個人の連携設定が行われていないため、書き込みできません。`)
  }

  if (message.includes('親レコードの作成に失敗したため')) {
    return t(`親レコードの作成に失敗したため、保存されませんでした`)
  }

  if (message.includes('レコードが存在しない')) {
    return t(`レコードが存在しないか、アクセス権限がありません。`)
  }

  if (message.includes('model not found')) {
    return message.split(',')[0]
  }

  if (message.includes('CT_ItemTrigger')) {
    return t(`Salesforce側で自動実行される処理でエラーが発生しました`)
  }

  // kintone用エラータイトル (量が増え煩雑になりそうだったらprovider毎に管理する)
  if (message.includes('URLの形式が正しくありません')) {
    return t(`URLの形式が正しくありません。「http://」または「https://」で始まる必要があります。`)
  }

  if (message.includes('REFERENCE_TABLE型のフィールドに値を設定することはできません')) {
    return t(`関連レコード一覧の項目に値を入力することはできません。`)
  }

  if (message.includes('ルックアップの参照先から値をコピーできません')) {
    return t(`ルックアップ項目に値を入力することはできません。`)
  }

  if (message.includes('GROUP型のフィールドに値を設定することはできません')) {
    return t(`GROUP型の項目に値を入力することはできません。`)
  }

  return message.split('\n')[0]?.split(':').slice(0, 2).join(':')
}
