import 'antd/dist/reset.css'
import '../../assets/styles/antd.less'
import '../../assets/styles/tailwind.less'
import '../../assets/styles/external.less'
import '../../assets/styles/transition.less'
import '../../assets/styles/main.less'
import '@salescore/client-base'
// Next.js がデフォルトでやってくれない Polyfill はここでやる: https://github.com/vercel/next.js/blob/canary/packages/next-polyfill-nomodule/src/index.js
// eslint-disable-next-line unicorn/no-unnecessary-polyfills
import 'core-js/features/array/to-reversed'

import BugsnagPerformance from '@bugsnag/browser-performance'
import { HoneycombWebSDK } from '@honeycombio/opentelemetry-web'
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
import { extendProperties, VERSION } from '@salescore/buff-common'
import { ErrorProvider } from '@salescore/client-common'
import { APP_CONFIG } from '@salescore/core'
import { useRedirect } from '@salescore/frontend-common'
import dayjs from 'dayjs'
import type { AppProps } from 'next/app'
import { isIE } from 'react-device-detect'

import { DefaultProvider } from '../layouts/base/DefaultProvider'
import { isMaintainancePeriod, MaintainanceLayout } from '../layouts/MaintainanceLayout'
import { MemberLayout } from '../layouts/MemberLayout'
import { NoMemberLayout } from '../layouts/NoMemberLayout'

const bugsnagApiKey = process.env.BUGSNAG_API_KEY
if (bugsnagApiKey !== undefined) {
  BugsnagPerformance.start({
    apiKey: bugsnagApiKey,
  })
}

const Observability = (): null => {
  const sdk = new HoneycombWebSDK({
    debug: false,
    apiKey: process.env.HONEYCOMB_API_KEY,
    serviceName: 'client',
    resourceAttributes: {
      'app.version': VERSION,
    },
    instrumentations: [
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-document-load': {
          enabled: true,
        },
      }),
    ], // Adds automatic instrumentation
    sampleRate: APP_CONFIG.HONEYCOMB_SAMPLE_RATE,
  })
  sdk.start()

  return null
}

extendProperties()

dayjs.locale('ja')

const NO_MEMBER_LAYOUT_PATHS = new Set([
  '/sign_up',
  '/sign_in',
  '/sign_out',
  '/reset_password',
  '/verification',
  '/ie',
  '/callback/integration',
  '/auth/[provider]/callback',
  '/',
  '/error',
  '/clear_cache',
  '/o/[organizationId]/sign_in',
  '/help/privacy_policy',
  '/help/terms_of_use',
  '/help',
  '/help/zoom',
])

const Page = (properties: AppProps): JSX.Element => {
  const { router } = properties
  const redirect = useRedirect()
  // const initialized = useAuthClientInitialized();

  // SPA only
  // eslint-disable-next-line unicorn/prefer-global-this
  if (typeof window === 'undefined') {
    return <></>
  }

  if (isMaintainancePeriod()) {
    return <MaintainanceLayout />
  }

  // IEは非対応
  if (isIE && router.pathname !== '/ie') {
    redirect('/ie')
  }

  if (NO_MEMBER_LAYOUT_PATHS.has(router.pathname)) {
    return (
      <DefaultProvider>
        <NoMemberLayout {...properties} />
      </DefaultProvider>
    )
  }

  return (
    <DefaultProvider>
      <ErrorProvider router={router}>
        {/*{contextHolder}*/}
        <MemberLayout {...properties} />
        <Observability />
      </ErrorProvider>
    </DefaultProvider>
  )
}
export default Page
