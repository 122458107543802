import { buildReadonlyHook, getQueryParameter, useMessage, useModalAtom } from '@salescore/frontend-common'
import { Button } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { useMe } from '../global/hooks'
import {
  archivedViewGroupTreeAtom,
  deleteViewOrViewGroupModalAtom,
  favoritesAtom,
  hasChangesAtom,
  isSaveConfigButtonActiveAtom,
  moveViewOrViewGroupModalAtom,
  pickedViewIdAtom,
  pickedViewIdsAtom,
  privateViewGroupTreeAtom,
  privateViewGroupTreeExpandedKeysAtom,
  urlQueryParametersAtom,
  viewFormModalAtom,
  viewGroupModalAtom,
  viewGroupTreeAtom,
  viewGroupTreeExpandedKeysAtom,
  viewGroupTreeFavoritedExpandedKeysAtom,
} from './atoms'
import { generateViewGroupTreeKey } from './functions/convertFlatNodesToAntdTree'
import { useViewsRelated } from './selector/viewsRelatedSelector'

export const useViewGroupTree = buildReadonlyHook(viewGroupTreeAtom)
export const useFavoritesValue = buildReadonlyHook(favoritesAtom)
export const useViewGroupTreeExpandedKeys = () => useRecoilState(viewGroupTreeExpandedKeysAtom)
export const useViewGroupTreeFavoritedExpandedKeys = () => useRecoilState(viewGroupTreeFavoritedExpandedKeysAtom)
export const useHasChanges = () => useRecoilState(hasChangesAtom)
export const useIsSaveConfigButtonActive = () => useRecoilState(isSaveConfigButtonActiveAtom)

export const usePrivateViewGroupTree = buildReadonlyHook(privateViewGroupTreeAtom)
export const usePrivateViewGroupTreeExpandedKeys = () => useRecoilState(privateViewGroupTreeExpandedKeysAtom)

export const useArchivedViewGroupTree = buildReadonlyHook(archivedViewGroupTreeAtom)

export const usePickedViewIds = () => {
  const me = useMe() // organization.settingのために必要
  const { defaultOpenViewIds } = me.organization.setting
  const [pickedViewIds, setPickedViewIds] = useRecoilState(pickedViewIdsAtom)
  const [pickedViewId, setPickedViewId] = useRecoilState(pickedViewIdAtom)
  const [urlQueryParameters, setUrlQueryParameters] = useRecoilState(urlQueryParametersAtom)
  const [, setExpandedKeys] = useViewGroupTreeExpandedKeys()
  const [hasChanges, setHasChanges] = useRecoilState(hasChangesAtom)
  const { views, viewGroupsWithParents } = useViewsRelated()
  const isSaveConfigButtonActive = useRecoilValue(isSaveConfigButtonActiveAtom)
  const message = useMessage()
  const pickedViews = [
    // pickedViewsとして表示されるビューカードは、以下の3つを合わせたもの
    ...defaultOpenViewIds,
    ...pickedViewIds,
    pickedViewId, // 基本的にpickedViewIdsに含まれるが、pickした直後は含まれない（これは、この後のuseEffectで解消される）
  ]
    .unique()
    .map((id) => views.find((view) => view.id === id))
    .compact()
  const pickedView = views.find((view) => view.id === pickedViewId) ?? pickedViews.first()

  useEffect(() => {
    if (views.length > 0) {
      setPickedViewIds(pickedViews.map((x) => x.id))
    }
  }, [views])

  useEffect(() => {
    if (pickedView === undefined) {
      return
    }

    if (pickedView.id !== pickedViewId) {
      setPickedViewId(pickedView.id)
    }

    // 選択したビューの親のグループをトグルするためのキーを追加する
    const viewGroupsByPicked = viewGroupsWithParents.find(({ viewGroup }) => viewGroup.id === pickedView.viewGroupId)

    if (viewGroupsByPicked !== undefined) {
      const viewGroupIdsByPicked = [viewGroupsByPicked.viewGroup.id, ...viewGroupsByPicked.parents.map((p) => p.id)]
      const viewGroupKeysByPicked = viewGroupIdsByPicked.map((element) => generateViewGroupTreeKey(element)).unique()
      setExpandedKeys((current) => [...viewGroupKeysByPicked, ...current].unique())
    }
  }, [pickedView])

  useEffect(() => {
    const url = new URL(globalThis.location.href)
    const parameters = ['shareId', 'pickedPresetName']
    for (const key of parameters) {
      const value = getQueryParameter(key)
      pickUrlQueryParameter(key, value)
      url.searchParams.delete(key)
    }
    globalThis.history.replaceState({}, '', url.toString())
  }, [])

  function pickUrlQueryParameter(key: string, value: string | null) {
    if (value === null) {
      return
    }
    setUrlQueryParameters((parameters) => {
      const newParameters = new Map(parameters)
      newParameters.set(key, { value, pickedAt: Date.now() })
      return newParameters
    })
  }

  function pickViewBase(id: string, shareId?: string) {
    setPickedViewIds((ids) => {
      if (ids.includes(id)) {
        return ids
      }
      return [...ids, id]
    })
    if (hasChanges || isSaveConfigButtonActive) {
      const messageKey = `move-tab-warning`
      message.warning({
        key: messageKey,
        content: (
          <span>
            {t(`変更差分がある場合はタブを切り替えられません。`)}
            <br />
            {t(`差分を削除してタブを切り替えますか？`)}
            <Button
              className="ml-2"
              onClick={() => {
                pickUrlQueryParameter('shareId', shareId ?? null)
                setPickedViewId(id)
                message.destroy(messageKey)
              }}
            >
              {t(`はい`)}
            </Button>
          </span>
        ),
        duration: 3,
      })
      return
    }
    pickUrlQueryParameter('shareId', shareId ?? null)
    setPickedViewId(id)
  }

  return {
    pickedViewId: pickedView?.id,
    pickedViewIds: pickedViews.map((x) => x.id),
    pickedView,
    pickedViews,
    defaultOpenViewIds,
    setPickedViewIds,
    setPickedViewId,
    urlQueryParameters,
    setUrlQueryParameters,
    pickView: (id: string) => {
      pickViewBase(id)
      // URL クエリパラメータは初期レンダリングのタブでのみ適用したいため、他のタブを選択したときにはクリアする
      setUrlQueryParameters(new Map())
    },
    pickViewAndSetShareId: pickViewBase,

    unpickView: (id: string) => {
      if (pickedView?.id === id) {
        const index = pickedViews.findIndex((x) => x.id === id)
        const previousView = pickedViews[index - 1]
        const nextView = pickedViews[index + 1]
        setPickedViewId(previousView?.id ?? nextView?.id ?? null)
      }
      setPickedViewIds((ids) => ids.filter((x) => x !== id))
    },
  }
}

export const useNavigationModal = () => {
  const viewFormModal = useModalAtom(viewFormModalAtom)
  const viewGroupModal = useModalAtom(viewGroupModalAtom)
  const moveViewOrViewGroupModal = useModalAtom(moveViewOrViewGroupModalAtom)
  const deleteViewOrViewGroupModal = useModalAtom(deleteViewOrViewGroupModalAtom)

  return {
    viewFormModal,
    viewGroupModal,
    moveViewOrViewGroupModal,
    deleteViewOrViewGroupModal,
  }
}
