import { useApolloClient } from '@apollo/client'
import { isSome, isTruthy } from '@salescore/buff-common'
import { FetchViewQueryResultByStreamDocument, FetchViewQueryResultDocument } from '@salescore/client-api'
import { checkRiEnabled, checkVisualizeEnabled } from '@salescore/client-recoil'
import type { ViewQuery, ViewQueryResult } from '@salescore/core'
import { queueTimeWindowExecutorV2 } from '@salescore/frontend-common'
import { message } from 'antd'
import { t } from 'i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { kpiUserAppearanceAtom } from '../../navigation/atoms'
import { multiTablePivotQuerySelecttor } from '../../view/atoms'
import { useMeValue, useViewValue } from '../../view/hooks'
import { errorsAtom, organizationIdAtom, recordsAtom, viewQueryResultAtom } from '../atoms'
import { useLoadingState } from '../hooks'
import { useMustacheParameterSelector } from '../selectors/mustacheParameterSelector'
import { sendRefetchErrorToPosthog } from './useRefetchMutation'

const POLLING_MAX_RETRY_COUNT = 65

export const useRefetchKpiPivotQueryResult = () => {
  const organizationId = useRecoilValue(organizationIdAtom)
  const mustacheParameter = useMustacheParameterSelector()
  const query = useRecoilValue(multiTablePivotQuerySelecttor)
  const client = useApolloClient()
  const loading = useLoadingState()
  const me = useMeValue()
  const view = useViewValue()
  const kpiUserAppearance = useRecoilValue(kpiUserAppearanceAtom)

  const setErrors = useSetRecoilState(errorsAtom)
  const setDataState = useSetRecoilState(recordsAtom)
  const setViewQueryResult = useSetRecoilState(viewQueryResultAtom)

  const posthogProperties = {
    organizationId,
    organizationName: me.organization.name,
    viewId: view.id,
    viewType: view.type,
    viewName: view.name,
    userName: me.myUser.name,
    email: me.myUser.identity.email,
  }

  const applyViewQueryResult = (viewQueryResult: ViewQueryResult) => {
    setViewQueryResult(viewQueryResult)
    setDataState(viewQueryResult.result)

    const { error } = viewQueryResult
    if (isSome(error) && error !== '') {
      setErrors([error])
      sendRefetchErrorToPosthog({
        ...posthogProperties,
        error,
      })
      void message.error(t(`エラーが発生しました。エラー詳細ボタンから詳細をご確認ください。`))
      // return // sheetだとここでreturnするが、kpiの場合は複数KPI実行で一部のみがエラーになりうるため行わない
    } else {
      setErrors([])
    }
    if (isSome(viewQueryResult.warn) && viewQueryResult.warn !== '') {
      // i18n: バックエンドから以下の警告文が文字列として送られてくる場合がある。
      // `レコードが多すぎるため、全てを表示できません。`
      // `行が多すぎるため、全てを表示できません。「集計項目 - 行」を減らすと問題が解消する場合があります。`
      void message.warning(t(viewQueryResult.warn))
    }
    return viewQueryResult
  }

  const fetchViewQueryResult = async ({
    viewQuery,
    mustacheParameter,
    useCache,
  }: {
    viewQuery: ViewQuery
    mustacheParameter: Record<string, string | undefined>
    useCache?: boolean
  }) => {
    const { data: result } = await client.query({
      query: FetchViewQueryResultDocument,
      variables: {
        organizationId,
        viewQuery,
        mustacheParameter,
        viewId: view.id,
        unlimitedRowsEnabled: kpiUserAppearance.unlimitedRowsEnabled,
      },
      fetchPolicy: isTruthy(useCache) ? 'cache-first' : 'no-cache', // cache-and-networkが選べない？
    })

    applyViewQueryResult(result.viewQueryResult)

    return result.viewQueryResult
  }

  const pollStream = async ({ streamId, count = 1 }: { streamId: string; count?: number }) => {
    if (count > POLLING_MAX_RETRY_COUNT) {
      const errorMessage = 'タイムアウト。リトライ回数が上限に達しました'
      setErrors([errorMessage])
      sendRefetchErrorToPosthog({
        ...posthogProperties,
        error: errorMessage,
      })
      void message.error(t(`エラーが発生しました。エラー詳細ボタンから詳細をご確認ください。`))
      return
    }

    const { data: result } = await client.query({
      query: FetchViewQueryResultByStreamDocument,
      variables: {
        organizationId,
        streamId,
        unlimitedRowsEnabled: kpiUserAppearance.unlimitedRowsEnabled,
      },
      fetchPolicy: 'no-cache',
    })

    applyViewQueryResult(result.viewQueryResultByStream)

    const isStreaming =
      result.viewQueryResultByStream.ui?.pivotColumns?.some((c) => c.valuesWithLabel.some((v) => v.isStreaming)) ??
      false
    if (isStreaming) {
      // 最初のほうは間隔短め、最大5秒間隔
      const interval = Math.min(1000 * count, 5000)
      setTimeout(() => {
        void pollStream({ streamId, count: count + 1 })
      }, interval)
    }
  }

  const fetch = async ({
    viewQuery,
    mustacheParameter,
    useCache,
  }: {
    viewQuery: ViewQuery | undefined
    mustacheParameter: Record<string, string | undefined>
    useCache?: boolean
  }) => {
    if (!checkVisualizeEnabled(me.myUser) && !checkRiEnabled(me.myUser)) {
      return
    }

    if (viewQuery === undefined) {
      return
    }

    if (query === undefined || query.queries.isBlank()) {
      return
    }

    try {
      loading.setTrue()
      const viewQueryResult = await fetchViewQueryResult({ viewQuery, mustacheParameter, useCache })
      loading.setFalse()

      const isStreaming =
        viewQueryResult.ui?.pivotColumns?.some((c) => c.valuesWithLabel.some((v) => v.isStreaming)) ?? false
      if (viewQueryResult.streamId != null && isStreaming) {
        await pollStream({ streamId: viewQueryResult.streamId })
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrors([error.message])
        sendRefetchErrorToPosthog({
          ...posthogProperties,
          error: error.message,
        })
        void message.error(t(`エラーが発生しました。エラー詳細ボタンから詳細をご確認ください。`))
      }
    } finally {
      loading.setFalse()
    }
  }

  return {
    refetch(useCache?: boolean) {
      queueTimeWindowExecutorV2({
        key: `useRefetchKpiPivotQueryResult`,
        f: async () => {
          await fetch({ viewQuery: query, mustacheParameter, useCache })
        },
      })
    },
  }
}
