import { useApolloClient } from '@apollo/client'
import { isPresent, isSome } from '@salescore/buff-common'
import {
  FetchOauthAuthorizationUrlDocument,
  type FetchOauthAuthorizationUrlQueryVariables,
  type LangEnum,
  type SourceFieldsFragment,
} from '@salescore/client-api'
import { getOrganizationIdFromPath, routes } from '@salescore/client-base'
import { useState } from 'react'
import { z } from 'zod'

import { setCurrentOrganizationIdToLocalStorage, setRedirectPathToLocalStorage } from '../presentation/common/auth'

export const LATEST_OPENED_SOURCE_KEY = 'LATEST_OPENED_SOURCE_KEY'
export const LATEST_OPENED_EXTRA_CONFIG = 'LATEST_OPENED_EXTRA_CONFIG'
export const LATEST_OPENED_I18N_LANG = 'LATEST_OPENED_I18N_LANG'

export const oauthExtraConfigSchema = z.object({
  authorizeUrl: z.string().optional(),
  clientId: z.string().optional(),
  clientSecret: z.string().optional(),
  spreadsheetId: z.string().optional(),
  name: z.string().optional(),
  additionalScope: z.string().optional(),
})
export type OAuthExtraConfig = z.infer<typeof oauthExtraConfigSchema>

// XXX: SalesforceのAppExchangeアプリからSALESCOREを使っている場合、
//      不明な原因でSalesforceのログイン画面にリダイレクトされてしまうことがあり、iframeのエラーになるので
//      AppExchangeアプリを使っている組織については、location.assignではなく、window.openする形にする
//      （本来は組織ID指定ではなく、別の方法でAppExchangeかどうかを判定したいが一旦わかりやすさを重視した）
//      ※ iframeのエラー : Salesforceのログイン画面は Content Security Policy : frame-ancestors 'none'が設定されておりiframeで開けない
//        c.f. https://help.salesforce.com/s/articleView?id=sf.security_clickjack_protection_configure.htm
const appExchangeOrganizationIds = new Set([
  `e67bc5cf-650e-43c2-b34f-de10ec18588c`,
  `dc1915af-d91a-41d6-bd59-1f64ec6e6a8b`,
  `4636aa05-cf7c-472a-8160-06c396c567ba`,
  `4`, // 調査用
])

// サーバーのOauthAuthorizationUrlのクエリを叩いて認証用URLを取得し、取得したURLをそのまま開くhooks
export const useOpenAuthorizationUrl = () => {
  const [loadingProvider, setLoadingProvider] = useState<string>()
  const client = useApolloClient()

  const openAuthorizationUrl = async ({
    provider,
    source,
    type,
    extraConfig,
    i18nLang,
  }: {
    provider: string
    source?: Pick<SourceFieldsFragment, 'id' | 'organizationId'> // userSourceのときでもsourceを渡すこと。（source.idを使って、sfのsandbox urlなどを取得するため）
    type: 'userSource' | 'organizationSource' | 'signIn' | 'i18nSource'
    extraConfig?: OAuthExtraConfig
    i18nLang?: LangEnum
  }) => {
    const { authorizeUrl, clientId, additionalScope } = extraConfig ?? {}

    if (isPresent(authorizeUrl) && !isUrl(authorizeUrl)) {
      throw new Error(`invalid url`)
    }
    setLoadingProvider(provider)

    const organizationId = source?.organizationId
    const variables: FetchOauthAuthorizationUrlQueryVariables = {
      organizationId, // sourceがないとき、organizationIdも空になるが、特に問題はない
      provider,
      authorizeUrl,
      clientId,
      sourceId: source?.id,
      additionalScope,
    }
    const result = await client.query({
      query: FetchOauthAuthorizationUrlDocument,
      variables,
    })
    if (isPresent(organizationId)) {
      setCurrentOrganizationIdToLocalStorage(organizationId)
    }
    localStorage.setItem(LATEST_OPENED_SOURCE_KEY, JSON.stringify(source) ?? '{}')
    localStorage.setItem(LATEST_OPENED_EXTRA_CONFIG, JSON.stringify(extraConfig))
    if (isSome(i18nLang)) {
      localStorage.setItem(LATEST_OPENED_I18N_LANG, i18nLang)
    } else {
      localStorage.removeItem(LATEST_OPENED_I18N_LANG)
    }

    switch (type) {
      case 'organizationSource': {
        setRedirectPathToLocalStorage(routes.sourceSettingsPathV2())
        break
      }
      case 'i18nSource': {
        setRedirectPathToLocalStorage(routes.sourceSettingsPathV2())
        break
      }
      case 'userSource': {
        setRedirectPathToLocalStorage(routes.mePathV2())
        break
      }
      case 'signIn': {
        setRedirectPathToLocalStorage(routes.signInPath())
        break
      }
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
      default: {
        const x: never = type
        return
      }
    }

    if (result.data.oauthAuthorizationUrl !== undefined) {
      const organizationId = getOrganizationIdFromPath()
      if (appExchangeOrganizationIds.has(organizationId)) {
        window.open(result.data.oauthAuthorizationUrl)
      } else {
        location.assign(result.data.oauthAuthorizationUrl)
      }
    }
  }

  // useEffect(() => {
  //   localStorage.removeItem(LATEST_OPENED_SOURCE_KEY)
  //   localStorage.removeItem(LATEST_OPENED_EXTRA_CONFIG)
  // }, [])

  return {
    openAuthorizationUrl,
    loadingProvider,
  }
}

function isUrl(url: string) {
  return url.startsWith(`https://`)
}
