import type { UserAbility } from './ability'
import type { PolicySubject } from './schema'

function toSubject(
  record: { id: string; createdById?: string | null },
  entityType: PolicySubject['entityType'],
): PolicySubject {
  return {
    id: record.id,
    entityType,
    createdById: record.createdById ?? undefined,
  }
}

// このパターンのチェックが非常に多いので、utilとしてまとめてしまう
export type ViewPolicyAction =
  | 'create'
  | 'update'
  | 'delete'
  | 'create-new-record'
  | 'save-record'
  | 'delete-record'
  | 'open-relation-input-form'
  | 'download-csv'

async function throwIfCannotForView(
  ability: UserAbility,
  action: ViewPolicyAction,
  view: { id?: string; createdById?: string | null; type: 'sheet' | 'kpi' | 'kpiPivot' | 'form' | 'kpiTimeSeries' },
) {
  const subject = view.id === undefined ? undefined : toSubject({ ...view, id: view.id }, 'view')
  switch (view.type) {
    case 'sheet': {
      switch (action) {
        case 'create': {
          await ability.throwIfCannot('sheet-create', subject)
          return
        }
        case 'update': {
          await ability.throwIfCannot('sheet-update', subject)
          return
        }
        case 'delete': {
          await ability.throwIfCannot('sheet-delete', subject)
          return
        }
        case 'create-new-record': {
          await ability.throwIfCannot('sheet-create-new-record', subject)
          return
        }
        case 'save-record': {
          await ability.throwIfCannot('sheet-save-record', subject)
          return
        }
        case 'delete-record': {
          await ability.throwIfCannot('sheet-delete-record', subject)
          return
        }
        case 'open-relation-input-form': {
          await ability.throwIfCannot('sheet-open-relation-input-form', subject)
          return
        }
        case 'download-csv': {
          await ability.throwIfCannot('sheet-download-csv', subject)
          return
        }
        // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        default: {
          const _: never = action
          return
        }
      }
    }
    case 'kpi': {
      switch (action) {
        case 'create': {
          await ability.throwIfCannot('kpi-create', subject)
          return
        }
        case 'update': {
          await ability.throwIfCannot('kpi-update', subject)
          return
        }
        case 'delete': {
          await ability.throwIfCannot('kpi-delete', subject)
          return
        }
        case 'create-new-record': {
          await ability.throwIfCannot('kpi-create-new-record', subject)
          return
        }
        case 'save-record': {
          await ability.throwIfCannot('kpi-save-record', subject)
          return
        }
        case 'delete-record': {
          await ability.throwIfCannot('kpi-delete-record', subject)
          return
        }
        case 'open-relation-input-form': {
          await ability.throwIfCannot('kpi-open-relation-input-form', subject)
          return
        }
        case 'download-csv': {
          await ability.throwIfCannot('kpi-download-csv', subject)
          return
        }
        // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        default: {
          const _: never = action
          return
        }
      }
    }
    case 'kpiPivot': {
      switch (action) {
        case 'create': {
          await ability.throwIfCannot('kpi-pivot-create', subject)
          return
        }
        case 'update': {
          await ability.throwIfCannot('kpi-pivot-update', subject)
          return
        }
        case 'delete': {
          await ability.throwIfCannot('kpi-pivot-delete', subject)
          return
        }
        case 'create-new-record': {
          return false
        }
        case 'save-record': {
          return false
        }
        case 'delete-record': {
          return false
        }
        case 'open-relation-input-form': {
          return false
        }
        case 'download-csv': {
          await ability.throwIfCannot('kpi-pivot-download-csv', subject)
          return
        }
        // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        default: {
          const _: never = action
          return
        }
      }
    }
    case 'form': {
      return // formのとき、特になし
    }
    case 'kpiTimeSeries': {
      // TODO: RI
      return
    }
  }
  const _: never = view.type
}

export const policyUtil = {
  toSubject,
  throwIfCannotForView,
}
