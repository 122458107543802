import type { ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'
import { message } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { useAddEmptyRecordMutation } from '../../../recoil/records/mutations/addEmptyRecordMutation'
import { isPastingAtom } from '../../../recoil/view/atoms'
import { getPseudoRows } from '../mutations/paste/getChangesByPseudoRootNode'
import type { PasteRelatedData } from '../mutations/paste/getRowsAndColumns'
import { useCursorMutation } from '../mutations/useCursorMutation'
import { readFromClipboard, usePaste } from './usePaste'

function getPseudoRowsToCreate(clipboard: { text: string; pasteRelatedData: PasteRelatedData | undefined }): number {
  const { text, pasteRelatedData } = clipboard
  const pseudoRootNode = pasteRelatedData?.pseudoRootNodes.first()
  if (pasteRelatedData === undefined || pseudoRootNode === undefined) {
    // SALESCORE 以外からコピーされた場合と、なぜか pseudoRootNode が undefined の場合。後者は実際ないはず。
    return text.split('\n').length
  }
  // SALESCORE でコピーされた場合の処理
  return getPseudoRows(pasteRelatedData.rows, pseudoRootNode, pasteRelatedData.area).length
}

//
// 「レコードを追加して貼り付け」のためのhooks
// 現在clipboardにあるテキストを読み取り、その行数分のレコードを追加してから、追加した行に対して貼り付けを行う。

export function usePasteWithNewRecord() {
  const { addRootRecords, addChildRecords } = useAddEmptyRecordMutation()
  const paste = usePaste()
  const [count, setCount] = useState(0) // pasteのタイミングを遅らすためだけのstate
  const cursorMutation = useCursorMutation()
  const setIsPasting = useSetRecoilState(isPastingAtom)

  const pasteWithNewChildRecord = async ({
    rowIndex,
    innerRowIndex,
    node,
  }: {
    rowIndex: number
    innerRowIndex: number
    node: ViewQueryNode
  }) => {
    const clipboard = await readFromClipboard()
    if (!clipboard.success) {
      if (clipboard.error instanceof Error) {
        void message.error(`${t(`クリップボードからの読み取りに失敗しました。`)}${clipboard.error.message}`)
      } else {
        void message.error(t(`クリップボードからの読み取りに失敗しました。`))
      }
      return
    }
    const pseudoRowsToCreate = getPseudoRowsToCreate(clipboard)
    const isRoot = node.path.length <= 1
    if (isRoot) {
      await addRootRecords({ rowIndex, num: pseudoRowsToCreate })
      cursorMutation.setCursor((x) => ({
        ...x,
        main: {
          ...x!.main,
          rowIndex: x!.main.rowIndex + 1,
        },
      }))
    } else {
      // eslint-disable-next-line @typescript-eslint/init-declarations
      let firstChildNode: ViewQueryRecordNode | undefined
      await addChildRecords({
        rowIndex,
        innerRowIndex,
        node,
        num: pseudoRowsToCreate,
        onAfterInsert(x) {
          firstChildNode = x
        },
      })
      cursorMutation.setCursor((x) => ({
        ...x,
        main: {
          ...x!.main,
          innerRowIndex: firstChildNode?.meta.innerRowIndexStart ?? x!.main.innerRowIndex + 1,
        },
      }))
    }
    setCount((x) => x + 1)
  }

  useEffect(() => {
    if (count > 0) {
      cursorMutation.hideContextMenu()
      void paste().finally(() => {
        void message.info(t(`ペーストが完了しました`))
        setIsPasting(false)
      })
    }
  }, [count])

  return pasteWithNewChildRecord
}
