import type { SheetCell } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'
import type { GetRecoilValue } from 'recoil'

import type { RSheetColumn, RSheetRecordNode, RSheetsColumnMeta, RSheetsColumnType } from '../../../types'
import type { RSheetsCursorPosition } from '../../../types/CursorTypes'
import { cursorModel } from '../../models/cursorModel'
import { columnsModel, rowsModel } from '../../models/propModels'
import { columnRelatedSelector } from '../../selectors/columnRelatedSelector'
import { cursorSelector } from '../../selectors/cursorSelector'
import { rowRelatedSelector } from '../../selectors/rowRelatedSelector'
import { rowsRelatedSelector } from '../../selectors/rowsRelatedSelector'
import { CURSOR_LEFT_COLUMN_INDEX } from '../useCursorMutation'

export const moveCursorMutation = mutation({
  key: `moveCursorMutation`,
  set({ get, set }, { expand, ...argument }: { expand: boolean; columnDiff: number; rowDiff: number }) {
    set(cursorModel, (oldCursor) => {
      // cursorがまだなかったら先頭とする
      if (oldCursor === undefined) {
        return {
          main: {
            rowIndex: 0,
            columnIndex: CURSOR_LEFT_COLUMN_INDEX,
            innerRowIndex: 0,
          },
        }
      }

      if (expand) {
        const newExpand = movePositionAsExpandCursor({
          currentPosition: oldCursor.expand ?? oldCursor.main,
          ...argument,
          get,
        })
        return {
          main: oldCursor.main,
          expand: newExpand,
          copying: oldCursor.copying,
        }
      }

      return {
        main: movePosition({
          currentPosition: oldCursor.main,
          ...argument,
          get,
        }),
        copying: oldCursor.copying,
      }
    })
  },
})

function movePositionAsExpandCursor(argument: {
  currentPosition: RSheetsCursorPosition
  columnDiff: number
  rowDiff: number
  get: GetRecoilValue
}): RSheetsCursorPosition {
  const { currentPosition, rowDiff, get } = argument
  // 横移動の際のロジックは、expandであろうと変わらないのでそのまま返す
  if (rowDiff === 0) {
    return movePosition(argument)
  }

  const { selectedArea } = get(cursorSelector)
  if (selectedArea === undefined) {
    // undefinedになるのはmainCursorが存在しない時のみなので、この文脈ではありえないはず
    return movePosition(argument)
  }

  // 既に選択中の範囲が複数のブロックにまたがっているときの縦移動は、現在と同じ行にある最大高の列での移動のように扱う必要がある
  // この挙動のため、「現在と同じ行にある最大高の列」を求める必要がある
  const columns = get(columnsModel)
  const currentColumns = columns.slice(selectedArea.area.leftColumnIndex, selectedArea.area.rightColumnIndex + 1)
  const rows = get(rowsModel)
  const row = rows[currentPosition.rowIndex]
  if (row === undefined) {
    return {
      rowIndex: 0,
      columnIndex: 0,
      innerRowIndex: 0,
    }
  }

  const rowRelated = get(rowRelatedSelector(row.id!))
  const recordNodes = currentColumns.map((column, index) =>
    rowRelated.getSheetCell(index + selectedArea.area.leftColumnIndex, currentPosition.innerRowIndex),
  )
  // 最大の高さを持つ列を求める
  const maxHeight = recordNodes.map((x) => x?.height ?? 0).max()
  const maxHeightCurrentColumnIndex = recordNodes.findIndex((x) => (x?.height ?? 0) === maxHeight)
  if (maxHeightCurrentColumnIndex === -1) {
    return movePosition(argument)
  }

  return movePosition({
    ...argument,
    maxHeightColumnIndex: maxHeightCurrentColumnIndex + selectedArea.area.leftColumnIndex,
  })
}

function movePosition({
  currentPosition,
  columnDiff,
  rowDiff,
  maxHeightColumnIndex,
  get,
}: {
  currentPosition: RSheetsCursorPosition
  columnDiff: number
  rowDiff: number
  maxHeightColumnIndex?: number
  get: GetRecoilValue
}): RSheetsCursorPosition {
  const rows = get(rowsModel)
  const columns = get(columnsModel)
  const maxRowIndex = rows.length - 1
  const maxColumnIndex = columns.length - 1
  const row = rows[currentPosition.rowIndex]
  const column = columns[currentPosition.columnIndex]

  // ありえないはずだが、現在指しているセルがない場合はとりあえず左上をセットする
  if (row === undefined || column === undefined) {
    return {
      rowIndex: 0,
      columnIndex: 0,
      innerRowIndex: 0,
    }
  }

  const rowRelated = get(rowRelatedSelector(row.id!))

  // エッジケース以外の移動先
  const movedDefault = {
    rowIndex: forceBetween(currentPosition.rowIndex + rowDiff, 0, maxRowIndex),
    columnIndex: forceBetween(currentPosition.columnIndex + columnDiff, CURSOR_LEFT_COLUMN_INDEX, maxColumnIndex),
    innerRowIndex: 0, // ネストは別判定とするので、いったん0
  }

  //
  // 縦移動の時、ネストしたセルの移動の場合にはnestedを移動させる特殊な動きをする
  //
  if (rowDiff !== 0) {
    // ネストしたセルではない場合
    if (column.node.path.length <= 1) {
      return movedDefault
    }

    // 既に選択中の範囲が複数のブロックにまたがっているときの縦移動は、現在と同じ行にある最大高の列での移動のように扱う必要がある
    // expandの移動のときは、maxHeightColumnIndexが引数で渡されるので、もしこれがあればcolumnIndexを入れ替えた上での移動先を計算する
    if (maxHeightColumnIndex !== undefined) {
      const movedPositionAsMaxHeightColumn = movePosition({
        currentPosition: {
          ...currentPosition,
          columnIndex: maxHeightColumnIndex,
        },
        columnDiff,
        rowDiff,
        get,
      })
      return {
        ...movedPositionAsMaxHeightColumn,
        columnIndex: currentPosition.columnIndex,
      }
    }

    //
    // ネストしているときの縦移動
    //
    const innerCells = rowRelated.getInnerCells(currentPosition.columnIndex)
    const innerRecords = getInnerRecordNodeWithPreviousAndNext(innerCells, currentPosition.innerRowIndex)

    // 上方向への移動のとき
    if (rowDiff < 0) {
      // ネストしたセルの最上段でないとき
      if (innerRecords.prev !== undefined) {
        return {
          ...currentPosition,
          innerRowIndex: innerRecords.prev.innerRowIndexStart,
        }
      }
      // ネストしたセルの最上段のとき、上のセルの最後尾とする
      // 既に最上段にいるときは何もしない
      if (currentPosition.rowIndex === 0) {
        return currentPosition
      }
      const next = upDownRecursively(rows, currentPosition.columnIndex, currentPosition.rowIndex, -1, get)
      if (next === undefined) {
        return currentPosition
      }
      return {
        ...currentPosition,
        rowIndex: next.rowIndex,
        innerRowIndex: next.innerRowIndex,
      }

      // 下方向への移動のとき
    }
    if (rowDiff > 0) {
      // ネストしたセルの最下段のとき
      if (innerRecords.next === undefined) {
        // 行の最下段にいるときは何もしない
        if (currentPosition.rowIndex === rows.length - 1) {
          return currentPosition
        }
        // それ以外の時は、次の行に移動する
        const next = upDownRecursively(rows, currentPosition.columnIndex, currentPosition.rowIndex, +1, get)
        if (next === undefined) {
          return currentPosition
        }
        return {
          ...currentPosition,
          rowIndex: next.rowIndex,
          innerRowIndex: 0,
        }
        // ネストしたセルの最下段以外の時
      }
      return {
        ...currentPosition,
        innerRowIndex: innerRecords.next.innerRowIndexStart,
      }
    }
    //
    // 横移動のとき、ネストしたセルであればそのセルがあることを確認
    //
  } else if (columnDiff !== 0) {
    // 移動先と元のカラムが同じとき（左端・右端で移動しようとしたとき）は、元のまま移動するだけ
    if (movedDefault.columnIndex === currentPosition.columnIndex) {
      return currentPosition
    }

    const next = leftOrRightRecursively(
      row,
      columns,
      currentPosition.columnIndex,
      currentPosition.innerRowIndex,
      columnDiff,
      get,
    )
    if (next === undefined) {
      return currentPosition
    }
    // 次もネストしたカラムのとき
    return {
      ...movedDefault,
      columnIndex: next.columnIndex,
      innerRowIndex: next.innerRowIndex,
    }
  }

  return movedDefault
}

// 1行分のセルを選択
export const selectRowCellMutation = mutation<{ rowIndex: number; rootRecordNode: RSheetRecordNode }>({
  key: `selectRowCellMutation`,
  set({ get, set }, { rowIndex, rootRecordNode }) {
    const { columns, maxColumnIndex } = get(columnRelatedSelector)
    const cursor = get(cursorModel)

    // 実用的に、左端のシステム系カラムやレコードURLは不要なはずなので、これを除く
    const ignoreColumnTypes = ['rowIndex', 'open', 'checkbox'] as RSheetsColumnType[]
    const ignoreColumnMetaTypes = ['url', 'record_url'] as RSheetsColumnMeta[]
    const columnIndex = columns.findIndex(
      (c) =>
        c.isSystem !== true && !ignoreColumnTypes.includes(c.type!) && !ignoreColumnMetaTypes.includes(c.metaType!),
    )

    set(cursorModel, {
      ...cursor, // copyingを残すために必要
      main: {
        rowIndex,
        columnIndex,
        innerRowIndex: 0,
      },
      expand: {
        rowIndex,
        columnIndex: maxColumnIndex,
        innerRowIndex: rootRecordNode.meta.height - 1,
      },
      editing: undefined,
    })
  },
})

// 全てのセルを選択
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const selectAllCellMutation = mutation<void>({
  key: `selectAllCellMutation`,
  set({ get, set }) {
    const { columns, maxColumnIndex } = get(columnRelatedSelector)
    const { maxRowIndex } = get(rowsRelatedSelector)

    // 実用的に、左端のシステム系カラムやレコードURLは不要なはずなので、これを除く
    const ignoreColumnTypes = ['rowIndex', 'open', 'checkbox'] as RSheetsColumnType[]
    const ignoreColumnMetaTypes = ['url', 'record_url'] as RSheetsColumnMeta[]
    const columnIndex = columns.findIndex(
      (c) => !ignoreColumnTypes.includes(c.type!) && !ignoreColumnMetaTypes.includes(c.metaType!),
    )

    set(cursorModel, {
      main: {
        rowIndex: 0,
        columnIndex,
        innerRowIndex: 0,
      },
      expand: {
        rowIndex: maxRowIndex,
        columnIndex: maxColumnIndex,
        innerRowIndex: 1000, // TODO
      },
      editing: undefined,
    })
  },
})

// TODO: nodeUtilにも同じようなメソッドがある
function getInnerRecordNodeWithPreviousAndNext(innerCells: SheetCell[], innerRowIndex: number) {
  const currentIndex = innerCells.findIndex(
    (x) => x.innerRowIndexStart <= innerRowIndex && innerRowIndex < x.innerRowIndexEnd,
  )
  return {
    prev: currentIndex > 0 ? innerCells[currentIndex - 1] : undefined,
    current: innerCells[currentIndex],
    next: innerCells[currentIndex + 1],
  }
}

// eslint-disable-next-line @typescript-eslint/max-params
function upDownRecursively(
  data: RSheetRecordNode[],
  columnIndex: number,
  rowIndex: number,
  dy: number,
  get: GetRecoilValue,
):
  | undefined
  | {
      rowIndex: number
      innerRowIndex: number
    } {
  const previousRootRecordNode = data[rowIndex + dy]
  if (previousRootRecordNode === undefined) {
    return undefined
  }
  const rowRelated = get(rowRelatedSelector(previousRootRecordNode.id!))
  const previousRecordNodesWithIndex = rowRelated.getInnerCells(columnIndex)
  const recordNode = previousRecordNodesWithIndex.last()
  if (recordNode === undefined) {
    return upDownRecursively(data, columnIndex, rowIndex + dy, dy, get)
  }
  return {
    rowIndex: rowIndex + dy,
    innerRowIndex: recordNode.innerRowIndexStart,
  }
}

// eslint-disable-next-line @typescript-eslint/max-params
function leftOrRightRecursively(
  row: RSheetRecordNode,
  columns: Array<RSheetColumn<RSheetRecordNode>>,
  columnIndex: number,
  innerRowIndex: number,
  dx: number,
  get: GetRecoilValue,
):
  | undefined
  | {
      columnIndex: number
      innerRowIndex: number
    } {
  const nextColumn = columns[columnIndex + dx]
  if (nextColumn === undefined) {
    return undefined
  }
  if (nextColumn.node.path.length <= 1) {
    return {
      columnIndex: columnIndex + dx,
      innerRowIndex: 0,
    }
  }

  // 次のカラムがネストしたカラムのとき
  const rowRelated = get(rowRelatedSelector(row.id!))
  const recordNodesWithIndex = rowRelated.getInnerCells(columnIndex + dx)
  // 空の孫など、セルが一切存在しない場合は、更に次の列へ移動
  if (recordNodesWithIndex.isEmpty()) {
    return leftOrRightRecursively(row, columns, columnIndex + dx, innerRowIndex, dx, get)
  }
  // ある場合は、適した列に移動
  return {
    columnIndex: columnIndex + dx,
    innerRowIndex:
      recordNodesWithIndex.find((x) => x.innerRowIndexStart <= innerRowIndex && innerRowIndex < x.innerRowIndexEnd)
        ?.innerRowIndexStart ?? 0,
  }
}

function forceBetween(x: number, min: number, max: number) {
  return Math.max(Math.min(x, max), min)
}
