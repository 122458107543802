import { isSameNodeProperty, type ViewConfigFilterNode, type ViewQueryField } from '@salescore/core'
import { buildReadonlyHook, mutation, useModalAtom, useModalAtomWithInnerState } from '@salescore/frontend-common'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import type {
  ViewConfigFilterNodeForForm,
  ViewConfigPropertyFilterForForm,
} from '../../components/view_ui/ViewUISheetWithNavigation/SheetNavigation/filter/common'
// TODO
import { rowConfigAtomFamily } from '../../rsheet/recoil/atoms'
import { configSheetAtom } from '../view/atoms'
import { queryFieldToConfigField } from '../view/mutations/field/util'
import {
  changesModalAtom,
  compileLogsModalAtom,
  conditionalEffectsFormModalAtom,
  conditionalEffectsFormModalAtom2,
  debugModalAtom,
  dimensionFiltersModalAtom,
  drillDownModalAtom,
  dynamicSelectOptionModalAtom,
  errorsModalAtom,
  fieldsModalAtom,
  filtersModalAtom,
  importFieldsModalAtom,
  joinFiltersModalAtom,
  joinFilterTargetNodeAtom,
  kpiBulkDuplicateModalAtom,
  kpiFormModalAtom,
  kpiParameterAtom,
  kpiPivotFormModalAtom,
  kpiPivotKpisModalAtom,
  kpiPivotParameterDrawerVisibilityAtom,
  kpiPivotPickedPresetNameAtom,
  kpiPresetFormModalAtom,
  measureFormModalAtom,
  metadataModalAtom,
  modelFormViewModalAtom,
  newKpiFormModalAtom,
  nodeModalAtom,
  nodeSearchSqlModalAtom,
  rowLineNumAtom as rowLineNumberAtom,
  searchableFieldsModalAtom,
  searchSqlModalAtom,
  sheetPickedPresetNameAtom,
  sheetPresetFormModalAtom,
  sortersModalAtom,
  syncUserPlanNotFoundModalAtom,
} from './atoms'
import { rowLineSelector } from './selector'

export const useNavigationModal = () => {
  const modelFormViewModal = useModalAtom(modelFormViewModalAtom)
  const syncUserPlanNotFoundModal = useModalAtom(syncUserPlanNotFoundModalAtom)
  const measureFormModal = useModalAtom(measureFormModalAtom)
  const conditionalEffectsFormModal = useModalAtom(conditionalEffectsFormModalAtom)
  const conditionalEffectsFormModal2 = useModalAtom(conditionalEffectsFormModalAtom2)
  const changesModal = useModalAtom(changesModalAtom)
  const compileLogsModal = useModalAtom(compileLogsModalAtom)
  const debugModal = useModalAtom(debugModalAtom)
  const drillDownModal = useModalAtomWithInnerState(drillDownModalAtom, { hasChanges: false })
  const errorsModal = useModalAtom(errorsModalAtom)
  const fieldsModal = useModalAtom(fieldsModalAtom)
  const filtersModal = useModalAtom(filtersModalAtom)
  const importFieldsModal = useModalAtom(importFieldsModalAtom)
  const joinFiltersModal = useModalAtom(joinFiltersModalAtom)
  const metadataModal = useModalAtom(metadataModalAtom)
  const nodeModal = useModalAtom(nodeModalAtom)
  const nodeSearchSqlModal = useModalAtom(nodeSearchSqlModalAtom)
  const searchableFieldsModal = useModalAtom(searchableFieldsModalAtom)
  const searchSqlModal = useModalAtom(searchSqlModalAtom)
  const sortersModal = useModalAtom(sortersModalAtom)
  const sheetPresetFormModal = useModalAtom(sheetPresetFormModalAtom)
  const dynamicSelectOptionModal = useModalAtom(dynamicSelectOptionModalAtom)

  return {
    modelFormViewModal,
    syncUserPlanNotFoundModal,
    conditionalEffectsFormModal,
    conditionalEffectsFormModal2,
    measureFormModal,
    changesModal,
    compileLogsModal,
    debugModal,
    drillDownModal,
    errorsModal,
    fieldsModal,
    filtersModal,
    importFieldsModal,
    joinFiltersModal,
    metadataModal,
    nodeModal,
    nodeSearchSqlModal,
    searchableFieldsModal,
    searchSqlModal,
    sortersModal,
    sheetPresetFormModal,
    dynamicSelectOptionModal,
  }
}

export const useJoinFilterTargetNodeValue = buildReadonlyHook(joinFilterTargetNodeAtom)

export const useRowHeightState = () => {
  const { rowLineNum, rowHeight } = useRecoilValue(rowLineSelector)
  const setRowLineNumber = useSetRecoilState(rowLineNumberAtom)
  return {
    rowLineNum,
    rowHeight,
    setRowLineNum: setRowLineNumber,
  }
}

export const useRowConfigState = (id: string) => useRecoilState(rowConfigAtomFamily({ id }))

const showFilterModalWithFieldMutation = mutation({
  key: `showFilterModalWithFieldMutation`,

  set({ get, set }, { field }: { field: ViewQueryField }) {
    const config = get(configSheetAtom)
    const tree: ViewConfigFilterNode = config.filterTree ?? {
      logicalOperator: 'and',
      leafs: [],
      children: [],
    }
    const treeForForm = convertToFilterNodeForForm(tree)

    const configField = queryFieldToConfigField(field, config.fields ?? [])
    const fieldLeaf: ViewConfigPropertyFilterForForm | undefined =
      configField === undefined
        ? undefined
        : {
            type: 'property',
            property: configField.property,
          }

    const filterNodeForForm = {
      ...treeForForm,
      leafs: [
        ...treeForForm.leafs,
        // 元々のleafにproeprtyが含まれていなければ追加
        fieldLeaf?.property !== undefined &&
        treeForForm.leafs.every(
          (leaf) => leaf.type === 'property' && !isSameNodeProperty(leaf.property, fieldLeaf.property),
        )
          ? fieldLeaf
          : undefined,
      ].compact(),
    }

    set(filtersModalAtom, (x) => ({
      content: filterNodeForForm,
      visible: true,
    }))
  },
})

export function convertToFilterNodeForForm(filterTree: ViewConfigFilterNode): ViewConfigFilterNodeForForm {
  return {
    ...filterTree,
    leafs: filterTree.leafs.map((x) => (x.type === 'property' ? x : undefined)).compact(),
    children: filterTree.children.map((child) => convertToFilterNodeForForm(child)),
  }
}

export const useShowFilterModalWithFieldMutation = () => useSetRecoilState(showFilterModalWithFieldMutation)

export const useKpiPivotParameter = () => useRecoilState(kpiParameterAtom)

export const useKpiPivotParameterDrawerVisibility = () => useRecoilState(kpiPivotParameterDrawerVisibilityAtom)

export const useKpiPivotNavigationModal = () => {
  const kpiBulkDuplicateModal = useModalAtom(kpiBulkDuplicateModalAtom)
  const kpiFormModal = useModalAtom(kpiFormModalAtom)
  const kpiPivotFormModal = useModalAtom(kpiPivotFormModalAtom)
  const kpiPivotKpisModal = useModalAtom(kpiPivotKpisModalAtom)
  const kpiPresetFormModal = useModalAtom(kpiPresetFormModalAtom)
  const newKpiFormModal = useModalAtom(newKpiFormModalAtom)
  const dimensionFiltersModal = useModalAtom(dimensionFiltersModalAtom)

  return {
    kpiBulkDuplicateModal,
    kpiFormModal,
    kpiPivotFormModal,
    kpiPivotKpisModal,
    kpiPresetFormModal,
    newKpiFormModal,
    dimensionFiltersModal,
  }
}

export const useKpiPivotPickedPresetName = () => useRecoilState(kpiPivotPickedPresetNameAtom)

export const useSheetPickedPresetName = () => useRecoilState(sheetPickedPresetNameAtom)
