import { UserLicenseTypeEnum } from '@salescore/client-api'
import type { Me } from '@salescore/client-recoil'
import Script from 'next/script'

export const DefaultScript = ({ me }: { me: Me }) => {
  if (process.env.NODE_ENV === 'development') {
    return <></>
  }
  const licenceValue = createLicenceValue(me)
  return (
    <>
      <Script strategy="afterInteractive">
        {`
        window.TechtouchObject = {
          startTechtouch: function() { 
            // iframe内で動作させる場合は以下の1行を削除もしくはコメントアウトしてください
            // try { if (window.top !== window.self) return; } catch(e) { return; }
            if ('TechtouchObject' in window && document.querySelector('script#techtouch-snippet')) return;
            if ('TechtouchAddon' in window) return;
            window.TechtouchObject = {
              organizationUuid: "orga-6639dad2-0373-8000-ba3f-c67ebe4b3f30"
            };
            var e = document.createElement("script"); e.async = 1, e.src = "https://apps.techtouch.jp/script/orga-6639dad2-0373-8000-ba3f-c67ebe4b3f30/main.js?projectUuid=proj-6639dbaf-7bbe-76ac-a5c5-77e230a97123"; e.id = "techtouch-snippet";
            var t = document.getElementsByTagName("script")[0];
            t.parentNode.insertBefore(e, t);
          }
        };

        window.TechtouchAdmin={customProperty:{_data:{},get:function(t){return this._data[t]},set:function(t,e){this._data[t]=e},delete:function(t){delete this._data[t]},keys:function(){return Object.keys(this._data)}}};
        // TechtouchAdmin.customProperty.set("47cbdac1-62f7-46b9-bab0-e3d25e91842e", "") // ラベル「招待時のアラート不要」に対応
        TechtouchAdmin.customProperty.set("a710f9a2-d1c2-4735-bf3d-2ceb7675e4d9", "${licenceValue}") // ラベル「ライセンス種別」に対応

        window.TechtouchObject.startTechtouch();
        `}
      </Script>
    </>
  )
}

function createLicenceValue(me: Me): string {
  return [
    me.myUser.userLicenses.some((x) => x.license === UserLicenseTypeEnum.Sync) ? 'Sync' : undefined,
    me.myUser.userLicenses.some((x) => x.license === UserLicenseTypeEnum.Visualize) ? 'Visualize' : undefined,
    me.myUser.userLicenses.some((x) => x.license === UserLicenseTypeEnum.Ri) ? 'Ri' : undefined,
  ]
    .compact()
    .join('&')
}
