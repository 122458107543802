import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useMutation, useQuery } from '@apollo/client'
import { isNull, isPresent, isSome } from '@salescore/buff-common'
import { FetchDimensionGroupsDocument, type GoalDimension, UpsertGoalDimensionsDocument } from '@salescore/client-api'
import { Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { ErrorContext, getOrganizationIdFromPath, SuspenseWithLoading } from '@salescore/client-common'
import { generateRandomString, useModal } from '@salescore/frontend-common'
import { Button, Form, Input, message, Modal, Row, Select, Switch } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UpsertDimensionGroupForm } from '../../../dimension_group/UpsertDimensionGroupForm'
import { ImportDimensionModal } from '../ImportDimension'
import { GoalDimensionListFormItem, type SelectOptionItem } from './GoalDimensionListFormItem'

interface FormValue {
  name?: string
  label: string
  dimensionGroupIds?: string[]
  selectOptions: {
    items: SelectOptionItem[]
    itemsStr: string
  }
}

export const UpsertGoalDimensionForm = ({
  goalDimension,
  withDimensionGroup,
  onAfterFinish = () => {
    /* empty */
  },
}: {
  withDimensionGroup?: boolean
  goalDimension?: GoalDimension
  onAfterFinish?: () => void
}) => {
  const modal = useModal()
  const { t } = useTranslation()
  const dimensionGroupModal = useModal()
  const fetchDimensionGroupsQuery = useQuery(FetchDimensionGroupsDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })
  const dimensionGroups = fetchDimensionGroupsQuery.data?.dimensionGroups
  const goalDimensions = fetchDimensionGroupsQuery.data?.goalDimensions
  const [form] = useForm<FormValue>()
  const [upsertGoalDimensionsMutation] = useMutation(UpsertGoalDimensionsDocument)
  const [loading, setLoading] = useState(false)
  const [bulkEditable, setBulkEditable] = useState(false)
  const errorContext = useContext(ErrorContext)

  const onFinish = async (values: FormValue) => {
    try {
      setLoading(true)
      const selectOptions = bulkEditable
        ? parseItemsStrToItems(values.selectOptions.itemsStr)
        : values.selectOptions.items

      const goalDimensionsConfig = {
        id: goalDimension?.id,
        name: values.name ?? goalDimension?.name ?? generateRandomString(), // v2ではこのForm.Itemを表示していないので、values.nameがない。v2ではそもそもこの項目を使わないので、適当な値を生成して渡す
        label: values.label,
        selectOptions,
        dimensionGroupIds: values.dimensionGroupIds ?? [],
      }

      const result = await upsertGoalDimensionsMutation({
        variables: {
          organizationId: getOrganizationIdFromPath(),
          goalDimensions: [goalDimensionsConfig],
        },
      })
      if (isSome(goalDimension)) {
        void message.info(t(`目標軸を更新しました！`))
      } else {
        void message.info(t(`目標軸を新規作成処理を開始しました！完了までしばしお待ちください`))
      }
      onAfterFinish()
      if (isNull(goalDimension?.id)) {
        form.resetFields()
      }
      Posthog.track(POSTHOG_EVENTS.save_goal_dimention, {
        organizationId: getOrganizationIdFromPath(),
        config: goalDimensionsConfig,
      })
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      errorContext.throwErrors(error as Error)
    } finally {
      setLoading(false)
    }
  }
  const initialValues: FormValue = {
    name: goalDimension?.name ?? '',
    label: goalDimension?.label ?? '',
    selectOptions: {
      items: goalDimension?.selectOptionsV3 ?? [],
      itemsStr: goalDimension?.selectOptionsV3.map((option) => `${option.label},${option.value}`).join(`\n`) ?? '',
    },
    dimensionGroupIds: dimensionGroups
      ?.filter((x) => x.properties.some((y) => y.type === `goalDimension` && y.goalDimensionId === goalDimension?.id))
      .map((x) => x.id),
  }

  return (
    <PageHeader>
      <Form form={form} onFinish={onFinish} initialValues={initialValues} layout="vertical">
        <Form.Item name="label" rules={[{ required: true, message: t(`目標軸名を入力してください`) }]}>
          <Input
            size="large"
            bordered={false}
            placeholder="目標軸の名前を入力"
            style={{ fontSize: 24, fontWeight: 'bold' }}
          />
        </Form.Item>
        {isNull(goalDimension) && (
          <Form.Item
            name="name"
            label={<span>{t(`内部名(変更不可)`)}</span>}
            tooltip={t(`SALESCOREオブジェクトの項目名として使われる内部名を設定してください。`)}
            rules={[
              { required: true, message: t(`内部名を入力してください`) },
              {
                validator: async (_, name: string) => {
                  if (isPresent(initialValues.name)) {
                    await Promise.resolve()
                  } else if (goalDimensions?.some((x) => x.name === name)) {
                    return await Promise.reject(new Error(t(`内部名が重複しています`)))
                  }
                  await Promise.resolve()
                },
                message: t(`既に存在する内部名は入力できません`),
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="selectOptions"
          label={t(`対応させるラベルと値`)}
          rules={[
            {
              required: true,

              validator: async (_, options: { items: SelectOptionItem[]; itemsStr: string }) => {
                if (bulkEditable) {
                  const items = parseItemsStrToItems(options.itemsStr)

                  if (items.length === 0) {
                    return await Promise.reject(new Error(t(`目標軸を1つ以上入力してください`)))
                  }

                  if (items.some((item) => item.label === '')) {
                    return await Promise.reject(new Error(t(`ラベルを入力してください`)))
                  }

                  if (items.some((item) => item.value === '')) {
                    return await Promise.reject(new Error(t(`値を入力してください`)))
                  }
                } else if (options.items.length === 0) {
                  return await Promise.reject(new Error(t(`目標軸を1つ以上入力してください`)))
                }

                await Promise.resolve()
              },
            },
          ]}
        >
          <span className="absolute -top-8 right-0 ml-4">
            {t(`一括で入力`)}
            <Switch onChange={setBulkEditable} defaultChecked={bulkEditable} className="ml-1 mr-4" />
            <Button
              size="small"
              onClick={() => {
                modal.showModal()
              }}
            >
              {t(`インポート`)}
              <UploadOutlined />
            </Button>
          </span>
          {<GoalDimensionListFormItem bulkEditable={bulkEditable} form={form} />}
        </Form.Item>
        {withDimensionGroup === true && (
          <Form.Item
            name="dimensionGroupIds"
            rules={[{ required: true, message: t(`軸グループを入力してください`) }]}
            label={
              <span>
                {t(`軸グループを選択`)}
                <Button
                  icon={<PlusOutlined />}
                  type="text"
                  className="text-sm text-blue-600"
                  onClick={() => {
                    dimensionGroupModal.showModal()
                  }}
                >
                  {t(`軸グループの新規作成`)}
                </Button>
              </span>
            }
          >
            <Select
              allowClear
              mode="multiple"
              loading={loading}
              options={dimensionGroups?.map((x) => ({ value: x.id, label: x.label }))}
            />
          </Form.Item>
        )}
        <Form.Item>
          <Row justify="end">
            <Button type="primary" htmlType="submit" loading={loading}>
              {t(`保存`)}
            </Button>
          </Row>
        </Form.Item>
      </Form>
      <ImportDimensionModal
        modal={modal}
        onSave={(items: SelectOptionItem[]) => {
          form.setFieldValue([`selectOptions`, `items`], items)
          form.setFieldValue(
            [`selectOptions`, `itemsStr`],
            items.map((option) => `${option.label},${option.value}`).join(`\n`) ?? '',
          )
          modal.hideModal()
        }}
      />
      <Modal
        key="editModal"
        open={dimensionGroupModal.isModalVisible}
        onCancel={dimensionGroupModal.hideModal}
        width={'70%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        style={{ top: '3%' }}
        destroyOnClose
      >
        <SuspenseWithLoading>
          <UpsertDimensionGroupForm
            withoutGoalFormItem
            onAfterFinish={(record) => {
              void fetchDimensionGroupsQuery.refetch()
              dimensionGroupModal.hideModal()
              const existed = form.getFieldsValue().dimensionGroupIds ?? []
              form.setFieldsValue({ dimensionGroupIds: [...existed, record.id] })
            }}
          />
        </SuspenseWithLoading>
      </Modal>
    </PageHeader>
  )
}

export function parseItemsStrToItems(itemsString: string): SelectOptionItem[] {
  return itemsString.split(`\n`).map((item) => ({ label: item.split(`,`)[0] ?? '', value: item.split(`,`)[1] ?? '' }))
}
