import { WarningOutlined } from '@ant-design/icons'
import { isPresent, isSome, r } from '@salescore/buff-common'
import type { ViewConfigKpiParameter, ViewConfigKpiPreset } from '@salescore/core'
import { Button, Form, Input, message, Row, Table, Tag } from 'antd'
import { diff as deepObjectDiff } from 'deep-object-diff'
import { t } from 'i18next'
import { useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { useKpiPivotParameter, useKpiPivotPickedPresetName } from '../../../recoil/navigation/hooks'
import { configAtom, hasChangeToViewSchemaAtom } from '../../../recoil/view/atoms'
import { recursiveRemoveEmptyValues } from './recursive'

type PresetParameterKeys = keyof ViewConfigKpiPreset['parameter']

export function ViewConfigKpiPresetForm({
  preset,
  onFinish,
}: {
  preset?: ViewConfigKpiPreset
  onFinish: (preset: ViewConfigKpiPreset) => void
}) {
  const [config, setConfig] = useRecoilState(configAtom)
  const [picked, setPicked] = useKpiPivotPickedPresetName()
  const [parameter, setParameter] = useKpiPivotParameter()
  const setHasChangeToViewSchema = useSetRecoilState(hasChangeToViewSchemaAtom)
  const [selectedRowKeys, setSelectedRowKeys] = useState<PresetParameterKeys[]>(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    r(preset?.parameter ?? parameter)
      .compact()
      .keys() as PresetParameterKeys[],
  )

  if (config.type !== 'kpi' && config.type !== 'kpiPivot') {
    // ありえないはず
    return <></>
  }

  const dataSource: Array<{
    label: string
    key: PresetParameterKeys
    content: unknown
  }> = [
    {
      label: t(`行・列`),
      key: `pivot` as const,
      content: parameter.pivot,
    },
    {
      label: t(`期間`),
      key: `queryParameter` as const,
      content: parameter.queryParameter,
    },
    {
      label: t(`絞り込み`),
      key: `dimensionFilterLeafs` as const,
      content: parameter.dimensionFilterLeafs,
    },
    {
      label: t(`目標種別`),
      key: `goalConfig` as const,
      content: parameter.goalConfig,
    },
    {
      label: t(`非表示KPI設定`),
      key: `invisibleKpiIds` as const,
      content: parameter.invisibleKpiIds,
    },
    {
      label: t(`行を値で並び替え`),
      key: `sorter` as const,
      content: parameter.sorter,
    },
  ].filter((x) => isSome(x.content))

  return (
    <Form<{ name: string }>
      onFinish={
        // eslint-disable-next-line complexity
        (values) => {
          const keys = selectedRowKeys
          // チェックボックスを外した際は上書きや初期化はせずに元のプリセットの値を設定する
          const newPreset: ViewConfigKpiPreset = {
            name: values.name,
            parameter: {
              pivot: keys.includes(`pivot`)
                ? parameter.pivot
                : {
                    rows: preset?.parameter.pivot.rows ?? [],
                    columns: preset?.parameter.pivot.columns ?? [],
                  },
              queryParameter: keys.includes(`queryParameter`)
                ? parameter.queryParameter
                : (preset?.parameter.queryParameter ?? undefined),
              invisibleKpiIds: keys.includes(`invisibleKpiIds`)
                ? parameter.invisibleKpiIds
                : (preset?.parameter.invisibleKpiIds ?? undefined),
              sorter: keys.includes(`sorter`) ? parameter.sorter : (preset?.parameter.sorter ?? undefined),
              dimensionFilterLeafs: keys.includes(`dimensionFilterLeafs`)
                ? parameter.dimensionFilterLeafs
                : (preset?.parameter.dimensionFilterLeafs ?? undefined),
              goalConfig: keys.includes(`goalConfig`)
                ? parameter.goalConfig
                : (preset?.parameter.goalConfig ?? undefined),
            },
          }

          setConfig((oldValue) => {
            if (oldValue.type !== 'kpi' && oldValue.type !== 'kpiPivot') {
              // ありえないが一応
              return oldValue
            }
            // 設定値の見やすさを考慮して、nameをid代わりに使っているが、name自体を書き換えることが可能なので、upsertByでそのまま使えない
            // 一旦keyというプロパティを作ってから後で削除する
            // （考慮する必要が薄いデータの見やすさのために、ロジックが難しくなっているので、素直にidをつけた方が早い気もする。またこれだと同じ名前が二つ作れてしまう）
            const presetsWithKey = (oldValue.presets ?? []).map((x) => ({
              ...x,
              key: x.name,
            }))
            const newPresetWithKey = {
              ...newPreset,
              key: preset?.name ?? newPreset.name,
            }
            return {
              ...oldValue,
              presets: presetsWithKey
                .upsertBy(newPresetWithKey, (x) => x.key)
                .map((x) => ({
                  ...x,
                  key: undefined,
                }))
                .uniqueBy((x) => x.name),
            }
          })
          // 現在編集中のプリセットが選択中のプリセットだった場合は表示名を変える必要があるため更新する
          // また新規作成時(presetがundef)は作成するプリセットに切り替える
          if (picked === preset?.name || preset === undefined) {
            setPicked(newPreset.name)
          }
          setHasChangeToViewSchema(true)
          onFinish(newPreset)
          void message.success(t(`集計プリセットを保存しました`))
        }
      }
      initialValues={preset}
      layout="vertical"
      validateMessages={{
        required: t(`プリセット名を入力してください`),
        pattern: {
          mismatch: t(`[共有リンク]から始まる文字列をプリセット名に使用することはできません`),
        },
      }}
    >
      <Form.Item
        name="name"
        label={t(`集計プリセット名`)}
        rules={[
          {
            required: true,

            pattern: /^(?!\[共有リンク\]).*/,
          },
        ]}
      >
        <Input width={200}></Input>
      </Form.Item>
      <Form.Item name="parameter" label={t(`集計プリセットに含める設定`)}>
        <Table
          pagination={false}
          dataSource={dataSource}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys,
            onChange: (selectedRowKeys) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
              setSelectedRowKeys(selectedRowKeys as PresetParameterKeys[])
            },
            getCheckboxProps: (record) => ({
              disabled: false,
              name: record.key,
            }),
          }}
          columns={[
            {
              // title: `設定名`,
              dataIndex: `label`,
              width: 200,
            },
            {
              title: t(`設定内容`),
              width: 500,
              dataIndex: `content`,
              render: (_, record) => getParameterContent(parameter, record.key),
            },
            {
              title: ``,
              width: 500,
              dataIndex: `isOverriding`,

              render: (_, record) => {
                if (preset === undefined) {
                  return <></>
                }

                const oldValue = preset.parameter[record.key] ?? {}
                const newValue = parameter[record.key] ?? {}

                if (record.key === 'goalConfig') {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                  const { id: oldId } = oldValue as { id: string | undefined }
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                  const { id: newId } = newValue as { id: string | undefined }

                  if (oldId === newId) {
                    return <></>
                  }
                }

                // 差分からundefined/null/空arrayを除外する
                const diff = recursiveRemoveEmptyValues(deepObjectDiff(oldValue, newValue))

                if (!isPresent(Object.keys(diff))) {
                  return <></>
                }

                return (
                  <>
                    <Tag color="yellow" icon={<WarningOutlined />}>
                      {t(`下記の設定が上書きされます。`)}
                    </Tag>
                    <br />
                    <span className="text-yellow-600">
                      {t(`既存の設定内容`)}: {getParameterContent(preset.parameter, record.key)}
                    </span>
                  </>
                )
              },
            },
          ]}
        />
      </Form.Item>
      <Row justify="end">
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t(`保存`)}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  )
}

function getParameterContent(parameter: ViewConfigKpiParameter, key: PresetParameterKeys) {
  switch (key) {
    case 'pivot': {
      const rows = parameter.pivot.rows
        .map((x) => [x.label, x.sortedValues === undefined ? `` : t(`(ソートあり)`)].join(''))
        .join(',')
      const columns = parameter.pivot.columns
        .map((x) => [x.label, x.sortedValues === undefined ? `` : t(`(ソートあり)`)].join(''))
        .join(',')
      return (
        <div>
          {t(`行`)}: {rows}
          <br />
          {t(`列`)}: {columns}
        </div>
      )
    }
    case 'queryParameter': {
      return (
        <div>
          {parameter.queryParameter?.periodName ?? t(`なし`)}
          {parameter.queryParameter?.startAt !== undefined && (
            <>
              <br />
              {t(`開始`)}: {parameter.queryParameter.startAt}
            </>
          )}
          {parameter.queryParameter?.endAt !== undefined && (
            <>
              <br />
              {t(`終了`)}: {parameter.queryParameter.endAt}
            </>
          )}
        </div>
      )
    }
    case 'goalConfig': {
      return <div>{parameter.goalConfig?.name ?? t(`なし`)}</div>
    }
    case 'dimensionFilterLeafs': {
      const leafs = parameter.dimensionFilterLeafs ?? []
      if (leafs.isBlank()) {
        return t(`なし`)
      }
      const labels = leafs.map((leaf) => {
        if (leaf.type === 'dimension') {
          // この文脈では、必ずdimensionのフィルタが入っているはず？
          return [leaf.dimension.label, leaf.filterValueLabel].compact().join(': ')
        }
        return leaf.filterValueLabel ?? `フィルタ`
      })
      return <pre>{labels.join('\n')}</pre>
    }
    case 'sorter': {
      const { sorter } = parameter
      if (isSome(sorter)) {
        return t(`あり`) // どの列かを明示するべきだが、やや面倒なので後回し
      }
      return t(`なし`)
    }
    case 'sorterForDrilldownComments': {
      const { sorterForDrilldownComments } = parameter
      if (isSome(sorterForDrilldownComments)) {
        return t(`あり`) // どの列かを明示するべきだが、やや面倒なので後回し
      }
      return t(`なし`)
    }
    case 'invisibleKpiIds': {
      const { invisibleKpiIds } = parameter
      if (isSome(invisibleKpiIds)) {
        return t(`{{length}}件のKPIを非表示`, { length: invisibleKpiIds.length }) // どのKPIかを明示するべきだが、やや面倒なので後回し
      }
      return t(`なし`)
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      throw new Error(key satisfies never)
    }
  }
}
