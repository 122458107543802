import type { ApolloClient } from '@apollo/client'
import { isNull, isSome } from '@salescore/buff-common'
import { FetchViewQueryResultDocument, type FetchViewQueryResultQueryVariables } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import type { ViewQueryField, ViewQueryFilter, ViewQueryList, ViewQueryNode } from '@salescore/core'
import { message } from 'antd'
import { t } from 'i18next'

// 指定したnode,idのViewQueryRecordNodeを取得する関数
// ノードが親ノードから参照されている関係にある場合、parentReferenceFieldを渡すことで_labelも一緒に取得することができる
// eslint-disable-next-line @typescript-eslint/max-params
export async function fetchChildRecordNode(
  node: ViewQueryNode,
  id: string,
  query: ViewQueryList,
  client: ApolloClient<unknown>,
  parentReferenceField: ViewQueryField | undefined,
) {
  const labelField: ViewQueryField | undefined =
    parentReferenceField === undefined
      ? undefined
      : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-unsafe-type-assertion
        ({
          name: `${parentReferenceField.name}_label`,
          nodePath: node.path,
          read: {
            sql: parentReferenceField.read.labelSql,
          },
        } as ViewQueryField) // TODO
  const partialViewQuery: ViewQueryList = {
    type: 'list',
    tree: {
      ...node,
      read: {
        ...node.read,
        join: undefined,
      },
    },
    fields: [
      ...query.fields.filter((field) => field.nodePath.join(',').startsWith(node.path.join(','))),
      labelField,
    ].compact(),
    filterTree: {
      logicalOperator: 'and',
      leafs: [
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          nodePaths: [[node.name]],
          read: {
            sql: `"${node.name}"."id" = '${id}'`,
            meta: {
              type: 'column',
            },
          },
        } as ViewQueryFilter,
      ],
      children: [],
    },
    sorters: [],
  }

  try {
    const variables: FetchViewQueryResultQueryVariables = {
      viewQuery: partialViewQuery,
      organizationId: getOrganizationIdFromPath(),
      mustacheParameter: {},
      viewId: ``, // XXX: バックエンド側でログなどの目的でviewIdを必要としている
    }
    const result = await client.query({
      query: FetchViewQueryResultDocument,
      variables,
      fetchPolicy: 'no-cache', // cache-and-networkが選べない？
    })
    if (isSome(result.error)) {
      void message.error(result.error.message)
      return
    }

    if (isNull(result.data)) {
      return
    }

    const { viewQueryResult } = result.data
    const { error } = viewQueryResult

    if (isSome(error)) {
      void message.error(error)
      return
    }

    const viewQueryRecordNodes = viewQueryResult.result
    return viewQueryRecordNodes.first()
  } catch (error) {
    if (error instanceof Error) {
      void message.error(error.message)
    } else {
      void message.error(t(`エラーが発生しました`))
    }
  }
}
