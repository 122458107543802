// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { dateUtil, isNull, range } from '@salescore/buff-common'
import type { DateTimeFilterExpressionType, FilterType, ModelProperty } from '@salescore/core'
import { getCurrentHalfYears, getCurrentQuarters, type OrganizationSetting } from '@salescore/features'
import { wrapKeyboardEvent } from '@salescore/frontend-common'
import { Col, DatePicker, Form, type FormItemProps, Input, InputNumber, message, Row, Select, TimePicker } from 'antd'
import { isDayjs } from 'dayjs'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useMeValue } from '../../../../../recoil/view/hooks'
import { readFromClipboard } from '../../../../../rsheet/recoil/hooks/usePaste'
import {
  type DatePickerType,
  getFilterDateValue,
  getFilterOptionAndInputValue,
  isNSelected,
  type RelativeOptionGroup,
} from '../expression/CoreDslFormDateLiteralFormItem'
import type { FilterFormItemState } from './common'

const SKIP_VALUE_DEFINITION_FILTER_TYPES = new Set<FilterType>(['null', 'not_null', 'blank', 'present', 'equal_me'])

interface SelectOption {
  label: string
  value: string | number
}

interface FilterFormItemProperties {
  onChange: (x: FilterFormItemState) => void
  filter: FilterFormItemState
  selectOptions: SelectOption[]
  formArgs: FormItemProps
}

const StringFilterFormItem = ({ onChange, filter, selectOptions, formArgs }: FilterFormItemProperties) => {
  const { filterValue } = filter
  const { filterType } = filter

  const [currentFilterValue, setCurrentFilterValue] = useState<string | undefined>(
    typeof filterValue === 'string' ? filterValue : undefined,
  )

  useEffect(() => {
    setCurrentFilterValue(typeof filterValue === 'string' ? filterValue : undefined)
  }, [filterValue])

  if (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    !['include', 'not_include', 'starts_with', 'not_starts_with'].includes(filterType as string) &&
    Array.isArray(selectOptions) &&
    selectOptions.length > 0
  ) {
    return (
      <Form.Item {...formArgs}>
        <Select
          {...(filterType === 'in' || filterType === 'not_in' ? { mode: 'multiple', maxTagCount: 'responsive' } : {})}
          allowClear
          style={{ width: '100%' }}
          // 選択式の場合はキーボードを打つ場合と違い、短時間で複数の値を入力することはない（UIフリーズしない）と思われるため、onChangeイベントを使う。
          onChange={(value) => {
            onChange({
              ...filter,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
              filterValue: value as unknown as string, // TODO
            })
          }}
          // arrayか？
          value={typeof filterValue === 'string' ? [filterValue] : Array.isArray(filterValue) ? filterValue : undefined}
          options={selectOptions.map((x) => ({
            value: x.value ?? '',
            label: x.label,
          }))}
          showSearch
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        />
      </Form.Item>
    )
  }

  async function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): Promise<void> {
    const wrapped = wrapKeyboardEvent(event)
    if (wrapped.commandKey && wrapped.key === 'v') {
      const clipboard = await readFromClipboard()
      if (!clipboard.success) {
        return
      }
      const splited = clipboard.text
        .split('\t')
        .flatMap((x) => x.split('\n'))
        .filter((x) => x !== '')
      if (splited.length <= 1) {
        return
      }
      // 顧客要望で、スプレッドシートなどからの複数セルの貼り付け(=タブや改行を含む時)では、タブをカンマに変換してから貼り付ける
      // https://github.com/salescore-inc/issues/issues/245
      event.preventDefault()
      event.stopPropagation()
      void message.info(t(`条件の値へのタブ・改行を含む貼り付けは、カンマに変換されて貼り付けされます`))
      onChange({
        ...filter,
        // 真面目にやるなら現在のカーソル位置を考慮する必要があるが、
        // タブを含むペーストを行うケースが上記以外でほぼないと考え、
        // 実装工数の削減のためカーソル位置は一旦考慮しない
        filterValue: [currentFilterValue, splited.join(',')].join(''),
      })
      setCurrentFilterValue([currentFilterValue, splited.join(',')].join(''))
    }
  }

  return (
    <Form.Item {...formArgs}>
      <Input
        onKeyDown={(event) => {
          void handleKeyDown(event)
        }}
        value={currentFilterValue}
        onChange={(e) => {
          setCurrentFilterValue(e.target.value)
        }}
        // issues #1092
        // onChange イベントを使うと入力中に何回も onChange が発火してしまい UI がフリーズする現象があったため、 onBlur と onPressEnter で対応
        onBlur={() => {
          onChange({
            ...filter,
            filterValue: currentFilterValue,
          })
        }}
        onPressEnter={() => {
          onChange({
            ...filter,
            filterValue: currentFilterValue,
          })
        }}
      />
    </Form.Item>
  )
}

// いったん、常に選択肢項目と仮定してしまい、この時のみ対応
const ArrayFilterFormItem = ({ onChange, filter, selectOptions, formArgs }: FilterFormItemProperties) => {
  const { filterValue } = filter
  const { filterType } = filter

  return (
    <Form.Item {...formArgs}>
      <Select
        mode="multiple"
        maxTagCount="responsive"
        allowClear
        style={{ width: '100%' }}
        onChange={(value) => {
          onChange({
            ...filter,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            filterValue: value as unknown as string, // TODO
          })
        }}
        // arrayか？
        value={typeof filterValue === 'string' ? [filterValue] : Array.isArray(filterValue) ? filterValue : undefined}
        options={(selectOptions ?? []).map((x) => ({
          value: x.value ?? '',
          label: x.label,
        }))}
        showSearch
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      />
    </Form.Item>
  )
}

interface PickerOption {
  value: DatePickerType
  label: string
}

function convertDateFilterTypeToPickerType(x: DateTimeFilterExpressionType | undefined): DatePickerType | undefined {
  // 対応していない場合にundefinedにしてしまう
  switch (x) {
    case 'year': {
      return x
    }
    case 'month': {
      return x
    }
    case 'date': {
      return x
    }
    case 'day': {
      return 'date'
    }
    // fiscal_quarterはUI上では相対日時の一部として扱う
    case 'fiscal_quarter':
    case 'relative': {
      return 'relative'
    }
    default: {
      break
    }
  }
  return undefined
}

const pickerOptions: PickerOption[] = [
  {
    value: 'date',
    label: t(`日`),
  },
  // {
  //   value: 'week',
  //   label: '週',
  // },
  {
    value: 'month',
    label: t(`月`),
  },
  // {
  //   value: 'quarter',
  //   label: '四半期',
  // },
  {
    value: 'year',
    label: t(`年`),
  },
  {
    value: 'relative',
    label: t(`相対日時`),
  },
]

function getRelativeOptionGroups({ setting }: { setting: OrganizationSetting }): RelativeOptionGroup[] {
  return [
    {
      label: t(`日`),
      options: [
        {
          label: t(`今日`),
          value: '0 day',
        },
        {
          label: t(`昨日`),
          value: '-1 day',
        },
        {
          label: t(`一昨日`),
          value: '-2 day',
        },
        ...range(3, 7).map((x) => ({
          label: t(`{{x}}日前`, { x }),
          value: `-${x} day`,
        })),
        {
          label: t(`N日前`),
          value: '-{N} day',
        },
        {
          label: t(`明日`),
          value: '+1 day',
        },
        {
          label: t(`明後日`),
          value: '+2 day',
        },
        ...range(3, 7).map((x) => ({
          label: t(`{{x}}日後`, { x }),
          value: `+${x} day`,
        })),
        {
          label: t(`N日後`),
          value: '+{N} day',
        },
      ],
    },
    {
      label: t(`週`),
      options: [
        {
          label: t(`今週`),
          value: '0 week',
        },
        {
          label: t(`先週`),
          value: '-1 week',
        },
        ...range(2, 3).map((x) => ({
          label: t(`{{x}}週間前`, { x }),
          value: `-${x} week`,
        })),
        {
          label: t(`N週間前`),
          value: '-{N} week',
        },
        {
          label: t(`来週`),
          value: '+1 week',
        },
        ...range(2, 3).map((x) => ({
          label: t(`{{x}}週間後`, { x }),
          value: `+${x} week`,
        })),
        {
          label: t(`N週間後`),
          value: '+{N} week',
        },
      ],
    },
    {
      label: t(`月`),
      options: [
        {
          label: t(`今月`),
          value: '0 month',
        },
        {
          label: t(`先月`),
          value: '-1 month',
        },
        ...[2, 3, 6, 9].map((x) => ({
          label: t(`{{x}}ヶ月前`, { x }),
          value: `-${x} month`,
        })),
        {
          label: t(`Nヶ月前`),
          value: '-{N} month',
        },
        {
          label: t(`来月`),
          value: '+1 month',
        },
        ...[2, 3, 6, 9].map((x) => ({
          label: t(`{{x}}ヶ月後`, { x }),
          value: `+${x} month`,
        })),
        {
          label: t(`Nヶ月後`),
          value: '+{N} month',
        },
      ],
    },
    {
      label: t(`四半期`),
      options: [
        {
          label: t(`今四半期`),
          value: '0 fiscal_quarter',
        },
        {
          label: t(`前四半期`),
          value: '-1 fiscal_quarter',
        },
        {
          label: t(`翌四半期`),
          value: '+1 fiscal_quarter',
        },
        ...getQuarterOptions({ setting, yearOffset: 0 }),
      ],
    },
    {
      label: t(`四半期(前年)`),
      options: [...getQuarterOptions({ setting, yearOffset: -1 })],
    },
    {
      label: t(`四半期(来年)`),
      options: [...getQuarterOptions({ setting, yearOffset: 1 })],
    },
    {
      label: t(`半期`),
      options: [
        {
          label: t(`上半期`),
          value: '0 fiscal_half',
        },
        {
          label: t(`下半期`),
          value: '+1 fiscal_half',
        },
      ],
    },
    {
      label: t(`半期(前年)`),
      options: [...getHalfYearOptions({ setting, yearOffset: -1 })],
    },
    {
      label: t(`半期(来年)`),
      options: [...getHalfYearOptions({ setting, yearOffset: 1 })],
    },
    {
      label: t(`会計年度`),
      options: [
        {
          label: t(`当会計年度`),
          value: '0 fiscal_year',
        },
        {
          label: t(`前会計年度`),
          value: '-1 fiscal_year',
        },
        {
          label: t(`翌会計年度`),
          value: '+1 fiscal_year',
        },
      ],
    },
    {
      label: t(`年`),
      options: [
        {
          label: t(`今年`),
          value: '0 year',
        },
        {
          label: t(`昨年`),
          value: '-1 year',
        },
        {
          label: t(`来年`),
          value: '+1 year',
        },
      ],
    },
  ]
}

function getQuarterOptions({ setting, yearOffset }: { setting: OrganizationSetting; yearOffset: number }) {
  const quarters = getCurrentQuarters(setting)
  // 会計開始月の年を基準とするため基準年を用意 (e.g. 2024年4月開始の場合、2025年1-3月を2024-4Qとするため)
  const baseYear = quarters.first()?.beginningOfQuarter.add(yearOffset, 'year').year()

  return quarters.map((quarter) => {
    const startAt = quarter.beginningOfQuarter.add(yearOffset, 'year').startOf('day')
    const endAt = quarter.beginningOfQuarter.add(yearOffset, 'year').add(3, 'month').subtract(1, 'day').endOf('day')

    return {
      label: `${quarter.quarter}Q(${startAt.format('YYYY/M')}-${endAt.format('M')})`,
      value: `${baseYear}-${quarter.quarter}Q`,
    }
  })
}

function getHalfYearOptions({ setting, yearOffset }: { setting: OrganizationSetting; yearOffset: number }) {
  const halfYears = getCurrentHalfYears(setting)

  return halfYears.map((halfYear) => {
    const startAt = halfYear.beginningOfQuarter.add(yearOffset, 'year').startOf('day')
    const endAt = halfYear.beginningOfQuarter.add(yearOffset, 'year').add(6, 'month').subtract(1, 'day').endOf('day')
    // halfYearは上半期 -> 1, 下半期 -> 2であり、
    // fiscal_halfは上半期(前年) -> -2, 下半期(前年) -> -1, 上半期(今年) -> 0, 下半期(今年) -> +1, 上半期(来年) -> +2, 下半期(来年) -> +3 のようになる
    const fisicalHalfValue = halfYear.halfYear - 1 + yearOffset * 2

    return {
      label: `${halfYear.halfYear === 1 ? t('上半期') : t('下半期')}(${startAt.format('YYYY/M')}-${endAt.format('M')})`,
      value: `${fisicalHalfValue > 0 ? `+` : ``}${fisicalHalfValue} fiscal_half`,
    }
  })
}

const TimeFilterFormItem = ({ onChange, filter, formArgs }: FilterFormItemProperties) => {
  const { filterValue } = filter
  const { filterType } = filter

  return (
    <div>
      <TimePicker
        format={`HH:mm`}
        value={isDayjs(filterValue) ? filterValue : undefined}
        onChange={(filterValue) => {
          // TODO
          // viewState.setter.setFilterValue(index, filterValue)
        }}
      />
    </div>
  )
}

const DateFilterFormItem = ({ onChange, filter, formArgs }: FilterFormItemProperties) => {
  const { organization } = useMeValue()
  const dateTimeType = convertDateFilterTypeToPickerType(filter.filterDateTimeType)
  const relativeOptionGroups = getRelativeOptionGroups({ setting: organization.setting })
  const { optionValue, inputValue } = getFilterOptionAndInputValue({
    filterValue: filter.filterValue,
    dateTimeType,
    relativeOptionGroups,
  })
  const filterDateValue = getFilterDateValue(filter.filterValue)
  const [filterOptionValue, setFilterOptionValue] = useState<string | undefined>(optionValue)
  const [filterInputValue, setFilterInputValue] = useState<number | undefined>(inputValue)

  function onEnterInput() {
    if (isNull(filterOptionValue) || isNull(filterInputValue) || !isNSelected(filterOptionValue)) {
      return
    }

    const filterValue = filterOptionValue.replace('{N}', filterInputValue.toString())
    onChange({
      ...filter,
      filterValue,
    })
  }

  useEffect(() => {
    if (isNull(dateTimeType)) {
      onChange({
        ...filter,
        filterDateTimeType: 'month',
      })
    }
  }, [])

  return (
    <Row justify="space-between">
      <Col span={9} style={{ paddingRight: '8px' }}>
        <Form.Item label={`単位`}>
          <Select
            onChange={(e: PickerOption['value']) => {
              onChange({
                ...filter,
                filterDateTimeType: e,
              })
            }}
            value={dateTimeType}
            options={pickerOptions}
          />
        </Form.Item>
      </Col>
      {dateTimeType === 'relative' ? (
        <>
          <Col
            span={isNSelected(filterOptionValue) ? 9 : 15}
            style={{ paddingRight: isNSelected(filterOptionValue) ? '8px' : undefined }}
          >
            <Form.Item {...formArgs}>
              <Select
                allowClear
                style={{ width: '100%' }}
                onChange={(value) => {
                  setFilterOptionValue(value)
                  setFilterInputValue(undefined)

                  const detected = dateUtil.detect(value)
                  const isFiscalQuarter = detected?.timeframe === 'fiscal_quarter'
                  onChange({
                    ...filter,
                    filterValue: isNSelected(value) ? filter.filterValue : value,
                    filterDateTimeType: isFiscalQuarter ? 'fiscal_quarter' : 'relative',
                  })
                }}
                value={filterOptionValue}
              >
                {relativeOptionGroups.map((group) => (
                  <Select.OptGroup label={group.label}>
                    {group.options.map((option) => (
                      <Select.Option value={option.value}>{option.label}</Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {isNSelected(filterOptionValue) && (
            <Col span={6}>
              <Form.Item label={t('Nの値')}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  value={filterInputValue}
                  onChange={(value) => {
                    setFilterInputValue(value ?? undefined)
                  }}
                  onPressEnter={() => {
                    onEnterInput()
                  }}
                  onBlur={() => {
                    onEnterInput()
                  }}
                />
              </Form.Item>
            </Col>
          )}
        </>
      ) : (
        <Col span={15}>
          <Form.Item {...formArgs}>
            <DatePicker
              picker={dateTimeType}
              style={{ width: '100%' }}
              onChange={(value) => {
                onChange({
                  ...filter,
                  filterValue: value?.format('YYYY-MM-DD'),
                })
              }}
              value={filterDateValue}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  )
}

const NumericFilterFormItem = ({ onChange, filter, formArgs }: FilterFormItemProperties) => {
  const { filterValue } = filter
  const [currentFilterValue, setCurrentFilterValue] = useState<number | undefined>(
    typeof filterValue === 'number' ? filterValue : undefined,
  )
  useEffect(() => {
    setCurrentFilterValue(typeof filterValue === 'number' ? filterValue : undefined)
  }, [filterValue])

  return (
    <Form.Item {...formArgs}>
      <InputNumber
        style={{ width: '100%' }}
        onChange={(value) => {
          setCurrentFilterValue(value ?? undefined)
        }}
        // issues #1092
        // onChange イベントを使うと入力中に何回も onChange が発火してしまい UI がフリーズする現象があったため、 onBlur と onPressEnter で対応
        onBlur={() => {
          onChange({
            ...filter,
            filterValue: currentFilterValue,
          })
        }}
        onPressEnter={() => {
          onChange({
            ...filter,
            filterValue: currentFilterValue,
          })
        }}
        value={currentFilterValue}
      />
    </Form.Item>
  )
}

const BooleanFilterFormItem = ({ onChange, filter, formArgs }: FilterFormItemProperties) => {
  const { filterValue } = filter

  return (
    <Form.Item {...formArgs}>
      <Select
        onChange={(value) => {
          onChange({
            ...filter,
            filterValue: value,
          })
        }}
        value={
          typeof filterValue === 'boolean'
            ? filterValue
              ? 'TRUE'
              : 'FALSE'
            : typeof filterValue === 'string' && ['TRUE', 'FALSE'].includes(filterValue)
              ? filterValue
              : undefined
        }
        options={[
          {
            label: 'TRUE',
            value: 'TRUE',
          },
          {
            label: 'FALSE',
            value: 'FALSE',
          },
        ]}
      />
    </Form.Item>
  )
}

const NotSupportedFieldType = <div>not supported</div>

export const ViewFilterValueFormItem = ({
  filter,
  property,
  onChange,
}: {
  onChange: (x: FilterFormItemState) => void
  filter: FilterFormItemState
  property: ModelProperty | undefined
}) => {
  const { filterType } = filter
  const fieldType = property?.type
  const selectOptions: SelectOption[] = property?.selectOptions ?? []
  const formArguments: FormItemProps = {
    label: t(`条件の値`),
    rules: [{ required: true, message: t(`入力してください`) }],
  }

  if (isNull(filterType) || isNull(fieldType)) {
    return (
      <Form.Item {...formArguments}>
        <Input disabled={true} />
      </Form.Item>
    )
  }

  if (SKIP_VALUE_DEFINITION_FILTER_TYPES.has(filterType)) {
    return <></>
  }

  switch (fieldType) {
    case 'string': {
      return <StringFilterFormItem {...{ onChange, filter, selectOptions, formArgs: formArguments }} />
    }
    case 'numeric': {
      return <NumericFilterFormItem {...{ onChange, filter, selectOptions, formArgs: formArguments }} />
    }
    case 'integer': {
      return <NumericFilterFormItem {...{ onChange, filter, selectOptions, formArgs: formArguments }} />
    }
    case 'date': {
      return <DateFilterFormItem {...{ onChange, filter, selectOptions, formArgs: formArguments }} />
    }
    case 'datetime': {
      return <DateFilterFormItem {...{ onChange, filter, selectOptions, formArgs: formArguments }} />
    }
    case 'time': {
      return <TimeFilterFormItem {...{ onChange, filter, selectOptions, formArgs: formArguments }} />
    }
    case 'boolean': {
      return <BooleanFilterFormItem {...{ onChange, filter, selectOptions, formArgs: formArguments }} />
    }
    case 'array': {
      return <ArrayFilterFormItem {...{ onChange, filter, selectOptions, formArgs: formArguments }} />
    }
    case 'object': {
      return NotSupportedFieldType
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      // const x: never = fieldType // TODO
      return NotSupportedFieldType
    }
  }
}
