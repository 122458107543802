import { CheckSquareFilled, MinusSquareOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons'
import { HUB_PROPERTY_TYPE_ICONS, propertyTypeOptions } from '@salescore/client-common'
import { isRequiredProperty, isSameNodeProperty, type NodePropertyName, type ViewConfigTreeNode } from '@salescore/core'
import { Button, Checkbox, Input, Row, Table, Tooltip } from 'antd'
import { t } from 'i18next'
import { type ReactNode, useMemo, useState } from 'react'

import { getFieldLabel } from '../../../../domain/service/fieldRelated'
import { isNodePropertyIncluded } from '../../../../recoil/view/mutations/field/addFieldByProperty'
import type { ReferenceToPropertyForAddChildNodeMutation } from '../../../../recoil/view/mutations/tree/addChildNode'
import { useConnectionsSelector } from '../../../../recoil/view/selectors/connectionsSelector'
import { useViewSelector } from '../../../../recoil/view/selectors/viewSelector'
import { useColumnsValue } from '../../../../rsheet/recoil/models/propModels'
import { TABLE_HEIGHT } from './const'

export function PropertySelectorTableBody({
  node,
  addField,
  addChildNode,
}: {
  node: ViewConfigTreeNode
  addField: (nodeProperty: NodePropertyName) => void
  addChildNode: (referenceToProperty: ReferenceToPropertyForAddChildNodeMutation) => void
}): ReactNode {
  const [searchKey, setSearchKey] = useState('')
  const { models, getModel, getModelAndProperty } = useConnectionsSelector()
  const { config } = useViewSelector()
  const sheetColumns = useColumnsValue()
  const fields = config.fields ?? []

  const { properties, model } = useMemo(() => {
    const model = getModel(node.modelName)

    const properties = model?.properties.filter((property) => {
      // 過去の負債で、選択肢系プロパティには*_labelと*_valueのsuffixをもつ派生プロパティが存在している、
      // しかし、これは現在使う必要がなく、表示させないようにする
      if (
        (property.selectOptions?.isPresent() ?? false) &&
        (property.name.endsWith(`_label`) || property.name.endsWith(`_value`))
      ) {
        const rawName = property.name.replace(/_label$/, '').replace(/_value$/, '')
        const originalProperty = getModelAndProperty(node.modelName, rawName)
        return originalProperty === undefined // オリジナルが見つからなかったら、派生プロパティでない可能性（単純に名前に_labelが含まれているなど）があるため、表示する
      }

      return true
    })
    return {
      model,
      properties: properties ?? [],
    }
  }, [models, node])

  // やや動作が重いのでメモ化したが、動作が重いのはレンダリングのせいか？
  const { dataSource, requiredProperties, uncheckedRequiredProperties } = useMemo(() => {
    const dataSource = properties.filter(
      (x) =>
        // !isIncluded(x, viewQueryFields) &&
        x.label.includes(searchKey) || x.name.includes(searchKey),
    )
    const requiredProperties = dataSource.filter((x) => isRequiredProperty(x))
    // まだチェックされていないプロパティ
    const uncheckedRequiredProperties = requiredProperties.filter(
      (requiredProperty) =>
        !fields
          .map((x) => x.property)
          .some((x) =>
            isSameNodeProperty(x, {
              nodeName: node.name,
              modelName: node.modelName,
              propertyName: requiredProperty.name,
            }),
          ),
    )

    return {
      dataSource,
      requiredProperties,
      uncheckedRequiredProperties,
    }
  }, [properties, searchKey, fields])

  if (model === undefined) {
    // TODO
    return <></>
  }

  return (
    <div className="bg-white">
      <Row className="my-3" align="middle">
        {/* <Space className="font-bold mb-2">
          {isSome(onBack) && <Button type="text" size="small" icon={<LeftOutlined />} onClick={onBack} />}
          <div>{node.meta.label}の項目を追加</div>
        </Space> */}
        <Input.Search
          data-e2e="property-selector-table-body-search"
          allowClear
          enterButton={false}
          className=""
          style={{ width: '100%' }}
          onKeyDown={(event) => {
            event.stopPropagation() // stopPropagationしないと、シート側のhandleKeyDownが発火する
          }}
          onChange={(event) => {
            setSearchKey(event.target.value)
          }}
        />
      </Row>

      <Table
        scroll={{ y: TABLE_HEIGHT - 70 }}
        dataSource={dataSource}
        pagination={{ pageSize: 100, simple: true }}
        size="small"
        rowClassName={`cursor-pointer hover:opacity-80`}
        onRow={(record) => {
          const sheetColumn = sheetColumns.find((x) =>
            isSameNodeProperty(x.configField?.property, {
              propertyName: record.name,
              modelName: model.name,
              nodeName: node.name,
            }),
          )
          const notDeletable = sheetColumn?.columnDeletable === false
          return {
            onClick() {
              if (notDeletable) {
                return
              }
              addField({
                propertyName: record.name,
                modelName: model.name,
                nodeName: node.name,
              })
            },
            className: isNodePropertyIncluded(
              {
                propertyName: record.name,
                modelName: model.name,
                nodeName: node.name,
              },
              fields.map((x) => x.property),
            )
              ? `bg-blue-50 ${notDeletable ? 'cursor-not-allowed' : ''}`
              : '',
          }
        }}
        rowKey={(record) => record.name}
        columns={[
          {
            key: 'checkbox',
            width: 30,
            render(_, record) {
              const checked = isNodePropertyIncluded(
                {
                  propertyName: record.name,
                  modelName: model.name,
                  nodeName: node.name,
                },
                fields.map((x) => x.property),
              )
              const sheetColumn = sheetColumns.find((x) =>
                isSameNodeProperty(x.configField?.property, {
                  propertyName: record.name,
                  modelName: model.name,
                  nodeName: node.name,
                }),
              )
              if (sheetColumn?.columnDeletable === false) {
                return (
                  <Tooltip title={t('この項目は削除できません')}>
                    <CheckSquareFilled style={{ color: '#d1d5db' }} />
                  </Tooltip>
                )
              }
              // 表示のためだけでCheckboxを使っても、チェック時の挙動がおかしくなるため使わない
              if (checked) {
                return <CheckSquareFilled style={{ color: '#386EF8' }} />
              }
              return <MinusSquareOutlined />
            },
          },
          {
            key: 'propertyType',
            title: t(`型`),
            width: 70,
            filters: [
              ...propertyTypeOptions.map((x) => ({
                value: x.value,
                text: t(x.label),
              })),
              {
                value: 'reference',
                text: t(`参照`),
              },
            ],
            onFilter: (value, property) => {
              if (value === 'reference') {
                return property.referenceTo?.isPresent() ?? false
              }
              return property.type === value
            },
            render(_, property) {
              const icon =
                (property.referenceTo?.isPresent() ?? false) ? (
                  <SearchOutlined />
                ) : (
                  HUB_PROPERTY_TYPE_ICONS[property.type]
                )
              return <span>{icon}</span>
            },
          },
          {
            key: 'propertyLabel',
            title: t(`項目名`),
            render(_, record) {
              return <span>{getFieldLabel(record)}</span>
            },
          },
          {
            key: 'reference',
            width: 50,
            render(_, record) {
              // このノードのプロパティが参照項目のとき、これを選択したらブロックを作成する
              if (record.referenceTo?.isPresent() ?? false) {
                return (
                  <span>
                    <Button
                      // type="text"
                      size="small"
                      icon={<RightOutlined />}
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()

                        addChildNode({
                          referenceTo: record.referenceTo!.first()!, // TODO,
                          propertyName: record.name,
                          modelName: model.name,
                          nodeName: node.name,
                          parentNodeName: node.name,
                        })
                      }}
                    />
                  </span>
                )
              }
              return <></>
            },
          },
        ]}
      />
      {/* XXX: Tableのページネーションと同じ位置にボタンをおきたいが、antdのAPIだとそれが不可能なので、marginTopを操作して無理やり同じ高さにしている */}
      <div
        style={{
          height: 0,
          marginTop: -38,
          paddingLeft: 7,
        }}
      >
        <Checkbox
          // 必須項目がないとき、disabledにする
          disabled={requiredProperties.isBlank()}
          // 必須項目があって、uncheckedなものがない=全てチェックしている時は、チェック済みにする
          checked={requiredProperties.isPresent() && uncheckedRequiredProperties.isBlank()}
          onClick={() => {
            if (uncheckedRequiredProperties.isPresent()) {
              // 未チェックのものがあるときは、全てチェックする
              for (const property of uncheckedRequiredProperties) {
                addField({
                  propertyName: property.name,
                  modelName: model.name,
                  nodeName: node.name,
                })
              }
            } else {
              // 未チェックのものがなく、必須項目がある時、すでにチェックしたものを全て削除する
              for (const property of requiredProperties) {
                const sheetColumn = sheetColumns.find((x) =>
                  isSameNodeProperty(x.configField?.property, {
                    propertyName: property.name,
                    modelName: model.name,
                    nodeName: node.name,
                  }),
                )
                if (sheetColumn?.columnDeletable === false) {
                  continue
                }
                addField({
                  // XXX: addFieldという名前だが、toggleFieldが正しい
                  propertyName: property.name,
                  modelName: model.name,
                  nodeName: node.name,
                })
              }
            }
          }}
        >
          {t(`必須項目を全て追加`)}
        </Checkbox>
      </div>
    </div>
  )
}
