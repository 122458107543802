interface Node {
  name: string
  children?: Node[]
}

export function getNode<T extends Node>(node: T, nodePath: string[]): T | undefined {
  if (nodePath.length === 1 && node.name === nodePath[0]) {
    return node
  }
  if (nodePath.length <= 1) {
    // TODO: ノードが見つからなかった時
    return undefined
  }
  const childNode = (node.children ?? []).find((childNode) => childNode.name === nodePath[1])
  if (childNode === undefined) {
    return undefined
  }
  // FIXME: 型定義に問題あり
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  return getNode(childNode as T, nodePath.slice(1))
}

export function getNodeByName<T extends Node>(
  node: T,
  nodeName: string,
  currentPath?: string[],
): { node: T; path: string[] } | undefined {
  const path = [...(currentPath ?? []), node.name]
  if (node.name === nodeName) {
    return {
      node,
      path,
    }
  }

  for (const childNode of node.children ?? []) {
    const result = getNodeByName(childNode, nodeName, path)
    if (result !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      return result as { node: T; path: string[] }
    }
  }
  return undefined
}
