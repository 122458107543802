import { isNull } from '@salescore/buff-common'
import type { StreamSourceRecordsInput } from '@salescore/client-api'
import { logger } from '@salescore/frontend-common'

import type { ViewRecordFieldChange } from '../../state/useViewRecordsState/useChangesState'
import { VIEW_NEW_RECORD_PREFIX } from '../constant'

type PropertySourceName = string

interface RecordChange {
  id?: string
  streamName: string
  recordNameLabel?: string | undefined
  before?: Record<PropertySourceName, unknown>
  after?: Record<PropertySourceName, unknown>
  deleted?: boolean
}

// 同じstreamName, idをもつchangesを1つにまとめる
export function mergeChanges(changes: ViewRecordFieldChange[]): StreamSourceRecordsInput[] {
  const groupedChanges = changes.groupBy((change) => JSON.stringify([change.streamName, change.id]))

  const mergedChanges = groupedChanges.map((_key, groupedChange): StreamSourceRecordsInput | undefined => {
    const id = groupedChange[0]?.id
    const streamName = groupedChange[0]?.streamName
    const recordNameLabel = groupedChange[0]?.recordNameLabel
    if (streamName === undefined) {
      return undefined
    }
    if (isNull(id)) {
      logger.debug(`id must not be null`) // TODO
      return undefined
    }

    const after = groupedChange.reduce((accumulator, c) => ({ ...accumulator, ...c.after }), {})
    const before = groupedChange.reduce((accumulator, c) => ({ ...c.before, ...accumulator }), {})

    // 削除差分が1件でもあれば削除
    if (groupedChange.some((x) => x.deleted === true)) {
      // 削除差分であり、かつ新規レコードであるときはサーバー側に送信する必要がない
      if (id.startsWith(VIEW_NEW_RECORD_PREFIX)) {
        return undefined
      }

      return {
        id,
        streamName,
        recordNameLabel,
        before,
        deleted: true,
      }
    }

    return {
      id: id.startsWith(VIEW_NEW_RECORD_PREFIX) ? undefined : id,
      streamName,
      recordNameLabel,
      before,
      after,
    }
  })

  return mergedChanges.compact()
}
