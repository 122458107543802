import { CaretDownFilled } from '@ant-design/icons'
import { CORE_CONSTANT } from '@salescore/core'

import { useRowHeightState } from '../../../recoil/navigation/hooks'
import type { LatestFieldChangeItem } from '../../../recoil/records/selectors/changesSelector'
import type { useRowRelatedSelector } from '../../recoil/selectors/rowRelatedSelector'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import { RSheetsStyle } from '../../util/RSheetsStyle'
import { getHeaderColor } from '../header/RSheetsNodeBlockHeader'
import { CELL_WIDTH, RsheetsCell } from './cell/RsheetsCell'

interface RSheetCellGroupProperties {
  row: RSheetRecordNode
  column: RSheetColumn<RSheetRecordNode>
  rowIndex: number
  columnIndex: number
  getSheetCellGroup: ReturnType<typeof useRowRelatedSelector>['getInnerCells']
  expandable: boolean
  expanded: boolean
  latestFieldChangesRow?: Map<string, LatestFieldChangeItem>
}

export function RSheetCellGroup(properties: RSheetCellGroupProperties) {
  const { rowHeight } = useRowHeightState()
  return <RSheetCellGroupInner {...properties} rowHeight={rowHeight} />
}

type RSheetCellGroupInnerProperties = RSheetCellGroupProperties & {
  rowHeight: number
}

function RSheetCellGroupInner({
  row,
  column,
  rowIndex,
  columnIndex,
  getSheetCellGroup,
  expandable,
  expanded,
  latestFieldChangesRow,
  rowHeight,
}: RSheetCellGroupInnerProperties) {
  const sheetCellGroup = getSheetCellGroup(columnIndex)
  const height = row.meta.height * rowHeight
  const width = column.width ?? CELL_WIDTH

  return (
    <div
      className="rsheet-cell-group relative"
      style={{
        width: column.width ?? CELL_WIDTH,
        transition: RSheetsStyle.cellTransition,
        backgroundColor: getHeaderColor(column.color).cellColor,
      }}
      data-e2e={`rsheet-cell-group-${rowIndex}-${columnIndex}`}
    >
      {sheetCellGroup.map((sheetCell) => {
        const { recordNode, parentRecordNode, height } = sheetCell
        if (parentRecordNode === undefined && recordNode === undefined) {
          return <BlankCell width={width} height={height * rowHeight} />
        }

        const changeKey: `${string},${string},${string}` = `${recordNode?.id},${
          column.node.write?.streamName ?? column.node.name // 目標シートの時は write.streamName が undefined になる
        },${column.field?.name ?? ''}`
        const fieldChange = latestFieldChangesRow?.get(changeKey)
        const isFieldChanged = fieldChange !== undefined
        const isNewRecord =
          recordNode?.id !== undefined &&
          recordNode.id.startsWith(CORE_CONSTANT.VIEW_NEW_RECORD_PREFIX) &&
          /*
            changes をこのコンポーネントの中で呼び出すと不必要にレンダリングが走る懸念があるためコメントアウト
            changes.some((change) => change.changes.some((x) =>
              // 新規レコード作成の変更履歴の判定
              // 他のレコードと一緒に保存して、新規レコードは保存されたが、その他のレコードが保存されなかった場合に特に効いてくる
              x.id === recordNode?.id &&
              Object.keys(x.before ?? {}).length === 0 &&
              Object.keys(x.after ?? {}).length === 0 &&
              x.fieldChanges.length === 0
            )) &&
             */
          column.isSystem !== true

        return (
          <RsheetsCell
            // keyを設定するとレコード追加の際に想定していない箇所に追加されてしまうため保留
            // key={recordNode?.id ?? ''}
            rootRecordNode={row}
            sheetCell={sheetCell}
            column={column}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            expandable={expandable}
            expanded={expanded}
            isFieldChanged={isFieldChanged}
            isNewRecord={isNewRecord}
            overrideValue={fieldChange?.value}
          />
        )
      })}
      {sheetCellGroup.isEmpty() && <BlankCell width={width} height={height} />}
      {expandable && !expanded && column.node.path.length > 1 && (
        <div
          className="absolute flex w-full items-center justify-center align-middle"
          style={{
            height: 1,
            width: '100%',
            bottom: 4,
          }}
        >
          <CaretDownFilled style={{ fontSize: 10, color: RSheetsStyle.color.gray }} />
        </div>
      )}
    </div>
  )
}

function BlankCell({ width, height }: { width: number; height: number }) {
  return (
    <div
      style={{
        height,
        border: `1px ${RSheetsStyle.color.border} solid`,
        width,
      }}
    >
      <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`}>
        <line fill="none" stroke={RSheetsStyle.color.border} strokeWidth="1.5" x1="0" y1="0" x2={width} y2={height} />
      </svg>
    </div>
  )
}
