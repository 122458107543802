import type { ViewConfigDimension, ViewTimeframe, ViewUiDimensionCandidate } from '@salescore/core'
import { Select } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'

import { useMeValue } from '../../../recoil/view/hooks'

function useDateSpanOptions(): Array<{ value: ViewTimeframe; label: string }> {
  const me = useMeValue()
  const accountStartMonth = dayjs().month(me.organization.setting.accountClosingMonth)
  return [
    {
      value: 'year',
      label: t(`年`),
    },
    {
      value: 'fiscal_quarter',
      label: t(`Q({{val, datetime}}始まり)`, {
        val: accountStartMonth.toDate(),
        formatParams: {
          val: { month: 'short' },
        },
      }),
    },
    {
      value: 'month',
      label: t(`月`),
    },
    {
      value: 'week',
      label: t(`週`),
    },
    {
      value: 'weekday',
      label: t(`曜日`),
    },
    {
      value: 'day',
      label: t(`日`),
    },
    {
      value: 'hour',
      label: t(`時間`),
    },
  ]
}

// dimensionを1つ選ぶ

export function DimensionPicker({
  currentDimension,
  dimensions,
  allowClear,
  stretch,
  onChange,
}: {
  currentDimension: ViewConfigDimension | undefined
  dimensions: ViewUiDimensionCandidate[]
  allowClear?: boolean
  stretch?: boolean
  onChange: (x?: ViewConfigDimension) => void
}) {
  const dateSpanOptions = useDateSpanOptions()
  // const generateValue = (d: ViewConfigDimension) => [d.key, d.dateSpan].compact().join('_')
  const generateValue = (d: ViewConfigDimension) => [d.key].compact().join('_')
  const options = dimensions.map((dimension) => ({
    value: generateValue(dimension),
    label: dimension.label,
    dimension,
  }))

  return (
    <div className="flex w-full">
      <Select
        allowClear={allowClear}
        className={stretch === true ? 'w-full' : 'max-w-xs grow-[7]'}
        options={options}
        value={currentDimension === undefined ? undefined : generateValue(currentDimension)}
        onChange={(value) => {
          const picked = options.find((x) => x.value === value)
          if (picked === undefined) {
            onChange()
            return
          }
          if (picked.dimension.fieldType === 'date' || picked.dimension.fieldType === 'datetime') {
            onChange({ ...picked.dimension, dateSpan: 'month' })
          } else {
            onChange(picked.dimension)
          }
        }}
        showSearch
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      />
      {((currentDimension !== undefined && currentDimension.fieldType === 'date') ||
        currentDimension?.fieldType === 'datetime') && (
        <>
          <Select
            className="ml-2 min-w-[140px] max-w-xs grow-[3]"
            options={dateSpanOptions}
            value={currentDimension.dateSpan}
            onChange={(dateSpan) => {
              onChange({
                ...currentDimension,
                dateSpan,
              })
            }}
          />
        </>
      )}
    </div>
  )
}
