import { isPresent, isSome } from '@salescore/buff-common'
import { POSTHOG_EVENTS } from '@salescore/client-base'
import {
  CORE_CONSTANT,
  createDimensionFieldName,
  flatRecordNodes,
  generateLabelSqlFieldName,
  type ViewUiKpi,
} from '@salescore/core'
import { type CsvDownloadButtonArgument, EChartStacked } from '@salescore/frontend-common'
import { Card, Empty, Spin } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { chartDesignSettingsAtom } from '../../../recoil/navigation/atoms'
import { useNavigationModal } from '../../../recoil/navigation/hooks'
import { additionalConfigAtom, recordsAtom } from '../../../recoil/records/atoms'
import { useLoadingState } from '../../../recoil/records/hooks'
import { usePosthogTrackClick } from '../../../recoil/usePosthogTrack'
import { useViewValue } from '../../../recoil/view/hooks'

type ChartRecord = Record<string, unknown>

export function ChartCardWrapper({ children, loading }: { children: JSX.Element; loading?: boolean }) {
  return (
    <div className="h-full overflow-y-auto bg-slate-100 p-6">
      <Spin spinning={loading ?? false}>
        <Card className="overflow-hidden p-6 shadow-lg shadow-slate-300">{children}</Card>
      </Spin>
    </div>
  )
}

export function KpiStackedChart({
  component,
  options,
}: {
  component: ViewUiKpi
  options?: { ignoreNull?: boolean; fillKeysAsDate?: boolean }
}) {
  const view = useViewValue()
  const loadingState = useLoadingState()
  const { records, key1, key2, csv } = useRecords({ component, options })
  const { drillDownModal } = useNavigationModal()
  // 一旦、目標値があれば常に目標付きで描画
  const additionalConfig = useRecoilValue(additionalConfigAtom)
  const withGoalConfig = isSome(additionalConfig.kpiParameter?.goalConfig)
  const {
    chartType,
    chartValueLabelDisplayMode,
    chartValueLabelUnitType,
    chartValueLabelDecimalPlaceType,
    chartValueLabelShouldShowPercentage,
    chartAxisLabelDisplayMode,
    chartLegendsDisplayMode,
  } = useRecoilValue(chartDesignSettingsAtom)
  const posthogTrackClick = usePosthogTrackClick()

  if (component.pivot.rows.isEmpty()) {
    return <Empty description={t(`軸を指定してください`)} />
  }

  if (component.pivot.rows.length > 2) {
    return <Empty description={t(`グラフを描画するには、軸を1つ以上2つ以下にしてください`)} />
  }

  return (
    <ChartCardWrapper loading={loadingState.isTrue}>
      <EChartStacked
        title={view.name}
        csv={csv}
        onCsvDownloadClick={() => {
          posthogTrackClick(POSTHOG_EVENTS.click_kpi_bar_chart_csv_download)
        }}
        onImageDownloadClick={() => {
          posthogTrackClick(POSTHOG_EVENTS.click_kpi_bar_chart_image_download)
        }}
        height={400}
        onSeriesClick={(value) => {
          drillDownModal.showModal({
            type: 'kpi',
            dimensionValues: {
              rows: [
                {
                  value: value.key,
                  label: value.label,
                },
                isPresent(value.groupKey)
                  ? {
                      value: value.groupKey,
                      label: value.groupLabel,
                    }
                  : undefined,
              ].compact(),
              columns: [],
            },
          })
        }}
        options={[
          {
            chartType,
            records,
            xKey: key1,
            groupKey: key2,
            xLabelKey: generateLabelSqlFieldName(key1),
            groupLabelKey: generateLabelSqlFieldName(key2),
            stackGroups: [
              withGoalConfig
                ? {
                    valueKey: JSON.stringify(['goal']),
                    stackKey: t('目標'),
                  }
                : undefined,
              {
                valueKey: JSON.stringify(['actual']),
                stackKey: t('実績'),
              },
            ].compact(),
            fillKeysAsDate: options?.fillKeysAsDate,
            valueLabelDisplayMode: chartValueLabelDisplayMode,
            valueLabelUnitType: chartValueLabelUnitType,
            valueLabelDecimalPlaceType: chartValueLabelDecimalPlaceType,
            valueLabelShouldShowPercentage: chartValueLabelShouldShowPercentage,
            axisLabelDisplayMode: chartAxisLabelDisplayMode,
            legendsDisplayMode: chartLegendsDisplayMode,
          },
        ]}
      />
    </ChartCardWrapper>
  )
}

// KPI用のrecordNodesはシート用のrecordNodesと異なり、親以下の全てのノードのattributesをマージしても重複が発生しないような作りになっている
// （そもそもそういうデータ構造なのを、RSheetを使うために無理やりrecordNodeにしている、と言ったほうが正しい）
// グラフを描画するには、flatな形のレコード配列にする必要があるので、フラットにする
export function useRecords({
  component,
  options,
}: {
  component: Pick<ViewUiKpi, 'pivot'>
  options?: { ignoreNull?: boolean }
}) {
  const view = useViewValue()
  const recordNodes = useRecoilValue(recordsAtom)

  // レコードの算出
  const records = useMemo(() => {
    const firstDimensionName = createDimensionFieldName(1)
    const dimensions = [...component.pivot.rows, ...component.pivot.columns]
    const dimensionFields = dimensions.map((_, index) => createDimensionFieldName(index + 1))
    const records: ChartRecord[] = recordNodes
      .flatMap((x) => flatRecordNodes(x, []))
      .filter((x) =>
        dimensionFields.every((fieldName) => {
          // dimension1の場合、小計のレコードを除外する
          if (fieldName === firstDimensionName && x[fieldName] === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING) {
            return false
          }
          // NOTE: transform処理で空文字をNULL変換する処理をパフォーマンス問題で一時的に止めているため、
          // ignoreNullがtrueの場合は空文字も除外する
          if (
            options?.ignoreNull === true &&
            (x[fieldName] === CORE_CONSTANT.KPI_PIVOT_NULL_STRING || x[fieldName] === '')
          ) {
            return false
          }
          return true
        }),
      )
    return records
  }, [recordNodes])

  const mergedRecords = getMergedRecordsEmptyToNull(records)
  // 選択肢の場合、レコードの値をラベル値に変換する
  // このロジックの中でkeyも関連するので、同時にkeyも生成する
  const { converted1, converted2 } = useMemo(() => {
    const converted1 = convertByOptions(mergedRecords, component, 1)
    const converted2 = convertByOptions(converted1.records, component, 2)
    return {
      converted1,
      converted2,
    }
  }, [records])

  // csvダウンロード用の値を生成
  const csv = useMemo((): CsvDownloadButtonArgument => {
    const label1 = converted1.row?.label
    const label2 = converted2.row?.label
    const valueKey = JSON.stringify(['actual'])
    const data = converted2.records.map((record) => {
      if (label1 === undefined) {
        // ありえないはず
        return [record[valueKey]]
      }
      if (label2 === undefined) {
        return [record[converted1.key], record[valueKey]]
      }
      return [record[converted1.key], record[converted2.key], record[valueKey]]
    })

    return {
      filename: view.name,
      data,
      headers: [label1, label2, view.name].compact(),
    }
  }, [converted1, converted2, view])

  return {
    records: converted2.records,
    key1: converted1.key,
    key2: converted2.key,
    csv,
  }
}

function convertByOptions(records: ChartRecord[], component: Pick<ViewUiKpi, 'pivot'>, depth: number) {
  const key = getKeyNameByDepth(depth)
  const row = component.pivot.rows[depth - 1]
  const options = row?.selectOptions
  if (options === undefined) {
    return { records, key, row }
  }
  const mapper = options.groupByUniqueKey((x) => x.value)
  const labelKey = generateLabelSqlFieldName(key)
  const convertedRecords = records.map((record) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    const value = record[key] as string
    const option = mapper[value]
    return {
      ...record,
      [key]: value,
      [labelKey]: option?.label ?? value,
    }
  })
  return {
    records: convertedRecords,
    key,
    row,
  }
}

function getKeyNameByDepth(depth: number) {
  const key = createDimensionFieldName(depth)
  return key
}

// NOTE: transform処理で空文字をNULL変換する処理をパフォーマンス問題で一時的に止めているため、
// グラフ描画系のUIにおいては空文字をNULLに統合して表現する
function getMergedRecordsEmptyToNull(records: ChartRecord[]) {
  const actualKey = JSON.stringify(['actual'])
  // dimension1に空文字がある場合、それに対応する_NULL_オブジェクトに空文字のactualの値を加算した配列を作る
  const nullDimension1Records = records
    .filter((x) => x.dimension1 === '')
    .map((x) => {
      const nullActualValue =
        records.find((y) => y.dimension1 === CORE_CONSTANT.KPI_PIVOT_NULL_STRING && y.dimension2 === x.dimension2)?.[
          actualKey
        ] ?? '0'
      const addedActualValue = Number(x[actualKey]) + Number(nullActualValue)
      return { ...x, dimension1: CORE_CONSTANT.KPI_PIVOT_NULL_STRING, [actualKey]: String(addedActualValue) }
    })
  const emptyAndNullDeletedRecordsD1 = records
    .filter((x) => x.dimension1 !== '')
    .map((x) => {
      if (x.dimension1 === CORE_CONSTANT.KPI_PIVOT_NULL_STRING) {
        const updatedRecord = nullDimension1Records.find((y) => y.dimension2 === x.dimension2)
        if (isSome(updatedRecord)) {
          return
        }
      }
      return x
    })
    .compact()

  const dimension1MergedRecoreds = [...emptyAndNullDeletedRecordsD1, ...nullDimension1Records]
  // 同様にdimension2に空文字がある場合、それに対応する_NULL_オブジェクトに空文字のactualの値を加算した配列を作る
  // 一時対応かつ時間に余裕もないためやや手抜きで冗長なコードになってしまっている
  const nullDimension2Records = dimension1MergedRecoreds
    .filter((x) => x.dimension2 === '')
    .map((x) => {
      const nullActualValue =
        dimension1MergedRecoreds.find(
          (y) => y.dimension2 === CORE_CONSTANT.KPI_PIVOT_NULL_STRING && y.dimension1 === x.dimension1,
        )?.[actualKey] ?? '0'
      const addedActualValue = Number(x[actualKey]) + Number(nullActualValue)
      return { ...x, dimension2: CORE_CONSTANT.KPI_PIVOT_NULL_STRING, [actualKey]: String(addedActualValue) }
    })
  const emptyAndNullDeletedRecordsD2 = dimension1MergedRecoreds
    .filter((x) => x.dimension2 !== '')
    .map((x) => {
      if (x.dimension2 === CORE_CONSTANT.KPI_PIVOT_NULL_STRING) {
        const updatedRecord = nullDimension2Records.find((y) => y.dimension1 === x.dimension1)
        if (isSome(updatedRecord)) {
          return
        }
      }
      return x
    })
    .compact()

  return [...emptyAndNullDeletedRecordsD2, ...nullDimension2Records]
}
