import { type MyUserFieldsFragment, UserLicenseTypeEnum } from '@salescore/client-api'

export const checkSyncEnabled = (myUser: Pick<MyUserFieldsFragment, 'role' | 'userLicenses'> | undefined) =>
  myUser !== undefined &&
  (myUser.role === 'admin' || myUser.userLicenses.some((x) => x.license === UserLicenseTypeEnum.Sync))

export const checkVisualizeEnabled = (myUser: Pick<MyUserFieldsFragment, 'role' | 'userLicenses'> | undefined) =>
  myUser !== undefined &&
  (myUser.role === 'admin' || myUser.userLicenses.some((x) => x.license === UserLicenseTypeEnum.Visualize))

export const checkRiEnabled = (myUser: Pick<MyUserFieldsFragment, 'role' | 'userLicenses'> | undefined) =>
  myUser !== undefined &&
  (myUser.role === 'admin' || myUser.userLicenses.some((x) => x.license === UserLicenseTypeEnum.Ri))

export const checkDataExtensionEnabled = (myUser: Pick<MyUserFieldsFragment, 'role' | 'organization'> | undefined) =>
  myUser !== undefined &&
  (myUser.organization.organizationPlans.some((x) => x.license === 'dataExtension') || myUser.role === 'admin')
