import { isPresent } from '@salescore/buff-common'
import { useMessage } from '@salescore/frontend-common'
import { Alert, Button, Checkbox, Collapse, Form, Modal, Row } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useNavigationModal } from '../../../recoil/navigation/hooks'
import { useChangesValue } from '../../../recoil/records/hooks'
import { useSearchQuerySelector } from '../../../recoil/records/selectors/searchQuerySelector'
import { useFieldMutations } from '../../../recoil/view/mutations/fieldMutations'
import { useQuerySelector } from '../../../recoil/view/selectors/querySelector'
import { CustomSearchQueryForm } from './CustomSearchQueryForm'

export const SearchableFieldsFormModal = () => {
  const { searchableFieldsModal } = useNavigationModal()
  const changes = useChangesValue()
  const message = useMessage()

  useEffect(() => {
    if (searchableFieldsModal.isModalVisible && changes.length > 0) {
      message.warning(t('設定を変更すると変更情報が失われます。'))
    }
  }, [searchableFieldsModal.isModalVisible])

  return (
    <Modal
      open={searchableFieldsModal.isModalVisible}
      onCancel={searchableFieldsModal.hideModal}
      width={'60%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      title={<div className="font-bold">{t(`検索項目設定`)}</div>}
      style={{ top: '3%' }}
      destroyOnClose
    >
      <Body
        onAfterFinish={() => {
          searchableFieldsModal.hideModal()
        }}
      />
    </Modal>
  )
}

const ignoreMetaTypes = new Set(['url', 'record_url'])

export function Body({ onAfterFinish }: { onAfterFinish: () => void }) {
  const { query, flattenNodes } = useQuerySelector()
  const { searchableFields } = useSearchQuerySelector()
  const candidateFields = query.fields.filter(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    (x) => x.meta.fieldType === 'string' && !ignoreMetaTypes.has(x.meta.fieldMetaType as string),
  )
  const [pickedFieldNames, setPickedFieldNames] = useState(() => searchableFields.map((x) => x.name))
  const { setSearchableFields } = useFieldMutations()
  const stringFieldsWithNode = flattenNodes
    .map((node) => ({
      node,
      fields: candidateFields.filter((field) => field.nodePath.isEqual(node.path)),
    }))
    .filter((x) => x.fields.isPresent())
    .sortBy((x) => x.node.path.length)
  const isCustomed = isPresent(query.extra?.searchQuery)

  return (
    <>
      <Form
        layout="vertical"
        onFinish={(value) => {
          setSearchableFields({
            searchableFields: pickedFieldNames
              .map((name) => query.fields.find((field) => field.name === name))
              .compact(),
          })
          onAfterFinish()
        }}
      >
        <div className="mb-4">
          {t(`検索対象としたいフィールドを選択してください。`)}
          <br />
          <Alert
            className="mt-2"
            showIcon
            type="warning"
            message={
              <span className="text-xs">
                ※{t(`検索対象となるフィールドが多ければ多いほど、検索に時間がかかります。`)}
              </span>
            }
          />
        </div>
        {stringFieldsWithNode.map(({ node, fields }) => (
          <Form.Item label={node.meta.label}>
            <Checkbox.Group
              disabled={isCustomed}
              defaultValue={pickedFieldNames}
              onChange={(newFieldNames) => {
                setPickedFieldNames((oldFieldNames) => [
                  // eslint-disable-next-line max-nested-callbacks
                  ...oldFieldNames.filter((oldFieldName) => !fields.map((x) => x.name).includes(oldFieldName)),
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                  ...(newFieldNames as string[]),
                ])
              }}
              options={fields.map((field) => ({
                label: field.meta.label,
                value: field.name,
              }))}
            />
          </Form.Item>
        ))}
        <Row justify="end">
          <Button htmlType="submit">{t(`適用`)}</Button>
        </Row>
      </Form>
      <Collapse defaultActiveKey={isCustomed ? [`custom`] : []} className="my-4">
        <Collapse.Panel header={t(`高度な設定`)} key="custom">
          <CustomSearchQueryForm
            onAfterFinish={() => {
              onAfterFinish()
            }}
          />
        </Collapse.Panel>
      </Collapse>
    </>
  )
}
