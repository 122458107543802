import { PlusOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation, useSuspenseQuery } from '@apollo/client'
import {
  CreateSalescoreModelDocument,
  FetchEltModelDocument,
  FetchModelsDocument,
  PropertyTypeEnum,
  UpdateSalescoreModelDocument,
} from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import type { NodePropertyName, ViewConfigTreeNode } from '@salescore/core'
import { CORE_CONSTANT } from '@salescore/core'
import { Alert, App, Button, Form, Input, Select } from 'antd'
import { t } from 'i18next'
import type { ReactNode } from 'react'

import type { ReferenceToPropertyForAddChildNodeMutation } from '../../../../recoil/view/mutations/tree/addChildNode'

const SalescoreCustomModelEditForm = ({
  node,
  addField,
  addChildNode,
  label,
}: {
  node: ViewConfigTreeNode
  addField: (nodeProperty: NodePropertyName) => void
  addChildNode: (
    referenceToProperty: ReferenceToPropertyForAddChildNodeMutation,
    option?: { skipSetCurrentNodePath?: boolean },
  ) => void
  label: string
}): ReactNode => {
  const { message } = App.useApp()
  const [updateSalescoreModel, { loading }] = useMutation(UpdateSalescoreModelDocument)
  const [form] = Form.useForm<{ type: string; label: string; options: string[] }>()
  // refetchをやらないとモデルのカラムが増えたことに気付かない。きれいな実装ではないが仕方がない。
  const [, { refetch, loading: modelsLoading }] = useLazyQuery(FetchModelsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  return (
    <>
      <Form
        form={form}
        layout={'vertical'}
        className="p-4"
        onFinish={(value) => {
          void updateSalescoreModel({
            variables: { organizationId: getOrganizationIdFromPath(), modelName: node.modelName, column: value },
            onCompleted: ({ updateSalescoreModel }) => {
              void refetch()
              const property = updateSalescoreModel.model.properties.last()
              if (property === undefined) {
                throw new Error('property is undefined')
              }
              const children = node.children ?? []
              const relatedModelName = updateSalescoreModel.model.name
              if (!children.some((child) => child.modelName === relatedModelName)) {
                addChildNode(
                  {
                    parentNodeName: node.modelName,
                    nodeName: relatedModelName,
                    modelName: relatedModelName,
                    propertyName: `reference_to_${node.modelName}`,
                    referenceTo: {
                      modelName: node.modelName,
                    },
                  },
                  { skipSetCurrentNodePath: true },
                )
              }
              addField({
                nodeName: relatedModelName,
                modelName: relatedModelName,
                propertyName: property.name,
              })
              form.resetFields()
              message.success(t(`カスタム項目を作成しました`))
            },
            onError: (error) => {
              message.error(error.message)
            },
          })
        }}
      >
        <Form.Item
          name={'label'}
          label={t('ラベル')}
          rules={[{ required: true, message: t(`ラベルを入力してください`) }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('データ型')} name={'type'} rules={[{ required: true, message: t(`型を入力してください`) }]}>
          <Select
            className="min-w-28 max-w-28"
            options={[
              {
                value: PropertyTypeEnum.String,
                label: t(`文字列`),
              },
              {
                value: PropertyTypeEnum.Numeric,
                label: t(`数値`),
              },
              {
                value: PropertyTypeEnum.Date,
                label: t(`日付`),
              },
              {
                value: PropertyTypeEnum.Datetime,
                label: t(`日時`),
              },
              {
                value: PropertyTypeEnum.Boolean,
                label: t(`真偽値`),
              },
              {
                value: PropertyTypeEnum.Integer,
                label: t(`整数(Int)`),
              },
              {
                value: PropertyTypeEnum.Array,
                label: t(`複数選択肢`),
              },
            ]}
          />
        </Form.Item>
        <Form.Item shouldUpdate={true} noStyle>
          {({ getFieldValue }) =>
            getFieldValue('type') === 'array' ? (
              <Form.Item label={t('選択肢')} name="options" rules={[{ required: true }]}>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Tags Mode"
                  disabled={form.getFieldsValue().type !== PropertyTypeEnum.Array}
                />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <div className="flex flex-row-reverse">
          <Form.Item>
            <Button htmlType="submit" icon={<PlusOutlined />} type="primary" loading={loading || modelsLoading}>
              {t(`追加`)}
            </Button>
          </Form.Item>
        </div>
        <Alert
          message={t('作成した項目は関連オブジェクトの「{{label}}」に作成されます。', { label })}
          type="info"
          showIcon
        />
      </Form>
    </>
  )
}

export const SalescoreCustomModelPropertyForm = ({
  node,
  parentNode,
  addField,
  addChildNode,
}: {
  node: ViewConfigTreeNode
  parentNode: ViewConfigTreeNode | undefined
  addField: (nodeProperty: NodePropertyName) => void
  addChildNode: (
    referenceToProperty: ReferenceToPropertyForAddChildNodeMutation,
    option?: { skipSetCurrentNodePath?: boolean },
  ) => void
}): ReactNode => {
  const { message } = App.useApp()
  const { modelName } = node

  const relatedSalescoreModelName = [CORE_CONSTANT.SALESCORE_CUSTOM_MODEL_PREFIX, `related_${modelName}`].join('')
  const [createSalescoreModel, { loading }] = useMutation(CreateSalescoreModelDocument)
  const {
    data: { eltModel: relatedEltModel },
    refetch,
  } = useSuspenseQuery(FetchEltModelDocument, {
    variables: { organizationId: getOrganizationIdFromPath(), name: relatedSalescoreModelName },
  })

  return relatedEltModel == null ? (
    <div className="flex flex-col items-center pt-6">
      <Button
        onClick={() => {
          void createSalescoreModel({
            variables: {
              organizationId: getOrganizationIdFromPath(),
              modelName,
            },
            onCompleted: () => {
              void message.success(t(`カスタムモデルを作成しました`))
              void refetch()
            },
          })
        }}
        loading={loading}
      >
        {t('作成')}
      </Button>
    </div>
  ) : (
    <SalescoreCustomModelEditForm
      addChildNode={addChildNode}
      addField={addField}
      node={node}
      label={relatedEltModel.model.label}
    />
  )
}
