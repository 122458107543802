import { DoubleLeftOutlined } from '@ant-design/icons'
import { isSome } from '@salescore/buff-common'
import { CONSTANT } from '@salescore/client-base'
import { useResize, useStateWithLocalStorage } from '@salescore/frontend-common'
import { Button } from 'antd'
import { type CSSProperties, type PointerEvent, useState } from 'react'

const MINIMUM_WIDTH = 34

interface Argument {
  sider: (argument: { close: () => void; open: () => void; visibility: boolean }) => JSX.Element
  body: (argument: { close: () => void; open: () => void; visibility: boolean }) => JSX.Element
  defaultVisibility?: boolean
  defaultWidth?: number // %表示も受け付けたいが、ロジック的に厳しい
  position?: 'right' | 'left'
  showMinimum?: boolean
  siderKey: string
}

// いい実装ができず、やや無理やりになっている

export function ClosableResizableSider({
  sider,
  body,
  defaultVisibility,
  defaultWidth,
  position,
  showMinimum,
  siderKey: key,
}: Argument) {
  const initialWidth = isSome(defaultWidth) && defaultWidth > 0 ? defaultWidth : 360
  const [width, setWidth] = useStateWithLocalStorage(key, initialWidth)
  const [visibility, setVisibility] = useState(defaultVisibility ?? true)
  const siderWidth = visibility ? width : showMinimum === true ? MINIMUM_WIDTH : 0

  return (
    <div
      className="closable-resizable-sider relative flex h-full"
      style={{
        backgroundColor: `white`, // TODO: ここで指定したくない
      }}
    >
      {position !== 'right' && (
        <Sider
          visibility={visibility}
          width={siderWidth}
          sider={sider}
          setVisibility={setVisibility}
          showMinimum={showMinimum}
        />
      )}
      <Body visibility={visibility} body={body} setVisibility={setVisibility} />
      {position === 'right' && (
        <Sider
          visibility={visibility}
          width={siderWidth}
          sider={sider}
          setVisibility={setVisibility}
          showMinimum={showMinimum}
        />
      )}
      {visibility && (
        <DraggableBar
          position={position ?? 'left'}
          width={width}
          setWidth={(w) => {
            setWidth(w)
          }}
        />
      )}
    </div>
  )
}

function Sider({
  visibility,
  width,
  showMinimum,
  sider,
  setVisibility,
}: {
  visibility: boolean
  width: number
  showMinimum?: boolean
  sider: (argument: { close: () => void; open: () => void; visibility: boolean }) => JSX.Element
  setVisibility: (x: boolean) => void
}) {
  return (
    <div
      className="closable-resizable-sider-sider"
      style={{
        overflow: `hidden`,
        width,
        height: '100%',
        transition: `all 0.2s`,
        borderLeft: `1px solid ${CONSTANT.colors.border}`,
        borderRight: `1px solid ${CONSTANT.colors.border}`,
      }}
    >
      {showMinimum === true && !visibility && (
        <div>
          <Button
            type="text"
            style={{ color: `#777` }}
            icon={<DoubleLeftOutlined />}
            onClick={() => {
              setVisibility(true)
            }}
          />
        </div>
      )}
      {/* この要素でwrapすることで、close時のちらつきがなくなる */}
      {(showMinimum !== true || visibility) && (
        <div
          style={{ width }}
          className="closable-resizable-sider-sider-inner no-scrollbar relative h-full  overflow-y-scroll"
        >
          {sider({
            visibility,
            close: () => {
              setVisibility(false)
            },
            open: () => {
              setVisibility(true)
            },
          })}
        </div>
      )}
    </div>
  )
}

function Body({
  visibility,
  body,
  setVisibility,
}: {
  visibility: boolean
  body: (argument: { close: () => void; open: () => void; visibility: boolean }) => JSX.Element
  setVisibility: (x: boolean) => void
}) {
  return (
    <div
      className="closable-resizable-sider-body min-w-0 flex-1"
      style={{
        transition: 'all 0.2s',
      }}
    >
      {body({
        visibility,
        close: () => {
          setVisibility(false)
        },
        open: () => {
          setVisibility(true)
        },
      })}
    </div>
  )
}

export function ClosableResizableSiderWithPercent(argument: Omit<Argument, 'defaultWidth'> & { widthPercent: number }) {
  const { ref, rect } = useResize()
  const { widthPercent } = argument

  return (
    <div className="ClosableResizableSiderWithPercent size-full" ref={ref}>
      {rect === undefined ? (
        <></>
      ) : (
        <ClosableResizableSider {...argument} defaultWidth={(rect.width * widthPercent) / 100} />
      )}
    </div>
  )
}

const DRAGGABLE_WIDTH = 20
const zIndex = 100

function DraggableBar({
  position,
  width,
  setWidth,
}: {
  position: 'right' | 'left'
  width: number
  setWidth: (width: number) => void
}) {
  const [startX, setStartX] = useState<number | undefined>()
  const [startWidth, setStartWidth] = useState<number | undefined>()

  const pointerDownHandler = (e: PointerEvent) => {
    setStartX(e.clientX)
    setStartWidth(width)
    e.currentTarget.setPointerCapture(e.pointerId)
  }

  const pointerUpHandler = (e: PointerEvent) => {
    setStartX(undefined)
    setStartWidth(undefined)
    e.currentTarget.releasePointerCapture(e.pointerId)
  }

  const pointerMoveHandler = (e: PointerEvent) => {
    if (startWidth === undefined || startX === undefined) {
      return
    }
    setWidth(Math.max(MINIMUM_WIDTH, startWidth + (e.clientX - startX) * (position === 'right' ? -1 : 1)))
  }

  // 全体設定
  const style: CSSProperties = {
    position: 'absolute',
    height: `100%`,
    width: 3,
    backgroundColor: '#5991F6',
    zIndex,
    top: 0,
    cursor: 'col-resize',
    ...(position === 'right' ? { right: width } : { left: width }),
  }

  return (
    <>
      {/*  */}
      {/* ドラッグ可能な要素。hoverで表示される */}
      {/*  */}
      <div
        // hoverで表示される線よりも実際のdraggableな範囲を広く取るため、wrapする
        className="visible-on-hover wrapper"
        style={{
          ...style,
          width: DRAGGABLE_WIDTH, // ドラッグ可能な範囲を広めにしておく
          // left: -(DRAGGABLE_WIDTH / 2), // TODO: 範囲を広めにした分、調整
          transform: `translateX(${(DRAGGABLE_WIDTH / 2) * (position === 'right' ? 1 : -1)}px)`,
          backgroundColor: 'none',
        }}
        onPointerDown={pointerDownHandler}
        onPointerMove={pointerMoveHandler}
        onPointerUp={pointerUpHandler}
      >
        <div
          style={{
            ...style,
            left: DRAGGABLE_WIDTH / 2, // ここはwrap要素が起点になるので、rightでもleftでもどちらでも良い
            // opacity: 1,
            zIndex: zIndex - 1,
          }}
        />
      </div>
    </>
  )
}
