import { LockOutlined } from '@ant-design/icons'
import { checkVisualizeEnabled } from '@salescore/client-recoil'
import { OverlaySection } from '@salescore/frontend-common'
import { t } from 'i18next'

import { useMeValue } from '../../../recoil/view/hooks'

export function VisualizeOnlySection({ children }: { children: JSX.Element }) {
  const me = useMeValue()
  const isVisualizeEnabled = checkVisualizeEnabled(me.myUser)

  return (
    <OverlaySection
      disabled={!isVisualizeEnabled}
      message={
        <>
          <LockOutlined style={{ fontSize: 30 }} />
          <br />
          {t(`VISUALIZEライセンスがないため、KPIを作成できません。`)}
        </>
      }
    >
      {children}
    </OverlaySection>
  )
}
