import { EyeOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { isSome } from '@salescore/buff-common'
import { AdminCollapse } from '@salescore/client-common'
import { type ViewConfigTreeNode, viewConfigTreeNodeSchema } from '@salescore/core'
import { useMessage } from '@salescore/frontend-common'
import { Button, Collapse, Descriptions, Modal, Space, Typography } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'

import { useNavigationModal } from '../../../../recoil/navigation/hooks'
import { useChangesValue } from '../../../../recoil/records/hooks'
import { useTreeMutations } from '../../../../recoil/view/mutations/treeMutations'
import { useConnectionsSelector } from '../../../../recoil/view/selectors/connectionsSelector'
import { FieldDefaultValuesForm } from './node/FieldDefaultValuesForm'

export const ViewQueryNodeDetailModal = () => {
  const { nodeModal, debugModal } = useNavigationModal()
  const { getModel } = useConnectionsSelector()
  const mutation = useTreeMutations()
  const node = nodeModal.content
  const currentLabel = node?.ui?.label ?? getModel(node?.modelName)?.label ?? node?.modelName ?? ''
  const changes = useChangesValue()
  const message = useMessage()

  useEffect(() => {
    if (nodeModal.isModalVisible && changes.length > 0) {
      message.warning(t('設定を変更すると変更情報が失われます。'))
    }
  }, [nodeModal.isModalVisible])

  return (
    <Modal
      open={nodeModal.isModalVisible}
      onCancel={() => {
        nodeModal.hideModal()
      }}
      width={'90%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        // top: '3%',
        maxWidth: 1000,
      }}
      destroyOnClose
      footer={<></>}
    >
      {isSome(node) && (
        // TODO
        <PageHeader title={t(`{{label}}のブロック設定`, { label: currentLabel })}>
          <Descriptions layout="vertical" column={2} className="description-vertical-small-label">
            <Descriptions.Item label={t(`表示名`)}>
              <Typography.Paragraph
                editable={{
                  onChange: (label) => {
                    mutation.editNode({
                      node,
                      f: (oldNode) => {
                        oldNode.ui = {
                          ...oldNode.ui,
                          label,
                        }
                      },
                    })
                    // TODO
                    nodeModal.showModal({
                      ...node,
                      ui: {
                        ...node.ui,
                        label,
                      },
                    })
                  },
                }}
              >
                {currentLabel}
              </Typography.Paragraph>
            </Descriptions.Item>
            <Descriptions.Item label={t(`テーブル名`)}>
              <Typography.Text code>{node.modelName}</Typography.Text>
            </Descriptions.Item>
            {/* TODO */}
            {/* node.read.join !== undefined && (
              <>
                <Descriptions.Item label="親ブロック">{node.read.join.joinOn.meta.parentNodeLabel}</Descriptions.Item>
                <Descriptions.Item label="結合条件">
                  「
                  {node.read.join.joinOn.meta.parentNodeLabel.split('(').first()!}.
                  {node.read.join.joinOn.meta.parentNodeColumnLabel}」と「
                  {node.read.join.joinOn.meta.currentNodeLabel.split('(').first()!}.
                  {node.read.join.joinOn.meta.currentNodeColumnLabel}」で結合
                </Descriptions.Item>
              </>
            ) */}
          </Descriptions>
          <Collapse defaultActiveKey={[]} className="mt-4">
            <Collapse.Panel
              header={
                <Space>
                  {/* <SettingOutlined />  */}
                  <span>{t(`デフォルト値設定`)}</span>
                </Space>
              }
              key="defaultValue"
            >
              <FieldDefaultValuesForm
                node={node}
                onAfterFinish={() => {
                  nodeModal.hideModal()
                }}
              />
            </Collapse.Panel>
          </Collapse>
          <AdminCollapse>
            <Space>
              {/* TODO: 必要であればqueryNodeの表示 */}
              {/* <Button
                onClick={() => {
                  debugModal.showModal({
                    content: node,
                  })
                }}
                icon={<EyeOutlined />}
              >
                スキーマ表示
              </Button> */}
              <Button
                onClick={() => {
                  debugModal.showModal({
                    content: node,
                    schema: viewConfigTreeNodeSchema,
                    onChange(newNode) {
                      mutation.setNode({
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                        node: newNode as ViewConfigTreeNode,
                      })
                      nodeModal.hideModal()
                    },
                  })
                }}
                icon={<EyeOutlined />}
              >
                {t(`設定編集`)}
              </Button>
            </Space>
          </AdminCollapse>
        </PageHeader>
      )}
    </Modal>
  )
}
