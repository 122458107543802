import { isSome, r } from '@salescore/buff-common'
import { notifyBugsnag } from '@salescore/client-base'
import type { ModelSearcher, ViewQueryField, ViewQueryList, ViewQueryRecordNode } from '@salescore/core'
import { logger } from '@salescore/frontend-common'
import { t } from 'i18next'
import type { SetRecoilState } from 'recoil'

import { VIEW_NEW_RECORD_PREFIX } from '../../../../domain/constant'
import { generateNewRecordId, getNode } from '../../../../state/nodeUtil'
import type { ViewRecordFieldChange } from '../../../../state/useViewRecordsState/useChangesState'
import { getLabeledFieldName } from '../../../../state/util'
import { feedbackMessagesAtom, recordAtomFamily } from '../../atoms'
import { getDefaultAttributes } from '../../selectors/querySelector'
import { getChildRecordsWithParent } from '../addNewRecord/addNewChildRecord'
import { getParentRecord, isNewRecord, type RecordChange, updateHeight, updateInnerRow } from '../upsertViewRecords'
import { generateDeleteChanges } from './generateDeleteChanges'

// eslint-disable-next-line @typescript-eslint/max-params
export function upsertChildViewRecord(
  set: SetRecoilState,
  recordChange: RecordChange,
  view: ViewQueryList,
  childRecordNode: ViewQueryRecordNode | undefined,
  rootRecordNode: ViewQueryRecordNode,
  modelSearcher: ModelSearcher,
): ViewRecordFieldChange[] {
  const { rowIndex, innerRowIndex, field, value, label, isDelete } = recordChange
  const changes: ViewRecordFieldChange[] = [] // TODO: createNewChildRecordに参照渡ししているのをやめたい

  const property = field.name

  if (isDelete === true) {
    return destroyChildRecord(set, recordChange, view, childRecordNode, rootRecordNode, modelSearcher)
  }

  if (field.write === undefined) {
    // TODO: どうハンドリングするのが正しいだろうか？
    logger.debug('field.write not found')
    return []
  }

  // 指定したinnerRowIndexが存在しないときは、子レコードを新規作成する
  // changesも参照渡しで更新する
  if (childRecordNode === undefined) {
    if (field.meta.creatable === false) {
      set(feedbackMessagesAtom, [
        {
          message: t(`{{label}}は作成禁止です`, { label: field.meta.label }),
          type: 'warn' as const,
        },
      ])
      return changes
    }
    const labelFieldName = getLabeledFieldName(field.name)
    createNewChildRecord({
      attributes: {
        [property]: value,
        ...(isSome(label) ? { [labelFieldName]: label } : {}),
      },
      rootRecordNode,
      field,
      innerRowIndex,
      view,
      changes,
      change: {
        [field.write.propertySourceName]: value,
      },
      set,
    })
    // heightを参照渡しで更新
    updateHeight(rootRecordNode)
    // innerStartIndexを参照渡しで更新
    updateInnerRow(rootRecordNode)

    // ルートレコードを更新
    set(recordAtomFamily({ id: rootRecordNode.id! }), r(rootRecordNode).clone().data) // 複製して渡さないとfreezeされてしまい、後続の関数で編集できなくなる
    return changes
  }

  // 値が同じならば更新は行わない（更新を行わないことで、再レンダリングを行わないようにする）
  const oldValue = childRecordNode.attributes[property]
  if (oldValue === value) {
    return changes
  }
  if (field.meta.creatable === false && isNewRecord(childRecordNode)) {
    set(feedbackMessagesAtom, [
      {
        message: t(`{{label}}は作成禁止です`, { label: field.meta.label }),
        type: 'warn' as const,
      },
    ])
    return changes
  }
  if (field.meta.updatable === false && !isNewRecord(childRecordNode)) {
    set(feedbackMessagesAtom, [
      {
        message: t(`{{label}}は更新禁止です`, { label: field.meta.label }),
        type: 'warn' as const,
      },
    ])
    return changes
  }

  // 値が異なる時は値を代入する。childViewNodeRecordはnewDataの参照先なので、これでnewDataに反映される
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  childRecordNode.attributes[property] = value as string // TODO
  if (label !== undefined) {
    childRecordNode.attributes[getLabeledFieldName(field.name)] = label
  }

  // ルートレコードを更新
  set(recordAtomFamily({ id: rootRecordNode.id! }), r(rootRecordNode).clone().data) // 複製して渡さないとfreezeされてしまい、後続の関数で編集できなくなる

  // changesを更新
  if (childRecordNode.id === undefined) {
    /* empty */
  } else {
    const nameField = view.fields
      .filter((x) => x.nodePath.isEqual(field.nodePath))
      .find((field) => field.meta.fieldMetaType === 'name')
    changes.push({
      id: childRecordNode.id,
      streamName: field.write.streamName,
      fieldChanges: [
        {
          id: childRecordNode.id,
          fieldName: field.name,
          propertySourceName: field.write.propertySourceName,
          value,
          valueLabel: label,
          rowIndex,
          innerRowIndex,
        },
      ],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      recordNameLabel: nameField === undefined ? undefined : (childRecordNode.attributes[nameField.name] as string),
      before: {
        [field.write.propertySourceName]: oldValue,
      },
      after: {
        [field.write.propertySourceName]: value,
      },
    })
  }
  return changes
}

function createNewChildRecord({
  attributes,
  rootRecordNode,
  field,
  innerRowIndex,
  view,
  changes,
  change,
  set,
}: {
  attributes: Record<string, unknown>
  rootRecordNode: ViewQueryRecordNode
  field: ViewQueryField
  innerRowIndex: number
  view: ViewQueryList
  changes: ViewRecordFieldChange[]
  change: Record<string, unknown>
  set: SetRecoilState
}) {
  // nodeがhasかbelongsかを確認
  const currentNode = getNode(view.tree, field.nodePath)
  if (currentNode?.write?.streamName === undefined) {
    set(feedbackMessagesAtom, [
      {
        message: t(`このブロックは書き込み禁止です。`),
        type: 'warn' as const,
      },
    ])
    return
  }

  if (currentNode.write.parentIdColumn === undefined) {
    // TODO: ViewTree上での子ノードが、DB上での親テーブルに相当するときの分岐
    // このケースの場合は、親テーブルのレコードを新規作成した上で、そのIDをViewTree上での親ノードの参照IDに更新すべきだが、現状これが行えないため、なにも行わない形にする
    set(feedbackMessagesAtom, [
      {
        message: t(`参照先を新規作成することはできません`),
        type: 'warn' as const,
      },
    ])
    return
  }

  // 親のchildrenに対して、レコードを追加する
  const parentRecord = getParentRecord(rootRecordNode, field, innerRowIndex)
  if (parentRecord === undefined) {
    // TODO: そもそもこの条件になるセルは書き込み禁止にする
    set(feedbackMessagesAtom, [
      {
        message: t(`親レコードが存在しません。先に親レコードを作成してください。`),
        type: 'warn' as const,
      },
    ])
    return
  }
  if (parentRecord.id === undefined || parentRecord.id.startsWith(VIEW_NEW_RECORD_PREFIX)) {
    set(feedbackMessagesAtom, [
      {
        message: t(`レコードを保存してから、子レコードを作成してください。`),
        type: 'warn' as const,
      },
    ])
    return
  }
  const currentNodeName = field.nodePath.last() ?? ''
  const childRecordNodes = parentRecord.children.find((x) => x.nodeName === currentNodeName)!.children
  const insertIndex = childRecordNodes.findIndex(
    (x) => x.meta.innerRowIndexStart <= innerRowIndex && innerRowIndex < x.meta.innerRowIndexEnd,
  )
  // changesの生成
  const joinOn = currentNode.read.join?.joinOn
  const relationColumn = joinOn?.type === 'sql' ? undefined : joinOn?.meta.currentNodePropertyName

  const defaultAttributes = getDefaultAttributes(currentNode, view)
  // レコードの作成
  const newChildNodeItem: ViewQueryRecordNode = {
    id: generateNewRecordId(),
    attributes: {
      ...defaultAttributes.recordAttributes,
      ...attributes,
      ...(relationColumn === undefined
        ? {}
        : {
            // relationColumnは厳密にはattributesでないのでrecordNodeに含める必要はないが、changesのみに存在するのが分かりづらいためいれておく。
            // サーバーサイドのレスポンスも同様になっている。
            [`${currentNodeName}_${relationColumn}`]: parentRecord.id,
          }),
    },
    children: [],
    meta: {
      height: 1,
      innerRowIndexStart: 0, // TODO
      innerRowIndexEnd: 1,
    },
  }
  childRecordNodes.splice(insertIndex + 1, 0, newChildNodeItem) // 参照渡しなので、newDataに反映される

  if (isSome(relationColumn)) {
    changes.push({
      id: newChildNodeItem.id,
      streamName: currentNode.write.streamName,
      fieldChanges: [], // TODO 新規子レコード生成時のcallbackはそこまで重要じゃないはずなので後回し
      before: {},
      after: {
        ...defaultAttributes.changeAttributes,
        [relationColumn]: parentRecord.id,
        ...change,
      },
    })
  } else {
    notifyBugsnag({ error: new Error(`relationColumn does not exist`) })
  }

  return newChildNodeItem
}

// TODO: addNewChildRecordとロジックが似ているので、共通化したい
// eslint-disable-next-line @typescript-eslint/max-params
export function destroyChildRecord(
  set: SetRecoilState,
  { innerRowIndex, nodePath, isDeleteAllChildren }: RecordChange,
  query: ViewQueryList,
  childRecordNode: ViewQueryRecordNode | undefined,
  rootRecordNode: ViewQueryRecordNode,
  modelSearcher: ModelSearcher,
): ViewRecordFieldChange[] {
  const node = getNode(query.tree, nodePath)
  if (node?.write === undefined) {
    return []
  }
  if (childRecordNode === undefined) {
    return []
  }

  const result = getChildRecordsWithParent(rootRecordNode, { innerRowIndex, node }, set)
  if (result === undefined) {
    return []
  }
  const { recordNodes } = result

  // 破壊的操作で変更
  const deleteIndex = recordNodes.findIndex(
    (x) => x.meta.innerRowIndexStart <= innerRowIndex && innerRowIndex < x.meta.innerRowIndexEnd,
  )
  recordNodes.splice(deleteIndex, 1)

  // メタ情報を更新
  if (rootRecordNode !== undefined) {
    // 挿入後なので、ないことはありえないが一応チェック
    // heightを参照渡しで更新
    updateHeight(rootRecordNode)
    // innerStartIndexを参照渡しで更新
    updateInnerRow(rootRecordNode)
  }

  // familyを更新
  set(recordAtomFamily({ id: rootRecordNode.id! }), r(rootRecordNode).clone().data) // 複製して渡さないとfreezeされてしまい、後続の関数で編集できなくなる

  // 変更差分を返す
  return generateDeleteChanges(query, node, childRecordNode, modelSearcher, isDeleteAllChildren)
}
