import { useMutation } from '@apollo/client'
import { isNull, isPresent, isSome } from '@salescore/buff-common'
import {
  CreateOauthI18nSourceDocument,
  CreateOauthSourceDocument,
  type LangEnum,
  SourceProviderEnum,
} from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import {
  CLIENT_COMMON_CONSTANT,
  getOrganizationIdFromPath,
  handleMe,
  LATEST_OPENED_EXTRA_CONFIG,
  LATEST_OPENED_I18N_LANG,
  LATEST_OPENED_SOURCE_KEY,
  type OAuthExtraConfig,
  oauthExtraConfigSchema,
  ProviderLogo,
} from '@salescore/client-common'
import { parseJsonIfValid, useRedirect } from '@salescore/frontend-common'
import { Input, message, Modal, Result } from 'antd'
import Form from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'
import { t } from 'i18next'
import { useRouter } from 'next/router'
import { type ReactNode, useState } from 'react'
import { z } from 'zod'

import { SourceConfigForm } from './CreateSourceConfirmModal/SourceConfigForm'

const langSchema = z.enum(['en', 'ja'])

const langToLabel: Record<LangEnum, string> = {
  en: t(`英語`),
  ja: t(`日本語`),
}

interface Source {
  id: string
  name: string
  provider: SourceProviderEnum
}

const getLatestOpenedSourceId = (provider: string): Source | undefined => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const latestOpenedSource = JSON.parse(localStorage.getItem(LATEST_OPENED_SOURCE_KEY) ?? '{}')

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (latestOpenedSource.provider === provider && isPresent(latestOpenedSource.id)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return latestOpenedSource
    }

    return undefined
  } catch {
    return undefined
  }
}

export const CreateSourceConfirmModal = (): ReactNode => {
  const [sourceConfig, setSourceConfig] = useState<Record<string, unknown>>({})
  const redirect = useRedirect()
  const router = useRouter()
  const [createOauthSourceMutation] = useMutation(CreateOauthSourceDocument)
  const [createOauthI18nSourceMutation] = useMutation(CreateOauthI18nSourceDocument)
  const { oauthCode, state, provider } = router.query
  const isValidParameter =
    isSome(oauthCode) && isSome(state) && isSome(provider) && oauthCode !== '' && state !== '' && provider !== ''
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const latestOpenedSource = getLatestOpenedSourceId(provider as string)
  const [visibility, setVisibility] = useState(isValidParameter)
  const [sourceName, setSourceName] = useState(latestOpenedSource?.name ?? '')
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const tokenUrl = getTokenUrl(provider as SourceProviderEnum)
  const extraConfig = getExtraConfig()
  const [i18nLang] = useState(() => {
    const lang = localStorage.getItem(LATEST_OPENED_I18N_LANG)
    const validated = langSchema.safeParse(lang)
    return validated.success ? validated.data : undefined
  })
  const shouldCreateI18nSource = isPresent(i18nLang) && isSome(latestOpenedSource)

  const createOauthI18nSourceFromQueryParameter = async (): Promise<void> => {
    setLoading(true)
    if (!shouldCreateI18nSource) {
      // この分岐に来る時は、この条件はありえないはず
      return
    }
    try {
      await createOauthI18nSourceMutation({
        variables: {
          input: {
            organizationId: getOrganizationIdFromPath(),
            sourceId: latestOpenedSource.id,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            code: oauthCode as string,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            state: state as string,
            lang: i18nLang,
            name: sourceName,
          },
        },
      })
      localStorage.removeItem(LATEST_OPENED_EXTRA_CONFIG)
      localStorage.removeItem(LATEST_OPENED_I18N_LANG)
      void message.success(t(`言語設定用の連携を追加しました`))
      // TODO: ユーザーリロードに備えてqueryのリセットをしたい。リロードを走らせずに変更する方法が分からなかったので一旦なし
      setVisibility(false)
    } catch (error: unknown) {
      void message.error(t(`言語設定用の連携を追加しました`))
      if (error instanceof Error) {
        setErrorMessage(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const createOauthSourceFromQueryParameter = async (): Promise<void> => {
    setLoading(true)
    try {
      const result = await createOauthSourceMutation({
        variables: {
          oauthSourceInput: {
            id: latestOpenedSource?.id,
            organizationId: getOrganizationIdFromPath(),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            code: oauthCode as string,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            state: state as string,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            provider: provider as SourceProviderEnum,
            sourceConfig,
            name: sourceName,
            tokenUrl,
            clientId: extraConfig?.clientId,
            clientSecret: extraConfig?.clientSecret,
          },
        },
      })
      localStorage.removeItem(LATEST_OPENED_EXTRA_CONFIG)
      void message.success(t(`連携を追加しました`))
      // TODO: ユーザーリロードに備えてqueryのリセットをしたい。リロードを走らせずに変更する方法が分からなかったので一旦なし
      setVisibility(false)
      if (result.data?.createOauthSourceV2?.id !== undefined) {
        void message.success(t(`同期項目の設定をお願いします`))
        redirect(routes.connectionSourceSettingsPath(result.data.createOauthSourceV2.id))
      }
    } catch (error: unknown) {
      void message.error(t(`連携に失敗しました`))
      if (error instanceof Error) {
        setErrorMessage(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return handleMe(({ myUser }) => (
    <Modal
      key="createModal"
      open={visibility}
      onCancel={() => {
        setVisibility(false)
      }}
      width={600}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      style={{ top: '3%' }}
    >
      {isSome(provider) && (
        <Result
          icon={
            <ProviderLogo
              provider={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                provider as string
              }
            />
          }
          title={
            <div>
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                CLIENT_COMMON_CONSTANT.i18n.ja.provider[provider as SourceProviderEnum]
              }
              {shouldCreateI18nSource
                ? t(`の{{lang}}設定用の連携を行います`, { lang: langToLabel[i18nLang] })
                : isSome(latestOpenedSource?.id)
                  ? t('を再連携します')
                  : t('と連携します')}
              <br />
              {t(`よろしいですか？`)}
            </div>
          }
          subTitle={`${t(`ログイン中の組織`)}：${myUser.organization.name}`}
          extra={
            isNull(errorMessage)
              ? [
                  <Form layout="vertical" key={'form'}>
                    {!shouldCreateI18nSource && (
                      <FormItem label={t(`(任意)連携先の名前`)} required={false}>
                        <Input
                          value={sourceName}
                          onChange={(event) => {
                            setSourceName(event.target.value)
                          }}
                        />
                      </FormItem>
                    )}
                    <div>
                      {isSome(tokenUrl) && (
                        <FormItem label={'URL'} required={false}>
                          <Input value={tokenUrl} readOnly disabled />
                        </FormItem>
                      )}
                      {isSome(extraConfig?.clientId) && (
                        <FormItem label={'URL'} required={false}>
                          <Input value={extraConfig.clientId} readOnly disabled />
                        </FormItem>
                      )}
                    </div>
                  </Form>,
                  <SourceConfigForm
                    key={'source_config_form'}
                    // TODO validation
                    provider={
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                      provider as SourceProviderEnum
                    }
                    loading={loading}
                    onSave={async () => {
                      await (shouldCreateI18nSource
                        ? createOauthI18nSourceFromQueryParameter()
                        : createOauthSourceFromQueryParameter())
                    }}
                    setSourceConfig={setSourceConfig}
                    sourceConfig={sourceConfig}
                  />,
                  // <Button
                  //   onClick={() => {
                  //     setVisibility(false)
                  //   }}
                  // >
                  //   キャンセル
                  // </Button>,
                ]
              : [
                  <div className="text-red-600" key={'error_message'}>
                    {errorMessage}
                  </div>,
                ]
          }
        />
      )}
    </Modal>
  ))
}

function getTokenUrl(provider: SourceProviderEnum): string | undefined {
  if (provider === SourceProviderEnum.Salesforce) {
    const extraConfig = getExtraConfig()
    if (extraConfig === undefined) {
      return undefined
    }
    const { authorizeUrl } = extraConfig

    if (
      isNull(authorizeUrl) ||
      !authorizeUrl.startsWith(`https://`) ||
      !authorizeUrl.endsWith(`/services/oauth2/authorize`)
    ) {
      return undefined
    }
    // TODO: このロジックがクライアントベタかつ、サーバーサイドのcreateOauthUserSource側でも行っているのがよくない
    return authorizeUrl.replace(`/services/oauth2/authorize`, `/services/oauth2/token`)
  }
  return undefined
}

function getExtraConfig(): OAuthExtraConfig | undefined {
  const extraConfigJson = localStorage.getItem(LATEST_OPENED_EXTRA_CONFIG)
  const validated = oauthExtraConfigSchema.safeParse(parseJsonIfValid(extraConfigJson ?? ''))
  if (!validated.success) {
    return
  }
  return validated.data
}
