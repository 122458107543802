import { HttpLink } from '@apollo/client'
import { logger, LoggerEvents } from '@salescore/frontend-common'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { z } from 'zod'

import { notifyBugsnag } from '../bugsnag'
import { getAuth0UserFromLocalStorage } from '../getAuth0UserFromLocalStorage'

class ApolloClientFetchError extends Error {
  public constructor(message: string) {
    super(message)
    this.name = 'ApolloClientFetchError'
  }
}

class ApolloClientHttpStatusNotOkError extends Error {
  public constructor(message: string) {
    super(message)
    this.name = 'ApolloClientHttpStatusNotOkError'
  }
}

export const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_URL,
  credentials: 'include',
  headers: {
    // NOTE: JWTを使って認証をするため、cookieは基本的に使わない。
    // integrationの設定時にOAuthのアクセストークンをサーバーサイドに渡すときのみセッションを用いるため必要。
    'Access-Control-Allow-Credentials': 'true',
  },

  async fetch(uri, options) {
    const requestId = uuidv4()
    const graphqlBody = z.object({ operationName: z.string() }).safeParse(options?.body ?? {})
    const operationName = graphqlBody.success ? graphqlBody.data.operationName : `(not graphql operation)`
    const user = getAuth0UserFromLocalStorage()
    const userName = user === undefined ? `未ログインユーザー` : `${user.name}(${user.email}, ${user.sub})`
    const loggerBody = {
      type: 'fetch',
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      uri: uri.toString(),
      operationName,
      user: user ?? null,
      options: JSON.stringify({
        // header.authorizationにJWTが含まれるので、これをログに残すのはやめること
        body: options?.body,
        method: options?.method,
      }),
      requestId,
    }

    try {
      logger.info(LoggerEvents.APOLLO_CLIENT, `${operationName} ${userName}`, loggerBody)
      const startAt = dayjs()
      const res = await fetch(uri, {
        ...options,
        // eslint-disable-next-line @typescript-eslint/no-misused-spread
        headers: { ...options?.headers, 'X-REQUEST-ID': requestId },
      })
      const duration = -startAt.diff()
      const body = {
        ...loggerBody,
        type: 'fetch finished',
        duration,
        res: {
          status: res.status,
          statusText: res.statusText,
          // NOTE: 調査用にprod環境以外ではLogtailにbodyも含めて出力する、
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          body: process.env.APP_ENV === 'prod' ? '' : (res.body?.toString() ?? null),
        },
      }
      if (res.status === 200) {
        logger.info(
          LoggerEvents.APOLLO_CLIENT,
          `${duration}ms ${operationName} ${userName} / status: ${res.status}, statusText: ${res.statusText}`,
          body,
        )
      } else {
        notifyBugsnag({
          error: new ApolloClientHttpStatusNotOkError(`http status: ${res.status}, statusText: ${res.statusText}`),
        })
        logger.error(
          LoggerEvents.APOLLO_CLIENT,
          `${duration}ms ${operationName} ${userName} / status: ${res.status}, statusText: ${res.statusText}`,
          body,
        )
      }
      return res
    } catch (error) {
      const metadata = { key: `fetch`, value: { requestId, options: loggerBody.options } }
      if (error instanceof Error) {
        if (error.name === 'AbortError') {
          // ページ遷移などで中断されるとAbortErrorが発生するが、この場合は挙動に悪影響はないので通知はせずそのままエラーを返す
          throw error
        }
        notifyBugsnag({ error: new ApolloClientFetchError(`${error.name}: ${error.message}`), metadata })
        logger.error(
          LoggerEvents.APOLLO_CLIENT,
          `error ${operationName} ${userName} / ${error.name}: ${error.message}`,
          {
            ...loggerBody,
            error: {
              name: error.name,
              message: error.message,
            },
          },
        )
      } else {
        notifyBugsnag({ error: new ApolloClientFetchError(`fetch error occured`), metadata })
        logger.error(LoggerEvents.APOLLO_CLIENT, `error ${operationName} / [is not instnace of Error]`, loggerBody)
      }

      throw error
    }
  },
})
