import { isTruthy, range } from '@salescore/buff-common'
import { mutation } from '@salescore/frontend-common'
import { t } from 'i18next'
import { useSetRecoilState } from 'recoil'

import { feedbackMessagesAtom } from '../../../recoil/records/atoms'
import { upsertViewRecordsMutation } from '../../../recoil/records/mutations/upsertViewRecordsMutation'
import type { RSheetCursorContextMenuPosition, RSheetsCursorPositionByRecordId } from '../../types/CursorTypes'
import { getCellRelated } from '../../util/getCellValue'
import { cursorModel } from '../models/cursorModel'
import { columnsModel, rowsModel } from '../models/propModels'
import { changeSystemFieldMutation } from './changeSystemFieldMutation'
import { getArea, isCursorOverlap } from './useCursorMutation'

export interface SetMainCursorByRecordIdArguments {
  position: RSheetsCursorPositionByRecordId
  option?: {
    isEditing?: boolean
    toggle?: boolean
    contextMenuPosition?: RSheetCursorContextMenuPosition
    disabled?: boolean
    keepExpand?: boolean
  }
}

//
// 新規行追加時に全ての行でレンダリングが走らないよう、RSheetRowをメモ化しており、rowIndexをキャッシュのキーに含めていない。
// これによりセルに渡されるrowIndexが古いことがあり、クリック時などに普通にrowIndexを渡してsetCursorしようとすると間違ったindexになりうるため
// rowIndexの代わりにrecordIdを渡し、最新の行インデックスを取得してカーソルをセットする。
// メモ化の影響を受けないコンポーネントからの入力であれば、このmutationを使う必要はない。
//
const setMainCursorByRecordIdMutation = mutation({
  key: `rsheet/setMainCursorByRecordIdMutation`,
  // eslint-disable-next-line complexity
  set({ get, set }, { position, option }: SetMainCursorByRecordIdArguments) {
    const columns = get(columnsModel)
    const rows = get(rowsModel)
    const cursor = get(cursorModel)

    const column = columns[position.columnIndex]
    if (column === undefined) {
      return
    }

    const rowIndex = rows.findIndex((x) => x.id === position.recordId)
    if (rowIndex === -1) {
      return
    }
    const isSamePosition = [
      cursor?.main.rowIndex === rowIndex,
      cursor?.main.columnIndex === position.columnIndex,
      cursor?.main.innerRowIndex === position.innerRowIndex,
    ].every(Boolean)
    const isEditingByToggle = option?.toggle === undefined ? undefined : isSamePosition
    const isEditing = option?.isEditing ?? isEditingByToggle ?? false

    //
    // 以下、clickCurrentCursorMutationよりコピペ。TODO: 共通化
    //

    const { recordNode, value } = getCellRelated(columns, rows, { ...position, rowIndex })

    // checkbox系であれば、編集モードにするのではなく、直接値をtoggleする
    if (
      column !== undefined &&
      (isTruthy(column.isSystem) || option?.contextMenuPosition === undefined) &&
      (column.type === 'checkbox' ||
        column.type === 'rowCheckbox' ||
        column.type === 'boolean' ||
        column.type === 'kpi')
    ) {
      const toggledValue = typeof value === 'boolean' ? !value : true
      // if (currentCursor.expand !== undefined) {
      //   const up = Math.min(currentCursor.main.rowIndex, currentCursor.expand.rowIndex)
      //   const down = Math.max(currentCursor.main.rowIndex, currentCursor.expand.rowIndex)
      //   range(up, down).forEach((rowIndex) => {
      //     set(changeSystemFieldMutation, {
      //       value: toggledValue,
      //       rowIndex,
      //       innerRowIndex: 0,
      //       column,
      //     })
      //   })
      // } else
      if (column.isSystem === true || column.type === 'kpi') {
        set(changeSystemFieldMutation, {
          value: toggledValue,
          rowIndex,
          innerRowIndex: position.innerRowIndex,
          column,
        })
      } else {
        set(upsertViewRecordsMutation, {
          recordChanges: [
            {
              rowIndex,
              innerRowIndex: position.innerRowIndex,
              isNestedColumn: column.node.path.length > 1,
              nodePath: column.node.path,
              field: column.field!,
              value: toggledValue,
              label: undefined,
            },
          ],
        })
      }

      set(cursorModel, (oldValue) => ({
        main: {
          rowIndex,
          columnIndex: position.columnIndex,
          innerRowIndex: position.innerRowIndex,
        },
        expand: isTruthy(option?.keepExpand) ? oldValue?.expand : undefined,
        editing: undefined,
        contextMenuPosition: undefined, // これでよいか？
        copying: undefined, // これでよいか？
      }))

      return
    }
    // レコードURLであれば、リンクを開く
    if (column.metaType === 'record_url' && typeof value === 'string' && option?.contextMenuPosition === undefined) {
      window.open(value)
      return
    }

    // open系であればonChangeする
    if (column.type === 'open' && (isTruthy(column.isSystem) || option?.contextMenuPosition === undefined)) {
      set(changeSystemFieldMutation, {
        value,
        rowIndex,
        innerRowIndex: position.innerRowIndex,
        column,
      })
      return
    }

    //
    // コピペ終わり。
    //

    if (isEditing) {
      if (column.readonly === true) {
        set(feedbackMessagesAtom, [
          {
            message: t(`この列は書き込み禁止です`),
            type: `warn` as const,
          },
        ])
        return
      }
      if (option?.disabled === true) {
        set(feedbackMessagesAtom, [
          {
            message: t(`このセルは書き込み禁止です`),
            type: `warn` as const,
          },
        ])
        return
      }
    }

    set(cursorModel, (oldValue) => {
      const newMain = {
        rowIndex,
        columnIndex: position.columnIndex,
        innerRowIndex: position.innerRowIndex,
      }
      const overlap = isCursorOverlap(oldValue, newMain)
      if (
        isTruthy(option?.keepExpand) &&
        oldValue?.expand !== undefined &&
        (overlap.isExpandSelected || overlap.isMainSelected || overlap.isRangeSelected)
      ) {
        const { left, right, up, upInnerRowIndex, down, downInnerRowIndex } = getArea(oldValue.main, oldValue.expand)
        const objectSet = new Set<string>()
        for (const columnIndex of range(left, right)) {
          const column = columns[columnIndex]
          if (column === undefined) {
            continue
          }
          objectSet.add(column.node.name)
        }
        if (objectSet.size === 1) {
          // 選択範囲に単一オブジェクトのカラムしか入っていない場合

          return {
            ...oldValue,
            contextMenuPosition: option?.contextMenuPosition,
          }
        }
      }
      return {
        main: newMain,
        expand: undefined,
        editing: column.readonly === true || !isEditing ? undefined : { isEditing: true },
        contextMenuPosition: option?.contextMenuPosition,
        copying: oldValue?.copying,
      }
    })
  },
})

export const useCursorByRecordIdMutation = () => ({
  setMainCursorByRecordId: useSetRecoilState(setMainCursorByRecordIdMutation),
})
