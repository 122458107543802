import { isNull, isPresent } from '@salescore/buff-common'
import type { ViewForSiderFieldsFragment, ViewGroupFieldsFragment } from '@salescore/client-api'
import type { ViewConfig } from '@salescore/core'
import type { DataNode as AntdNode } from 'antd/es/tree'
import { t } from 'i18next'

export type ExpandedAntdNode = Omit<AntdNode, 'children'> & {
  searchKey: string
  children: ExpandedAntdNode[]
  item: ViewForSiderFieldsFragment | ViewGroupFieldsFragment
}

type ViewGroupTree = ViewGroupFieldsFragment & {
  children: ViewGroupTree[]
}

interface DfsResult {
  node: ViewGroupTree
  parentIndex: number
  parentNodes: ViewGroupTree[]
}

class ViewSearcher {
  // private readonly views: CoreView[]
  private readonly mapper: Record<string, ViewForSiderFieldsFragment[]>

  // private readonly views: ViewForSiderFieldsFragment[]

  public constructor(views: ViewForSiderFieldsFragment[]) {
    // this.views = views
    this.mapper = views.groupBy((x) => x.viewGroupId ?? '').data // 空文字のとき、親グループなし
  }

  public searchViewsByViewGroupId(viewGroupId: string): ViewForSiderFieldsFragment[] {
    return this.mapper[viewGroupId] ?? []
  }
}

export function generateViewGroupTreeKey(viewGroupId: string) {
  return [`g`, viewGroupId].join('_')
}

export function convertFlatViewGroupsToAntdTree(
  viewGroups: ViewGroupFieldsFragment[],
  views: ViewForSiderFieldsFragment[],
): ExpandedAntdNode[] {
  // uniqueは必要ないが、念の為やっておく
  const viewGroupTrees = createViewGroupTree(viewGroups.uniqueBy((x) => x.id)) // まずViewGroupsのツリーを作る
  const searcher = new ViewSearcher(views.uniqueBy((x) => x.id))
  return [
    // viewGroupによるツリー
    // ViewGroupsのツリーに対してviewsを付与しつつ、antdのツリー構造に変換
    ...viewGroupTrees.sortBy((x) => x.rank).map((x) => convertViewGroupTreeToAntdTree(x, searcher)),
    // viewによるツリー（ルートしかないツリー、すなわち単体ノード）
    ...views
      .filter((view) => isNull(view.viewGroupId))
      .sortBy((x) => x.rank)
      .map((view) => viewToNode(view)),
  ]
}

function createViewGroupTree(viewGroups: ViewGroupFieldsFragment[]): ViewGroupTree[] {
  // ルートとそれ以外に分割してから再帰する
  const [roots, children] = viewGroups
    .map(
      (x): ViewGroupTree => ({
        ...x,
        children: [],
      }),
    )
    .partition((x) => isNull(x.viewGroupId))
  return createViewGroupTreeRec(roots, children)
}

function convertViewGroupTreeToAntdTree(viewGroupTree: ViewGroupTree, searcher: ViewSearcher): ExpandedAntdNode {
  const views = searcher.searchViewsByViewGroupId(viewGroupTree.id)
  return {
    item: {
      ...viewGroupTree,
      // Omitしているが、Viewの__typenameが紛れ込むことがあるので明示的に指定する
      __typename: 'ViewGroup',
    },
    key: generateViewGroupTreeKey(viewGroupTree.id),
    title: getTitle(viewGroupTree),
    searchKey: viewGroupTree.name,
    children: [
      ...viewGroupTree.children.sortBy((x) => x.rank).map((child) => convertViewGroupTreeToAntdTree(child, searcher)),
      ...views.sortBy((x) => x.rank).map((view): ExpandedAntdNode => viewToNode(view)),
    ],
    icon: getIcon(viewGroupTree.emoji),
  }
}

function viewToNode(view: ViewForSiderFieldsFragment): ExpandedAntdNode {
  return {
    item: {
      ...view,
      // Omitしているが、Viewの__typenameが紛れ込むことがあるので明示的に指定する
      __typename: 'ViewForSider',
    },
    key: view.id,
    title: getTitle(view),
    searchKey: view.name,
    isLeaf: true,
    children: [],
    icon: getIcon(view.emoji, view.type),
  }
}

function getTitle(view: ViewForSiderFieldsFragment | ViewGroupFieldsFragment) {
  const title = `${t(`タイトル`)}: ${view.name}
${t(`作成日時`)}: ${t(`{{datetime, datetimestr}}`, { datetime: view.createdAt })}
${t(`作成者`)}: ${view.createdBy?.name ?? '-'}
${t(`最終更新日時`)}: ${t(`{{datetime, datetimestr}}`, { datetime: view.updatedAt })}
${t(`最終更新者`)}: ${view.updatedBy?.name ?? '-'}`

  return (
    <span title={title}>
      {view.name}
      {view.createdBy && <span className="ml-1 text-xs opacity-50">{view.createdBy.name}</span>}
    </span>
  )
}

function getIcon(emoji: string | null | undefined, viewConfigType?: ViewConfig['type']): JSX.Element | undefined {
  if (isNull(emoji) || !isPresent(emoji)) {
    return undefined
  }

  return (
    <span
      style={{
        paddingRight: 5,
      }}
    >
      {emoji}
    </span>
  )
}

function createViewGroupTreeRec(rootNodes: ViewGroupTree[], candidateNodes: ViewGroupTree[]): ViewGroupTree[] {
  if (candidateNodes.length === 0) {
    return rootNodes
  }

  const restNodes = []
  for (const candidateNode of candidateNodes) {
    const parentId = candidateNode.viewGroupId
    if (isNull(parentId)) {
      continue
    }

    const parentNode = dfsForViewGroupTree(rootNodes, parentId)?.node
    if (parentNode === undefined) {
      restNodes.push(candidateNode)
    } else {
      parentNode.children ??= []
      parentNode.children.push(candidateNode)
    }
  }
  if (candidateNodes.length === restNodes.length) {
    return rootNodes
  }
  return createViewGroupTreeRec(rootNodes, restNodes)
}

function dfsForViewGroupTree(nodes: ViewGroupTree[], id: string | number): DfsResult | undefined {
  // 全ての子ツリーについて探索
  for (let index = 0; index < nodes.length; index++) {
    // 対象のキーだったらcallbackを発火
    if (nodes[index]?.id === id) {
      return {
        node: nodes[index]!,
        parentIndex: index,
        parentNodes: nodes,
      }
    }
    const children = nodes[index]?.children

    // 深さ優先探索
    if (children === undefined) {
      continue
    }

    const result = dfsForViewGroupTree(children, id)
    if (result !== undefined) {
      return result
    }
  }
  return undefined
}
