import { Either, pipe } from 'effect'
import type { SetRecoilState } from 'recoil'

import { feedbackMessagesAtom } from '../../../recoil/records/atoms'
import { upsertViewRecordsMutation } from '../../../recoil/records/mutations/upsertViewRecordsMutation'
import { getPasteRelatedData, sliceCellValuesInText } from '../../action/copy'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import type { RSheetsCursor } from '../../types/CursorTypes'
import { generateRecordChangesByCopiedValues } from './changeAreaMutation'

// TODO: mutationにする
export const deleteCellValues = (
  data: RSheetRecordNode[],
  columns: Array<RSheetColumn<RSheetRecordNode>>,
  cursor: RSheetsCursor,
  set: SetRecoilState,
) => {
  const pasteRelatedData = getPasteRelatedData(data, columns, cursor)
  pipe(
    sliceCellValuesInText(pasteRelatedData),
    Either.map((values) =>
      generateRecordChangesByCopiedValues({
        copiedValues: values,
        data,
        columns,
        cursor,
        maxRowIndex: data.length,
        options: { asDelete: true },
        feedback(message) {
          set(feedbackMessagesAtom, [message])
        },
      }),
    ),
    Either.map((recordChanges) => {
      set(upsertViewRecordsMutation, { recordChanges })
    }),
  )
}
