import { isSome } from '@salescore/buff-common'
import { logger, mutation, wrapKeyboardEvent } from '@salescore/frontend-common'
import { Either, pipe } from 'effect'
import { t } from 'i18next'
import type { KeyboardEvent } from 'react'
import { useSetRecoilState } from 'recoil'

import { feedbackMessagesAtom, recordsAtom } from '../../../recoil/records/atoms'
import { backHistoryMutation, forwardHistoryMutation } from '../../../recoil/records/mutations/changesMutation'
import { copy } from '../../action/copy'
import type { RSheetsCursor } from '../../types/CursorTypes'
import { getRecordNodeAttributeValue } from '../../util/innerRowRelated'
import { openSheetThreadsFormAtom } from '../atoms'
import { cursorModel } from '../models/cursorModel'
import { columnsModel } from '../models/propModels'
import { columnRelatedSelector } from '../selectors/columnRelatedSelector'
import { rowsRelatedSelector } from '../selectors/rowsRelatedSelector'
import { clickCurrentCursorMutation } from './cursor/clickCurrentCursorMutation'
import { moveCursorMutation, selectAllCellMutation } from './cursor/moveCursor'
import { deleteCellValues } from './deleteCellValues'
import { clearCursorMutation, moveToEdgeMutation } from './useCursorMutation'

const handleKeyDownMutation = mutation({
  key: `rsheet/handleKeyDownMutation`,
  // eslint-disable-next-line complexity
  set({ get, set }, event: KeyboardEvent<HTMLDivElement>) {
    const rows = get(recordsAtom)
    const columns = get(columnsModel)
    const cursor = get(cursorModel)
    const rowsRelated = get(rowsRelatedSelector)
    const columnRelated = get(columnRelatedSelector)
    const openSheetThreadsForm = get(openSheetThreadsFormAtom)
    const data = rows
    if (cursor?.editing?.isEditing === true || openSheetThreadsForm.length > 0) {
      return
    }

    const wrapedEvent = wrapKeyboardEvent(event)
    logger.debug(`handleKeyDownMutation`, event.key, wrapedEvent) // 2022/05 windowsでの不具合対応
    const column = cursor?.main.columnIndex === undefined ? undefined : columns[cursor.main.columnIndex]

    const moveCursorDefaultArgument = {
      columnDiff: 0,
      rowDiff: 0,
      data,
      columns,
      maxColumnIndex: columnRelated.maxColumnIndex,
      maxRowIndex: rowsRelated.maxRowIndex,
      expand: event.shiftKey,
    }

    switch (event.key) {
      case 'Enter': {
        if (column === undefined) {
          return
        }
        // TODO: 要仕様検討。書き込み禁止列は新規行追加できないようにするか？
        // 何にしろ、丁寧にやるなら新規行追加はしつつ、編集モードにしない対応が必要
        if (column.readonly === true) {
          set(feedbackMessagesAtom, [
            {
              message: t('この列は書き込み禁止です'),
              type: 'warn' as const,
            },
          ])
          return
        }
        if (wrapedEvent.commandKey && cursor !== undefined) {
          if (column.nodeType === 'children' || column.nodeType === 'child') {
            // asyncで実装
            event.preventDefault()
            return
          }
          if (column.node.path.length <= 1) {
            // asyncで実装している
            event.preventDefault()
            return
          }
        } else {
          set(clickCurrentCursorMutation, {})
        }
        event.preventDefault()
        return
      }
      case 'Escape': {
        set(clearCursorMutation, undefined)
        event.preventDefault()
        return
      }
      case ' ': {
        // space
        // 基本的にエンターと同じ挙動をさせるが、一部異なる挙動をさせる
        const value = getRecordNodeAttributeValue(data, column, cursor?.main)
        if (column?.metaType === 'url' && typeof value === 'string') {
          window.open(value)
        } else if (column?.metaType === 'phone_number' && typeof value === 'string') {
          window.open(`tel:${value}`)
        } else if (column?.metaType === 'email' && typeof value === 'string') {
          window.open(`mailto:${value}`)
        } else {
          set(clickCurrentCursorMutation, {})
        }
        event.preventDefault()
        return
      }
      case 'Backspace': {
        if (isSome(cursor)) {
          deleteCellValues(data, columns, cursor, set)
          event.preventDefault()
        }
        return
      }
      case 'Delete': {
        if (isSome(cursor)) {
          deleteCellValues(data, columns, cursor, set)
          event.preventDefault()
        }
        return
      }
      case 'ArrowLeft': {
        if (wrapedEvent.commandKey) {
          set(moveToEdgeMutation, { type: 'left' as const, expand: event.shiftKey })
        } else {
          set(moveCursorMutation, {
            ...moveCursorDefaultArgument,
            columnDiff: -1,
          })
        }
        event.preventDefault()
        return
      }
      case 'ArrowRight': {
        if (wrapedEvent.commandKey) {
          set(moveToEdgeMutation, { type: 'right' as const, expand: event.shiftKey })
        } else {
          set(moveCursorMutation, {
            ...moveCursorDefaultArgument,
            columnDiff: 1,
          })
        }
        event.preventDefault()
        return
      }
      case 'ArrowDown': {
        if (wrapedEvent.commandKey) {
          set(moveToEdgeMutation, { type: 'down' as const, expand: event.shiftKey })
        } else {
          set(moveCursorMutation, {
            ...moveCursorDefaultArgument,
            rowDiff: 1,
          })
        }
        event.preventDefault()
        return
      }
      case 'ArrowUp': {
        if (wrapedEvent.commandKey) {
          set(moveToEdgeMutation, { type: 'up' as const, expand: event.shiftKey })
        } else {
          set(moveCursorMutation, {
            ...moveCursorDefaultArgument,
            rowDiff: -1,
          })
        }
        event.preventDefault()
        return
      }
      case 'Tab': {
        if (event.shiftKey) {
          set(moveCursorMutation, {
            ...moveCursorDefaultArgument,
            columnDiff: -1,
            expand: false, // Shift+Tabと競合しないよう、常にfalse
          })
        } else {
          set(moveCursorMutation, {
            ...moveCursorDefaultArgument,
            columnDiff: 1,
            expand: false, // Shift+Tabと競合しないよう、常にfalse
          })
        }
        event.preventDefault()
        return
      }
      case 'c': {
        if (wrapedEvent.commandKey) {
          if (cursor === undefined) {
            return
          }
          pipe(
            copy(data, columns, cursor),
            Either.map(({ text, json }): void => {
              logger.debug(text)
              const textBlob = new Blob([text], { type: 'text/plain' })
              const jsonBlob = new Blob([JSON.stringify(json)], { type: 'application/json' })
              const clipboardItem = new ClipboardItem({
                [textBlob.type]: textBlob,
                [`web ${jsonBlob.type}`]: jsonBlob,
              })
              void navigator.clipboard.write([clipboardItem]).catch((error: unknown) => {
                if (error instanceof Error) {
                  logger.warn('SHEET_COPY', 'Failed to copy', { error: error.message })
                } else {
                  logger.warn('SHEET_COPY', 'Failed to copy for unknown reason')
                }
              })
            }),
          )
          set(cursorModel, (cursor): RSheetsCursor | undefined => {
            if (cursor === undefined) {
              return cursor
            }
            return {
              ...cursor,
              copying: {
                main: cursor.main,
                expand: cursor.expand,
                visible: true,
              },
            }
          })
          event.preventDefault()
          return
        }
        break
      }
      // case 'v':
      //   if (wrapedEvent.commandKey) {
      //     await paste(data, columns, cursor, set)
      //     event.preventDefault()
      //     return
      //   }
      //   break
      case 'z': {
        if (wrapedEvent.commandKey && wrapedEvent.shiftKey) {
          set(forwardHistoryMutation, undefined)
          event.preventDefault()
          return
        }
        if (wrapedEvent.commandKey) {
          set(backHistoryMutation, 1)
          event.preventDefault()
          return
        }
        break
      }
      case 'y': {
        if (wrapedEvent.commandKey) {
          set(forwardHistoryMutation, undefined)
          event.preventDefault()
          return
        }
        break
      }
      case 's': {
        if (wrapedEvent.commandKey) {
          // void recordsAndChangesMutation.onSave() // TODO: async setはrecoilで未実装なため、mutationとして実装できない。ここだけuseAsyncHandleKeyDownで実装している。
          event.preventDefault()
          return
        }
        break
      }
      case 'a': {
        // 全てのセルを選択
        if (wrapedEvent.commandKey) {
          set(selectAllCellMutation, undefined)
          event.preventDefault()
          return
        }
        break
      }
    }

    if (wrapedEvent.macCtrlKey) {
      // emacs key binding
      switch (event.key) {
        case 'a': {
          set(moveToEdgeMutation, { type: 'left' as const, expand: event.shiftKey })
          event.preventDefault()
          return
        }
        case 'b': {
          set(moveCursorMutation, {
            ...moveCursorDefaultArgument,
            columnDiff: -1,
          })
          event.preventDefault()
          return
        }
        case 'e': {
          set(moveToEdgeMutation, { type: 'right' as const, expand: event.shiftKey })
          event.preventDefault()
          return
        }
        case 'f': {
          set(moveCursorMutation, {
            ...moveCursorDefaultArgument,
            columnDiff: 1,
          })
          event.preventDefault()
          return
        }
        case 'v': {
          set(moveCursorMutation, {
            ...moveCursorDefaultArgument,
            rowDiff: rowsRelated.maxRowIndex,
          })
          event.preventDefault()
          return
        }
        case 'n': {
          set(moveCursorMutation, {
            ...moveCursorDefaultArgument,
            rowDiff: 1,
          })
          event.preventDefault()
          return
        }
        case 'p': {
          set(moveCursorMutation, {
            ...moveCursorDefaultArgument,
            rowDiff: -1,
          })
          event.preventDefault()
          return
        }
      }
    }

    if (cursor?.main === undefined || column === undefined) {
      return
    }

    if (wrapedEvent.commandKey || event.shiftKey) {
      return
    }

    // 何らかの文字が入力されたとき、スプレッドシートのように入力開始させる
    // TODO: いったん実装したが、日本語入力に対応できていないことなどが微妙。おそらく、Input要素をhideしておくような形でないと実装できない
    if (event.key.length === 1 && ((event.key >= 'a' && event.key <= 'z') || event.key >= '0' || event.key <= '9')) {
      set(clickCurrentCursorMutation, { pressedKey: event.key })
      if (column.readonly === true) {
        return
      }
      // TODO
      // set(upsertSheetRowMutation, {
      //   value: event.key,
      //   rowIndex: cursor.main.rowIndex,
      //   innerRowIndex: cursor.main.innerRowIndex,
      //   column,
      // })
      event.preventDefault()
      return
    }

    // 特に必要ないが、unnecessary returnと言われないように定義しておく
    return false
  },
})

export const useHandleKeyDownMutation = () => useSetRecoilState(handleKeyDownMutation)
