import { isNull } from '@salescore/buff-common'
import type { ViewKpiAppearance } from '@salescore/core'
import { Checkbox, Form, Select } from 'antd'
import { t } from 'i18next'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'

type Options<T> = Array<{ label: string; value: T }>
const GOAL_TYPE: Options<ViewKpiAppearance['aggregationFunction']> = [
  {
    label: t(`合計`),
    value: 'sum',
  },
  {
    label: t(`平均`),
    value: 'avg',
  },
]

const ABBREVIATION_TYPES: Options<ViewKpiAppearance['abbreviationType']> = [
  {
    label: t(`自動`),
    value: 'automatic',
  },
  {
    label: t(`桁を省略しない`),
    value: `noAbbreviation`,
  },
  {
    label: t(`小数点以下を省略`),
    value: `abbreviateDecimal`,
  },
  {
    label: t(`小数第2位以下を省略`),
    value: `abbreviateSecondDecimalPlace`,
  },
  {
    label: t(`小数第3位以下を省略`),
    value: `abbreviateThirdDecimalPlace`,
  },
  {
    label: t(`小数第4位以下を省略`),
    value: `abbreviateFourthDecimalPlace`,
  },
]

export const DECIMALPLACE_TYPES: Options<ViewKpiAppearance['decimalPlaceType']> = [
  {
    label: t(`自動`),
    value: 'automatic',
  },
  {
    label: t(`桁を省略しない`),
    value: `noDecimalPlace`,
  },
  {
    label: t(`小数点以下を省略`),
    value: `firstDecimalPlace`,
  },
  {
    label: t(`小数第2位以下を省略`),
    value: `secondDecimalPlace`,
  },
  {
    label: t(`小数第3位以下を省略`),
    value: `thirdDecimalPlace`,
  },
  {
    label: t(`小数第4位以下を省略`),
    value: `fourthDecimalPlace`,
  },
]

export const UNIT_TYPES: Options<ViewKpiAppearance['unitType']> = [
  {
    label: t(`単位を指定しない`),
    value: `noUnit`,
  },
  {
    label: t(`単位：千`),
    value: `unitThousand`,
  },
  {
    label: t(`単位：万`),
    value: `unitTenThousand`,
  },
  {
    label: t(`単位：百万`),
    value: `unitMillion`,
  },
  {
    label: t(`単位：億`),
    value: `unitHandredMillion`,
  },
]

const KPI_ACHIEVEMENT_STATUS_CALCULATION_TYPE_ENUM: Options<ViewKpiAppearance['achievementStatusCalculationType']> = [
  {
    label: t(`実績が目標以上のとき`),
    value: `greaterThanOrEqual`,
  },
  {
    label: t(`実績が目標以下のとき`),
    value: `lessThanOrEqual`,
  },
]

const KPI_ACHIEVEMENT_STATUS_TARGET_VALUE_ENUM: Options<ViewKpiAppearance['achievementStatusTargetValueType']> = [
  {
    label: t(`目標の標準進捗`),
    value: `averageProgress`,
  },
  {
    label: t(`目標`),
    value: `goal`,
  },
  {
    label: t(`ゼロ`),
    value: `zero`,
  },
]

const DISPLAY_FORMAT_TYPES: Options<ViewKpiAppearance['displayFormat']> = [
  {
    label: t(`パーセント(%が表示されます)`),
    value: `percent`,
  },
  {
    label: t(`円 (¥が表示されます)`),
    value: `yen`,
  },
  {
    label: t(`通常 (デフォルト)`),
    value: `none`,
  },
]

export const KpiFormDetailFormItems = () => {
  const { kpi, setKpi, setFormModified } = useKpiFormSelectors()

  return (
    <>
      {/* <Collapse defaultActiveKey={[]} className="mb-4"> */}
      {/* <Collapse.Panel header="高度な設定" key="detail"> */}
      <Form.Item className="mt-4" label={t(`移行率を表示`)}>
        <Checkbox
          checked={kpi.showTransition ?? false}
          value={kpi.showTransition ?? false}
          onChange={(value) => {
            setKpi({ showTransition: value.target.checked })
            setFormModified(true)
          }}
        />
      </Form.Item>
      <Form.Item label={t(`目標の集計方法`)}>
        <Select
          value={kpi.aggregationFunction ?? GOAL_TYPE[0]!.value}
          options={GOAL_TYPE}
          onChange={(value) => {
            setKpi({ aggregationFunction: value })
            setFormModified(true)
          }}
        />
      </Form.Item>
      <Form.Item label={t(`目標達成の定義`)}>
        <Select
          value={kpi.achievementStatusCalculationType ?? KPI_ACHIEVEMENT_STATUS_CALCULATION_TYPE_ENUM[0]!.value}
          options={KPI_ACHIEVEMENT_STATUS_CALCULATION_TYPE_ENUM}
          onChange={(value) => {
            setKpi({ achievementStatusCalculationType: value })
            setFormModified(true)
          }}
        />
      </Form.Item>
      <Form.Item label={t(`目標達成の対象`)}>
        <Select
          value={kpi.achievementStatusTargetValueType ?? KPI_ACHIEVEMENT_STATUS_TARGET_VALUE_ENUM[0]!.value}
          options={KPI_ACHIEVEMENT_STATUS_TARGET_VALUE_ENUM}
          onChange={(value) => {
            setKpi({ achievementStatusTargetValueType: value })
            setFormModified(true)
          }}
        />
      </Form.Item>
      <Form.Item label={t(`表示形式`)}>
        <Select
          value={kpi.displayFormat ?? 'none'}
          allowClear
          options={DISPLAY_FORMAT_TYPES}
          onChange={(value) => {
            setKpi({ displayFormat: value })
            setFormModified(true)
          }}
        />
      </Form.Item>
      <Form.Item label={t(`単位`)}>
        <Select
          value={kpi.unitType ?? convertAbbreviationTypeToUnitType(kpi.abbreviationType)}
          options={UNIT_TYPES}
          onChange={(value) => {
            setKpi({ unitType: value })
            setFormModified(true)
          }}
        />
      </Form.Item>
      <Form.Item label={t(`桁の省略`)}>
        <Select
          value={kpi.decimalPlaceType ?? convertAbbreviationTypeToDecimalPlaceType(kpi.abbreviationType)}
          options={DECIMALPLACE_TYPES}
          onChange={(value) => {
            setKpi({ decimalPlaceType: value })
            setFormModified(true)
          }}
        />
      </Form.Item>
      {/* </Collapse.Panel> */}
      {/* </Collapse> */}
    </>
  )
}

// データ移行時の互換性対応のために必要
function convertAbbreviationTypeToDecimalPlaceType(
  abbreviationType: ViewKpiAppearance['abbreviationType'],
): ViewKpiAppearance['decimalPlaceType'] {
  if (isNull(abbreviationType)) {
    return DECIMALPLACE_TYPES[0]!.value
  }

  switch (abbreviationType) {
    case 'automatic': {
      return `automatic`
    }
    case 'abbreviateDecimal': {
      return `firstDecimalPlace`
    }
    case 'abbreviateSecondDecimalPlace': {
      return `secondDecimalPlace`
    }
    case 'abbreviateThirdDecimalPlace': {
      return `thirdDecimalPlace`
    }
    case 'abbreviateFourthDecimalPlace': {
      return `fourthDecimalPlace`
    }
    case 'noAbbreviation': {
      return `noDecimalPlace`
    }
    case 'abbreviateThousand':
    case 'abbreviateTenThousand':
    case 'abbreviateMillion':
    case 'abbreviateHandredMillion': {
      return 'automatic'
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const a: never = abbreviationType
      return DECIMALPLACE_TYPES[0]!.value
    }
  }
}

function convertAbbreviationTypeToUnitType(
  abbreviationType: ViewKpiAppearance['abbreviationType'],
): ViewKpiAppearance['unitType'] {
  if (isNull(abbreviationType)) {
    return UNIT_TYPES[0]!.value
  }

  switch (abbreviationType) {
    case 'automatic':
    case 'abbreviateDecimal':
    case 'abbreviateSecondDecimalPlace':
    case 'abbreviateThirdDecimalPlace':
    case 'abbreviateFourthDecimalPlace':
    case 'noAbbreviation': {
      return UNIT_TYPES[0]!.value
    }
    case 'abbreviateThousand': {
      return 'unitThousand'
    }
    case 'abbreviateTenThousand': {
      return 'unitTenThousand'
    }
    case 'abbreviateMillion': {
      return 'unitMillion'
    }
    case 'abbreviateHandredMillion': {
      return 'unitHandredMillion'
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const a: never = abbreviationType
      return UNIT_TYPES[0]!.value
    }
  }
}
