import { FilterOutlined } from '@ant-design/icons'
import { flatNodes, type ViewConfigField, type ViewConfigFilterNode, type ViewConfigSheet } from '@salescore/core'
import { addModelIdProperty, useBooleanState } from '@salescore/frontend-common'
import { Col, Row, Space, Switch } from 'antd'
import { t } from 'i18next'

import type { ViewConfigFilterNodeForForm } from '../../../components/view_ui/ViewUISheetWithNavigation/SheetNavigation/filter/common'
import {
  applyAdvancedSettingsFlag,
  ViewFilterTreeForm,
} from '../../../components/view_ui/ViewUISheetWithNavigation/SheetNavigation/filter/ViewFilterTreeForm'
import { useConfigSheetSelector } from '../../../recoil/view/selectors/configSheetSelector'

export function MeasureFilterForm({
  sheetConfig,
  originalField,
  onChange,
  initialFilterTree,
}: {
  sheetConfig: ViewConfigSheet
  originalField: ViewConfigField
  onChange: (filterTree: ViewConfigFilterNode | undefined) => void
  initialFilterTree: ViewConfigFilterNodeForForm | undefined
}) {
  const { getModel } = useConfigSheetSelector()
  const isAdvancedModeState = useBooleanState(
    (initialFilterTree?.children ?? []).isPresent() || initialFilterTree?.logicalOperator === 'or',
  )
  const flattenNodes = flatNodes(sheetConfig.tree)
  const getNode = (nodeName: string) => flattenNodes.find((x) => x.name === nodeName)
  const originalNode = getNode(originalField.property.nodeName)
  const originalModel = getModel(originalField.property.modelName)
  const properitesWithNode = (sheetConfig.fields ?? [])

    .map((field) => {
      const { nodeName, modelName, propertyName } = field.property
      if (nodeName !== originalNode?.name) {
        // 重複レコードの回避処理周りの都合で、いったん同じノードのフィールドのみに限定する
        // この処理をなくすためには、pruneの処理と、case whenをやめてfilterに統合するような処理が必要
        return
      }
      const node = getNode(nodeName)
      const model = getModel(modelName)
      const property = model?.properties.find((x) => x.name === propertyName)
      if (node === undefined || model === undefined || property === undefined) {
        return
      }
      return {
        node,
        model,
        property,
      }
    })
    .compact()
  const properitesAndOriginalIdWithNode = addModelIdProperty(
    { node: originalNode, model: originalModel },
    properitesWithNode,
  )

  return (
    <div>
      <Row justify="space-between" className="mb-4">
        <Col>
          <Space className="text-md font-normal">
            <FilterOutlined />
            <span>{t(`集計条件`)}</span>
          </Space>
        </Col>
        <Col>
          <Space>
            {t(`高度なフィルタ条件`)}
            <Switch
              checked={isAdvancedModeState.isTrue}
              onChange={(value) => {
                if (value) {
                  isAdvancedModeState.setTrue()
                } else {
                  isAdvancedModeState.setFalse()
                }
              }}
            />
          </Space>
        </Col>
      </Row>
      <ViewFilterTreeForm
        filterTree={initialFilterTree}
        // 内部でDividerWithLogicalOperatorにclass: ant-form-horizontalがあたってしまうことがあるためのWorkaround
        inNestedForm
        propertiesWithNode={properitesAndOriginalIdWithNode}
        isAdvancedMode={isAdvancedModeState.isTrue}
        onChange={(filterTree) => {
          const newFilterTree = applyAdvancedSettingsFlag(isAdvancedModeState.isTrue, filterTree)
          onChange(newFilterTree)
        }}
      />
    </div>
  )
}
